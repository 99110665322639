import React, { FC, memo, useEffect } from "react"
import css from "./ParticipationPrerequisites.module.css"
import {
    GetParticipationPrerequisitesByCourseTypeIdQuery,
    useGetParticipationPrerequisitesByCourseTypeIdQuery,
} from "../../../../../generated/graphql"
import LoadingContent from "../../../../../views/LoadingContent/LoadingContent"
import { Checkbox, Space } from "antd"
import { CheckCircleTwoTone, CloseCircleTwoTone, InfoCircleTwoTone } from "@ant-design/icons"
import { green, red } from "@ant-design/colors"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import classnames from "classnames"
import SummarySection from "../../../../../views/SummarySection/SummarySection"

type ElementType<T> = T extends (infer U)[] ? U : never

type QueryResultElement = ElementType<
    GetParticipationPrerequisitesByCourseTypeIdQuery["participationPrerequisitesByCourseTypeId"]
>

type ParticipationPrerequisitesProps = {
    courseTypeId: string
    setCheckedOptions: (checkedOptions: { [id: string]: Array<string> }) => void
    checkedOptions: { [id: string]: Array<string> }
    setRequermentGroupsMet: (requermentGroupsMet: { [id: string]: boolean }) => void
    requermentGroupsMet: { [id: string]: boolean }
    sectionGap: number
}

const ParticipationPrerequisites: FC<ParticipationPrerequisitesProps> = ({
    courseTypeId,
    setCheckedOptions,
    checkedOptions,
    setRequermentGroupsMet,
    requermentGroupsMet,
    sectionGap,
}) => {
    // GET PARTICIPATION PREREQUISITES BY COURSE TYPE ID -------------------------------------------------------------
    const { data, loading, error } = useGetParticipationPrerequisitesByCourseTypeIdQuery({
        skip: !courseTypeId,
        variables: { courseTypeId },
    })

    if (error) console.log("participationPrerequisitesByCourseTypeId error", error)

    const prerequisites: Array<QueryResultElement> | undefined = data?.participationPrerequisitesByCourseTypeId

    // RENDER -------------------------------------------------------------------------------------------------------

    if (loading) return <LoadingContent />

    const Prerequisite = ({ prerequisite }: { prerequisite: QueryResultElement }) => {
        // const [checkedOptions, setCheckedOptions] = React.useState<Array<string>>([])
        const checkBoxCount = prerequisite.optionalInputFields?.length
        const checkedCount = checkedOptions[prerequisite.id]?.length ?? 0
        const minCheckCount = prerequisite.minCheckCount ?? 0
        const requirementsMet = checkedCount >= minCheckCount
        const footerText = `Bitte wähle mindestens ${minCheckCount} der ${checkBoxCount} Bedingungen`
        const statusColor = requirementsMet ? green[2] : red[2]
        const statusIcon = (intencity?: number, style?: React.CSSProperties) =>
            requirementsMet ? (
                <CheckCircleTwoTone style={style} twoToneColor={green[intencity ?? 2]} />
            ) : (
                <CloseCircleTwoTone style={style} twoToneColor={red[intencity ?? 2]} />
            )

        useEffect(() => {
            const newRequermentGroupsMet = { ...requermentGroupsMet }
            if (prerequisite.id in newRequermentGroupsMet) {
                newRequermentGroupsMet[prerequisite.id] = requirementsMet
                if (requermentGroupsMet[prerequisite.id] === requirementsMet) {
                    return
                } else {
                    newRequermentGroupsMet[prerequisite.id] = requirementsMet
                    setRequermentGroupsMet(newRequermentGroupsMet)
                }
            } else {
                newRequermentGroupsMet[prerequisite.id] = requirementsMet
                setRequermentGroupsMet(newRequermentGroupsMet)
            }
        }, [prerequisite.groupLabel, prerequisite.id, requirementsMet])

        const handleCheckboxChange = (event: CheckboxChangeEvent, optionValue: string, groupId: string) => {
            if (event.target.checked) {
                if (checkedOptions[groupId]) {
                    setCheckedOptions({ ...checkedOptions, [groupId]: [...checkedOptions[groupId], optionValue] })
                } else {
                    setCheckedOptions({ ...checkedOptions, [groupId]: [optionValue] })
                }
            } else {
                if (checkedOptions[groupId]) {
                    setCheckedOptions({
                        ...checkedOptions,
                        [groupId]: checkedOptions[groupId].filter((option) => option !== optionValue),
                    })
                } else {
                    setCheckedOptions({ ...checkedOptions, [groupId]: [] })
                }
            }
        }

        return (
            <div
                key={prerequisite.id}
                className={css.prerequisiteContainer}
                style={{ border: requirementsMet ? undefined : `1px solid ${statusColor}` }}
            >
                <h4 className={css.headline}>
                    <div className={css.infoIcon}>{statusIcon(3, { fontSize: "1.1em" })}</div>
                    {prerequisite.groupLabel}
                </h4>
                <div className={css.groupDescription}>
                    <InfoCircleTwoTone className={css.infoIcon} />
                    {prerequisite.groupDescription}
                </div>
                {prerequisite.optionalInputFields?.map((optionalInputField) => {
                    return (
                        <div className={css.inputCheckbox}>
                            <Checkbox
                                className={css.labelUnderlined}
                                onChange={(e) => handleCheckboxChange(e, optionalInputField.label, prerequisite.id)}
                                // checked={checkedOptions.includes(optionalInputField.label)}
                                // checked={checkedOptions[prerequisite.id].includes(optionalInputField.label)}
                                checked={checkedOptions[prerequisite.id]?.includes(optionalInputField.label)}
                            >
                                {optionalInputField.label}
                            </Checkbox>
                            <div className={css.checkBoxDescription}>{optionalInputField.description}</div>
                        </div>
                    )
                })}
                <div
                    className={classnames(checkedCount >= minCheckCount ? `${css.fadeOut} ${css.footer}` : css.footer)}
                    style={{ backgroundColor: statusColor }}
                >
                    <div className={css.infoIcon}>{statusIcon()}</div>
                    {footerText}
                </div>
            </div>
        )
    }

    const renderContent = () => {
        return (
            <Space direction='vertical' className={css.prerequisites}>
                {prerequisites?.map((prerequisite) => {
                    return <Prerequisite prerequisite={prerequisite} />
                })}
            </Space>
        )
    }

    if (!prerequisites?.length || prerequisites?.length === 0) {
        return null
    }

    return <SummarySection style={{ paddingTop: sectionGap }} title='Teilnahmebedingungen' content={renderContent()} />

    // return (
    //     <Space direction='vertical' className={css.prerequisites}>
    //         {prerequisites?.map((prerequisite) => {
    //             return <Prerequisite prerequisite={prerequisite} />
    //         })}
    //     </Space>
    // )
}

export default memo(ParticipationPrerequisites)
