import { Select } from "antd"
import FormItem from "antd/lib/form/FormItem"
import React, { CSSProperties, FC, memo, useEffect, useState } from "react"
import {
    GetBadgesQuery,
    GetUpdatedBookerQuery,
    NewBookingInput,
    OptionalInputFieldsInput,
    SettingEndUserApp,
    SettingEndUserAppQuery,
    useGetBadgesQuery,
    useGetCompanyQuery,
} from "../../../../generated/graphql"
import { useMediaSize } from "../../../../layouts/Main/GetWindowDimensions/GetWindowDimensions"
import LayoutForm from "../../../../views/LayoutForm/LayoutForm"

import { NewBookingInputKeys } from "../../BookingSummary/BookingSummary"
import css from "../StepSummary2.module.css"
import NewsLetterInput from "./OptionalInputFields/NewsLetterInput"
import AGBInput from "./OptionalInputFields/AGBInput"
import PrivacyStatementInput from "./OptionalInputFields/PrivacyStatementInput"

type OptionalInputFieldsProps = {
    booker: GetUpdatedBookerQuery["booker"]
    bookingData?: NewBookingInput
    updateBookingData: (key: NewBookingInputKeys, value: any) => void
    appSettings?: SettingEndUserAppQuery["settingEndUserAppPublic"]
    showAlert?: boolean
}

const OptionalInputFields: FC<OptionalInputFieldsProps> = ({
    booker,
    bookingData,
    updateBookingData,
    appSettings,
    showAlert,
}) => {
    const mediaSize = useMediaSize()
    const { media } = mediaSize
    const [optionalInputFields, setOptionalInputFields] = useState<SettingEndUserApp["optionalInputFields"]>([
        { name: "test" },
    ])
    const [additionalInfoJSON, setAdditionalInfoJSON] = useState<any>(JSON.parse(bookingData?.additionalInfo || "{}"))

    const { data: companyData } = useGetCompanyQuery()
    const companySettings = companyData?.companyPublic

    useEffect(() => {
        const updatedFields: Array<OptionalInputFieldsInput> = []
        appSettings?.optionalInputFields?.forEach((field: OptionalInputFieldsInput) => {
            if (field.enabeld) {
                updatedFields.push(field)
            }

            setOptionalInputFields(updatedFields)
        })
    }, [appSettings])

    const BadgesInput = ({
        field,
        bookingData,
        style,
    }: {
        field: OptionalInputFieldsInput
        bookingData?: NewBookingInput
        style?: CSSProperties
    }) => {
        const { data: companyBadgeData } = useGetBadgesQuery()
        const companybadges: GetBadgesQuery["badges"] = companyBadgeData?.badges ?? []

        const updateAdditionalInfo = (selectedBadge?: string) => {
            const updatedAdditionalInfoJSON = { ...additionalInfoJSON }

            if (field.label) {
                if (!selectedBadge) {
                    delete updatedAdditionalInfoJSON[field.label]
                } else if (field.label && selectedBadge) {
                    updatedAdditionalInfoJSON[field.label] = selectedBadge
                }

                updateBookingData("additionalInfo", JSON.stringify(updatedAdditionalInfoJSON))
                setAdditionalInfoJSON(updatedAdditionalInfoJSON)
            }
        }

        return (
            <FormItem label={field.label} style={style} key={field.name}>
                <Select
                    onSelect={(e: string) => updateAdditionalInfo(e)}
                    // value={selectedBadge}
                    value={additionalInfoJSON[field.label ?? ""]}
                    // placeholder={`Keine ${field.label}`}
                    placeholder={field.placeholder}
                >
                    <Select.Option /* key={null} */ value={undefined}>{`Keine ${field.label}`}</Select.Option>
                    {companybadges.map((badge) => {
                        return (
                            <Select.Option key={badge.id} value={badge.name}>
                                {badge.name}
                            </Select.Option>
                        )
                    })}
                </Select>
                <div className={css.inputDescription}>{field.description}</div>
            </FormItem>
        )
    }

    const CustomInput = ({
        field,
        bookingData,
        style,
    }: {
        field: OptionalInputFieldsInput
        bookingData?: NewBookingInput
        style?: CSSProperties
    }) => {
        const updateCustomFieldValues = (selectedValue?: string) => {
            const updatedAdditionalInfoJSON = { ...additionalInfoJSON }

            if (field.label) {
                if (!selectedValue) {
                    delete updatedAdditionalInfoJSON[field.label]
                } else if (field.label && selectedValue) {
                    updatedAdditionalInfoJSON[field.label] = selectedValue
                }

                updateBookingData("additionalInfo", JSON.stringify(updatedAdditionalInfoJSON))
                setAdditionalInfoJSON(updatedAdditionalInfoJSON)
            }
        }

        return (
            <FormItem label={field.label} style={style} key={field.name}>
                <Select
                    onSelect={updateCustomFieldValues}
                    // value={customSelection}
                    value={additionalInfoJSON[field.label ?? ""]}
                    placeholder={field.placeholder}
                >
                    <Select.Option key={null} value={undefined}>
                        {field.placeholder}
                    </Select.Option>
                    {field.options?.map((option) => {
                        return (
                            <Select.Option key={option.label} value={option.label}>
                                {option.label}
                            </Select.Option>
                        )
                    })}
                </Select>
                <div className={css.inputDescription}>{field.description}</div>
            </FormItem>
        )
    }

    const columns = media === "xs" ? 1 : 2

    const standardInputFields = ["newsletter", "agb", "privacyStatement"]

    return (
        <LayoutForm columns={columns}>
            {optionalInputFields?.map((field: OptionalInputFieldsInput) => {
                if (!field.name) return null
                if (field.name === "badges") {
                    return (
                        <div key={field.name}>
                            <BadgesInput field={field} bookingData={bookingData} style={{ gridColumn: "span 1" }} />
                        </div>
                    )
                } else if (!standardInputFields.includes(field.name)) {
                    // } else if (field.name === "newsletter") {
                    return (
                        <div key={field.name}>
                            <CustomInput field={field} bookingData={bookingData} style={{ gridColumn: "span 1" }} />
                        </div>
                    )
                } else {
                    return null
                }
            })}

            {optionalInputFields?.some((field) => field.name === "agb") && (
                <div key={"agb"}>
                    <AGBInput
                        field={optionalInputFields.filter((field) => field.name === "agb")[0]}
                        bookingData={bookingData}
                        updateBookingData={updateBookingData}
                        style={{ gridColumn: `span ${columns}` }}
                        showAlert={showAlert}
                        companySettings={companySettings}
                    />
                </div>
            )}
            {optionalInputFields?.some((field) => field.name === "privacyStatement") && (
                <div key={"privacyStatement"}>
                    <PrivacyStatementInput
                        field={optionalInputFields.filter((field) => field.name === "privacyStatement")[0]}
                        bookingData={bookingData}
                        updateBookingData={updateBookingData}
                        style={{ gridColumn: `span ${columns}` }}
                        showAlert={showAlert}
                        companySettings={companySettings}
                    />
                </div>
            )}
            {optionalInputFields?.some((field) => field.name === "newsletter") && (
                <div key={"newsLetter"}>
                    <NewsLetterInput
                        field={optionalInputFields.filter((field) => field.name === "newsletter")[0]}
                        booker={booker}
                        style={{ gridColumn: `span ${columns}` }}
                    />
                </div>
            )}
        </LayoutForm>
    )
}

export default memo(OptionalInputFields)
