import React, { FC, useEffect, useState } from "react"
import { Button } from "antd"
import { LogoutOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import { useLogoutMutation } from "../../generated/graphql"
import { Link } from "react-router-dom"
import { URL_BASE } from "../../models/url"

// const LOGOUT_USER = gql`
//     mutation Logout {
//         logoutUser
//     }
// `

type LogoutProps = {
    authDate?: Date
    style?: React.CSSProperties
    forceRefresh: () => void
}
const Logout: FC<LogoutProps> = ({ authDate, style, forceRefresh }) => {
    const history = useHistory()
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [logoutLoading, setLogoutLoading] = useState(false)
    // const [logoutUser] = useMutation(LOGOUT_USER)
    const [logoutUser] = useLogoutMutation()
    useEffect(() => {
        setIsLoggedIn(!!localStorage.getItem("loggedInUser"))
    }, [])
    useEffect(() => {
        return () => {
            setIsLoggedIn(true)
        }
    }, [authDate])
    const logout = async () => {
        setLogoutLoading(true)
        try {
            const { data } = await logoutUser()
            console.log("logoutUser data: ", data)
            setLogoutLoading(false)
            if (data?.logoutUser) {
                localStorage.removeItem("accessToken")
                localStorage.removeItem("refreshToken")
                localStorage.removeItem("booker")
                localStorage.removeItem("attendeeId")
                localStorage.removeItem("loggedInUser")
                localStorage.removeItem("bookingData")
                localStorage.removeItem("course")
                // localStorage.removeItem("course") THIS NEEDS TO BE REMOVED FOR RELOGING IN
                setIsLoggedIn(false)
                // history.replace(URL_BASE)
                // history.go(0)
                // history.replace(URL_BASE)
                forceRefresh()

                // history.goBack()
            }
        } catch (error) {
            setLogoutLoading(false)
            console.log("err====>>>>", error)
        }
    }

    return (
        <>
            {isLoggedIn && (
                <div style={style}>
                    <Link to={{ pathname: URL_BASE, state: { from: history.location } }}>
                        <Button size='small' type='link' onClick={logout} loading={logoutLoading}>
                            abmelden
                            <LogoutOutlined />
                        </Button>
                    </Link>
                </div>
            )}
        </>
    )
}
export default Logout

// testuser1@kursorganizer.com
