import { green, orange, red } from "@ant-design/colors"
import { Alert, Button, Calendar, Card, Descriptions, Space } from "antd"
import classnames from "classnames"
// import moment from "moment"
import moment from "moment-timezone"
import React, { FC, useContext, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
    CourseDetailsInput,
    GetCourseDetailsByIdPublicQuery,
    NewBookingInput,
    useGetCourseDetailsByIdPublicQuery,
} from "../../generated/graphql"
import Page from "../../layouts/Page/Page"
import { URL_BASE, URL_BOOKING_SUMMARY, URL_LOGIN, URL_RESULTS } from "../../models/url"
import { colorBlueLight, colorBlueSlight, colorDarkGrey, colorGrey } from "../../styles/colors"
import {
    renderCoursePrice,
    renderEntranceFee,
    renderFirstCourseLesson,
    renderFreePlaces,
    renderInstructors,
    renderMonthAsAge,
} from "../../views/TableColumnRenderers/TableColumnRenderers"
import css from "./CourseDetails.module.css"
import PanelCard from "../../views/PanelCard/PanelCard"
import { useMediaSize } from "../../layouts/Main/GetWindowDimensions/GetWindowDimensions"
import { margin } from "../../styles/layout"
import { SettingsContext } from "../../layouts/Main/MainLayout"

type CourseDetailsProps = { style?: React.CSSProperties }

const CourseDetails: FC<CourseDetailsProps> = ({ style }) => {
    const history = useHistory()
    const mediaSize = useMediaSize()
    const { media } = mediaSize
    const [courseId, setCourseId] = useState<string>()
    const params = useParams<{ id?: string }>()
    const isLoggedIn = !!localStorage.getItem("loggedInUser")

    useEffect(() => {
        if (params.id) {
            setCourseId(params.id)
        }
    }, [params])

    const { data: courseData, loading: loadingCourseData } = useGetCourseDetailsByIdPublicQuery({
        skip: !courseId,
        variables: { courseId: courseId ?? "" },
    })

    const course: GetCourseDetailsByIdPublicQuery["courseByIdPublic"] | undefined = courseData?.courseByIdPublic

    // SAVE SELECTED COURSE TO LOCAL STORAGE
    useEffect(() => {
        if (course) {
            localStorage.setItem("course", JSON.stringify(course))
            // console.log("LOCAL STORAGE: course saved: ", JSON.parse(localStorage.getItem("course") as string))
        }
    }, [course])

    // CUSTOMIZABLE DETAILS -------------------------------------------------------------------------------------------

    const defaultCourseCustomDetails: CourseDetailsInput = {
        age: { label: "Alter", enabled: false },
        calendar: { label: "Kurskalender", enabled: false },
        city: { label: "Ort", enabled: false },
        courseFee: { label: "Kursgebühr", enabled: false },
        courseInfos: { label: "Informationen zum Kurs", enabled: false },
        entranceFee: { label: "Badeintritt/Einheit", enabled: false },
        freePlaces: { label: "Freie Plätze", enabled: false },
        location: { label: "Kursort", enabled: false },
        instructor: { label: "Kursleiter", enabled: true },
        firstCourseLesson: { label: "Kursbeginn", enabled: true },
    }

    const [customCourseDetails, setCustomCourseDetails] = useState<CourseDetailsInput>(defaultCourseCustomDetails)

    const fetchedCourseCustomDetails: CourseDetailsInput = useContext(SettingsContext).courseDetails

    useEffect(() => {
        if (fetchedCourseCustomDetails) {
            setCustomCourseDetails(fetchedCourseCustomDetails)
        }
    }, [customCourseDetails, fetchedCourseCustomDetails])

    const showCourseInfoBox =
        customCourseDetails.age?.enabled === true ||
        customCourseDetails.courseFee?.enabled === true ||
        customCourseDetails.entranceFee?.enabled === true ||
        customCourseDetails.freePlaces?.enabled === true ||
        customCourseDetails.location?.enabled === true ||
        customCourseDetails.instructor?.enabled === true ||
        customCourseDetails.firstCourseLesson?.enabled === true ||
        customCourseDetails.city?.enabled === true

    // CUSTOMIZABLE DETAILS -------------------------------------------------------------------------------------------

    if (!courseId || !course) {
        if (!courseId) {
            return <div>loading...</div>
        }
        if (!loadingCourseData) {
            console.log("courseId: ", courseId)
            console.log("course: ", course)
            console.log("should remove course")
            localStorage.removeItem("course")
            history.push(URL_RESULTS.replace(":resultType", "courseNotFound"))
        }

        return (
            <>
                <h3>Es wurde kein Kurs ausgewählt.</h3>
                <Button href={URL_BASE}>Zurück zur Kurssuche</Button>
            </>
        )
    }

    type ContactCardCSSProperties = React.CSSProperties & {
        "--column-count": number
    }

    const actualStyle: ContactCardCSSProperties = {
        "--column-count": 1,
        lineBreak: "auto",
        // ...style,
    }

    const firstCourseLessonOfMonth: Array<string> = []

    const courseLessonDates = course.lessons.map((lesson) => {
        return moment(lesson.startDateTime).format("YYYY.MM.DD")
    })

    const months = () => {
        const months: Array<string> = []
        let nthMonth = 0

        course.lessons.forEach((lesson, index) => {
            const firstDate = moment(lesson.startDateTime).startOf("month").format("YYYY-MM-DD hh:mm")
            if (!months.includes(firstDate)) {
                nthMonth = nthMonth + 1
                months.push(firstDate)
            }
            if (nthMonth > firstCourseLessonOfMonth.length) {
                firstCourseLessonOfMonth.push(lesson.startDateTime)
            }
        })

        return months
    }

    const bookingSummaryLink = URL_BOOKING_SUMMARY.replace(":courseId", courseId)

    const updateLocalStorageBookingData = (course: GetCourseDetailsByIdPublicQuery["courseByIdPublic"]) => {
        const bookingData = JSON.parse(localStorage.getItem("bookingData") as string)

        const newBookingData: NewBookingInput = {
            courseId: course.id,
            coursePriceId: course.coursePrice[0].id,
            grossPrice: course.coursePrice[0].grossPrice,
            netPrice: course.coursePrice[0].netPrice,
            vat: course.coursePrice[0].vat,
            notifyUser: true,
            startDateTime: course.startDateTime,
            agbConfirmed: false,
            privacyStatementConfirmed: false,
        }

        if (!bookingData) {
            localStorage.setItem("bookingData", JSON.stringify(newBookingData))
        } else {
            const updatedBookingData = { ...bookingData, ...newBookingData }
            localStorage.setItem("bookingData", JSON.stringify(updatedBookingData))
        }
    }

    const goToBookingSummary = () => {
        if (isLoggedIn) {
            updateLocalStorageBookingData(course)
            // console.log("isLoggedIn: ", isLoggedIn)
            history.push(bookingSummaryLink)
        } else {
            // console.log("isLoggedIn: ", isLoggedIn)
            history.push(URL_LOGIN)
        }
    }

    const descriptionStyle = {
        bordered: false,
        contentStyle: { paddingLeft: 10, display: "flex", justifyContent: "space-between" },
        labelStyle: { paddingLeft: 10, fontWeight: 600 },
    }

    type DetailCardProps = {
        columns?: number
        title?: string | React.ReactNode
        gapAfter?: boolean
        style?: React.CSSProperties
        children?: React.ReactNode
        visible?: boolean
        className?: string
    }

    const DetailCard: FC<DetailCardProps> = ({
        columns,
        title,
        gapAfter,
        style,
        children,
        visible = true,
        className,
    }) => {
        if (!visible) {
            return null
        }

        return (
            <PanelCard columns={columns} title={title} gapAfter style={style} className={className}>
                {children}
            </PanelCard>
        )
    }

    const mediaColumns = media === "xxl" ? 4 : media === "xl" ? 3 : media === "md" ? 2 : media === "sm" ? 2 : 1

    return (
        <Page
            style={actualStyle}
            content={
                <>
                    <Card
                        title={
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                                className={css.titles}
                            >
                                <Space className={css.titlePrimary}>
                                    <h1
                                        style={{
                                            marginBottom: 0,
                                            paddingBottom: 0,
                                            color: "rgba(0,0,0,0.75)",
                                        }}
                                    >
                                        Kursdetails
                                    </h1>
                                    <Button type='primary' onClick={() => goToBookingSummary()}>
                                        Jetzt buchen
                                    </Button>
                                </Space>
                                <Space className={css.titleSecondary}>
                                    <div>{course.courseType.name}</div>
                                    <div>{course.prefixedCourseNumber}</div>
                                </Space>
                            </div>
                        }
                        actions={[
                            <Button
                                style={{ width: `calc(100% - (2 * ${margin.std}px))` }}
                                type='primary'
                                onClick={() => goToBookingSummary()}
                            >
                                Jetzt buchen
                            </Button>,
                        ]}
                        key={course.id}
                        bordered={false}
                        className={css.card}
                        size='small'
                        headStyle={{
                            background: course.courseType.color,
                        }}
                    >
                        <DetailCard
                            columns={1}
                            title={"Kursdaten"}
                            gapAfter
                            style={{ minWidth: 1, background: "white" }}
                            visible={showCourseInfoBox}
                        >
                            <Descriptions
                                bordered={media === "xs" ? true : false}
                                size={media === "xs" ? "small" : "default"}
                                contentStyle={descriptionStyle.contentStyle}
                                labelStyle={descriptionStyle.labelStyle}
                                className={css.descriptions}
                                column={{ xs: 1, sm: 1, md: 2, xl: 2 }}
                            >
                                {customCourseDetails.city?.enabled && (
                                    <Descriptions.Item label='Ort'>{course.location.city}</Descriptions.Item>
                                )}
                                {customCourseDetails.location?.enabled && (
                                    <Descriptions.Item label='Kursort'>{course.location.name}</Descriptions.Item>
                                )}
                                {customCourseDetails.firstCourseLesson?.enabled && (
                                    <Descriptions.Item label='Kursbeginn'>
                                        {renderFirstCourseLesson(course.startDateTime, course.endDateTime)}
                                    </Descriptions.Item>
                                )}
                                {customCourseDetails.instructor?.enabled && (
                                    <Descriptions.Item label='Kursleiter'>
                                        {renderInstructors(course.instructors)}
                                    </Descriptions.Item>
                                )}
                                {customCourseDetails.freePlaces?.enabled && (
                                    <Descriptions.Item label='Freie Plätze'>
                                        {renderFreePlaces(course.freePlaces)}
                                    </Descriptions.Item>
                                )}
                                {customCourseDetails.age?.enabled && (
                                    <Descriptions.Item label='Alter'>
                                        {`${renderMonthAsAge(course.courseType.minAge)} - ${renderMonthAsAge(
                                            course.courseType.maxAge
                                        )}`}
                                    </Descriptions.Item>
                                )}
                                {customCourseDetails.courseFee?.enabled && (
                                    <Descriptions.Item label='Kursgebühr'>
                                        {renderCoursePrice(course)}
                                    </Descriptions.Item>
                                )}

                                {customCourseDetails.entranceFee?.enabled && (
                                    <Descriptions.Item label='Badeintritt inklusive'>
                                        {renderEntranceFee(course.coursePrice?.[0].includedEntranceFee, mediaSize)}
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                        </DetailCard>
                        <DetailCard
                            columns={1}
                            gapAfter
                            title='Informationen zum Kurs'
                            style={{ minWidth: 1, background: "white" }}
                            visible={customCourseDetails.courseInfos?.enabled}
                        >
                            <div className={classnames(css.container)} style={actualStyle}>
                                {course.websiteInfo && course.websiteInfo.length > 0 ? (
                                    <div className={css.entry}>{course.websiteInfo}</div>
                                ) : (
                                    <div className={css.entry} style={{ color: colorGrey }}>
                                        Für diesen Kurs liegen keine Infos vor.
                                    </div>
                                )}
                            </div>
                        </DetailCard>
                        <DetailCard
                            columns={mediaColumns}
                            title={`Termine`}
                            style={{ minWidth: 1, background: "white" }}
                            visible={customCourseDetails.calendar?.enabled}
                        >
                            <Alert
                                style={{
                                    gridColumn: `span ${mediaColumns}`,
                                    marginBottom: 20,
                                    background: "none",
                                    padding: "5px 0px",
                                }}
                                message={<RenderLessons record={course} />}
                            />
                            {months().map((month, nthMonth) => {
                                return (
                                    <Calendar
                                        key={`${month}${nthMonth}`}
                                        className={css.calendar}
                                        fullscreen={false}
                                        defaultValue={moment(firstCourseLessonOfMonth[nthMonth])}
                                        headerRender={({ value, type, onChange, onTypeChange }) => {
                                            const currentMonth = moment(month).format("MMMM")

                                            return <h3 className={css.headLine}>{currentMonth}</h3>
                                        }}
                                        disabledDate={(date) =>
                                            !courseLessonDates.includes(moment(date).format("YYYY.MM.DD")) ||
                                            moment(month).format("M") !== moment(date).format("M")
                                        }
                                    />
                                )
                            })}
                        </DetailCard>
                    </Card>
                </>
            }
        ></Page>
    )
}

export default CourseDetails

type RenderLessonProps = {
    record: GetCourseDetailsByIdPublicQuery["courseByIdPublic"]
}

const RenderLessons: FC<RenderLessonProps> = ({ record }) => {
    const { lessons, bookingCount, maxAttendees } = record

    const freePlaces = maxAttendees - bookingCount

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const color = freePlaces && freePlaces > 0 ? (freePlaces > 2 ? green[3] : orange[3]) : red[3]

    const sortedLessons = lessons.sort((a, b) => {
        return new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime()
    })

    return (
        <div className={css.courseLessonsContainer}>
            {sortedLessons.map((lesson, index) => {
                return (
                    <div className={css.courseLesson} key={lesson.id}>
                        <div style={{ backgroundColor: "#fff", color: colorDarkGrey }}>{index + 1}</div>
                        <div style={{ backgroundColor: colorBlueSlight }}>
                            {moment(lesson.startDateTime).locale("dddd").format("dd")}
                        </div>
                        <div
                            style={{
                                backgroundColor: colorBlueLight,
                                marginTop: 2,
                                padding: 2,
                            }}
                        >
                            {moment(lesson.startDateTime).locale("MMM").format("DD.MM")}
                        </div>
                        <div
                            style={{
                                backgroundColor: colorBlueSlight,
                            }}
                        >
                            {moment(lesson.startDateTime).locale("MMM").format("H:mm")}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
