import React, { FC, memo } from "react"
import { Alert, Button, Space } from "antd"
import { useHistory } from "react-router-dom"
import { URL_LOGIN } from "../models/url"

export type KnownError = {
    key: string
    title: string
    msg: string
    additionalInfo?: { [key: string]: string | number }
    actionBtn?: React.ReactNode
}

type ErrorPanelProps = {
    error: string
    knownErrors: Array<KnownError>
}

const ErrorPanel: FC<ErrorPanelProps> = ({ error, knownErrors }) => {
    const history = useHistory()

    const standardError: KnownError = {
        key: "standard error",
        title: "Ein Fehler ist aufgetreten",
        msg: "Es tut uns leid, da ist etwas schief gelaufen. Bitte versuchen Sie es später noch einmal.",
        actionBtn: <Button onClick={() => history.push(URL_LOGIN)}>Zurück zum Login</Button>,
    }

    const knownError = knownErrors.find((knownError) => knownError.key === error)
    if (knownError) {
        return (
            <Space direction='vertical' size='large'>
                <Alert
                    style={{ textAlign: "center" }}
                    type='error'
                    message={knownError.title}
                    description={knownError.msg}
                />
                {knownError.actionBtn}
            </Space>
        )
    } else {
        return (
            <Space direction='vertical' size='large'>
                <Alert
                    style={{ textAlign: "center" }}
                    type='error'
                    message={standardError.title}
                    description={standardError.msg}
                />
                {standardError.actionBtn}
            </Space>
        )
    }
}

export default memo(ErrorPanel)
