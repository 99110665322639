import { BASE_URL } from "../constants/constants"
// base
// export const URL_BASE = "/"
export const URL_BASE = BASE_URL ?? "/"
// courseDetails
export const URL_COURSE_DETAILS = URL_BASE + "/details/:id"
// login
export const URL_LOGIN = URL_BASE + "/login"
export const URL_LOGIN_EMAIL = URL_LOGIN + "/email/:email"
export const URL_LOGIN_PARAMS = URL_LOGIN + "/:code?"
export const URL_LOGIN_COMPLETE_PASSWORDLESS = URL_LOGIN + "/complete"
export const URL_LOGIN_COMPLETE_PASSWORDLESS_EMAIL = URL_LOGIN_COMPLETE_PASSWORDLESS + "/email/:email"
export const URL_REGISTER = URL_BASE + "/register"
export const URL_FORGOT_PASSWORD = URL_BASE + "/forgotpassword"
export const URL_FORGOT_PASSWORD_SUCCESS = URL_FORGOT_PASSWORD + "/success"
// account
export const URL_ACCOUNT = URL_BASE + "/account"
// bookingFlow
export const URL_BOOKING = URL_BASE + "/booking"
export const URL_BOOKING_STEPS = URL_BOOKING + "/steps/:courseId"
export const URL_BOOKING_SUMMARY = URL_BOOKING + "/summary/:courseId"
// results
export const URL_RESULTS = URL_BASE + "/results/:resultType"
