import { UserOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Space } from "antd"
import LogRocket from "logrocket"
import React, { FC } from "react"
import { Link, useHistory, useLocation, useParams } from "react-router-dom"
import { GetCompanyQuery, useGetCompanyQuery } from "../../generated/graphql"
import { useMediaSize } from "../../layouts/Main/GetWindowDimensions/GetWindowDimensions"
import {
    URL_ACCOUNT,
    URL_BASE,
    URL_BOOKING_SUMMARY,
    URL_COURSE_DETAILS,
    URL_LOGIN,
    URL_REGISTER,
    URL_RESULTS,
} from "../../models/url"
import { margin } from "../../styles/layout"
import { LocationState } from "../../types"
import Logout from "../../views/Logout/Logout"
import css from "./AuthHeader.module.css"

type AuthHeaderProps = { currentLocation: string | undefined }

const AuthHeader: FC<AuthHeaderProps> = ({ currentLocation }) => {
    const history = useHistory()
    const isLoggedIn = !!localStorage.getItem("loggedInUser")
    const mediaSize = useMediaSize()
    const { media } = mediaSize

    const forceRefresh = () => {
        window.location.reload()
    }

    const mediaIsGreaterThanXs = media === "sm" || media === "md" || media === "lg" || media === "xl"

    const { data: companyData } = useGetCompanyQuery()
    const companyName: GetCompanyQuery["companyPublic"]["name"] = companyData?.companyPublic.name ?? ""

    // console.group("AuthHeader")
    // console.log("localStorage: ", localStorage)
    // console.groupEnd()

    type BreadcrumbsProps = { style?: React.CSSProperties }
    const Breadcrumbs: FC<BreadcrumbsProps> = ({ style }) => {
        const location = useLocation<LocationState>()
        const params = useParams<{ courseId?: string }>()
        const courseId = params.courseId ?? ""
        // const courseId = (JSON.parse(localStorage.getItem("course") as string).id as string) || ""
        const courseDetailsUrl = URL_COURSE_DETAILS.replace(":courseId", courseId)

        // console.log("location: ", location)

        const { pathname } = location

        // console.log("pathname: ", pathname)

        const styleMobile: React.CSSProperties = mediaIsGreaterThanXs
            ? {}
            : { marginBottom: margin.min /* paddingBottom: margin.min */ }

        const actualStyle: React.CSSProperties = { ...styleMobile, ...style }

        if (pathname === `${URL_BASE}` /* && mediaIsGreaterThanXs */)
            return (
                <Breadcrumb style={actualStyle}>
                    <Breadcrumb.Item>
                        <Link to={URL_BASE}>Kurssuche</Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            )

        if (pathname.startsWith(`${URL_BASE}details/`))
            return (
                <Breadcrumb style={actualStyle}>
                    <Breadcrumb.Item>
                        <Link to={URL_BASE}>Kurssuche</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={courseDetailsUrl}>Kursdetails</Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            )

        if (pathname.startsWith(`${URL_BASE}bookingsummary/`))
            return (
                <Breadcrumb style={actualStyle}>
                    <Breadcrumb.Item>
                        <Link to={URL_BASE}>Kurssuche</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={courseDetailsUrl}>Kursdetails</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={URL_BOOKING_SUMMARY}>Buchungsübersicht</Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            )

        return (
            <Breadcrumb style={actualStyle}>
                <Breadcrumb.Item>
                    <Link to={URL_BASE}>
                        <Button type='link' size='small'>
                            Zur Kurssuche
                        </Button>
                    </Link>
                </Breadcrumb.Item>
            </Breadcrumb>
        )
    }

    const koOrganizationId = process.env.REACT_APP_APPLICATION_TYPE

    const renderContent = (isLoggedIn: boolean) => {
        if (isLoggedIn) {
            const booker = JSON.parse(localStorage.getItem("booker") as string)
            if (
                process.env.REACT_APP_ENV === "production" &&
                koOrganizationId !== "69bca0e2-3aae-4ca5-9968-ea33f187938d" &&
                booker !== null
            ) {
                LogRocket.identify("1hnae2/ko-integrated", {
                    companyName: companyName,
                    name: `${booker.firstname} ${booker.lastname}`,
                    email: booker.email,
                    customerId: booker.customerId,
                    createdBy: booker.createdBy,
                    createDate: booker.createDate,
                    updateDate: booker.updateDate,
                })
            }

            if (!booker) {
                console.log("booker not found")
                // localStorage.removeItem("loggedInUser")
                history.push(URL_RESULTS.replace(":resultType", "loggedOut"))
                return null
            }

            return (
                <div className={css.root}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            // flexDirection: mediaIsGreaterThanXs ? "row" : "column-reverse",
                            flexDirection: "row",
                        }}
                    >
                        <Breadcrumbs />
                        <Space
                            align='center'
                            size='small'
                            // className={css.buttonWrapper}
                            style={{ justifyContent: "right", paddingBottom: 7 }}
                        >
                            {mediaIsGreaterThanXs ? (
                                <>
                                    <div className={css.loggedInAs}>Angemeldet als</div>
                                    {/* <Button
                                        // className={css.accountButton}
                                        className={css.loggedInAs}
                                        size='small'
                                        type='link'
                                        href={URL_ACCOUNT}
                                        danger
                                    >{`${booker.firstname} ${booker.lastname}`}</Button> */}
                                    <Link to={URL_ACCOUNT}>
                                        <Button size='small' type='link' style={{ alignSelf: "top" }}>
                                            {`${booker.firstname} ${booker.lastname}`}
                                        </Button>
                                    </Link>
                                </>
                            ) : (
                                <Link to={URL_ACCOUNT}>
                                    <Button size='small' type='link' style={{ alignSelf: "top" }}>
                                        <UserOutlined />
                                    </Button>
                                </Link>
                            )}
                            <Logout style={{ alignSelf: "top" }} forceRefresh={() => forceRefresh()} />
                        </Space>
                    </div>
                </div>
            )
        }

        return (
            <div className={css.root}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexDirection: mediaIsGreaterThanXs ? "row" : "column-reverse",
                    }}
                >
                    <Breadcrumbs />
                    <Space align='center' style={{ justifyContent: "right", paddingBottom: 7 }}>
                        <Link to={URL_REGISTER}>
                            <Button size='small' type='link' style={{ alignSelf: "top" }}>
                                registrieren
                            </Button>
                        </Link>
                        <Link to={URL_LOGIN}>
                            <Button size='small' type='primary' ghost style={{ alignSelf: "top" }}>
                                anmelden
                            </Button>
                        </Link>
                    </Space>
                </div>
            </div>
        )
    }

    return renderContent(isLoggedIn)
}

export default AuthHeader
