import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AddPaymentMethodInput = {
  id: Scalars['ID'];
  cash?: Maybe<Scalars['Boolean']>;
  esr?: Maybe<Scalars['Boolean']>;
  creditCardAccountholder?: Maybe<Scalars['String']>;
  creditcardNumber?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  validFrom?: Maybe<Scalars['DateTime']>;
  cvc?: Maybe<Scalars['String']>;
  paypalAccountholder?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  sepaAccountholder?: Maybe<Scalars['String']>;
  bankTransfer?: Maybe<Scalars['Boolean']>;
  bookerId: Scalars['String'];
  type?: Maybe<PaymentTransferType>;
};

export type Admin = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  salutation?: Maybe<SalutationType>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  birthDate?: Maybe<Scalars['String']>;
  birthPlace?: Maybe<Scalars['String']>;
  annotation?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  joinDate?: Maybe<Scalars['String']>;
  leaveDate?: Maybe<Scalars['String']>;
  socialSecurityNumber?: Maybe<Scalars['String']>;
  healthInsuranceFund?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
  accountHolder?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
};

export type ArrayDataOptions = {
  sortColumn?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<SortOrder>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  filter?: Maybe<Array<FilterOption>>;
};

export type AssignedLocationIdInput = {
  id: Scalars['ID'];
};

export type Attachment = {
  ContentType: Scalars['String'];
  Filename: Scalars['String'];
  Base64Content: Scalars['String'];
};

export type Attendee = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  customer: Customer;
  customerId: Scalars['ID'];
  gender: Gender;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  customerNumberPrefix: Scalars['String'];
  customerNumber: Scalars['Float'];
  birthday: Scalars['DateTime'];
  member: Scalars['Boolean'];
  defaultPaymentMethod: PaymentMethod;
  defaultPaymentMethodId: Scalars['ID'];
  booker?: Maybe<Booker>;
  bookerId?: Maybe<Scalars['ID']>;
  bookings?: Maybe<Array<Booking>>;
  startedCourses: Array<Course>;
  deletable: Scalars['Boolean'];
  createdBy?: Maybe<CreatedBy>;
  addition?: Maybe<Scalars['String']>;
  annotation?: Maybe<Scalars['String']>;
  badgeXAttendee?: Maybe<Array<AttendeeBadge>>;
  bookingsKo9?: Maybe<Array<BookingsKo9>>;
};

export type AttendeeBadge = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  badgeId?: Maybe<Scalars['ID']>;
  attendeeId?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  dateOfAchivement?: Maybe<Scalars['DateTime']>;
  courseId?: Maybe<Scalars['ID']>;
  badge: Badge;
  attendee: Attendee;
};

export type AttendeeWithPagination = {
  existsMore: Scalars['Boolean'];
  items: Array<Attendee>;
  total: Scalars['Float'];
};

export type Badge = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['DateTime']>;
  rank: Scalars['Float'];
  badgeXAttendee?: Maybe<Array<AttendeeBadge>>;
};

export type Base = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  id: Scalars['ID'];
};

export type BasicEmailVariables = {
  footerText: Scalars['String'];
  headerText: Scalars['String'];
  signature: Scalars['String'];
  subject: Scalars['String'];
  replyTo: Scalars['String'];
};

export type BlankMailInputTypes = {
  text: Scalars['String'];
  subject: Scalars['String'];
  receivers: Array<BlankMailReceiversInput>;
  cc: Array<Scalars['String']>;
  bcc: Array<Scalars['String']>;
  type: BlankMailTypes;
};

export type BlankMailReceiversInput = {
  email: Scalars['String'];
  ids?: Maybe<Array<Scalars['String']>>;
};

export enum BlankMailTypes {
  Attendee = 'Attendee',
  Booker = 'Booker',
  Course = 'Course',
  Booking = 'Booking',
  Invoice = 'Invoice'
}

export type BookAttendees = {
  selectedAttendee?: Maybe<SelectedAttendee>;
  selectedCourseId?: Maybe<Scalars['ID']>;
};

export type BookPaymentMethods = {
  selectedPaymentMethod?: Maybe<SelectedPaymentMethod>;
  selectedCourseId?: Maybe<Scalars['ID']>;
};

export enum BookedBy {
  Booker = 'Booker',
  Staff = 'Staff'
}

export type Booker = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  customer: Customer;
  userId?: Maybe<Scalars['String']>;
  customerId: Scalars['ID'];
  customerNumberPrefix: Scalars['String'];
  customerNumber: Scalars['Float'];
  gender?: Maybe<Gender>;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  addition?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  initialContact: Scalars['DateTime'];
  dsgvo: Scalars['Boolean'];
  newsletter: Scalars['Boolean'];
  healthInsuranceFund?: Maybe<Scalars['String']>;
  annotation?: Maybe<Scalars['String']>;
  nonDisclosureNotice?: Maybe<Scalars['String']>;
  credit?: Maybe<Scalars['Float']>;
  birthday?: Maybe<Scalars['DateTime']>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  attendees?: Maybe<Array<Attendee>>;
  createdBy?: Maybe<CreatedBy>;
};

export type BookerAttendeeBookingInput = {
  id: Scalars['ID'];
  courseLessons: Array<BaseInput>;
  paymentMethod?: Maybe<BaseInput>;
  paymentType?: Maybe<PaymentType>;
  payDate?: Maybe<Scalars['DateTime']>;
  courseId?: Maybe<Scalars['ID']>;
  bookingPrefixNumber?: Maybe<Scalars['String']>;
};

export type BookerAttendeeInput = {
  id: Scalars['ID'];
  customer: BaseInput;
  gender: Gender;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  birthday: Scalars['DateTime'];
  member: Scalars['Boolean'];
  defaultPaymentMethod?: Maybe<BaseInput>;
  bookings?: Maybe<Array<BookerAttendeeBookingInput>>;
  addition?: Maybe<Scalars['String']>;
  annotation?: Maybe<Scalars['String']>;
  bookingsKo9?: Maybe<Array<NewBookingsKo9Input>>;
};

export type BookerCustomerInput = {
  id: Scalars['ID'];
  customer: BaseInput;
  gender: Gender;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
};

export type BookerCustomerUpdateInput = {
  id: Scalars['ID'];
  customer?: Maybe<BaseInput>;
  gender?: Maybe<Gender>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['Float']>;
};

export type BookerInput = {
  id: Scalars['ID'];
  customer: BaseInput;
  gender: Gender;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  addition?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  initialContact: Scalars['DateTime'];
  birthday?: Maybe<Scalars['DateTime']>;
  dsgvo: Scalars['Boolean'];
  newsletter?: Maybe<Scalars['Boolean']>;
  healthInsuranceFund?: Maybe<Scalars['String']>;
  annotation?: Maybe<Scalars['String']>;
  nonDisclosureNotice?: Maybe<Scalars['String']>;
  credit?: Maybe<Scalars['Float']>;
  paymentMethods?: Maybe<Array<BookerPaymentMethodInput>>;
  attendees?: Maybe<Array<BookerAttendeeInput>>;
  createdBy?: Maybe<CreatedBy>;
  customerNumber?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['ID']>;
};

export type BookerPaymentMethodInput = {
  id: Scalars['ID'];
  cash?: Maybe<Scalars['Boolean']>;
  esr?: Maybe<Scalars['Boolean']>;
  creditCardAccountholder?: Maybe<Scalars['String']>;
  creditcardNumber?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  validFrom?: Maybe<Scalars['DateTime']>;
  cvc?: Maybe<Scalars['String']>;
  paypalAccountholder?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  sepaAccountholder?: Maybe<Scalars['String']>;
  bankTransfer?: Maybe<Scalars['Boolean']>;
};

export type BookerWithPagination = {
  existsMore: Scalars['Boolean'];
  items: Array<Booker>;
  total: Scalars['Float'];
};

export type Booking = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  bookingNumber: Scalars['Float'];
  bookingNumberPrefix: Scalars['String'];
  noticeBooker?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['String']>;
  noticeStaff?: Maybe<Scalars['String']>;
  attendanceListNote?: Maybe<Scalars['String']>;
  bookingType: Scalars['String'];
  startDateTime?: Maybe<Scalars['DateTime']>;
  courseLessons: Array<CourseLesson>;
  courseLessonBookings?: Maybe<Array<CourseLessonBooking>>;
  attendee?: Maybe<Attendee>;
  attendeeId?: Maybe<Scalars['ID']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId?: Maybe<Scalars['ID']>;
  paymentType?: Maybe<PaymentType>;
  payDate?: Maybe<Scalars['DateTime']>;
  coursePrice: CoursePrice;
  coursePriceId: Scalars['ID'];
  isSpecial: Scalars['Boolean'];
  vat: Scalars['Float'];
  netPrice?: Maybe<Scalars['Float']>;
  grossPrice: Scalars['Float'];
  invoice?: Maybe<Invoice>;
  reversalInvoice?: Maybe<ReversalInvoice>;
  canceled?: Maybe<Scalars['DateTime']>;
  scheduledPayments?: Maybe<Array<ScheduledPayment>>;
  bookedBy: BookedBy;
  emailNotifications?: Maybe<Array<EmailNotification>>;
  courseId?: Maybe<Scalars['ID']>;
  monthlySchedule: MonthlySchedule;
  creditedBalance?: Maybe<Scalars['Float']>;
  agbConfirmed?: Maybe<Scalars['Boolean']>;
  privacyStatementConfirmed?: Maybe<Scalars['Boolean']>;
  instructors: Array<Instructor>;
};

export type BookingDeleteAction = {
  type: BookingDeleteActionType;
  bookingId?: Maybe<Scalars['ID']>;
};

export enum BookingDeleteActionType {
  NextFromWaitingList = 'NEXT_FROM_WAITING_LIST',
  SelectFromWaitingList = 'SELECT_FROM_WAITING_LIST',
  PlaceReservation = 'PLACE_RESERVATION',
  DoNothing = 'DO_NOTHING'
}

export type BookingHistory = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  bookingId: Scalars['ID'];
  bookingNumber?: Maybe<Scalars['Float']>;
  bookingNumberPrefix: Scalars['String'];
  bookingType: BookingType;
  startDateTime?: Maybe<Scalars['DateTime']>;
  attendeeId?: Maybe<Scalars['ID']>;
  bookerId?: Maybe<Scalars['ID']>;
  courseId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  vat: Scalars['Float'];
  netPrice?: Maybe<Scalars['Float']>;
  grossPrice: Scalars['Float'];
  canceled?: Maybe<Scalars['DateTime']>;
  bookedBy: BookedBy;
  action: BookingHistoryAction;
};

export enum BookingHistoryAction {
  BookingCreate = 'BookingCreate',
  BookingCreateTransfer = 'BookingCreateTransfer',
  BookingDelete = 'BookingDelete',
  BookingDeleteTransfer = 'BookingDeleteTransfer',
  BookingOrphanDelete = 'BookingOrphanDelete',
  BookingSettle = 'BookingSettle',
  BookingCancle = 'BookingCancle',
  BookingToWaitlist = 'BookingToWaitlist',
  WaitlistToBooking = 'WaitlistToBooking',
  WaitlistCreate = 'WaitlistCreate',
  WaitlistCreateTransfer = 'WaitlistCreateTransfer',
  WaitlistDelete = 'WaitlistDelete',
  WaitlistDeleteTransfer = 'WaitlistDeleteTransfer',
  PlaceReservationCreate = 'PlaceReservationCreate',
  PlaceReservationDelete = 'PlaceReservationDelete',
  PlaceReservationToBooking = 'PlaceReservationToBooking',
  Unknown = 'Unknown'
}

export type BookingOptions = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export enum BookingType {
  PlaceReservation = 'PlaceReservation',
  Booking = 'Booking',
  Waitlist = 'Waitlist'
}

export type BookingWithPagination = {
  existsMore: Scalars['Boolean'];
  items: Array<Booking>;
  total: Scalars['Float'];
};

export type BookingsKo9 = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  attendee?: Maybe<Attendee>;
  attendeeId: Scalars['String'];
  teilnahmeId: Scalars['String'];
  courseNumber: Scalars['String'];
  courseName: Scalars['String'];
  firstCourseLesson: Scalars['String'];
};

export type CamtGeneratorInput = {
  msgId: Scalars['String'];
  createDateTime: Scalars['String'];
  notificationId: Scalars['String'];
  rangeStart: Scalars['String'];
  rangeEnd: Scalars['String'];
  creditorIban: Scalars['String'];
  creditorName: Scalars['String'];
  entryReference: Scalars['String'];
  entrySumAmount: Scalars['String'];
  entryBookDate: Scalars['String'];
  entryValuaDate: Scalars['String'];
  amountOfTransactions: Scalars['String'];
  transactions?: Maybe<Array<CamtGeneratorTransactionInput>>;
};

export type CamtGeneratorTransactionInput = {
  invoiceAmount: Scalars['String'];
  deptorName: Scalars['String'];
  deptorStreet: Scalars['String'];
  deptorHouseNumber: Scalars['String'];
  deptorPostalCode: Scalars['String'];
  deptorCity: Scalars['String'];
  deptorCountry: Scalars['String'];
  deptorIban: Scalars['String'];
  qrReference: Scalars['String'];
  packageId: Scalars['String'];
  transactionReference: Scalars['String'];
};

export type CamtScheduledPaymentInput = {
  scheduledPaymentId: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
};

export type ColorsInput = {
  enabeld?: Maybe<Scalars['Boolean']>;
  primary?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
  tertiary?: Maybe<Scalars['String']>;
};

export type CompanySetting = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  name: Scalars['String'];
  businessForm?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  agb?: Maybe<Scalars['String']>;
  privacyStatement?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  ownerFirstName: Scalars['String'];
  ownerLastName: Scalars['String'];
  ownerStreet: Scalars['String'];
  ownerStreetNumber: Scalars['String'];
  ownerZip: Scalars['String'];
  ownerCity: Scalars['String'];
  ownerCountry: Scalars['String'];
  industry?: Maybe<Scalars['String']>;
  ownerPhone?: Maybe<Scalars['String']>;
  ownerFax?: Maybe<Scalars['String']>;
  ownerEmail: Scalars['String'];
  sendFromEmail: Scalars['String'];
  localCourt?: Maybe<Scalars['String']>;
  commercialRegister?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  bankAccountHolder?: Maybe<Scalars['String']>;
  IBAN?: Maybe<Scalars['String']>;
  BIC?: Maybe<Scalars['String']>;
  creditorId?: Maybe<Scalars['String']>;
  qrIban?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  createdByUserId: Scalars['String'];
  updatedByUserId: Scalars['String'];
};

export type CompanySettingInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  businessForm?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  agb?: Maybe<Scalars['String']>;
  privacyStatement?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  ownerFirstName: Scalars['String'];
  ownerLastName: Scalars['String'];
  ownerStreet: Scalars['String'];
  ownerStreetNumber: Scalars['String'];
  ownerZip: Scalars['String'];
  ownerCity: Scalars['String'];
  ownerCountry: Scalars['String'];
  industry?: Maybe<Scalars['String']>;
  ownerPhone?: Maybe<Scalars['String']>;
  ownerFax?: Maybe<Scalars['String']>;
  ownerEmail: Scalars['String'];
  localCourt?: Maybe<Scalars['String']>;
  commercialRegister?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  bankAccountHolder?: Maybe<Scalars['String']>;
  IBAN?: Maybe<Scalars['String']>;
  BIC?: Maybe<Scalars['String']>;
  creditorId?: Maybe<Scalars['String']>;
  qrIban?: Maybe<Scalars['String']>;
};

export type Course = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  courseType: CourseType;
  courseNumberFormat: CourseNumberFormat;
  customCourseNumberPrefix: Scalars['String'];
  courseNumber: Scalars['Float'];
  prefixedCourseNumber?: Maybe<Scalars['String']>;
  location: Location;
  locationId: Scalars['ID'];
  venue: Venue;
  venueId: Scalars['ID'];
  instructors: Array<Instructor>;
  paymentInterval?: Maybe<PaymentInterval>;
  minAttendees: Scalars['Float'];
  maxAttendees: Scalars['Float'];
  freePlaces: Scalars['Float'];
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  cycle: CourseCycle;
  annotation: Scalars['String'];
  websiteInfo?: Maybe<Scalars['String']>;
  lessons: Array<CourseLesson>;
  coursePrices: Array<CoursePrice>;
  allowMonthlyPayment?: Maybe<Scalars['Boolean']>;
  allowUpFrontPayment?: Maybe<Scalars['Boolean']>;
  monthlySchedule: MonthlySchedule;
  isActive: Scalars['Boolean'];
  showInWeb: Scalars['Boolean'];
  installmentRates?: Maybe<Array<InstallmentRate>>;
  paymentType?: Maybe<PaymentType>;
  lessonCount?: Maybe<Scalars['Float']>;
  coursePrice: Array<CoursePrice>;
  bookingCount: Scalars['Float'];
  bookingCountWithoutWaitlist: Scalars['Float'];
  billedBookingCount: Scalars['Float'];
  settledBookingCount: Scalars['Float'];
  settledBookings: Array<Booking>;
  unSettledBookings: Array<Booking>;
};

export type CourseCategory = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  linkedCourseTypes: Array<CourseType>;
};

export enum CourseCycle {
  Weekly = 'Weekly',
  EveryTwoWeeks = 'EveryTwoWeeks',
  Monthly = 'Monthly',
  Custom = 'Custom'
}

export type CourseDetailsInput = {
  city?: Maybe<Scalars['JSONObject']>;
  location?: Maybe<Scalars['JSONObject']>;
  freePlaces?: Maybe<Scalars['JSONObject']>;
  age?: Maybe<Scalars['JSONObject']>;
  courseFee?: Maybe<Scalars['JSONObject']>;
  courseInfos?: Maybe<Scalars['JSONObject']>;
  entranceFee?: Maybe<Scalars['JSONObject']>;
  calendar?: Maybe<Scalars['JSONObject']>;
  instructor?: Maybe<Scalars['JSONObject']>;
  firstCourseLesson?: Maybe<Scalars['JSONObject']>;
};

export type CourseIdInput = {
  id: Scalars['ID'];
};

export type CourseInstructorIdInput = {
  id: Scalars['ID'];
};

export type CourseLesson = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  course: Course;
  courseId: Scalars['ID'];
  location: Location;
  locationId: Scalars['ID'];
  venue: Venue;
  venueId: Scalars['ID'];
  instructors: Array<Instructor>;
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  bookings?: Maybe<Array<Booking>>;
  courseLessonBookings: Array<CourseLessonBooking>;
  indexOfLessonInCourse?: Maybe<Scalars['Float']>;
};

export type CourseLessonBooking = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  attendance?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  courseLessonId: Scalars['ID'];
  bookingId: Scalars['ID'];
  courseId?: Maybe<Scalars['String']>;
  booking: Booking;
  courseLesson: CourseLesson;
};

export type CourseLessonBookingAttendanceList = {
  bookingId: Scalars['String'];
  attendee: Attendee;
  booker: Booker;
  courseLessonBooking: Array<CourseLessonBooking>;
};

export type CourseLessonIdInput = {
  id: Scalars['ID'];
};

export type CourseLessonWithPagination = {
  existsMore: Scalars['Boolean'];
  items: Array<CourseLesson>;
  total: Scalars['Float'];
};

export type CourseListFilters = {
  ids?: Maybe<Array<Scalars['String']>>;
  dayFilter?: Maybe<Array<DayFilter>>;
  instructorId?: Maybe<Scalars['String']>;
  courseCategoryId?: Maybe<Scalars['String']>;
  courseTypeId?: Maybe<Scalars['String']>;
  courseTypeIds?: Maybe<Array<Scalars['String']>>;
  locationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  showOnlyInWebActive?: Maybe<Scalars['Boolean']>;
};

export enum CourseNumberFormat {
  CourseLocationPrefix = 'CourseLocationPrefix',
  CourseTypePrefix = 'CourseTypePrefix',
  NoPrefix = 'NoPrefix',
  CustomPrefix = 'CustomPrefix'
}

export type CoursePrice = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  isSpecial: Scalars['Boolean'];
  vat: Scalars['Float'];
  netPrice?: Maybe<Scalars['Float']>;
  grossPrice: Scalars['Float'];
  discount?: Maybe<Scalars['Float']>;
  discountUnit?: Maybe<Scalars['String']>;
  bookings?: Maybe<Array<Booking>>;
  course: Course;
  courseId: Scalars['ID'];
  includedEntranceFee?: Maybe<Scalars['Float']>;
};

export type CoursePriceIdInput = {
  id: Scalars['ID'];
};

export type CourseTableInput = {
  columns?: Maybe<Array<Scalars['JSONObject']>>;
};

export type CourseType = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  showInWeb: Scalars['Boolean'];
  name: Scalars['String'];
  shortHandId: Scalars['String'];
  category?: Maybe<CourseCategory>;
  description?: Maybe<Scalars['String']>;
  minAge?: Maybe<Scalars['Float']>;
  maxAge?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  paymentInterval?: Maybe<PaymentInterval>;
  vat?: Maybe<Scalars['Float']>;
  minAttendees?: Maybe<Scalars['Float']>;
  maxAttendees?: Maybe<Scalars['Float']>;
  annotation?: Maybe<Scalars['String']>;
  websiteInfo?: Maybe<Scalars['String']>;
  inWeekPlanner: Scalars['Boolean'];
  bold: Scalars['Boolean'];
  color: Scalars['String'];
  courses?: Maybe<Array<Course>>;
  participationPrerequisites?: Maybe<Array<ParticipationPrerequisites>>;
  courseCount: Scalars['Float'];
};

export type CourseWithPagination = {
  existsMore: Scalars['Boolean'];
  items: Array<Course>;
  total: Scalars['Float'];
};

export type CreateCourse = {
  locationId?: Maybe<Scalars['ID']>;
};

export type CreateDummySwissCamtByScheduledPaymentsInput = {
  scheduledPayments?: Maybe<Array<CamtScheduledPaymentInput>>;
};

export type CreateInstallmentRateByCourseCreateInput = {
  id?: Maybe<Scalars['ID']>;
  installmentAmount?: Maybe<Scalars['Float']>;
  installmentDue?: Maybe<Scalars['DateTime']>;
};

export type CreateMultipleSepaXmlInput = {
  ids: Array<Scalars['String']>;
  collectionDate: Scalars['DateTime'];
};

export type CreateSepaXmlByTimeRangeInput = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  collectionDate?: Maybe<Scalars['DateTime']>;
  saveFile?: Maybe<Scalars['Boolean']>;
};

export type CreateSingleSepaXmlInput = {
  id: Scalars['String'];
  collectionDate: Scalars['DateTime'];
};

export enum CreatedBy {
  AdminApp = 'ADMIN_APP',
  Import = 'IMPORT',
  DummyData = 'DUMMY_DATA',
  EndUserApp = 'END_USER_APP'
}

export enum Currency {
  Eur = 'Eur',
  Chf = 'Chf',
  Usd = 'Usd',
  Gbp = 'Gbp'
}

export type Customer = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  gender: Gender;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  customerNumberPrefix: Scalars['String'];
  customerNumber: Scalars['Float'];
};


export enum DayFilter {
  Montag = 'Montag',
  Dienstag = 'Dienstag',
  Mittwoch = 'Mittwoch',
  Donnerstag = 'Donnerstag',
  Freitag = 'Freitag',
  Samstag = 'Samstag',
  Sonntag = 'Sonntag'
}

export type DetailsCheckboxInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
};

export enum DiscountUnit {
  Percentage = 'Percentage',
  Euro = 'Euro'
}

export type EditAttendeeBadgeInput = {
  attendeeBadgeId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  dateOfAchivement?: Maybe<Scalars['DateTime']>;
  courseId?: Maybe<Scalars['ID']>;
};

export type EditBookingInput = {
  attendeeId?: Maybe<Scalars['ID']>;
  courseId?: Maybe<Scalars['ID']>;
  paymentMethodId?: Maybe<Scalars['ID']>;
  coursePriceId?: Maybe<Scalars['ID']>;
  noticeStaff?: Maybe<Scalars['String']>;
  noticeBooker?: Maybe<Scalars['String']>;
  specialPrice?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
  isSpecial?: Maybe<Scalars['Boolean']>;
  netPrice?: Maybe<Scalars['Float']>;
  grossPrice?: Maybe<Scalars['Float']>;
  paymentType?: Maybe<PaymentType>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  bookingNumberPrefix?: Maybe<Scalars['String']>;
  bookingType?: Maybe<BookingType>;
  scheduledPayments?: Maybe<Array<UpdateScheduledPaymentByBookingInput>>;
  monthlySchedule?: Maybe<MonthlySchedule>;
  creditedBalance?: Maybe<Scalars['Float']>;
  agbConfirmed?: Maybe<Scalars['Boolean']>;
  privacyStatementConfirmed?: Maybe<Scalars['Boolean']>;
};

export type EditCourseInput = {
  id?: Maybe<Scalars['ID']>;
  courseTypeId?: Maybe<Scalars['ID']>;
  courseNumberFormat: CourseNumberFormat;
  customCourseNumberPrefix?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  venueId?: Maybe<Scalars['ID']>;
  instructors?: Maybe<Array<CourseInstructorIdInput>>;
  paymentInterval?: Maybe<PaymentInterval>;
  minAttendees?: Maybe<Scalars['Float']>;
  maxAttendees?: Maybe<Scalars['Float']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  cycle?: Maybe<CourseCycle>;
  annotation?: Maybe<Scalars['String']>;
  websiteInfo?: Maybe<Scalars['String']>;
  lessons: Array<EditCourseLessonInput>;
  coursePrices?: Maybe<Array<EditCoursePriceInput>>;
  defaultLessonCount?: Maybe<Scalars['Float']>;
  allowMonthlyPayment?: Maybe<Scalars['Boolean']>;
  allowUpFrontPayment?: Maybe<Scalars['Boolean']>;
  monthlySchedule?: Maybe<MonthlySchedule>;
  isActive?: Maybe<Scalars['Boolean']>;
  showInWeb?: Maybe<Scalars['Boolean']>;
  installmentRates?: Maybe<Array<UpdateInstallmentRateInput>>;
  fee?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
  paymentType?: Maybe<PaymentType>;
  dateOfFirstInstallment?: Maybe<Scalars['DateTime']>;
};

export type EditCourseLessonInput = {
  id?: Maybe<Scalars['ID']>;
  courseId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  venueId?: Maybe<Scalars['ID']>;
  instructors?: Maybe<Array<CourseInstructorIdInput>>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

export type EditCoursePriceInput = {
  id?: Maybe<Scalars['ID']>;
  vat?: Maybe<Scalars['Float']>;
  isSpecial?: Maybe<Scalars['Boolean']>;
  netPrice?: Maybe<Scalars['Float']>;
  grossPrice?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  discountUnit?: Maybe<DiscountUnit>;
  courseId: Scalars['ID'];
  includedEntranceFee?: Maybe<Scalars['Float']>;
};

export type EditInvoiceInput = {
  billed?: Maybe<Scalars['DateTime']>;
  qrReference?: Maybe<Scalars['String']>;
};

export type EditLocationContactInput = {
  id: Scalars['ID'];
  salutation?: Maybe<SalutationType>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type EditLocationInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['String']>;
  venues?: Maybe<Array<EditVenueInput>>;
  generalLocationContact?: Maybe<GeneralLocationContactInput>;
  locationContacts?: Maybe<Array<LocationContactIdInput>>;
};

export type EditScheduledPaymentInput = {
  paymentDone?: Maybe<Scalars['DateTime']>;
  paymentTransferType?: Maybe<PaymentTransferType>;
  paymentMethodId?: Maybe<Scalars['String']>;
  openAmount?: Maybe<Scalars['Float']>;
};

export type EditTemplateInput = {
  headerText?: Maybe<Scalars['String']>;
  footerText?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type EditVenueInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  rent?: Maybe<Scalars['Float']>;
  rentType?: Maybe<RentType>;
  locationId?: Maybe<Scalars['ID']>;
};

export type EmailData = {
  receivers: Array<Scalars['String']>;
  ids: Array<Scalars['String']>;
  mailDataType: MailDataType;
};

export type EmailNotification = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  templateType?: Maybe<Scalars['String']>;
  mailjetTemplateId?: Maybe<Scalars['Float']>;
  messageID: Scalars['ID'];
  email: Scalars['String'];
  receiverName?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  messageHref?: Maybe<Scalars['String']>;
  booking?: Maybe<Booking>;
  bookerId?: Maybe<Scalars['String']>;
};

export type EmailNotificationWithPagination = {
  existsMore: Scalars['Boolean'];
  items: Array<EmailNotification>;
  total: Scalars['Float'];
};

export type EndUserPasswordlessLoginInputType = {
  email: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  sendFromMail?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type ExportedSepaXml = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['JSONObject']>;
  xml?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type ExportedSepaXmlWithPagination = {
  existsMore: Scalars['Boolean'];
  items: Array<ExportedSepaXml>;
  total: Scalars['Float'];
};

export type FaUser = {
  breachedPasswordLastCheckedInstant?: Maybe<Scalars['Float']>;
  connectorId?: Maybe<Scalars['String']>;
  encryptionScheme?: Maybe<Scalars['String']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  passwordChangeReason?: Maybe<Scalars['String']>;
  passwordChangeRequired?: Maybe<Scalars['Boolean']>;
  passwordLastUpdateInstant?: Maybe<Scalars['Float']>;
  salt?: Maybe<Scalars['String']>;
  uniqueUsername?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['String']>;
  cleanSpeakId?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['JSONObject']>;
  email?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['Float']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastName?: Maybe<Scalars['String']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  middleName?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  parentEmail?: Maybe<Scalars['String']>;
  preferredLanguages?: Maybe<Array<Scalars['String']>>;
  registrations?: Maybe<Array<UserRegistration>>;
  tenantId?: Maybe<Scalars['ID']>;
  timezone?: Maybe<Scalars['String']>;
};

export type FilterExpressionInput = {
  operator: Operator;
  filters: Array<FilterInput>;
  childExpressions?: Maybe<Array<FilterExpressionInput>>;
};

export type FilterInput = {
  op: Operation;
  values: Array<Scalars['String']>;
  field: Scalars['String'];
  relationField?: Maybe<Scalars['String']>;
};

export type FilterOption = {
  column: Scalars['String'];
  table: Scalars['String'];
  values: Array<Scalars['String']>;
  operation?: Maybe<FilterOperation>;
  compareOperation?: Maybe<FilterCompareOperation>;
};

export type FiltersInput = {
  courseType?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  instructor?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['String']>;
  weekDay?: Maybe<Scalars['String']>;
};

export type ForgotPasswordInput = {
  changePasswordId: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ForgotPasswordWithApiKeyInput = {
  applicationId: Scalars['String'];
  loginId: Scalars['String'];
  sendFogotPasswordEmail?: Maybe<Scalars['Boolean']>;
  state: ResetPasswordStateType;
};

export type ForgotPasswordWithStateInput = {
  loginId: Scalars['String'];
  sendForgotPasswordEmail?: Maybe<Scalars['Boolean']>;
  state: ResetPasswordStateType;
};

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  Diverse = 'Diverse',
  NotDefined = 'NotDefined'
}

export type GeneralLocationContact = {
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type GeneralLocationContactInput = {
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export enum GrossNet {
  Gross = 'Gross',
  Net = 'Net'
}

export type ImportBookerInput = {
  koOrganizationId: Scalars['String'];
  attendeeFirstName: Scalars['String'];
  attendeeLastName: Scalars['String'];
  attendeeBirthDate: Scalars['String'];
  attendeeGender?: Maybe<Scalars['String']>;
  bookerGender: Scalars['String'];
  bookerFirstName: Scalars['String'];
  bookerLastName: Scalars['String'];
  bookerStreet: Scalars['String'];
  bookerHouseNumber?: Maybe<Scalars['String']>;
  bookerPostCode: Scalars['String'];
  bookerCity: Scalars['String'];
  bookerPhoneNumber: Scalars['String'];
  bookerMobileNumber: Scalars['String'];
  bookerEmail: Scalars['String'];
  bookerBirthDate?: Maybe<Scalars['String']>;
  initialContact: Scalars['String'];
  bookerAnnotation: Scalars['String'];
  bookerAddition: Scalars['String'];
  attendeeAnnotation: Scalars['String'];
  attendeeAddition: Scalars['String'];
  annotation?: Maybe<Scalars['String']>;
  healthInsuranceFund: Scalars['String'];
  dsgvo: Scalars['String'];
  newsletter: Scalars['String'];
  member: Scalars['String'];
  defaultPaymentMethod: Scalars['String'];
  IBAN?: Maybe<Scalars['String']>;
  BIC?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  sepaAccountholder?: Maybe<Scalars['String']>;
  credit?: Maybe<Scalars['Float']>;
  nonDisclosureNotice?: Maybe<Scalars['String']>;
  badges?: Maybe<Scalars['String']>;
  bookingsKo9?: Maybe<Scalars['String']>;
};

export type ImportBookersInput = {
  bookers: Array<ImportBookerInput>;
};

export type InlinedAttachment = {
  ContentType: Scalars['String'];
  Filename: Scalars['String'];
  Base64Content: Scalars['String'];
  ContentID: Scalars['String'];
};

export type InstallmentRate = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  courseId: Scalars['ID'];
  course: Course;
  installmentAmount: Scalars['Float'];
  installmentDue: Scalars['DateTime'];
};

export type Instructor = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  level: Scalars['Float'];
  active: Scalars['Boolean'];
  showInWeb: Scalars['Boolean'];
  assignedLocations: Array<Location>;
  salutation: SalutationType;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  birthDate?: Maybe<Scalars['String']>;
  birthPlace?: Maybe<Scalars['String']>;
  annotation?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  joinDate?: Maybe<Scalars['String']>;
  leaveDate?: Maybe<Scalars['String']>;
  socialSecurityNumber?: Maybe<Scalars['String']>;
  healthInsuranceFund?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
  accountHolder?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  activeCourseCount: Scalars['Float'];
};

export type InstructorsWithPagination = {
  existsMore: Scalars['Boolean'];
  items: Array<Instructor>;
  total: Scalars['Float'];
};

export type Invoice = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  invoiceNumber: Scalars['Float'];
  invoiceNumberPrefix: Scalars['String'];
  billed?: Maybe<Scalars['DateTime']>;
  paymentDue?: Maybe<Scalars['DateTime']>;
  booking: Booking;
  bookingId: Scalars['ID'];
  scheduledPayments?: Maybe<Array<ScheduledPayment>>;
  qrReference?: Maybe<Scalars['String']>;
  koOrganization: KoOrganization;
  invoiceBody: Scalars['String'];
  invoiceFileName: Scalars['String'];
};

export type InvoiceInput = {
  bookingId: Scalars['ID'];
};

export type InvoiceWithPagination = {
  existsMore: Scalars['Boolean'];
  items: Array<Invoice>;
  total: Scalars['Float'];
};


export type KeyValue = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KoOrganization = {
  id: Scalars['String'];
  name: Scalars['String'];
  settings: Setting;
  settingsId: Scalars['ID'];
  companySettings: CompanySetting;
  companySettingsId: Scalars['ID'];
  createdByUserId: Scalars['String'];
  updatedByUserId: Scalars['String'];
  subscriptionType: SubscriptionType;
};

export type KoOrganizationInputType = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum Language {
  De = 'DE'
}

export type LocalState = {
  createCourse: CreateCourse;
  bookAttendees: BookAttendees;
  bookPaymentMethods: BookPaymentMethods;
};

export type Location = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  isPublished?: Maybe<Scalars['Boolean']>;
  zipCode: Scalars['String'];
  city: Scalars['String'];
  street: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  abbreviation: Scalars['String'];
  info?: Maybe<Scalars['String']>;
  venues: Array<Venue>;
  locationContacts: Array<LocationContact>;
  courses?: Maybe<Array<Course>>;
  lessons?: Maybe<Array<CourseLesson>>;
  generalLocationContact: GeneralLocationContact;
  instructors: Array<Instructor>;
};

export type LocationCity = {
  city: Scalars['String'];
};

export type LocationContact = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  salutation: SalutationType;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  locations: Array<Location>;
};

export type LocationContactIdInput = {
  id: Scalars['ID'];
};

export type LocationGroupedByCityLocation = {
  id: Scalars['ID'];
  city: Scalars['String'];
  name: Scalars['String'];
  venues: Array<LocationGroupedByCityVenue>;
};

export type LocationGroupedByCityVenue = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type LocationsGroupedByCity = {
  city: Scalars['String'];
  locations: Array<LocationGroupedByCityLocation>;
};

export type LocationsWithPagination = {
  existsMore: Scalars['Boolean'];
  items: Array<Location>;
  total: Scalars['Float'];
};

export enum LogLevelType {
  Error = 'ERROR',
  Warn = 'WARN',
  Info = 'INFO',
  Verbose = 'VERBOSE',
  Debug = 'DEBUG',
  Silly = 'SILLY'
}

export type LoggingInput = {
  level?: Maybe<LogLevelType>;
  msg: Scalars['String'];
  additionalInfo?: Maybe<Scalars['String']>;
};

export enum MailDataType {
  Booker = 'Booker',
  Attendee = 'Attendee',
  Course = 'Course',
  Booking = 'Booking',
  Invoice = 'Invoice',
  ScheduledPayment = 'ScheduledPayment',
  InvoicePdf = 'InvoicePdf'
}

export type MailReplacementData = {
  email: Scalars['String'];
  receiverName: Scalars['String'];
  textBlocks?: Maybe<Array<BasicEmailVariables>>;
};

export enum MailjetEmailTemplates {
  BookingConfirmation = 'BookingConfirmation',
  BookingCancellation = 'BookingCancellation',
  InvoiceSettlement = 'InvoiceSettlement',
  WaitingListConfirmation = 'WaitingListConfirmation',
  Blank = 'Blank',
  FreeText = 'FreeText'
}

export type MailjetVariables = {
  footerText: Scalars['String'];
  headerText: Scalars['String'];
  signature: Scalars['String'];
  subject: Scalars['String'];
  replyTo: Scalars['String'];
  booking?: Maybe<KeyValue>;
  attendee?: Maybe<KeyValue>;
  course?: Maybe<KeyValue>;
  location?: Maybe<KeyValue>;
  booker?: Maybe<KeyValue>;
  bookerId?: Maybe<Scalars['String']>;
};

export type MjmlTemplate = {
  Count: Scalars['Float'];
  Data: Scalars['String'];
  Total: Scalars['Float'];
};

export type ModifyScheduledPaymentInput = {
  id: Scalars['ID'];
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentDue?: Maybe<Scalars['DateTime']>;
  paymentDone?: Maybe<Scalars['DateTime']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  sepaClearance?: Maybe<Scalars['Boolean']>;
  openAmount?: Maybe<Scalars['Float']>;
};

export enum MonthlySchedule {
  Consistent = 'Consistent',
  Dynamic = 'Dynamic',
  Custom = 'Custom'
}

export type Mutation = {
  addBookerAsAttendee: Booker;
  addFaUserByBookerId: FaUser;
  addPaymentMethod: PaymentMethod;
  addRoleToOrganization: Scalars['Boolean'];
  billMultipleBookings: Array<Booking>;
  bookAttendeeSetSelectedAttendee?: Maybe<Scalars['ID']>;
  bookAttendeeSetSelectedCourse?: Maybe<Scalars['ID']>;
  bookPaymentMethodSetSelectedPaymentMethod?: Maybe<Scalars['ID']>;
  cancelBookingAndGenerateReversalInvoice: Scalars['Boolean'];
  changeForgottenPassword: Scalars['Boolean'];
  completePasswordlessFlow: UserType;
  completePasswordlessFlowEndUserApp: UserType;
  confirmAGB: Scalars['Boolean'];
  confirmPrivacyStatement: Scalars['Boolean'];
  convertBookingToWaitlist: Booking;
  convertWaitlistToBooking: Booking;
  copyAttendeeToCourse: Booking;
  createAndRegisterAdmin: UserType;
  createAndRegisterThirdPartyUser: UserType;
  createAndRegisterThirdPartyUserAndBooker: UserType;
  createAttendeeBadge: AttendeeBadge;
  createAttendeeBadgeByBadgeName: AttendeeBadge;
  createBadge: Badge;
  createBooker: Booker;
  createBooking: Booking;
  createCourse: Course;
  createCourseCategory: CourseCategory;
  createCourseLesson: CourseLesson;
  createCourseLessonBooking: CourseLessonBooking;
  createCourseLessonBookingsByCourseId: Scalars['JSONObject'];
  createCourseLessons: Array<CourseLesson>;
  createCourseType: CourseType;
  createDummyCamtFileForMultiplePayments: Scalars['String'];
  createFaUserWithOrAddEndUserRights: FaUser;
  createInstallmentRate: InstallmentRate;
  createInvoice: Invoice;
  createInvoicesByBookingIds: Array<Invoice>;
  createInvoicesByCourseId: Array<Invoice>;
  createLocation: Location;
  createLocationContact: LocationContact;
  createOrganization: KoOrganization;
  createParticipationPrerequisite: ParticipationPrerequisites;
  createSepaXmlById: Scalars['String'];
  createSepaXmlByTimeRange: Scalars['JSONObject'];
  createSettingEndUserApp: Scalars['JSONObject'];
  createSettingEndUserAppWithDefaultValues: SettingEndUserApp;
  createTemplate: Template;
  deleteAttendee: Scalars['Boolean'];
  deleteAttendeeBadge: Scalars['Boolean'];
  deleteBadge: Scalars['Boolean'];
  deleteBooker: Scalars['Boolean'];
  deleteCategoryById: CourseCategory;
  deleteCourseLessonBooking: Scalars['Boolean'];
  deleteFusionAuthUserByIds: Scalars['Boolean'];
  deleteFusionAuthUsersByEmails: Scalars['JSONObject'];
  deleteInstallmentRate: Scalars['Boolean'];
  deleteInstallmentRatesByCourseId: Scalars['Float'];
  deleteTemplate: Scalars['Boolean'];
  disablePasswordlessLoginEndUserApp: Scalars['JSONObject'];
  enablePasswordlessLoginEndUserApp: Scalars['JSONObject'];
  forgetPassword: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  generatePaymentSchedulePreview: Array<Scalars['JSONObject']>;
  generateScheduledPaymentsForSettledBooking: Array<ScheduledPayment>;
  generateUpdatedSchedulesNewBookingPrice: Array<ScheduledPayment>;
  importBookers: Scalars['JSONObject'];
  logging: Scalars['Boolean'];
  logoutUser: Scalars['Boolean'];
  mergeBookers: Booker;
  modifyScheduledPayment: ScheduledPayment;
  passwordlessLogin: Scalars['Boolean'];
  passwordlessLoginEndUserApp: Scalars['Boolean'];
  reOrderBadges: Scalars['Boolean'];
  refreshAccessToken: Scalars['String'];
  removeAllBookersOfTenant: Scalars['Boolean'];
  removeAllBookings: Scalars['Boolean'];
  removeBooking: Scalars['Boolean'];
  removeBookings: Scalars['Boolean'];
  removeCourse: Scalars['Boolean'];
  removeCourseLesson: Scalars['Boolean'];
  removeCourseType: Scalars['Boolean'];
  removeInstructor: Scalars['Boolean'];
  removeLocation: Scalars['Boolean'];
  removeLocationContact: Scalars['Boolean'];
  removeParticipationPrerequisite: Scalars['Boolean'];
  resendBookingConfirmation: Scalars['JSONObject'];
  resendEmailVerification: Scalars['Boolean'];
  resetAllUnsetteledScheduledPaymentsOfCourse: ResetScheduledPaymentsOfCourseResult;
  resetScheduledPaymentsOfBooking: ResetScheduledPaymentsOfBookingResult;
  rollbackPaymentStatus: Scalars['Boolean'];
  saveAdmin: Admin;
  saveInstructor: Instructor;
  sendBlankMailToMultipleBookers: Scalars['Boolean'];
  sendBookingConfirmation: Scalars['JSONObject'];
  sendBookingInvoice: Scalars['JSONObject'];
  sendBookingInvoicesByBookingIds: Scalars['JSONObject'];
  sendEmailTemplate: Scalars['JSONObject'];
  sendTestMail: Scalars['JSONObject'];
  sendTestMailBooker: Scalars['JSONObject'];
  setNewCustomerNumber: Customer;
  setNewPaymentAmount: ScheduledPayment;
  setNewPaymentDoneDate: ScheduledPayment;
  setScheduledPaymentPaid: ScheduledPayment;
  setScheduledPaymentUnpaid: ScheduledPayment;
  setSepaClearance: ScheduledPayment;
  singleUpload: Scalars['JSONObject'];
  softDeleteBadge: Badge;
  thirdPartyLogin: UserType;
  transferAttendeeToBooker: Attendee;
  transferAttendeeToCourse: Booking;
  unBillMultipleBookings: Array<Booking>;
  updateAttendanceListNote: Booking;
  updateAttendeeBadge: AttendeeBadge;
  updateBadge: Badge;
  updateBooker: Booker;
  updateBookerAccountEndUserApp: Booker;
  updateBooking: Booking;
  updateBookingPaymentMethod: Booking;
  updateCompanyAgb: CompanySetting;
  updateCompanyPrivacyStatement: CompanySetting;
  updateCompanySettings: CompanySetting;
  updateCourse: Course;
  updateCourseCategory: CourseCategory;
  updateCourseLessonBooking: CourseLessonBooking;
  updateCourseType: CourseType;
  updateInstallmentRate: InstallmentRate;
  updateInvoice: Invoice;
  updateLocalLocationId?: Maybe<Scalars['ID']>;
  updateLocation: Location;
  updateLocationContact: LocationContact;
  updateManyScheduledPaymentIds: ScheduledPayment;
  updateNoticeStaff: Booking;
  updateParticipationPrerequisite: ParticipationPrerequisites;
  updateScheduledPayment: ScheduledPayment;
  updateSettingEndUserApp: SettingEndUserApp;
  updateSettings: Setting;
  updateTableColumnSettings: UserSetting;
  updateTemplate: Template;
  userSettingSetOptimizedCourseCalendar: UserSetting;
  userSettingsUpdateOrCreate: UserSetting;
  verifyBookersData: Scalars['JSONObject'];
};


export type MutationAddBookerAsAttendeeArgs = {
  bookerId: Scalars['ID'];
};


export type MutationAddFaUserByBookerIdArgs = {
  bookerId: Scalars['String'];
};


export type MutationAddPaymentMethodArgs = {
  sepaDebitIntput?: Maybe<SepaDebitIntput>;
  paymentMethodType: Scalars['String'];
  bookerId: Scalars['String'];
};


export type MutationAddRoleToOrganizationArgs = {
  role: Scalars['String'];
};


export type MutationBillMultipleBookingsArgs = {
  billDate?: Maybe<Scalars['DateTime']>;
  ids: Array<Scalars['ID']>;
};


export type MutationBookAttendeeSetSelectedAttendeeArgs = {
  id?: Maybe<Scalars['ID']>;
  firstname?: Maybe<Scalars['String']>;
  bookedCourseIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationBookAttendeeSetSelectedCourseArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationBookPaymentMethodSetSelectedPaymentMethodArgs = {
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  bookedCourseIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationCancelBookingAndGenerateReversalInvoiceArgs = {
  id: Scalars['String'];
};


export type MutationChangeForgottenPasswordArgs = {
  newPassword: Scalars['String'];
  changePasswordId: Scalars['String'];
};


export type MutationCompletePasswordlessFlowArgs = {
  loginCode: Scalars['String'];
};


export type MutationCompletePasswordlessFlowEndUserAppArgs = {
  loginCode: Scalars['String'];
};


export type MutationConfirmAgbArgs = {
  agbConfirmed: Scalars['Boolean'];
  bookingId: Scalars['ID'];
};


export type MutationConfirmPrivacyStatementArgs = {
  privacyStatementConfirmed: Scalars['Boolean'];
  bookingId: Scalars['ID'];
};


export type MutationConvertBookingToWaitlistArgs = {
  bookingId: Scalars['ID'];
};


export type MutationConvertWaitlistToBookingArgs = {
  bookingId: Scalars['ID'];
};


export type MutationCopyAttendeeToCourseArgs = {
  usePlaceReservation?: Maybe<Scalars['Boolean']>;
  targetCourseId: Scalars['ID'];
  bookingId: Scalars['ID'];
};


export type MutationCreateAndRegisterAdminArgs = {
  tenantId: Scalars['String'];
  userInput: UserInput;
};


export type MutationCreateAndRegisterThirdPartyUserArgs = {
  tenantId: Scalars['String'];
  thirdPartyUserInput: ThirdPartyUserInput;
};


export type MutationCreateAndRegisterThirdPartyUserAndBookerArgs = {
  newBookingData: BookerInput;
  thirdPartyUserInput: ThirdPartyUserInput;
};


export type MutationCreateAttendeeBadgeArgs = {
  newAttendeeBadgeInput: NewAttendeeBadgeInput;
};


export type MutationCreateAttendeeBadgeByBadgeNameArgs = {
  newAttendeeBadgeByBadgeNameInput: NewAttendeeBadgeByBadgeNameInput;
};


export type MutationCreateBadgeArgs = {
  newBadgeInput: NewBadgeInput;
};


export type MutationCreateBookerArgs = {
  newBookerData: BookerInput;
};


export type MutationCreateBookingArgs = {
  sendMail: Scalars['Boolean'];
  newBookingData: NewBookingInput;
};


export type MutationCreateCourseArgs = {
  newCourseData: NewCourseInput;
};


export type MutationCreateCourseCategoryArgs = {
  newCourseCategoryData: NewCourseCategoryInput;
};


export type MutationCreateCourseLessonArgs = {
  newCourseLessonData: NewCourseLessonInput;
};


export type MutationCreateCourseLessonBookingArgs = {
  newCourseLessonBookingInput: NewCourseLessonBookingInput;
};


export type MutationCreateCourseLessonBookingsByCourseIdArgs = {
  courseId: Scalars['String'];
};


export type MutationCreateCourseLessonsArgs = {
  newCourseLessonData: Array<NewCourseLessonInput>;
};


export type MutationCreateCourseTypeArgs = {
  newCourseTypeData: NewCourseTypeInput;
};


export type MutationCreateDummyCamtFileForMultiplePaymentsArgs = {
  createDummySwissCamtByScheduledPaymentsInput: CreateDummySwissCamtByScheduledPaymentsInput;
};


export type MutationCreateFaUserWithOrAddEndUserRightsArgs = {
  email: Scalars['String'];
};


export type MutationCreateInstallmentRateArgs = {
  newInstallmentRateInput: NewInstallmentRateInput;
};


export type MutationCreateInvoiceArgs = {
  sendMail?: Maybe<Scalars['Boolean']>;
  newInvoiceData: InvoiceInput;
};


export type MutationCreateInvoicesByBookingIdsArgs = {
  sendMail?: Maybe<Scalars['Boolean']>;
  bookingIds: Array<Scalars['ID']>;
};


export type MutationCreateInvoicesByCourseIdArgs = {
  sendMail?: Maybe<Scalars['Boolean']>;
  courseId: Scalars['String'];
};


export type MutationCreateLocationArgs = {
  newLocationData: NewLocationInput;
};


export type MutationCreateLocationContactArgs = {
  newLocationContactData: NewLocationContactInput;
};


export type MutationCreateOrganizationArgs = {
  koOrganizationData: KoOrganizationInputType;
};


export type MutationCreateParticipationPrerequisiteArgs = {
  newParticipationPrerequisitesInput: NewParticipationPrerequisitesInput;
};


export type MutationCreateSepaXmlByIdArgs = {
  createSingleSepaXmlInput: CreateSingleSepaXmlInput;
};


export type MutationCreateSepaXmlByTimeRangeArgs = {
  createSepaXmlByTimeRangeInput: CreateSepaXmlByTimeRangeInput;
};


export type MutationCreateSettingEndUserAppArgs = {
  settingEndUserAppInput: SettingEndUserAppInput;
};


export type MutationCreateTemplateArgs = {
  newTemplateData: NewTemplateInput;
};


export type MutationDeleteAttendeeArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAttendeeBadgeArgs = {
  id: Scalars['String'];
};


export type MutationDeleteBadgeArgs = {
  id: Scalars['String'];
};


export type MutationDeleteBookerArgs = {
  id: Scalars['String'];
};


export type MutationDeleteCategoryByIdArgs = {
  id: Scalars['String'];
};


export type MutationDeleteCourseLessonBookingArgs = {
  id: Scalars['String'];
};


export type MutationDeleteFusionAuthUserByIdsArgs = {
  koOrganizationId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};


export type MutationDeleteFusionAuthUsersByEmailsArgs = {
  emailAddresses: Array<Scalars['String']>;
};


export type MutationDeleteInstallmentRateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteInstallmentRatesByCourseIdArgs = {
  courseId: Scalars['ID'];
};


export type MutationDeleteTemplateArgs = {
  id: Scalars['String'];
};


export type MutationEnablePasswordlessLoginEndUserAppArgs = {
  passwordlessIdCodeLength: Scalars['Float'];
};


export type MutationForgetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  forgotPasswordWithStateInput: ForgotPasswordWithStateInput;
};


export type MutationGeneratePaymentSchedulePreviewArgs = {
  courseId?: Maybe<Scalars['ID']>;
  paymentType: PaymentType;
  monthlySchedule: MonthlySchedule;
  bookingPrice: Scalars['Float'];
  lessonDates: Array<Scalars['DateTime']>;
};


export type MutationGenerateScheduledPaymentsForSettledBookingArgs = {
  savePayments?: Maybe<Scalars['Boolean']>;
  bookingId: Scalars['ID'];
};


export type MutationGenerateUpdatedSchedulesNewBookingPriceArgs = {
  bookingPrice: Scalars['Float'];
  scheduledPayments: Array<UpdateScheduledPaymentByBookingInput>;
};


export type MutationImportBookersArgs = {
  importBookersInput: ImportBookersInput;
};


export type MutationLoggingArgs = {
  input: LoggingInput;
};


export type MutationMergeBookersArgs = {
  mergedBookerId: Scalars['ID'];
  updateBookerData: BookerInput;
};


export type MutationModifyScheduledPaymentArgs = {
  modifyScheduledPaymentInput: ModifyScheduledPaymentInput;
};


export type MutationPasswordlessLoginArgs = {
  loginData: PasswordlessLoginInputType;
};


export type MutationPasswordlessLoginEndUserAppArgs = {
  endUserPasswordlessLoginInput: EndUserPasswordlessLoginInputType;
};


export type MutationReOrderBadgesArgs = {
  targetBadgeId: Scalars['ID'];
  movedBadgeId: Scalars['ID'];
};


export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRemoveBookingArgs = {
  bookingDeleteAction?: Maybe<BookingDeleteAction>;
  id: Scalars['ID'];
};


export type MutationRemoveBookingsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationRemoveCourseArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveCourseLessonArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveCourseTypeArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveInstructorArgs = {
  id: Scalars['String'];
};


export type MutationRemoveLocationArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveLocationContactArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveParticipationPrerequisiteArgs = {
  id: Scalars['ID'];
};


export type MutationResendBookingConfirmationArgs = {
  bookingId: Scalars['ID'];
};


export type MutationResendEmailVerificationArgs = {
  userId: Scalars['String'];
};


export type MutationResetAllUnsetteledScheduledPaymentsOfCourseArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  courseId: Scalars['ID'];
};


export type MutationResetScheduledPaymentsOfBookingArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  bookingId: Scalars['ID'];
};


export type MutationRollbackPaymentStatusArgs = {
  id: Scalars['ID'];
};


export type MutationSaveAdminArgs = {
  saveAdminData: SaveAdminInput;
};


export type MutationSaveInstructorArgs = {
  saveInstructorData: SaveInstructorInput;
};


export type MutationSendBlankMailToMultipleBookersArgs = {
  input: BlankMailInputTypes;
  file?: Maybe<Scalars['Upload']>;
};


export type MutationSendBookingConfirmationArgs = {
  bookingId: Scalars['ID'];
};


export type MutationSendBookingInvoiceArgs = {
  reversal?: Maybe<Scalars['Boolean']>;
  bookingId: Scalars['ID'];
};


export type MutationSendBookingInvoicesByBookingIdsArgs = {
  reversal?: Maybe<Scalars['Boolean']>;
  bookingIds: Array<Scalars['ID']>;
};


export type MutationSendEmailTemplateArgs = {
  sendTemplateInput: SendTemplateInput;
};


export type MutationSendTestMailArgs = {
  sandboxMode?: Maybe<Scalars['Boolean']>;
};


export type MutationSendTestMailBookerArgs = {
  bookerId: Scalars['ID'];
};


export type MutationSetNewCustomerNumberArgs = {
  newCustomerNumber: Scalars['Float'];
};


export type MutationSetNewPaymentAmountArgs = {
  paymentAmount: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationSetNewPaymentDoneDateArgs = {
  paymentDoneDate: Scalars['DateTime'];
  id: Scalars['String'];
};


export type MutationSetScheduledPaymentPaidArgs = {
  paymentDone?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
};


export type MutationSetScheduledPaymentUnpaidArgs = {
  id: Scalars['String'];
};


export type MutationSetSepaClearanceArgs = {
  sepaClearance: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationSingleUploadArgs = {
  analysis?: Maybe<Scalars['Boolean']>;
  file: Scalars['Upload'];
};


export type MutationSoftDeleteBadgeArgs = {
  id: Scalars['String'];
};


export type MutationThirdPartyLoginArgs = {
  thirdPartyLoginInput: ThirdPartyLoginInput;
};


export type MutationTransferAttendeeToBookerArgs = {
  newBookerId: Scalars['ID'];
  attendeeId: Scalars['ID'];
};


export type MutationTransferAttendeeToCourseArgs = {
  usePlaceReservation?: Maybe<Scalars['Boolean']>;
  bookingDeleteAction?: Maybe<BookingDeleteAction>;
  targetCourseId: Scalars['ID'];
  bookingId: Scalars['ID'];
};


export type MutationUnBillMultipleBookingsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateAttendanceListNoteArgs = {
  attendanceListNote?: Maybe<Scalars['String']>;
  bookingId: Scalars['ID'];
};


export type MutationUpdateAttendeeBadgeArgs = {
  editAttendeeBadgeInput: EditAttendeeBadgeInput;
};


export type MutationUpdateBadgeArgs = {
  updateBadgeInput: UpdateBadgeInput;
};


export type MutationUpdateBookerArgs = {
  fromEndUserApp?: Maybe<Scalars['Boolean']>;
  updateBookerData: BookerInput;
};


export type MutationUpdateBookerAccountEndUserAppArgs = {
  fromEndUserApp?: Maybe<Scalars['Boolean']>;
  updateBookerEndUserAppData: UpdateBookerAccountEndUserAppInput;
};


export type MutationUpdateBookingArgs = {
  editBookingData: EditBookingInput;
  id: Scalars['String'];
};


export type MutationUpdateBookingPaymentMethodArgs = {
  paymentMethodId: Scalars['ID'];
  bookingId: Scalars['ID'];
};


export type MutationUpdateCompanyAgbArgs = {
  agb: Scalars['String'];
};


export type MutationUpdateCompanyPrivacyStatementArgs = {
  privacyStatement: Scalars['String'];
};


export type MutationUpdateCompanySettingsArgs = {
  newCompanyData: CompanySettingInput;
};


export type MutationUpdateCourseArgs = {
  fillFromWaitingList?: Maybe<Scalars['Boolean']>;
  editCourseData: EditCourseInput;
  id: Scalars['String'];
};


export type MutationUpdateCourseCategoryArgs = {
  newCourseCategoryData: NewCourseCategoryInput;
  id: Scalars['String'];
};


export type MutationUpdateCourseLessonBookingArgs = {
  updateCourseLessonBookingInput: UpdateCourseLessonBookingInput;
};


export type MutationUpdateCourseTypeArgs = {
  newCourseTypeData: NewCourseTypeInput;
  id: Scalars['String'];
};


export type MutationUpdateInstallmentRateArgs = {
  updateInstallmentRateInput: UpdateInstallmentRateInput;
};


export type MutationUpdateInvoiceArgs = {
  editInvoiceData: EditInvoiceInput;
  id: Scalars['String'];
};


export type MutationUpdateLocalLocationIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationUpdateLocationArgs = {
  editLocationData: EditLocationInput;
};


export type MutationUpdateLocationContactArgs = {
  editLocationContactData: EditLocationContactInput;
};


export type MutationUpdateManyScheduledPaymentIdsArgs = {
  editScheduledPaymentData: EditScheduledPaymentInput;
  paymentIds: Array<Scalars['ID']>;
};


export type MutationUpdateNoticeStaffArgs = {
  noticeStaff: Scalars['String'];
  id: Scalars['String'];
};


export type MutationUpdateParticipationPrerequisiteArgs = {
  updateParticipationPrerequisitesInput: UpdateParticipationPrerequisitesInput;
};


export type MutationUpdateScheduledPaymentArgs = {
  editScheduledPaymentData: EditScheduledPaymentInput;
  id: Scalars['String'];
};


export type MutationUpdateSettingEndUserAppArgs = {
  updateSettingEndUserAppInput: UpdateSettingEndUserAppInput;
};


export type MutationUpdateSettingsArgs = {
  newSettingsData: SettingInput;
};


export type MutationUpdateTableColumnSettingsArgs = {
  updateTableColumnSettingsInput: UpdateTableColumnSettingsInput;
};


export type MutationUpdateTemplateArgs = {
  editTemplateData: EditTemplateInput;
  id: Scalars['String'];
};


export type MutationUserSettingSetOptimizedCourseCalendarArgs = {
  optimize: Scalars['Boolean'];
};


export type MutationUserSettingsUpdateOrCreateArgs = {
  userSettingsInput: UserSettingsInputTypes;
};


export type MutationVerifyBookersDataArgs = {
  importBookersInput: ImportBookersInput;
};

export type NewAttendeeBadgeByBadgeNameInput = {
  name: Scalars['ID'];
  attendeeId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  dateOfAchivement?: Maybe<Scalars['DateTime']>;
  courseId?: Maybe<Scalars['ID']>;
};

export type NewAttendeeBadgeInput = {
  badgeId: Scalars['ID'];
  attendeeId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  dateOfAchivement?: Maybe<Scalars['DateTime']>;
  courseId?: Maybe<Scalars['ID']>;
};

export type NewBadgeInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type NewBookingInput = {
  courseId?: Maybe<Scalars['ID']>;
  attendeeId?: Maybe<Scalars['ID']>;
  paymentMethodId?: Maybe<Scalars['ID']>;
  paymentType?: Maybe<PaymentType>;
  coursePriceId?: Maybe<Scalars['ID']>;
  specialPrice?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
  isSpecial?: Maybe<Scalars['Boolean']>;
  netPrice?: Maybe<Scalars['Float']>;
  grossPrice?: Maybe<Scalars['Float']>;
  notifyUser: Scalars['Boolean'];
  noticeBooker?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['String']>;
  noticeStaff?: Maybe<Scalars['String']>;
  foundUs?: Maybe<Scalars['String']>;
  startDateTime: Scalars['DateTime'];
  newsLetter?: Maybe<Scalars['Boolean']>;
  bookingNumberPrefix?: Maybe<Scalars['String']>;
  bookingNumber?: Maybe<Scalars['Float']>;
  scheduledPayments?: Maybe<Array<UpdateScheduledPaymentByBookingInput>>;
  monthlySchedule?: Maybe<MonthlySchedule>;
  creditedBalance?: Maybe<Scalars['Float']>;
  agbConfirmed?: Maybe<Scalars['Boolean']>;
  privacyStatementConfirmed?: Maybe<Scalars['Boolean']>;
};

export type NewBookingsKo9Input = {
  teilnahmeId: Scalars['String'];
  courseNumber: Scalars['String'];
  courseName: Scalars['String'];
  firstCourseLesson: Scalars['DateTime'];
};

export type NewCourseCategoryInput = {
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type NewCourseInput = {
  courseTypeId: Scalars['ID'];
  courseNumberFormat: CourseNumberFormat;
  customCourseNumberPrefix?: Maybe<Scalars['String']>;
  locationId: Scalars['ID'];
  venueId: Scalars['ID'];
  instructors: Array<CourseInstructorIdInput>;
  paymentInterval: PaymentInterval;
  minAttendees: Scalars['Float'];
  maxAttendees: Scalars['Float'];
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  cycle: CourseCycle;
  annotation: Scalars['String'];
  websiteInfo: Scalars['String'];
  lessons: Array<NewCourseLessonInput>;
  coursePrices: Array<NewCoursePriceInput>;
  defaultLessonCount: Scalars['Float'];
  allowMonthlyPayment?: Maybe<Scalars['Boolean']>;
  allowUpFrontPayment?: Maybe<Scalars['Boolean']>;
  monthlySchedule?: Maybe<MonthlySchedule>;
  isActive?: Maybe<Scalars['Boolean']>;
  showInWeb?: Maybe<Scalars['Boolean']>;
  installmentRates?: Maybe<Array<CreateInstallmentRateByCourseCreateInput>>;
  fee?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
  paymentType?: Maybe<PaymentType>;
  dateOfFirstInstallment?: Maybe<Scalars['DateTime']>;
};

export type NewCourseLessonBookingInput = {
  bookingId: Scalars['String'];
  courseLessonId: Scalars['String'];
  attendance?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['String']>;
};

export type NewCourseLessonInput = {
  courseId?: Maybe<Scalars['ID']>;
  locationId: Scalars['ID'];
  venueId: Scalars['ID'];
  instructors: Array<CourseInstructorIdInput>;
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
};

export type NewCoursePriceInput = {
  vat: Scalars['Float'];
  isSpecial?: Maybe<Scalars['Boolean']>;
  netPrice?: Maybe<Scalars['Float']>;
  grossPrice: Scalars['Float'];
  discount?: Maybe<Scalars['Float']>;
  discountUnit?: Maybe<DiscountUnit>;
  bookings?: Maybe<Array<NewBookingInput>>;
  course?: Maybe<NewCourseInput>;
  courseId?: Maybe<Scalars['ID']>;
  includedEntranceFee?: Maybe<Scalars['Float']>;
};

export type NewCourseTypeInput = {
  isActive: Scalars['Boolean'];
  showInWeb: Scalars['Boolean'];
  name: Scalars['String'];
  shortHandId: Scalars['String'];
  categoryId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  minAge?: Maybe<Scalars['Float']>;
  maxAge?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  paymentInterval?: Maybe<PaymentInterval>;
  vat?: Maybe<Scalars['Float']>;
  minAttendees?: Maybe<Scalars['Float']>;
  maxAttendees?: Maybe<Scalars['Float']>;
  annotation?: Maybe<Scalars['String']>;
  websiteInfo?: Maybe<Scalars['String']>;
  inWeekPlanner: Scalars['Boolean'];
  bold: Scalars['Boolean'];
  color: Scalars['String'];
  participationPrerequisites?: Maybe<Array<UpdateParticipationPrerequisitesInput>>;
};

export type NewInstallmentPlanInput = {
  courseId?: Maybe<Scalars['String']>;
  coursePrice: Scalars['Float'];
  vat: Scalars['Float'];
  paymentInterval: PaymentInterval;
  paymentType: PaymentType;
  monthlySchedule?: Maybe<MonthlySchedule>;
  courseLessonStartDates: Array<Scalars['DateTime']>;
  monthCount?: Maybe<Scalars['Float']>;
  dateOfFirstInstallment?: Maybe<Scalars['DateTime']>;
};

export type NewInstallmentRateInput = {
  courseId: Scalars['String'];
  installmentAmount: Scalars['Float'];
  installmentDue: Scalars['DateTime'];
};

export type NewLocationContactInput = {
  salutation: SalutationType;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type NewLocationInput = {
  name: Scalars['String'];
  isPublished: Scalars['Boolean'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  street: Scalars['String'];
  website: Scalars['String'];
  abbreviation: Scalars['String'];
  info: Scalars['String'];
  venues: Array<NewVenueInput>;
  generalLocationContact: GeneralLocationContactInput;
  locationContacts: Array<LocationContactIdInput>;
};

export type NewParticipationPrerequisitesInput = {
  id?: Maybe<Scalars['ID']>;
  enabled: Scalars['Boolean'];
  groupDescription?: Maybe<Scalars['String']>;
  groupLabel?: Maybe<Scalars['String']>;
  optionalInputFields?: Maybe<Array<OptionalInputFieldsInput>>;
  minCheckCount?: Maybe<Scalars['Float']>;
  courseTypeId: Scalars['String'];
};

export type NewSepaXmlInput = {
  creationDate: Scalars['DateTime'];
  initiatorId: Scalars['String'];
  initiatorName: Scalars['String'];
  positions: Array<SepaXmlPositionInput>;
};

export type NewTemplateInput = {
  templateType: TemplateType;
  headerText?: Maybe<Scalars['String']>;
  footerText?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  mailDataType?: Maybe<MailDataType>;
};

export type NewVenueInput = {
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  rent: Scalars['Float'];
  rentType: RentType;
};

export enum Operation {
  Eq = 'EQ',
  In = 'IN',
  Like = 'LIKE',
  Gte = 'GTE',
  Lte = 'LTE'
}

export enum Operator {
  And = 'AND',
  Or = 'OR'
}

export type OptionalInputFieldsInput = {
  enabeld?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Scalars['JSONObject']>>;
  required?: Maybe<Scalars['Boolean']>;
};

export type PaginationInput = {
  page?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sortBy?: Maybe<Scalars['String']>;
};

export type ParticipationPrerequisites = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  groupDescription?: Maybe<Scalars['String']>;
  groupLabel?: Maybe<Scalars['String']>;
  optionalInputFields?: Maybe<Array<Scalars['JSONObject']>>;
  minCheckCount?: Maybe<Scalars['Float']>;
  courseTypeId?: Maybe<Scalars['ID']>;
  courseType: CourseType;
};

export type PasswordlessLoginInputType = {
  email: Scalars['String'];
};

export type Payment = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  reference: Scalars['String'];
  amount: Scalars['Float'];
  swissQRFile?: Maybe<SwissQrFile>;
  valutaDate: Scalars['DateTime'];
  scheduledPayment: ScheduledPayment;
};

export type PaymentInput = {
  bookingId: Scalars['ID'];
};

export enum PaymentInterval {
  PerCourse = 'PerCourse',
  PerLesson = 'PerLesson'
}

export type PaymentMethod = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  cash?: Maybe<Scalars['Boolean']>;
  esr?: Maybe<Scalars['Boolean']>;
  creditCardAccountholder?: Maybe<Scalars['String']>;
  creditcardNumber?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  validFrom?: Maybe<Scalars['DateTime']>;
  cvc?: Maybe<Scalars['String']>;
  paypalAccountholder?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  sepaAccountholder?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentTransferType>;
  bookings?: Maybe<Array<Booking>>;
  scheduledPayments?: Maybe<Array<ScheduledPayment>>;
  booker?: Maybe<Booker>;
  bookerId?: Maybe<Scalars['ID']>;
  bankTransfer?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<CreatedBy>;
};

export enum PaymentTransferType {
  Cash = 'Cash',
  SepaDebit = 'SepaDebit',
  BankTransfer = 'BankTransfer',
  Paypal = 'Paypal',
  Creditcard = 'Creditcard',
  Esr = 'Esr',
  None = 'None'
}

export enum PaymentType {
  PrePaid = 'PrePaid',
  Monthly = 'Monthly',
  OnTheSpot = 'OnTheSpot',
  Bill = 'Bill'
}

export type Query = {
  LocalState: LocalState;
  attendanceListByCourse: Array<Booking>;
  attendee: Attendee;
  attendeeCount: Scalars['Float'];
  attendees: AttendeeWithPagination;
  attendeesByBookerFirstLast: AttendeeWithPagination;
  authenticate: UserType;
  badge: Badge;
  badges: Array<Badge>;
  bookedAttendeesByCourseId: Array<Attendee>;
  bookedSpots: Scalars['Float'];
  booker: Booker;
  bookers: BookerWithPagination;
  booking: Array<Booking>;
  bookingById: Booking;
  bookings: BookingWithPagination;
  bookingsByUser: Array<Booking>;
  bookingsKo9: Array<BookingsKo9>;
  bookingsKo9ByAttendeeId: Array<BookingsKo9>;
  canBecomeInstructor: Scalars['Boolean'];
  checkAttendeesAgeAtCourseStart: Scalars['JSONObject'];
  checkEmailUniqueness: Scalars['Boolean'];
  checkIfIsBookedIntoCourse: Scalars['Boolean'];
  checkIfTemplateOfTypeExists: Scalars['JSONObject'];
  cities: Array<LocationCity>;
  companyInfo: KoOrganization;
  companyPublic: CompanySetting;
  companySettings: CompanySetting;
  courseById: Course;
  courseByIdPublic: Course;
  courseByLocationId: Array<Course>;
  courseCategories: Array<CourseCategory>;
  courseCategoriesPublic: Array<CourseType>;
  courseCategoryById: CourseCategory;
  courseHasAtLeastOneCourseLessonBooking: Scalars['Boolean'];
  courseLessonBooking: CourseLessonBooking;
  courseLessonBookingByCourseLessonAndBooking: CourseLessonBooking;
  courseLessonBookingsByCourse: Array<CourseLessonBooking>;
  courseLessonById: CourseLesson;
  courseLessons: Array<CourseLesson>;
  courseLessonsByCourseId: Array<CourseLesson>;
  courseLessonsByCourseIdPublic: Array<CourseLesson>;
  courseLessonsByDate: Array<CourseLesson>;
  courseLessonsForMonthView: Array<CourseLesson>;
  courseLessonsWithPagination: CourseLessonWithPagination;
  courseTypeById: CourseType;
  courseTypeStateById: CourseType;
  courseTypes: Array<CourseType>;
  courseTypesPublic: Array<CourseType>;
  courses: Array<Course>;
  coursesPublic: Array<Course>;
  coursesWithPagination: CourseWithPagination;
  coursesWithPaginationPublic: CourseWithPagination;
  customerById: Customer;
  emailNotificationById: EmailNotification;
  emailNotifications: EmailNotificationWithPagination;
  exportedSepaXml: ExportedSepaXml;
  exportedSepaXmls: ExportedSepaXmlWithPagination;
  faUserByEmail: FaUser;
  faUserByUserId?: Maybe<FaUser>;
  findByCourseId: Instructor;
  findDuplicateBookersUnperformant: Array<Array<Booker>>;
  findDuplicateBookersWithoutEmail: Array<Array<Booker>>;
  findInstructorById: Instructor;
  findInstructorByIds: Array<Instructor>;
  findInstructorsByLocation: Array<Instructor>;
  getAllCoursesWithAllowMonthlyPayment: Array<Course>;
  getAttendeeBadge: AttendeeBadge;
  getAttendeeBadges: Array<AttendeeBadge>;
  getAttendeeBadgesByAttendeeId: Array<AttendeeBadge>;
  getAttendeeBadgesByBadgeId: Array<AttendeeBadge>;
  getBankData: Scalars['JSONObject'];
  getBilledBookings: Array<Booking>;
  getBookerByEmail?: Maybe<Booker>;
  getBookersByBookingIds: Array<Booker>;
  getBookersByCourseIds: Array<Booker>;
  getBookersByCourseWithBookingType: Array<Booker>;
  getBookingsAsCsv: Scalars['String'];
  getBookingsByAttendeeId: Array<Booking>;
  getBookingsByAttendeeIdWithOptions: Array<Booking>;
  getBookingsByCourse: BookingWithPagination;
  getBookingsByCourseIds: BookingWithPagination;
  getBookingsByIds: Array<Booking>;
  getBookingsByInvoiceIds: Array<Booking>;
  getCanceledBookings: BookingWithPagination;
  getColumnsForTable: Array<Scalars['String']>;
  getCourseMinMaxAge: Scalars['JSONObject'];
  getDuplicateBookers: Array<Array<Booker>>;
  getEmailTemplateById: MjmlTemplate;
  getInfoUserByUserId: UserType;
  getInstallmentPlanPreview: Scalars['JSONObject'];
  getInvoiceDummyPreview: Scalars['JSONObject'];
  getInvoicePreview: Scalars['JSONObject'];
  getLastCustomer: Customer;
  getMonthlyPaymentPreview: Scalars['JSONObject'];
  getPasswordlessLoginSettings: Scalars['JSONObject'];
  getPaymentMethodTypeById: Scalars['String'];
  getPaymentMethodsByBookerId: Array<PaymentMethod>;
  getSentMailjetMessage: Scalars['JSONObject'];
  getSettledBookings: BookingWithPagination;
  getUnBilledBookings: Array<Booking>;
  getUnSettledBookings: BookingWithPagination;
  getUserRoles: Scalars['JSONObject'];
  installmentRate: InstallmentRate;
  installmentRates: Array<InstallmentRate>;
  installmentRatesByCourseId: Array<InstallmentRate>;
  instructor: Instructor;
  instructors: Array<Instructor>;
  instructorsPublic: Array<Instructor>;
  instructorsWithPagination: InstructorsWithPagination;
  invoice: Invoice;
  invoiceBodyByBookingId: Invoice;
  invoiceBodyById: Invoice;
  invoices: InvoiceWithPagination;
  invoicesWithRelations: Array<Invoice>;
  isBadgeNameUnique: Scalars['Boolean'];
  isCourseNameUnique: Scalars['Boolean'];
  isInstructor: Scalars['Boolean'];
  isUniqueEmail: Scalars['Boolean'];
  isUserEmailVerified: Scalars['Boolean'];
  koOrganizations: Array<KoOrganization>;
  locationById: Location;
  locationContactById: LocationContact;
  locationContacts: Array<LocationContact>;
  locationContactsByLocation: Array<LocationContact>;
  locations: Array<Location>;
  locationsByCity: Array<Location>;
  locationsByLocationContactId: Array<Location>;
  locationsPublic: Array<Location>;
  locationsSortedByCity: Array<LocationsGroupedByCity>;
  locationsWithPagination: LocationsWithPagination;
  notificationsByBookerOrEmail: EmailNotificationWithPagination;
  notificationsByBooking: Array<EmailNotification>;
  ownerEmail: CompanySetting;
  paidScheduledPayments: ScheduledPaymentsWithPagination;
  participationPrerequisite: ParticipationPrerequisites;
  participationPrerequisites: Array<ParticipationPrerequisites>;
  participationPrerequisitesByCourseTypeId: Array<ParticipationPrerequisites>;
  paymentMethod: PaymentMethod;
  paymentMethods: Array<PaymentMethod>;
  payments: Array<Payment>;
  reversalInvoiceBodyById: ReversalInvoice;
  reversalInvoices: ReversalInvoiceWithPagination;
  scheduledPayment: Array<ScheduledPayment>;
  scheduledPaymentById: ScheduledPayment;
  scheduledPayments: ScheduledPaymentsWithPagination;
  scheduledPaymentsByBookerId: Array<ScheduledPayment>;
  scheduledPaymentsByBookingId: Array<ScheduledPayment>;
  searchAttendeesByFullName: Array<Attendee>;
  searchBilledPayments: Array<PaymentMethod>;
  searchBookerByStreetAndHouseNumberAndPpostCodeAndCity: Array<Booker>;
  searchBookersByFirstAndLastName: Array<Booker>;
  searchBookersByStreetAndHouseNumberAndPostCodeAndCity: Array<Booker>;
  searchBookingByBookingNumberAndBookingNumberPrefix: Array<Booking>;
  searchBookingByCourseLocationPrefixAndCourseNumber: Array<Booking>;
  searchCourseByCourseLocationPrefixAndCourseNumber: Array<Course>;
  searchCourseByInstructorFirstNameAndLastName: Array<Course>;
  searchCustomerByCustomerNumberPrefixAndCustomerNumber: Array<Customer>;
  searchCustomerByFirstNameAndLastName: Array<Customer>;
  searchInvoicesByCourseNumber: Array<Invoice>;
  searchInvoicesByInvoiceNumberAndInvoiceNumberPrefix: Array<Invoice>;
  searchLocationsByName: Array<Location>;
  searchLocationsByPhoneNumberAndEmail: Array<Location>;
  searchStaffByFullnameAndAddress: Array<Staff>;
  searchUnBilledPayments: Array<PaymentMethod>;
  searchUnSettledBooking: Array<Booking>;
  sentEmailStatusFromMailjet: Scalars['JSONObject'];
  settingEndUserApp: SettingEndUserApp;
  settingEndUserAppPublic: SettingEndUserApp;
  settings: Setting;
  swissQRFiles: Array<SwissQrFile>;
  template: Template;
  templateByType: Template;
  templates: Array<Template>;
  testFusioAuthFunctions: Scalars['JSONObject'];
  unpaidScheduledPayments: ScheduledPaymentsWithPagination;
  userSettings?: Maybe<UserSetting>;
};


export type QueryAttendanceListByCourseArgs = {
  courseId: Scalars['String'];
};


export type QueryAttendeeArgs = {
  id: Scalars['String'];
};


export type QueryAttendeeCountArgs = {
  options?: Maybe<ArrayDataOptions>;
};


export type QueryAttendeesArgs = {
  options?: Maybe<ArrayDataOptions>;
};


export type QueryAttendeesByBookerFirstLastArgs = {
  searchKey: Scalars['String'];
};


export type QueryBadgeArgs = {
  id: Scalars['String'];
};


export type QueryBookedAttendeesByCourseIdArgs = {
  courseId: Scalars['ID'];
};


export type QueryBookedSpotsArgs = {
  courseId: Scalars['String'];
};


export type QueryBookerArgs = {
  id: Scalars['String'];
};


export type QueryBookersArgs = {
  options?: Maybe<ArrayDataOptions>;
};


export type QueryBookingArgs = {
  id: Scalars['String'];
};


export type QueryBookingByIdArgs = {
  id: Scalars['ID'];
};


export type QueryBookingsArgs = {
  onlyCurrentUser?: Maybe<Scalars['Boolean']>;
  options?: Maybe<ArrayDataOptions>;
};


export type QueryBookingsByUserArgs = {
  userId: Scalars['String'];
};


export type QueryBookingsKo9ByAttendeeIdArgs = {
  attendeeId: Scalars['String'];
};


export type QueryCanBecomeInstructorArgs = {
  email: Scalars['String'];
};


export type QueryCheckAttendeesAgeAtCourseStartArgs = {
  attendeeId: Scalars['String'];
  courseId: Scalars['String'];
};


export type QueryCheckEmailUniquenessArgs = {
  email: Scalars['String'];
};


export type QueryCheckIfIsBookedIntoCourseArgs = {
  attendeeId: Scalars['ID'];
  courseId: Scalars['ID'];
};


export type QueryCheckIfTemplateOfTypeExistsArgs = {
  templateType: TemplateType;
};


export type QueryCourseByIdArgs = {
  id: Scalars['String'];
};


export type QueryCourseByIdPublicArgs = {
  id: Scalars['String'];
};


export type QueryCourseByLocationIdArgs = {
  id: Scalars['ID'];
};


export type QueryCourseCategoryByIdArgs = {
  id: Scalars['String'];
};


export type QueryCourseHasAtLeastOneCourseLessonBookingArgs = {
  courseId: Scalars['String'];
};


export type QueryCourseLessonBookingArgs = {
  id: Scalars['String'];
};


export type QueryCourseLessonBookingByCourseLessonAndBookingArgs = {
  bookingId: Scalars['String'];
  courseLessonId: Scalars['String'];
};


export type QueryCourseLessonBookingsByCourseArgs = {
  courseId: Scalars['String'];
};


export type QueryCourseLessonByIdArgs = {
  id: Scalars['String'];
};


export type QueryCourseLessonsArgs = {
  ids?: Maybe<Array<Scalars['String']>>;
};


export type QueryCourseLessonsByCourseIdArgs = {
  courseId: Scalars['ID'];
};


export type QueryCourseLessonsByCourseIdPublicArgs = {
  courseId: Scalars['ID'];
};


export type QueryCourseLessonsByDateArgs = {
  date: Scalars['DateTime'];
};


export type QueryCourseLessonsForMonthViewArgs = {
  onlyCurrentUser?: Maybe<Scalars['Boolean']>;
  options?: Maybe<ArrayDataOptions>;
  year: Scalars['Float'];
  month: Scalars['Float'];
};


export type QueryCourseLessonsWithPaginationArgs = {
  onlyCurrentUser?: Maybe<Scalars['Boolean']>;
  options?: Maybe<ArrayDataOptions>;
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryCourseTypeByIdArgs = {
  id: Scalars['String'];
};


export type QueryCourseTypeStateByIdArgs = {
  id: Scalars['String'];
};


export type QueryCoursesArgs = {
  filterExpression?: Maybe<FilterExpressionInput>;
  ids?: Maybe<Array<Scalars['String']>>;
};


export type QueryCoursesPublicArgs = {
  showOnlyInWebActive?: Maybe<Scalars['Boolean']>;
  courseCategoryId?: Maybe<Scalars['ID']>;
  instructorId?: Maybe<Scalars['ID']>;
  dayFilter?: Maybe<DayFilter>;
  filterExpression?: Maybe<FilterExpressionInput>;
  ids?: Maybe<Array<Scalars['String']>>;
};


export type QueryCoursesWithPaginationArgs = {
  currentUserOnly?: Maybe<Scalars['Boolean']>;
  options?: Maybe<ArrayDataOptions>;
};


export type QueryCoursesWithPaginationPublicArgs = {
  filters?: Maybe<CourseListFilters>;
  filterExpression?: Maybe<FilterExpressionInput>;
  options?: Maybe<ArrayDataOptions>;
};


export type QueryCustomerByIdArgs = {
  id: Scalars['String'];
};


export type QueryEmailNotificationByIdArgs = {
  id: Scalars['String'];
};


export type QueryEmailNotificationsArgs = {
  options?: Maybe<ArrayDataOptions>;
};


export type QueryExportedSepaXmlArgs = {
  id: Scalars['String'];
};


export type QueryExportedSepaXmlsArgs = {
  options?: Maybe<ArrayDataOptions>;
};


export type QueryFaUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryFaUserByUserIdArgs = {
  userId: Scalars['String'];
};


export type QueryFindByCourseIdArgs = {
  id: Scalars['String'];
};


export type QueryFindDuplicateBookersUnperformantArgs = {
  options?: Maybe<ArrayDataOptions>;
};


export type QueryFindDuplicateBookersWithoutEmailArgs = {
  options?: Maybe<ArrayDataOptions>;
};


export type QueryFindInstructorByIdArgs = {
  instructorId: Scalars['String'];
};


export type QueryFindInstructorByIdsArgs = {
  instructorIds: Array<Scalars['ID']>;
};


export type QueryFindInstructorsByLocationArgs = {
  locationId: Scalars['String'];
};


export type QueryGetAttendeeBadgeArgs = {
  id: Scalars['String'];
};


export type QueryGetAttendeeBadgesByAttendeeIdArgs = {
  attendeeId: Scalars['String'];
};


export type QueryGetAttendeeBadgesByBadgeIdArgs = {
  badgeId: Scalars['String'];
};


export type QueryGetBankDataArgs = {
  iban: Scalars['String'];
};


export type QueryGetBilledBookingsArgs = {
  pagination?: Maybe<PaginationInput>;
};


export type QueryGetBookerByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetBookersByBookingIdsArgs = {
  bookingIds: Array<Scalars['String']>;
};


export type QueryGetBookersByCourseIdsArgs = {
  courseIds: Array<Scalars['String']>;
};


export type QueryGetBookersByCourseWithBookingTypeArgs = {
  courseId: Scalars['String'];
};


export type QueryGetBookingsAsCsvArgs = {
  bookingIds: Array<Scalars['String']>;
  filterFields: Array<Scalars['String']>;
};


export type QueryGetBookingsByAttendeeIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetBookingsByAttendeeIdWithOptionsArgs = {
  canceledBookings?: Maybe<Scalars['Boolean']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
};


export type QueryGetBookingsByCourseArgs = {
  options?: Maybe<ArrayDataOptions>;
  id: Scalars['ID'];
};


export type QueryGetBookingsByCourseIdsArgs = {
  options?: Maybe<ArrayDataOptions>;
  ids: Array<Scalars['ID']>;
};


export type QueryGetBookingsByIdsArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryGetBookingsByInvoiceIdsArgs = {
  invoiceIds: Array<Scalars['String']>;
};


export type QueryGetCanceledBookingsArgs = {
  options?: Maybe<ArrayDataOptions>;
};


export type QueryGetColumnsForTableArgs = {
  table: TablesEnum;
};


export type QueryGetCourseMinMaxAgeArgs = {
  courseId: Scalars['String'];
};


export type QueryGetDuplicateBookersArgs = {
  options?: Maybe<ArrayDataOptions>;
};


export type QueryGetEmailTemplateByIdArgs = {
  templateId: Scalars['String'];
};


export type QueryGetInfoUserByUserIdArgs = {
  userId: Scalars['String'];
};


export type QueryGetInstallmentPlanPreviewArgs = {
  newInstallmentPlanInput: NewInstallmentPlanInput;
};


export type QueryGetInvoiceDummyPreviewArgs = {
  monthlySchedule?: Maybe<MonthlySchedule>;
  paymentType?: Maybe<PaymentType>;
  reversal?: Maybe<Scalars['Boolean']>;
  editTemplateInput?: Maybe<EditTemplateInput>;
};


export type QueryGetInvoicePreviewArgs = {
  invoiceId: Scalars['String'];
};


export type QueryGetMonthlyPaymentPreviewArgs = {
  monthlyPaymentPreviewOptions: ScheduledPaymentPreviewInput;
};


export type QueryGetPaymentMethodTypeByIdArgs = {
  paymentMethodId: Scalars['String'];
};


export type QueryGetPaymentMethodsByBookerIdArgs = {
  bookerId: Scalars['String'];
};


export type QueryGetSentMailjetMessageArgs = {
  messageId: Scalars['String'];
};


export type QueryGetSettledBookingsArgs = {
  options?: Maybe<ArrayDataOptions>;
};


export type QueryGetUnBilledBookingsArgs = {
  pagination?: Maybe<PaginationInput>;
};


export type QueryGetUnSettledBookingsArgs = {
  options?: Maybe<ArrayDataOptions>;
};


export type QueryInstallmentRateArgs = {
  id: Scalars['ID'];
};


export type QueryInstallmentRatesByCourseIdArgs = {
  courseId: Scalars['ID'];
};


export type QueryInstructorArgs = {
  id: Scalars['String'];
};


export type QueryInstructorsWithPaginationArgs = {
  onlyCurrentUser?: Maybe<Scalars['Boolean']>;
  options?: Maybe<ArrayDataOptions>;
};


export type QueryInvoiceArgs = {
  id: Scalars['String'];
};


export type QueryInvoiceBodyByBookingIdArgs = {
  bookingId: Scalars['String'];
};


export type QueryInvoiceBodyByIdArgs = {
  id: Scalars['String'];
};


export type QueryInvoicesArgs = {
  options?: Maybe<ArrayDataOptions>;
};


export type QueryIsBadgeNameUniqueArgs = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


export type QueryIsCourseNameUniqueArgs = {
  courseId: Scalars['String'];
  customCourseNumberPrefix: Scalars['String'];
};


export type QueryIsUniqueEmailArgs = {
  email: Scalars['String'];
};


export type QueryIsUserEmailVerifiedArgs = {
  userId: Scalars['String'];
};


export type QueryLocationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryLocationContactByIdArgs = {
  id: Scalars['String'];
};


export type QueryLocationContactsByLocationArgs = {
  locationId: Scalars['ID'];
};


export type QueryLocationsByCityArgs = {
  city: Scalars['String'];
};


export type QueryLocationsByLocationContactIdArgs = {
  id: Scalars['ID'];
};


export type QueryLocationsWithPaginationArgs = {
  currentUserOnly?: Maybe<Scalars['Boolean']>;
  options?: Maybe<ArrayDataOptions>;
};


export type QueryNotificationsByBookerOrEmailArgs = {
  options?: Maybe<ArrayDataOptions>;
  email?: Maybe<Scalars['String']>;
  bookerId: Scalars['ID'];
};


export type QueryNotificationsByBookingArgs = {
  bookingId: Scalars['ID'];
};


export type QueryPaidScheduledPaymentsArgs = {
  bookingId?: Maybe<Scalars['ID']>;
  options?: Maybe<ArrayDataOptions>;
};


export type QueryParticipationPrerequisiteArgs = {
  id: Scalars['ID'];
};


export type QueryParticipationPrerequisitesByCourseTypeIdArgs = {
  courseTypeId: Scalars['ID'];
};


export type QueryPaymentMethodArgs = {
  id: Scalars['String'];
};


export type QueryReversalInvoiceBodyByIdArgs = {
  id: Scalars['String'];
};


export type QueryReversalInvoicesArgs = {
  options?: Maybe<ArrayDataOptions>;
};


export type QueryScheduledPaymentArgs = {
  id: Scalars['String'];
};


export type QueryScheduledPaymentByIdArgs = {
  id: Scalars['ID'];
};


export type QueryScheduledPaymentsArgs = {
  options?: Maybe<ArrayDataOptions>;
};


export type QueryScheduledPaymentsByBookerIdArgs = {
  bookerId: Scalars['ID'];
};


export type QueryScheduledPaymentsByBookingIdArgs = {
  bookingId: Scalars['ID'];
};


export type QuerySearchAttendeesByFullNameArgs = {
  name: Scalars['String'];
};


export type QuerySearchBilledPaymentsArgs = {
  searchKey: Scalars['String'];
};


export type QuerySearchBookerByStreetAndHouseNumberAndPpostCodeAndCityArgs = {
  searchKey: Scalars['String'];
};


export type QuerySearchBookersByFirstAndLastNameArgs = {
  searchKey: Scalars['String'];
};


export type QuerySearchBookersByStreetAndHouseNumberAndPostCodeAndCityArgs = {
  searchKey: Scalars['String'];
};


export type QuerySearchBookingByBookingNumberAndBookingNumberPrefixArgs = {
  searchKey: Scalars['String'];
};


export type QuerySearchBookingByCourseLocationPrefixAndCourseNumberArgs = {
  searchKey: Scalars['String'];
};


export type QuerySearchCourseByCourseLocationPrefixAndCourseNumberArgs = {
  searchKey: Scalars['String'];
};


export type QuerySearchCourseByInstructorFirstNameAndLastNameArgs = {
  searchKey: Scalars['String'];
};


export type QuerySearchCustomerByCustomerNumberPrefixAndCustomerNumberArgs = {
  searchKey: Scalars['String'];
};


export type QuerySearchCustomerByFirstNameAndLastNameArgs = {
  searchKey: Scalars['String'];
};


export type QuerySearchInvoicesByCourseNumberArgs = {
  courseNumber: Scalars['Float'];
};


export type QuerySearchInvoicesByInvoiceNumberAndInvoiceNumberPrefixArgs = {
  searchKey: Scalars['String'];
};


export type QuerySearchLocationsByNameArgs = {
  name: Scalars['String'];
};


export type QuerySearchLocationsByPhoneNumberAndEmailArgs = {
  searchKey: Scalars['String'];
};


export type QuerySearchStaffByFullnameAndAddressArgs = {
  searchKey: Scalars['String'];
};


export type QuerySearchUnBilledPaymentsArgs = {
  searchKey: Scalars['String'];
};


export type QuerySearchUnSettledBookingArgs = {
  searchKey: Scalars['String'];
};


export type QuerySentEmailStatusFromMailjetArgs = {
  messageID: Scalars['String'];
};


export type QueryTemplateArgs = {
  id: Scalars['String'];
};


export type QueryTemplateByTypeArgs = {
  templateType: TemplateType;
};


export type QueryTestFusioAuthFunctionsArgs = {
  userId: Scalars['String'];
};


export type QueryUnpaidScheduledPaymentsArgs = {
  bookingId?: Maybe<Scalars['ID']>;
  options?: Maybe<ArrayDataOptions>;
};

export type Rate = {
  key: Scalars['String'];
  value: RateDetail;
};

export type RateDetail = {
  amount: Scalars['String'];
  due: Scalars['String'];
};

export enum RentType {
  Per60Minutes = 'Per60Minutes',
  Per45Minutes = 'Per45Minutes',
  PerDay = 'PerDay',
  PerCourseHour = 'PerCourseHour',
  PerCourse = 'PerCourse'
}

export type ResetPasswordStateType = {
  resetPasswordUrl: Scalars['String'];
};

export type ResetScheduledPaymentsOfBookingResult = {
  bookingId: Scalars['String'];
  bookingNumber: Scalars['String'];
  bookingPrice: Scalars['Float'];
  coursePrice: Scalars['Float'];
  creditedBalance?: Maybe<Scalars['Float']>;
  currentScheduledPaymentsCount: Scalars['Float'];
  newScheduledPaymentsCount: Scalars['Float'];
  currentScheduledPaymnetsCount: Scalars['Float'];
  installmentPlanAmountsAndDueDates: Array<Rate>;
  newScheduledPaymentsAmountsAndDueDates: Array<Rate>;
  currentScheduledPaymentsAmountsAndDueDates: Array<Rate>;
};

export type ResetScheduledPaymentsOfCourseResult = {
  courseId: Scalars['String'];
  courseNumber: Scalars['String'];
  coursePrice: Scalars['Float'];
  courseDueDates: Array<Rate>;
  bookings: Array<ResetScheduledPaymentsOfBookingResult>;
};

export type ReversalInvoice = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  invoiceNumber: Scalars['Float'];
  invoiceNumberPrefix: Scalars['String'];
  booking: Booking;
  bookingId: Scalars['ID'];
  scheduledPayments?: Maybe<Array<ScheduledPayment>>;
  qrReference?: Maybe<Scalars['String']>;
  reversalInvoiceBody: Scalars['String'];
  koOrganization: KoOrganization;
  reversalInvoiceFileName: Scalars['String'];
};

export type ReversalInvoiceWithPagination = {
  existsMore: Scalars['Boolean'];
  items: Array<ReversalInvoice>;
  total: Scalars['Float'];
};

export enum SalutationType {
  Mr = 'Mr',
  Ms = 'Ms',
  Mx = 'Mx'
}

export type SaveAdminInput = {
  id: Scalars['String'];
  userId: Scalars['String'];
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  roles?: Maybe<Array<Scalars['String']>>;
  salutation: SalutationType;
  birthDate?: Maybe<Scalars['String']>;
  birthPlace?: Maybe<Scalars['String']>;
  annotation?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  joinDate?: Maybe<Scalars['String']>;
  leaveDate?: Maybe<Scalars['String']>;
  socialSecurityNumber?: Maybe<Scalars['String']>;
  healthInsuranceFund?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
  accountHolder?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
};

export type SaveInstructorInput = {
  id: Scalars['String'];
  userId: Scalars['String'];
  level: Scalars['Float'];
  salutation: SalutationType;
  assignedLocations: Array<AssignedLocationIdInput>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  active: Scalars['Boolean'];
  showInWeb: Scalars['Boolean'];
  birthDate?: Maybe<Scalars['String']>;
  birthPlace?: Maybe<Scalars['String']>;
  annotation?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  joinDate?: Maybe<Scalars['String']>;
  leaveDate?: Maybe<Scalars['String']>;
  socialSecurityNumber?: Maybe<Scalars['String']>;
  healthInsuranceFund?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
  accountHolder?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
};

export type ScheduledPayment = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  bookingId: Scalars['ID'];
  booking: Booking;
  paymentAmount: Scalars['Float'];
  openAmount?: Maybe<Scalars['Float']>;
  paymentDue: Scalars['DateTime'];
  sepaClearance: Scalars['Boolean'];
  paymentDone?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['ID']>;
  invoice?: Maybe<Invoice>;
  paymentTransferType?: Maybe<PaymentTransferType>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId?: Maybe<Scalars['ID']>;
};

export type ScheduledPaymentPreviewInput = {
  courseId: Scalars['ID'];
  specialPrice?: Maybe<Scalars['Float']>;
  editMode?: Maybe<Scalars['Boolean']>;
  monthlySchedule?: Maybe<MonthlySchedule>;
};

export type ScheduledPaymentsWithPagination = {
  existsMore: Scalars['Boolean'];
  items: Array<ScheduledPayment>;
  total: Scalars['Float'];
};

export type SecureIdentity = {
  breachedPasswordLastCheckedInstant?: Maybe<Scalars['Float']>;
  connectorId?: Maybe<Scalars['String']>;
  encryptionScheme?: Maybe<Scalars['String']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  passwordChangeReason?: Maybe<Scalars['String']>;
  passwordChangeRequired?: Maybe<Scalars['Boolean']>;
  passwordLastUpdateInstant?: Maybe<Scalars['Float']>;
  salt?: Maybe<Scalars['String']>;
  uniqueUsername?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type SelectedAttendee = {
  id: Scalars['ID'];
  firstname: Scalars['String'];
  bookedCourseIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SelectedPaymentMethod = {
  id: Scalars['ID'];
  type: Scalars['String'];
  bookedCourseIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SendParamsMessageInput = {
  From: SendParamsRecipient;
  Sender?: Maybe<SendParamsRecipient>;
  To: Array<SendParamsRecipient>;
  Cc?: Maybe<Array<SendParamsRecipient>>;
  Bcc?: Maybe<Array<SendParamsRecipient>>;
  ReplyTo?: Maybe<SendParamsRecipient>;
  Variables?: Maybe<MailjetVariables>;
  TemplateID?: Maybe<Scalars['Float']>;
  TemplateLanguage?: Maybe<Scalars['Boolean']>;
  Subject: Scalars['String'];
  TextPart?: Maybe<Scalars['String']>;
  HTMLPart?: Maybe<Scalars['String']>;
  MonitoringCategory?: Maybe<Scalars['String']>;
  URLTags?: Maybe<Scalars['String']>;
  CustomCampaign?: Maybe<Scalars['String']>;
  DeduplicateCampaign?: Maybe<Scalars['Boolean']>;
  EventPayload?: Maybe<Scalars['String']>;
  CustomID?: Maybe<Scalars['String']>;
  Attachments?: Maybe<Array<Attachment>>;
  InlinedAttachments?: Maybe<Array<InlinedAttachment>>;
};

export type SendParamsRecipient = {
  Email: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
};

export type SendTemplateInput = {
  templateType: TemplateType;
  mailjetTemplateId: MailjetEmailTemplates;
  messages: Array<SendParamsMessageInput>;
  templateName: Scalars['String'];
  emailData: EmailData;
};

export type SepaDebitIntput = {
  sepaAccountholder: Scalars['String'];
  iban: Scalars['String'];
  bic: Scalars['String'];
  bank: Scalars['String'];
};

export type SepaXmlPaymentInput = {
  mandateId: Scalars['String'];
  debtorName: Scalars['String'];
  debtorIBAN: Scalars['String'];
  debtorBIC: Scalars['String'];
  mandateSignatureDate: Scalars['DateTime'];
  amount: Scalars['Float'];
  currency: Scalars['String'];
  remittanceInfo: Scalars['String'];
  end2endId: Scalars['String'];
};

export type SepaXmlPositionInput = {
  creditorIBAN: Scalars['String'];
  creditorBIC: Scalars['String'];
  creditorName: Scalars['String'];
  creditorId: Scalars['String'];
  collectionDate: Scalars['DateTime'];
  batchBooking: Scalars['Boolean'];
  payments: Array<SepaXmlPaymentInput>;
};

export type Setting = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  courseNumberFormat: CourseNumberFormat;
  customerNumberPrefix: Scalars['String'];
  invoiceNumberPrefix: Scalars['String'];
  currency: Currency;
  grossNet: GrossNet;
  defaultVat: Scalars['Float'];
  cash: Scalars['Boolean'];
  bankTransfer: Scalars['Boolean'];
  sepaDebit: Scalars['Boolean'];
  esr: Scalars['Boolean'];
  createdByUserId: Scalars['String'];
  updatedByUserId: Scalars['String'];
  invoiceDueDays: Scalars['Float'];
  firstInstallmentDayOfMonth: Scalars['Float'];
  firstInstallmentMonth: Scalars['Float'];
};

export type SettingEndUserApp = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  colors?: Maybe<Scalars['JSONObject']>;
  courseTable?: Maybe<Scalars['JSONObject']>;
  courseDetails?: Maybe<Scalars['JSONObject']>;
  filters?: Maybe<Scalars['JSONObject']>;
  textEntries?: Maybe<Scalars['JSONObject']>;
  optionalInputFields?: Maybe<Array<Scalars['JSONObject']>>;
  hideAfterDays: Scalars['Float'];
  passwordlessLoginEnabled: Scalars['Boolean'];
  passwordlessIdCodeLength: Scalars['Float'];
};

export type SettingEndUserAppInput = {
  colors: ColorsInput;
  courseTable: CourseTableInput;
  courseDetails: CourseDetailsInput;
  filters: FiltersInput;
  textEntries: TextEntriesInput;
  optionalInputFields?: Maybe<Array<Scalars['JSONObject']>>;
  hideAfterDays?: Maybe<Scalars['Float']>;
};

export type SettingInput = {
  id: Scalars['ID'];
  courseNumberFormat: CourseNumberFormat;
  customerNumberPrefix?: Maybe<Scalars['String']>;
  invoiceNumberPrefix?: Maybe<Scalars['String']>;
  currency: Currency;
  grossNet: GrossNet;
  defaultVat: Scalars['Float'];
  cash: Scalars['Boolean'];
  bankTransfer: Scalars['Boolean'];
  sepaDebit: Scalars['Boolean'];
  esr: Scalars['Boolean'];
  invoiceDueDays: Scalars['Float'];
  firstInstallmentDayOfMonth: Scalars['Float'];
  firstInstallmentMonth: Scalars['Float'];
};

export enum SortOrder {
  Ascending = 'Ascending',
  Descending = 'Descending'
}

export type Staff = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
  salutation?: Maybe<SalutationType>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  birthDate?: Maybe<Scalars['String']>;
  birthPlace?: Maybe<Scalars['String']>;
  annotation?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  joinDate?: Maybe<Scalars['String']>;
  leaveDate?: Maybe<Scalars['String']>;
  socialSecurityNumber?: Maybe<Scalars['String']>;
  healthInsuranceFund?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
  accountHolder?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
};

export enum SubscriptionType {
  TestSubscription = 'TestSubscription',
  StandardSubscription = 'StandardSubscription'
}

export type SwissQrFile = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  fileId: Scalars['String'];
  fileContent: Scalars['String'];
  records?: Maybe<Array<SwissQrFileRecord>>;
};

export type SwissQrFileRecord = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  valutaDate: Scalars['DateTime'];
  amount: Scalars['Float'];
  swissQrRef: Scalars['String'];
  successful: Scalars['Boolean'];
  result: Scalars['String'];
  comment: Scalars['String'];
  payment: Payment;
  scheduledPayment: ScheduledPayment;
  swissQRFileId?: Maybe<Scalars['ID']>;
  swissQRFile?: Maybe<SwissQrFile>;
};

export type TableColumnsInput = {
  sortIndex?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['String']>;
};

export enum TablesEnum {
  Bookers = 'Bookers',
  BookerOverview = 'BookerOverview',
  Attendees = 'Attendees',
  AttendeesOverview = 'AttendeesOverview',
  AttendeeTransferToBooker = 'AttendeeTransferToBooker',
  SelectAttendee = 'SelectAttendee',
  Courses = 'Courses',
  CourseOverview = 'CourseOverview',
  CourseTypes = 'CourseTypes',
  CourseCategories = 'CourseCategories',
  StepSelectCourse = 'StepSelectCourse',
  Bookings = 'Bookings',
  MyBookings = 'MyBookings',
  BookingsByCourse = 'BookingsByCourse',
  BookingsByCourseExpandedBookings = 'BookingsByCourseExpandedBookings',
  MyBookingsOverview = 'MyBookingsOverview',
  MyBookingsByCourse = 'MyBookingsByCourse',
  CourseDetailsSettledBookings = 'CourseDetailsSettledBookings',
  CourseDetailsUnsettledBookings = 'CourseDetailsUnsettledBookings',
  CourseDetailsWaitlist = 'CourseDetailsWaitlist',
  CourseDetailsSettledPlacereservations = 'CourseDetailsSettledPlacereservations',
  CourseDetailsCanceledBookingsList = 'CourseDetailsCanceledBookingsList',
  CourseDetailsPlaceReservations = 'CourseDetailsPlaceReservations',
  CourseTransferAttendeeToCourse = 'CourseTransferAttendeeToCourse',
  Locations = 'Locations',
  LocationOverviewTable = 'LocationOverviewTable',
  Instructors = 'Instructors',
  Staff = 'Staff',
  StaffAll = 'StaffAll',
  InvoicesByCourse = 'InvoicesByCourse',
  InvoicesUnsetteled = 'InvoicesUnsetteled',
  InvoiceListByBookingUnsetteled = 'InvoiceListByBookingUnsetteled',
  InvoicesSettled = 'InvoicesSettled',
  InvoicesReversals = 'InvoicesReversals',
  InvoiceListByCourse = 'InvoiceListByCourse',
  PaymentsOpen = 'PaymentsOpen',
  PaymentsPaid = 'PaymentsPaid',
  NotificationsSent = 'NotificationsSent',
  NotificationsByBookerId = 'NotificationsByBookerId',
  BookingsAttendeeDetails = 'BookingsAttendeeDetails',
  AttendanceList = 'AttendanceList',
  OpenPaymentsList = 'OpenPaymentsList',
  DonePaymentsList = 'DonePaymentsList',
  SettledBookingOpenPaymentsList = 'SettledBookingOpenPaymentsList',
  SettledBookingDonePaymentsList = 'SettledBookingDonePaymentsList',
  TodaysCourseLessonsList = 'TodaysCourseLessonsList'
}

export type Template = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  templateType: Scalars['String'];
  headerText?: Maybe<Scalars['String']>;
  footerText?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  mailDataType?: Maybe<MailDataType>;
};

export enum TemplateType {
  BookingConfirmation = 'BookingConfirmation',
  WaitingListConfirmation = 'WaitingListConfirmation',
  BookingCancellation = 'BookingCancellation',
  SettlementConfirmation = 'SettlementConfirmation',
  SettledBookingCancellation = 'SettledBookingCancellation',
  Reminder = 'Reminder',
  FreeText = 'FreeText',
  Warning = 'Warning',
  NoTemplate = 'NoTemplate',
  Invoice = 'Invoice',
  InvoiceMonthly = 'InvoiceMonthly',
  InvoiceMonthlyConsistent = 'InvoiceMonthlyConsistent',
  InvoiceReversal = 'InvoiceReversal',
  InvoiceReversalMonthly = 'InvoiceReversalMonthly',
  InvoiceReversalMonthlyConsistent = 'InvoiceReversalMonthlyConsistent'
}

export type TextEntriesInput = {
  header?: Maybe<Scalars['String']>;
};

export type ThirdPartyLoginInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type ThirdPartyUserInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UpdateBadgeInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateBookerAccountEndUserAppInput = {
  id: Scalars['ID'];
  customer?: Maybe<BaseInput>;
  gender?: Maybe<Gender>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['Float']>;
  street?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  dsgvo?: Maybe<Scalars['Boolean']>;
  newsletter?: Maybe<Scalars['Boolean']>;
  healthInsuranceFund?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Array<BookerPaymentMethodInput>>;
};

export type UpdateCourseLessonBookingInput = {
  id: Scalars['String'];
  attendance?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
};

export type UpdateInstallmentRateInput = {
  id?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['String']>;
  installmentAmount?: Maybe<Scalars['Float']>;
  installmentDue?: Maybe<Scalars['DateTime']>;
};

export type UpdateParticipationPrerequisitesInput = {
  id?: Maybe<Scalars['ID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  groupDescription?: Maybe<Scalars['String']>;
  groupLabel?: Maybe<Scalars['String']>;
  optionalInputFields?: Maybe<Array<OptionalInputFieldsInput>>;
  minCheckCount?: Maybe<Scalars['Float']>;
  courseTypeId?: Maybe<Scalars['String']>;
};

export type UpdateScheduledPaymentByBookingInput = {
  id?: Maybe<Scalars['ID']>;
  bookingId?: Maybe<Scalars['ID']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  openAmount?: Maybe<Scalars['Float']>;
  paymentDue?: Maybe<Scalars['DateTime']>;
  paymentDone?: Maybe<Scalars['DateTime']>;
  paymentTransferType?: Maybe<PaymentTransferType>;
  invoiceId?: Maybe<Scalars['String']>;
};

export type UpdateSettingEndUserAppInput = {
  colors?: Maybe<ColorsInput>;
  courseTable?: Maybe<CourseTableInput>;
  courseDetails?: Maybe<CourseDetailsInput>;
  filters?: Maybe<FiltersInput>;
  textEntries?: Maybe<TextEntriesInput>;
  optionalInputFields?: Maybe<Array<Scalars['JSONObject']>>;
  hideAfterDays?: Maybe<Scalars['Float']>;
  passwordlessLoginEnabled?: Maybe<Scalars['Boolean']>;
  passwordlessIdCodeLength?: Maybe<Scalars['Float']>;
};

export type UpdateTableColumnSettingsInput = {
  table: TablesEnum;
  columns?: Maybe<Array<Scalars['String']>>;
};


export type UserInput = {
  id: Scalars['String'];
  email: Scalars['String'];
};

export type UserRegistration = {
  applicationId?: Maybe<Scalars['ID']>;
  authenticationToken?: Maybe<Scalars['String']>;
  cleanSpeakId?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['JSONObject']>;
  id?: Maybe<Scalars['String']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  preferredLanguages?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  timezone?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type UserSetting = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  language?: Maybe<Language>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  columnSettings?: Maybe<Scalars['JSONObject']>;
  optimizedCourseCalendar: Scalars['Boolean'];
};

export type UserSettingsInputTypes = {
  language?: Maybe<Language>;
  columnSettings?: Maybe<Scalars['JSONObject']>;
};

export type UserType = {
  id: Scalars['String'];
  email: Scalars['String'];
  roles?: Maybe<Array<Scalars['String']>>;
  hasProfile: Scalars['Boolean'];
  refreshToken?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  booker?: Maybe<Booker>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type Venue = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  updatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  rent?: Maybe<Scalars['Float']>;
  rentType?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  courses?: Maybe<Array<Course>>;
  lessons?: Maybe<Array<CourseLesson>>;
};

export enum FilterCompareOperation {
  Eq = 'EQ',
  Gr = 'GR',
  Lo = 'LO',
  Loeq = 'LOEQ',
  Greq = 'GREQ'
}

export enum FilterOperation {
  Search = 'SEARCH',
  Compare = 'COMPARE'
}

export type GetMonthlyPaymentPreviewQueryVariables = Exact<{
  monthlyPaymentPreviewOptions: ScheduledPaymentPreviewInput;
}>;


export type GetMonthlyPaymentPreviewQuery = Pick<Query, 'getMonthlyPaymentPreview'>;

export type LoggerMutationVariables = Exact<{
  input: LoggingInput;
}>;


export type LoggerMutation = Pick<Mutation, 'logging'>;

export type GetCompanySettingsPaymentmethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanySettingsPaymentmethodsQuery = { settings: Pick<Setting, 'id' | 'cash' | 'bankTransfer' | 'sepaDebit' | 'esr'> };

export type GetAppSettingsInvoiceDueDaysQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppSettingsInvoiceDueDaysQuery = { settings: Pick<Setting, 'id' | 'invoiceDueDays'> };

export type GetAppSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppSettingsQuery = { settings: Pick<Setting, 'id' | 'courseNumberFormat' | 'customerNumberPrefix' | 'currency' | 'grossNet' | 'defaultVat' | 'cash' | 'bankTransfer' | 'sepaDebit' | 'invoiceDueDays' | 'esr'> };

export type GetCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyQuery = { companyPublic: Pick<CompanySetting, 'name' | 'host' | 'agb' | 'privacyStatement'> };

export type GetBookingByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBookingByIdQuery = { bookingById: (
    Pick<Booking, 'id' | 'paymentType' | 'payDate'>
    & { courseLessons: Array<Pick<CourseLesson, 'id'>>, paymentMethod?: Maybe<Pick<PaymentMethod, 'id'>> }
  ) };

export type SettingEndUserAppQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingEndUserAppQuery = { settingEndUserAppPublic: Pick<SettingEndUserApp, 'colors' | 'courseTable' | 'courseDetails' | 'filters' | 'textEntries' | 'optionalInputFields' | 'hideAfterDays' | 'passwordlessLoginEnabled' | 'passwordlessIdCodeLength'> };

export type GetLocalStateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocalStateQuery = { LocalState: { createCourse: Pick<CreateCourse, 'locationId'>, bookAttendees: (
      Pick<BookAttendees, 'selectedCourseId'>
      & { selectedAttendee?: Maybe<Pick<SelectedAttendee, 'id' | 'firstname' | 'bookedCourseIds'>> }
    ) } };

export type GetInstructorsByIdQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetInstructorsByIdQuery = { findInstructorByIds: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>> };

export type RemoveCourseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveCourseMutation = Pick<Mutation, 'removeCourse'>;

export type BookAttendeeGetLocalStateQueryVariables = Exact<{ [key: string]: never; }>;


export type BookAttendeeGetLocalStateQuery = { LocalState: { bookAttendees: (
      Pick<BookAttendees, 'selectedCourseId'>
      & { selectedAttendee?: Maybe<Pick<SelectedAttendee, 'id' | 'firstname' | 'bookedCourseIds'>> }
    ) } };

export type BookAttendeeGetSelectedAttendeeQueryVariables = Exact<{ [key: string]: never; }>;


export type BookAttendeeGetSelectedAttendeeQuery = { LocalState: { bookAttendees: { selectedAttendee?: Maybe<Pick<SelectedAttendee, 'id' | 'firstname' | 'bookedCourseIds'>> } } };

export type BookPaymentMethodGetSelectedPaymentMethodQueryVariables = Exact<{ [key: string]: never; }>;


export type BookPaymentMethodGetSelectedPaymentMethodQuery = { LocalState: { bookPaymentMethods: { selectedPaymentMethod?: Maybe<Pick<SelectedPaymentMethod, 'id' | 'type' | 'bookedCourseIds'>> } } };

export type BookPaymentMethodSetSelectedPaymentMethodMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  bookedCourseIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type BookPaymentMethodSetSelectedPaymentMethodMutation = Pick<Mutation, 'bookPaymentMethodSetSelectedPaymentMethod'>;

export type BookAttendeeGetSelectedCourseQueryVariables = Exact<{ [key: string]: never; }>;


export type BookAttendeeGetSelectedCourseQuery = { LocalState: { bookAttendees: Pick<BookAttendees, 'selectedCourseId'> } };

export type BookAttendeeSetSelectedAttendeeMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  firstname?: Maybe<Scalars['String']>;
  bookedCourseIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type BookAttendeeSetSelectedAttendeeMutation = Pick<Mutation, 'bookAttendeeSetSelectedAttendee'>;

export type BookAttendeeSetSelectedCourseMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type BookAttendeeSetSelectedCourseMutation = Pick<Mutation, 'bookAttendeeSetSelectedCourse'>;

export type UpdateLocalLocationIdMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type UpdateLocalLocationIdMutation = Pick<Mutation, 'updateLocalLocationId'>;

export type GetCourseListWithPaginationPublicQueryVariables = Exact<{
  filters?: Maybe<CourseListFilters>;
  filterExpression?: Maybe<FilterExpressionInput>;
  options?: Maybe<ArrayDataOptions>;
}>;


export type GetCourseListWithPaginationPublicQuery = { coursesWithPaginationPublic: (
    Pick<CourseWithPagination, 'existsMore' | 'total'>
    & { items: Array<(
      Pick<Course, 'id' | 'courseNumber' | 'prefixedCourseNumber' | 'freePlaces' | 'maxAttendees' | 'startDateTime' | 'endDateTime' | 'showInWeb' | 'websiteInfo' | 'paymentInterval' | 'monthlySchedule' | 'paymentType' | 'lessonCount'>
      & { courseType: Pick<CourseType, 'id' | 'name' | 'description' | 'color' | 'minAge' | 'maxAge'>, location: Pick<Location, 'id' | 'city' | 'name'>, venue: Pick<Venue, 'id' | 'name'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>>, coursePrice: Array<Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat' | 'discount' | 'discountUnit' | 'isSpecial' | 'includedEntranceFee'>>, installmentRates?: Maybe<Array<Pick<InstallmentRate, 'id' | 'installmentAmount'>>> }
    )> }
  ) };

export type GetCoursesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCoursesQuery = { courses: Array<(
    Pick<Course, 'id' | 'courseNumber' | 'prefixedCourseNumber' | 'bookingCount' | 'billedBookingCount' | 'freePlaces' | 'settledBookingCount' | 'startDateTime' | 'endDateTime' | 'annotation' | 'maxAttendees' | 'paymentInterval' | 'allowMonthlyPayment'>
    & { courseType: Pick<CourseType, 'id' | 'name' | 'description' | 'color'>, location: Pick<Location, 'id' | 'city' | 'name'>, venue: Pick<Venue, 'id' | 'name'>, lessons: Array<(
      Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>
      & { location: Pick<Location, 'id' | 'city'>, venue: Pick<Venue, 'id' | 'name'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>>, bookings?: Maybe<Array<(
        Pick<Booking, 'id'>
        & { attendee?: Maybe<Pick<Attendee, 'id' | 'firstname' | 'lastname' | 'birthday'>> }
      )>> }
    )>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>>, coursePrice: Array<Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat' | 'discount' | 'discountUnit' | 'isSpecial'>> }
  )> };

export type GetCoursesPublicQueryVariables = Exact<{
  filterExpression?: Maybe<FilterExpressionInput>;
  ids?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  instructorId?: Maybe<Scalars['ID']>;
  dayFilter?: Maybe<DayFilter>;
  courseCategoryId?: Maybe<Scalars['ID']>;
  showOnlyInWebActive?: Maybe<Scalars['Boolean']>;
}>;


export type GetCoursesPublicQuery = { coursesPublic: Array<(
    Pick<Course, 'id' | 'courseNumber' | 'prefixedCourseNumber' | 'bookingCount' | 'freePlaces' | 'startDateTime' | 'endDateTime' | 'maxAttendees' | 'showInWeb' | 'websiteInfo'>
    & { courseType: (
      Pick<CourseType, 'id' | 'name' | 'description' | 'color' | 'minAge' | 'maxAge'>
      & { category?: Maybe<Pick<CourseCategory, 'id' | 'name'>> }
    ), location: Pick<Location, 'id' | 'city' | 'name'>, venue: Pick<Venue, 'id' | 'name'>, lessons: Array<Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>>, coursePrice: Array<Pick<CoursePrice, 'id' | 'grossPrice' | 'netPrice' | 'vat' | 'discount' | 'discountUnit' | 'isSpecial' | 'includedEntranceFee'>> }
  )> };

export type GetCourseByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCourseByIdQuery = { courseById: (
    Pick<Course, 'id' | 'courseNumber' | 'prefixedCourseNumber' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'startDateTime' | 'endDateTime' | 'cycle' | 'annotation' | 'websiteInfo' | 'allowMonthlyPayment'>
    & { courseType: Pick<CourseType, 'id' | 'name' | 'description'>, location: (
      Pick<Location, 'id' | 'city'>
      & { venues: Array<Pick<Venue, 'id' | 'name'>> }
    ), venue: Pick<Venue, 'id' | 'name'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>>, coursePrice: Array<Pick<CoursePrice, 'id' | 'grossPrice' | 'vat' | 'discount' | 'discountUnit' | 'isSpecial'>>, lessons: Array<(
      Pick<CourseLesson, 'id' | 'locationId' | 'venueId' | 'startDateTime' | 'endDateTime'>
      & { location: Pick<Location, 'id' | 'city'>, venue: Pick<Venue, 'id' | 'name'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>> }
    )> }
  ) };

export type GetCourseByLocationIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCourseByLocationIdQuery = { courseByLocationId: Array<(
    Pick<Course, 'id'>
    & { lessons: Array<Pick<CourseLesson, 'startDateTime' | 'endDateTime'>>, courseType: Pick<CourseType, 'name' | 'description' | 'annotation' | 'websiteInfo'> }
  )> };

export type UpdateCourseMutationVariables = Exact<{
  id: Scalars['String'];
  editCourseData: EditCourseInput;
}>;


export type UpdateCourseMutation = { updateCourse: (
    Pick<Course, 'id' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'startDateTime' | 'endDateTime' | 'cycle' | 'annotation' | 'websiteInfo' | 'allowMonthlyPayment'>
    & { coursePrice: Array<Pick<CoursePrice, 'id' | 'vat' | 'grossPrice' | 'discount' | 'discountUnit' | 'courseId'>> }
  ) };

export type GetCitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCitiesQuery = { cities: Array<Pick<LocationCity, 'city'>> };

export type GetLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsQuery = { locations: Array<(
    Pick<Location, 'id' | 'abbreviation' | 'city' | 'name'>
    & { venues: Array<Pick<Venue, 'name'>>, generalLocationContact: Pick<GeneralLocationContact, 'email' | 'phoneNumber'>, courses?: Maybe<Array<Pick<Course, 'id' | 'prefixedCourseNumber'>>> }
  )> };

export type GetLocationsPublicQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsPublicQuery = { locationsPublic: Array<(
    Pick<Location, 'id' | 'abbreviation' | 'city' | 'name'>
    & { generalLocationContact: Pick<GeneralLocationContact, 'email' | 'phoneNumber'> }
  )> };

export type GetLocationsSortedByCityQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsSortedByCityQuery = { locationsSortedByCity: Array<(
    Pick<LocationsGroupedByCity, 'city'>
    & { locations: Array<(
      Pick<LocationGroupedByCityLocation, 'id' | 'city' | 'name'>
      & { venues: Array<Pick<LocationGroupedByCityVenue, 'id' | 'name'>> }
    )> }
  )> };

export type GetLocationsByCityQueryVariables = Exact<{
  city: Scalars['String'];
}>;


export type GetLocationsByCityQuery = { locationsByCity: Array<(
    Pick<Location, 'id' | 'name'>
    & { venues: Array<Pick<Venue, 'id' | 'name'>> }
  )> };

export type GetInstructorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInstructorsQuery = { instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>> };

export type GetCourseTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCourseTypesQuery = { courseTypes: Array<Pick<CourseType, 'id' | 'name' | 'description'>> };

export type GetCourseTypesPublicQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCourseTypesPublicQuery = { courseTypesPublic: Array<Pick<CourseType, 'id' | 'name' | 'description' | 'showInWeb'>> };

export type GetCourseCategoriesPublicQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCourseCategoriesPublicQuery = { courseCategoriesPublic: Array<Pick<CourseType, 'id' | 'name'>> };

export type CreateCourseMutationVariables = Exact<{
  newCourseData: NewCourseInput;
}>;


export type CreateCourseMutation = { createCourse: Pick<Course, 'id'> };

export type GetDetailedCourseTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDetailedCourseTypesQuery = { courseTypes: Array<Pick<CourseType, 'id' | 'shortHandId' | 'name' | 'updateDate' | 'description' | 'minAge' | 'maxAge' | 'paymentInterval' | 'fee' | 'minAttendees' | 'maxAttendees' | 'annotation' | 'websiteInfo' | 'color'>> };

export type GetCourseTypeQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCourseTypeQuery = { courseTypeById: (
    Pick<CourseType, 'id' | 'isActive' | 'showInWeb' | 'name' | 'shortHandId' | 'description' | 'minAge' | 'maxAge' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'annotation' | 'websiteInfo' | 'inWeekPlanner' | 'bold' | 'color'>
    & { category?: Maybe<Pick<CourseCategory, 'id'>> }
  ) };

export type RemoveCourseTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveCourseTypeMutation = Pick<Mutation, 'removeCourseType'>;

export type CreateCourseTypeMutationVariables = Exact<{
  newCourseTypeData: NewCourseTypeInput;
}>;


export type CreateCourseTypeMutation = { createCourseType: (
    Pick<CourseType, 'id' | 'isActive' | 'showInWeb' | 'name' | 'shortHandId' | 'description' | 'minAge' | 'maxAge' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'annotation' | 'websiteInfo' | 'inWeekPlanner' | 'bold' | 'color'>
    & { category?: Maybe<Pick<CourseCategory, 'id'>> }
  ) };

export type UpdateCourseTypeMutationVariables = Exact<{
  id: Scalars['String'];
  newCourseTypeData: NewCourseTypeInput;
}>;


export type UpdateCourseTypeMutation = { updateCourseType: (
    Pick<CourseType, 'id' | 'isActive' | 'showInWeb' | 'name' | 'shortHandId' | 'description' | 'minAge' | 'maxAge' | 'paymentInterval' | 'minAttendees' | 'maxAttendees' | 'annotation' | 'websiteInfo' | 'inWeekPlanner' | 'bold' | 'color'>
    & { category?: Maybe<Pick<CourseCategory, 'id'>> }
  ) };

export type CourseTypeGetCourseCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CourseTypeGetCourseCategoriesQuery = { courseCategories: Array<Pick<CourseCategory, 'id' | 'name'>> };

export type GetCourseCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCourseCategoriesQuery = { courseCategories: Array<(
    Pick<CourseCategory, 'id' | 'isActive' | 'name' | 'description'>
    & { linkedCourseTypes: Array<Pick<CourseType, 'id' | 'name' | 'color'>> }
  )> };

export type GetCourseCategoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCourseCategoryQuery = { courseCategoryById: (
    Pick<CourseCategory, 'id' | 'isActive' | 'name' | 'description'>
    & { linkedCourseTypes: Array<Pick<CourseType, 'id' | 'name'>> }
  ) };

export type CreateCourseCategoryMutationVariables = Exact<{
  newCourseCategoryData: NewCourseCategoryInput;
}>;


export type CreateCourseCategoryMutation = { createCourseCategory: (
    Pick<CourseCategory, 'id' | 'name' | 'isActive' | 'description'>
    & { linkedCourseTypes: Array<Pick<CourseType, 'id'>> }
  ) };

export type UpdateCourseCategoryMutationVariables = Exact<{
  id: Scalars['String'];
  newCourseCategoryData: NewCourseCategoryInput;
}>;


export type UpdateCourseCategoryMutation = { updateCourseCategory: (
    Pick<CourseCategory, 'id' | 'name' | 'isActive' | 'description'>
    & { linkedCourseTypes: Array<Pick<CourseType, 'id'>> }
  ) };

export type CourseGetInstructorsQueryVariables = Exact<{ [key: string]: never; }>;


export type CourseGetInstructorsQuery = { instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>> };

export type CourseGetInstructorsPublicQueryVariables = Exact<{ [key: string]: never; }>;


export type CourseGetInstructorsPublicQuery = { instructorsPublic: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>> };

export type CourseGetInstructorsByLocationQueryVariables = Exact<{
  locationId: Scalars['String'];
}>;


export type CourseGetInstructorsByLocationQuery = { findInstructorsByLocation: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>> };

export type GetCourseDetailsByIdPublicQueryVariables = Exact<{
  courseId: Scalars['String'];
}>;


export type GetCourseDetailsByIdPublicQuery = { courseByIdPublic: (
    Pick<Course, 'id' | 'prefixedCourseNumber' | 'startDateTime' | 'endDateTime' | 'bookingCount' | 'freePlaces' | 'maxAttendees' | 'websiteInfo' | 'paymentInterval' | 'monthlySchedule' | 'paymentType' | 'lessonCount' | 'allowMonthlyPayment' | 'allowUpFrontPayment'>
    & { courseType: Pick<CourseType, 'id' | 'name' | 'color' | 'minAge' | 'maxAge'>, location: Pick<Location, 'id' | 'name' | 'city'>, venue: Pick<Venue, 'id' | 'name'>, instructors: Array<Pick<Instructor, 'id' | 'firstname' | 'lastname'>>, lessons: Array<Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>>, coursePrice: Array<Pick<CoursePrice, 'id' | 'netPrice' | 'grossPrice' | 'vat' | 'includedEntranceFee'>>, installmentRates?: Maybe<Array<Pick<InstallmentRate, 'id' | 'installmentAmount' | 'installmentDue'>>> }
  ) };

export type GetCourseLessonsByCourseIdQueryVariables = Exact<{
  courseId: Scalars['ID'];
}>;


export type GetCourseLessonsByCourseIdQuery = { courseLessonsByCourseId: Array<Pick<CourseLesson, 'id' | 'startDateTime' | 'endDateTime'>> };

export type GetCourseMinMaxAgeQueryVariables = Exact<{
  courseId: Scalars['String'];
}>;


export type GetCourseMinMaxAgeQuery = Pick<Query, 'getCourseMinMaxAge'>;

export type CheckAttendeesAgeAtCourseStartQueryVariables = Exact<{
  courseId: Scalars['String'];
  attendeeId: Scalars['String'];
}>;


export type CheckAttendeesAgeAtCourseStartQuery = Pick<Query, 'checkAttendeesAgeAtCourseStart'>;

export type GetBadgesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBadgesQuery = { badges: Array<Pick<Badge, 'id' | 'name' | 'description'>> };

export type CreateBookerMutationVariables = Exact<{
  newBookerData: BookerInput;
}>;


export type CreateBookerMutation = { createBooker: (
    Pick<Booker, 'id' | 'gender' | 'firstname' | 'lastname' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'birthday' | 'initialContact' | 'dsgvo' | 'newsletter' | 'healthInsuranceFund' | 'annotation'>
    & { paymentMethods?: Maybe<Array<Pick<PaymentMethod, 'id' | 'cash' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder'>>>, attendees?: Maybe<Array<(
      Pick<Attendee, 'id' | 'gender' | 'lastname' | 'firstname' | 'birthday' | 'member'>
      & { customer: Pick<Customer, 'id'>, defaultPaymentMethod: Pick<PaymentMethod, 'id'>, bookings?: Maybe<Array<(
        Pick<Booking, 'id' | 'paymentType' | 'payDate'>
        & { courseLessons: Array<Pick<CourseLesson, 'id'>>, paymentMethod?: Maybe<Pick<PaymentMethod, 'id'>> }
      )>> }
    )>> }
  ) };

export type UpdateBookerMutationVariables = Exact<{
  updateBookerData: BookerInput;
  fromEndUserApp: Scalars['Boolean'];
}>;


export type UpdateBookerMutation = { updateBooker: (
    Pick<Booker, 'id' | 'gender' | 'firstname' | 'lastname' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'birthday' | 'initialContact' | 'dsgvo' | 'newsletter' | 'healthInsuranceFund' | 'annotation' | 'userId'>
    & { paymentMethods?: Maybe<Array<Pick<PaymentMethod, 'id' | 'cash' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder'>>>, attendees?: Maybe<Array<(
      Pick<Attendee, 'id' | 'gender' | 'lastname' | 'firstname' | 'birthday' | 'member' | 'deletable'>
      & { customer: Pick<Customer, 'id'>, startedCourses: Array<Pick<Course, 'id' | 'prefixedCourseNumber'>>, defaultPaymentMethod: Pick<PaymentMethod, 'id'>, bookings?: Maybe<Array<(
        Pick<Booking, 'id' | 'paymentType' | 'payDate'>
        & { courseLessons: Array<Pick<CourseLesson, 'id'>>, paymentMethod?: Maybe<Pick<PaymentMethod, 'id'>> }
      )>> }
    )>> }
  ) };

export type GetUpdatedBookerQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUpdatedBookerQuery = { booker: (
    Pick<Booker, 'id' | 'firstname' | 'lastname' | 'gender' | 'createDate' | 'updateDate' | 'customerId' | 'addition' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'birthday' | 'initialContact' | 'dsgvo' | 'newsletter' | 'healthInsuranceFund' | 'annotation' | 'createdBy' | 'userId'>
    & { customer: Pick<Customer, 'id'>, paymentMethods?: Maybe<Array<Pick<PaymentMethod, 'id' | 'cash' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer' | 'esr'>>>, attendees?: Maybe<Array<(
      Pick<Attendee, 'id' | 'gender' | 'lastname' | 'firstname' | 'birthday' | 'member'>
      & { customer: Pick<Customer, 'id'>, defaultPaymentMethod: Pick<PaymentMethod, 'id'>, bookings?: Maybe<Array<(
        Pick<Booking, 'id' | 'paymentType' | 'payDate'>
        & { courseLessons: Array<Pick<CourseLesson, 'id'>>, paymentMethod?: Maybe<Pick<PaymentMethod, 'id'>> }
      )>> }
    )>> }
  ) };

export type DeleteAttendeeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAttendeeMutation = Pick<Mutation, 'deleteAttendee'>;

export type BookPaymentMethodGetLocalStateQueryVariables = Exact<{ [key: string]: never; }>;


export type BookPaymentMethodGetLocalStateQuery = { LocalState: { bookPaymentMethods: (
      Pick<BookPaymentMethods, 'selectedCourseId'>
      & { selectedPaymentMethod?: Maybe<Pick<SelectedPaymentMethod, 'id' | 'type' | 'bookedCourseIds'>> }
    ) } };

export type GetCourseLessonStartDatesQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetCourseLessonStartDatesQuery = { courseLessons: Array<(
    Pick<CourseLesson, 'id' | 'startDateTime'>
    & { course: Pick<Course, 'id' | 'prefixedCourseNumber'> }
  )> };

export type UpdateBookerAccountEndUserAppMutationVariables = Exact<{
  updateBookerEndUserAppData: UpdateBookerAccountEndUserAppInput;
  fromEndUserApp: Scalars['Boolean'];
}>;


export type UpdateBookerAccountEndUserAppMutation = { updateBookerAccountEndUserApp: Pick<Booker, 'id' | 'gender' | 'firstname' | 'lastname' | 'birthday' | 'street' | 'postCode' | 'city' | 'mobileNumber' | 'phoneNumber' | 'dsgvo' | 'newsletter' | 'email'> };

export type AddBookerAsAttendeeMutationVariables = Exact<{
  bookerId: Scalars['ID'];
}>;


export type AddBookerAsAttendeeMutation = { addBookerAsAttendee: (
    Pick<Booker, 'id' | 'customerId' | 'firstname' | 'lastname' | 'birthday' | 'gender'>
    & { attendees?: Maybe<Array<(
      Pick<Attendee, 'id' | 'customerId' | 'birthday' | 'defaultPaymentMethodId'>
      & { customer: Pick<Customer, 'firstname' | 'lastname' | 'gender'> }
    )>> }
  ) };

export type CreateBookingMutationVariables = Exact<{
  newBookingData: NewBookingInput;
  sendMail: Scalars['Boolean'];
}>;


export type CreateBookingMutation = { createBooking: Pick<Booking, 'id' | 'bookingType'> };

export type CreateAndRegisterThirdPartyUserAndBookerMutationVariables = Exact<{
  newBookingData: BookerInput;
  thirdPartyUserInput: ThirdPartyUserInput;
}>;


export type CreateAndRegisterThirdPartyUserAndBookerMutation = { createAndRegisterThirdPartyUserAndBooker: (
    Pick<UserType, 'id' | 'roles' | 'email' | 'refreshToken' | 'accessToken'>
    & { booker?: Maybe<(
      Pick<Booker, 'id' | 'firstname' | 'lastname' | 'gender' | 'createDate' | 'updateDate' | 'customerId' | 'addition' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'birthday' | 'initialContact' | 'dsgvo' | 'newsletter' | 'healthInsuranceFund' | 'annotation' | 'createdBy'>
      & { paymentMethods?: Maybe<Array<Pick<PaymentMethod, 'id' | 'cash' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer' | 'esr'>>>, attendees?: Maybe<Array<(
        Pick<Attendee, 'id' | 'gender' | 'lastname' | 'firstname' | 'birthday' | 'member'>
        & { customer: Pick<Customer, 'id'>, defaultPaymentMethod: Pick<PaymentMethod, 'id'>, bookings?: Maybe<Array<(
          Pick<Booking, 'id' | 'paymentType' | 'payDate'>
          & { courseLessons: Array<Pick<CourseLesson, 'id'>>, paymentMethod?: Maybe<Pick<PaymentMethod, 'id'>> }
        )>> }
      )>> }
    )> }
  ) };

export type ForgetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgetPasswordMutation = Pick<Mutation, 'forgetPassword'>;

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = Pick<Mutation, 'logoutUser'>;

export type AuthenticateQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthenticateQuery = { authenticate: Pick<UserType, 'id' | 'email' | 'roles' | 'hasProfile'> };

export type PasswordlessLoginMutationVariables = Exact<{
  loginData: PasswordlessLoginInputType;
}>;


export type PasswordlessLoginMutation = Pick<Mutation, 'passwordlessLogin'>;

export type CompletePasswordlessFlowMutationVariables = Exact<{
  loginCode: Scalars['String'];
}>;


export type CompletePasswordlessFlowMutation = { completePasswordlessFlow: Pick<UserType, 'id' | 'roles' | 'email'> };

export type CheckEmailUniquenessQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckEmailUniquenessQuery = Pick<Query, 'checkEmailUniqueness'>;

export type ChangeForgottenPasswordMutationVariables = Exact<{
  changePasswordId: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangeForgottenPasswordMutation = Pick<Mutation, 'changeForgottenPassword'>;

export type ForgotPasswordMutationVariables = Exact<{
  forgotPasswordWithStateInput: ForgotPasswordWithStateInput;
}>;


export type ForgotPasswordMutation = Pick<Mutation, 'forgotPassword'>;

export type GetParticipationPrerequisitesByCourseTypeIdQueryVariables = Exact<{
  courseTypeId: Scalars['ID'];
}>;


export type GetParticipationPrerequisitesByCourseTypeIdQuery = { participationPrerequisitesByCourseTypeId: Array<Pick<ParticipationPrerequisites, 'id' | 'enabled' | 'groupDescription' | 'groupLabel' | 'minCheckCount' | 'optionalInputFields' | 'courseTypeId'>> };

export type AddDefaultPaymentMethodMutationVariables = Exact<{
  bookerId: Scalars['String'];
  paymentMethodType: Scalars['String'];
  sepaDebitIntput?: Maybe<SepaDebitIntput>;
}>;


export type AddDefaultPaymentMethodMutation = { addPaymentMethod: Pick<PaymentMethod, 'id' | 'type' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer' | 'esr'> };

export type GetBankDataQueryVariables = Exact<{
  iban: Scalars['String'];
}>;


export type GetBankDataQuery = Pick<Query, 'getBankData'>;

export type ThirdPartyLoginMutationVariables = Exact<{
  thirdPartyLoginInput: ThirdPartyLoginInput;
}>;


export type ThirdPartyLoginMutation = { thirdPartyLogin: (
    Pick<UserType, 'id' | 'roles' | 'email' | 'refreshToken' | 'accessToken'>
    & { booker?: Maybe<(
      Pick<Booker, 'id' | 'firstname' | 'lastname' | 'gender' | 'createDate' | 'updateDate' | 'customerId' | 'addition' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'birthday' | 'initialContact' | 'dsgvo' | 'newsletter' | 'healthInsuranceFund' | 'annotation' | 'createdBy'>
      & { paymentMethods?: Maybe<Array<Pick<PaymentMethod, 'id' | 'cash' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer' | 'esr'>>>, attendees?: Maybe<Array<(
        Pick<Attendee, 'id' | 'gender' | 'lastname' | 'firstname' | 'birthday' | 'member'>
        & { customer: Pick<Customer, 'id'>, defaultPaymentMethod: Pick<PaymentMethod, 'id'>, bookings?: Maybe<Array<(
          Pick<Booking, 'id' | 'paymentType' | 'payDate'>
          & { courseLessons: Array<Pick<CourseLesson, 'id'>>, paymentMethod?: Maybe<Pick<PaymentMethod, 'id'>> }
        )>> }
      )>> }
    )> }
  ) };

export type StartPasswordlessLoginMutationVariables = Exact<{
  endUserPasswordlessLoginInput: EndUserPasswordlessLoginInputType;
}>;


export type StartPasswordlessLoginMutation = Pick<Mutation, 'passwordlessLoginEndUserApp'>;

export type CompletePasswordlessLoginEndUserAppMutationVariables = Exact<{
  loginCode: Scalars['String'];
}>;


export type CompletePasswordlessLoginEndUserAppMutation = { completePasswordlessFlowEndUserApp: (
    Pick<UserType, 'id' | 'email' | 'roles' | 'hasProfile' | 'refreshToken' | 'accessToken' | 'firstName' | 'lastName'>
    & { booker?: Maybe<(
      Pick<Booker, 'id' | 'firstname' | 'lastname' | 'gender' | 'createDate' | 'updateDate' | 'customerId' | 'addition' | 'street' | 'houseNumber' | 'postCode' | 'city' | 'phoneNumber' | 'mobileNumber' | 'email' | 'birthday' | 'initialContact' | 'dsgvo' | 'newsletter' | 'healthInsuranceFund' | 'annotation' | 'createdBy'>
      & { paymentMethods?: Maybe<Array<Pick<PaymentMethod, 'id' | 'cash' | 'creditCardAccountholder' | 'creditcardNumber' | 'expirationDate' | 'validFrom' | 'cvc' | 'paypalAccountholder' | 'email' | 'iban' | 'bank' | 'bic' | 'sepaAccountholder' | 'bankTransfer' | 'esr'>>>, attendees?: Maybe<Array<(
        Pick<Attendee, 'id' | 'gender' | 'lastname' | 'firstname' | 'birthday' | 'member'>
        & { customer: Pick<Customer, 'id'>, defaultPaymentMethod: Pick<PaymentMethod, 'id'>, bookings?: Maybe<Array<(
          Pick<Booking, 'id' | 'paymentType' | 'payDate'>
          & { courseLessons: Array<Pick<CourseLesson, 'id'>>, paymentMethod?: Maybe<Pick<PaymentMethod, 'id'>> }
        )>> }
      )>> }
    )> }
  ) };


export const GetMonthlyPaymentPreviewDocument = gql`
    query getMonthlyPaymentPreview($monthlyPaymentPreviewOptions: ScheduledPaymentPreviewInput!) {
  getMonthlyPaymentPreview(
    monthlyPaymentPreviewOptions: $monthlyPaymentPreviewOptions
  )
}
    `;

/**
 * __useGetMonthlyPaymentPreviewQuery__
 *
 * To run a query within a React component, call `useGetMonthlyPaymentPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyPaymentPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyPaymentPreviewQuery({
 *   variables: {
 *      monthlyPaymentPreviewOptions: // value for 'monthlyPaymentPreviewOptions'
 *   },
 * });
 */
export function useGetMonthlyPaymentPreviewQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetMonthlyPaymentPreviewQuery, GetMonthlyPaymentPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMonthlyPaymentPreviewQuery, GetMonthlyPaymentPreviewQueryVariables>(GetMonthlyPaymentPreviewDocument, options);
      }
export function useGetMonthlyPaymentPreviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMonthlyPaymentPreviewQuery, GetMonthlyPaymentPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMonthlyPaymentPreviewQuery, GetMonthlyPaymentPreviewQueryVariables>(GetMonthlyPaymentPreviewDocument, options);
        }
export type GetMonthlyPaymentPreviewQueryHookResult = ReturnType<typeof useGetMonthlyPaymentPreviewQuery>;
export type GetMonthlyPaymentPreviewLazyQueryHookResult = ReturnType<typeof useGetMonthlyPaymentPreviewLazyQuery>;
export type GetMonthlyPaymentPreviewQueryResult = ApolloReactCommon.QueryResult<GetMonthlyPaymentPreviewQuery, GetMonthlyPaymentPreviewQueryVariables>;
export function refetchGetMonthlyPaymentPreviewQuery(variables?: GetMonthlyPaymentPreviewQueryVariables) {
      return { query: GetMonthlyPaymentPreviewDocument, variables: variables }
    }
export const LoggerDocument = gql`
    mutation logger($input: LoggingInput!) {
  logging(input: $input)
}
    `;
export type LoggerMutationFn = ApolloReactCommon.MutationFunction<LoggerMutation, LoggerMutationVariables>;

/**
 * __useLoggerMutation__
 *
 * To run a mutation, you first call `useLoggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loggerMutation, { data, loading, error }] = useLoggerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoggerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoggerMutation, LoggerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LoggerMutation, LoggerMutationVariables>(LoggerDocument, options);
      }
export type LoggerMutationHookResult = ReturnType<typeof useLoggerMutation>;
export type LoggerMutationResult = ApolloReactCommon.MutationResult<LoggerMutation>;
export type LoggerMutationOptions = ApolloReactCommon.BaseMutationOptions<LoggerMutation, LoggerMutationVariables>;
export const GetCompanySettingsPaymentmethodsDocument = gql`
    query GetCompanySettingsPaymentmethods {
  settings {
    id
    cash
    bankTransfer
    sepaDebit
    esr
  }
}
    `;

/**
 * __useGetCompanySettingsPaymentmethodsQuery__
 *
 * To run a query within a React component, call `useGetCompanySettingsPaymentmethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySettingsPaymentmethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySettingsPaymentmethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanySettingsPaymentmethodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanySettingsPaymentmethodsQuery, GetCompanySettingsPaymentmethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCompanySettingsPaymentmethodsQuery, GetCompanySettingsPaymentmethodsQueryVariables>(GetCompanySettingsPaymentmethodsDocument, options);
      }
export function useGetCompanySettingsPaymentmethodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanySettingsPaymentmethodsQuery, GetCompanySettingsPaymentmethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCompanySettingsPaymentmethodsQuery, GetCompanySettingsPaymentmethodsQueryVariables>(GetCompanySettingsPaymentmethodsDocument, options);
        }
export type GetCompanySettingsPaymentmethodsQueryHookResult = ReturnType<typeof useGetCompanySettingsPaymentmethodsQuery>;
export type GetCompanySettingsPaymentmethodsLazyQueryHookResult = ReturnType<typeof useGetCompanySettingsPaymentmethodsLazyQuery>;
export type GetCompanySettingsPaymentmethodsQueryResult = ApolloReactCommon.QueryResult<GetCompanySettingsPaymentmethodsQuery, GetCompanySettingsPaymentmethodsQueryVariables>;
export function refetchGetCompanySettingsPaymentmethodsQuery(variables?: GetCompanySettingsPaymentmethodsQueryVariables) {
      return { query: GetCompanySettingsPaymentmethodsDocument, variables: variables }
    }
export const GetAppSettingsInvoiceDueDaysDocument = gql`
    query GetAppSettingsInvoiceDueDays {
  settings {
    id
    invoiceDueDays
  }
}
    `;

/**
 * __useGetAppSettingsInvoiceDueDaysQuery__
 *
 * To run a query within a React component, call `useGetAppSettingsInvoiceDueDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppSettingsInvoiceDueDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppSettingsInvoiceDueDaysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppSettingsInvoiceDueDaysQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAppSettingsInvoiceDueDaysQuery, GetAppSettingsInvoiceDueDaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAppSettingsInvoiceDueDaysQuery, GetAppSettingsInvoiceDueDaysQueryVariables>(GetAppSettingsInvoiceDueDaysDocument, options);
      }
export function useGetAppSettingsInvoiceDueDaysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAppSettingsInvoiceDueDaysQuery, GetAppSettingsInvoiceDueDaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAppSettingsInvoiceDueDaysQuery, GetAppSettingsInvoiceDueDaysQueryVariables>(GetAppSettingsInvoiceDueDaysDocument, options);
        }
export type GetAppSettingsInvoiceDueDaysQueryHookResult = ReturnType<typeof useGetAppSettingsInvoiceDueDaysQuery>;
export type GetAppSettingsInvoiceDueDaysLazyQueryHookResult = ReturnType<typeof useGetAppSettingsInvoiceDueDaysLazyQuery>;
export type GetAppSettingsInvoiceDueDaysQueryResult = ApolloReactCommon.QueryResult<GetAppSettingsInvoiceDueDaysQuery, GetAppSettingsInvoiceDueDaysQueryVariables>;
export function refetchGetAppSettingsInvoiceDueDaysQuery(variables?: GetAppSettingsInvoiceDueDaysQueryVariables) {
      return { query: GetAppSettingsInvoiceDueDaysDocument, variables: variables }
    }
export const GetAppSettingsDocument = gql`
    query GetAppSettings {
  settings {
    id
    courseNumberFormat
    customerNumberPrefix
    currency
    grossNet
    defaultVat
    cash
    bankTransfer
    sepaDebit
    invoiceDueDays
    esr
  }
}
    `;

/**
 * __useGetAppSettingsQuery__
 *
 * To run a query within a React component, call `useGetAppSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAppSettingsQuery, GetAppSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAppSettingsQuery, GetAppSettingsQueryVariables>(GetAppSettingsDocument, options);
      }
export function useGetAppSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAppSettingsQuery, GetAppSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAppSettingsQuery, GetAppSettingsQueryVariables>(GetAppSettingsDocument, options);
        }
export type GetAppSettingsQueryHookResult = ReturnType<typeof useGetAppSettingsQuery>;
export type GetAppSettingsLazyQueryHookResult = ReturnType<typeof useGetAppSettingsLazyQuery>;
export type GetAppSettingsQueryResult = ApolloReactCommon.QueryResult<GetAppSettingsQuery, GetAppSettingsQueryVariables>;
export function refetchGetAppSettingsQuery(variables?: GetAppSettingsQueryVariables) {
      return { query: GetAppSettingsDocument, variables: variables }
    }
export const GetCompanyDocument = gql`
    query GetCompany {
  companyPublic {
    name
    host
    agb
    privacyStatement
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
      }
export function useGetCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = ApolloReactCommon.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export function refetchGetCompanyQuery(variables?: GetCompanyQueryVariables) {
      return { query: GetCompanyDocument, variables: variables }
    }
export const GetBookingByIdDocument = gql`
    query getBookingById($id: ID!) {
  bookingById(id: $id) {
    id
    courseLessons {
      id
    }
    paymentMethod {
      id
    }
    paymentType
    payDate
  }
}
    `;

/**
 * __useGetBookingByIdQuery__
 *
 * To run a query within a React component, call `useGetBookingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookingByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetBookingByIdQuery, GetBookingByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetBookingByIdQuery, GetBookingByIdQueryVariables>(GetBookingByIdDocument, options);
      }
export function useGetBookingByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingByIdQuery, GetBookingByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetBookingByIdQuery, GetBookingByIdQueryVariables>(GetBookingByIdDocument, options);
        }
export type GetBookingByIdQueryHookResult = ReturnType<typeof useGetBookingByIdQuery>;
export type GetBookingByIdLazyQueryHookResult = ReturnType<typeof useGetBookingByIdLazyQuery>;
export type GetBookingByIdQueryResult = ApolloReactCommon.QueryResult<GetBookingByIdQuery, GetBookingByIdQueryVariables>;
export function refetchGetBookingByIdQuery(variables?: GetBookingByIdQueryVariables) {
      return { query: GetBookingByIdDocument, variables: variables }
    }
export const SettingEndUserAppDocument = gql`
    query SettingEndUserApp {
  settingEndUserAppPublic {
    colors
    courseTable
    courseDetails
    filters
    textEntries
    optionalInputFields
    hideAfterDays
    passwordlessLoginEnabled
    passwordlessIdCodeLength
  }
}
    `;

/**
 * __useSettingEndUserAppQuery__
 *
 * To run a query within a React component, call `useSettingEndUserAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingEndUserAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingEndUserAppQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingEndUserAppQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SettingEndUserAppQuery, SettingEndUserAppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SettingEndUserAppQuery, SettingEndUserAppQueryVariables>(SettingEndUserAppDocument, options);
      }
export function useSettingEndUserAppLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SettingEndUserAppQuery, SettingEndUserAppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SettingEndUserAppQuery, SettingEndUserAppQueryVariables>(SettingEndUserAppDocument, options);
        }
export type SettingEndUserAppQueryHookResult = ReturnType<typeof useSettingEndUserAppQuery>;
export type SettingEndUserAppLazyQueryHookResult = ReturnType<typeof useSettingEndUserAppLazyQuery>;
export type SettingEndUserAppQueryResult = ApolloReactCommon.QueryResult<SettingEndUserAppQuery, SettingEndUserAppQueryVariables>;
export function refetchSettingEndUserAppQuery(variables?: SettingEndUserAppQueryVariables) {
      return { query: SettingEndUserAppDocument, variables: variables }
    }
export const GetLocalStateDocument = gql`
    query getLocalState {
  LocalState @client {
    createCourse {
      locationId
    }
    bookAttendees {
      selectedAttendee {
        id
        firstname
        bookedCourseIds
      }
      selectedCourseId
    }
  }
}
    `;

/**
 * __useGetLocalStateQuery__
 *
 * To run a query within a React component, call `useGetLocalStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocalStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocalStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocalStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocalStateQuery, GetLocalStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLocalStateQuery, GetLocalStateQueryVariables>(GetLocalStateDocument, options);
      }
export function useGetLocalStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocalStateQuery, GetLocalStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLocalStateQuery, GetLocalStateQueryVariables>(GetLocalStateDocument, options);
        }
export type GetLocalStateQueryHookResult = ReturnType<typeof useGetLocalStateQuery>;
export type GetLocalStateLazyQueryHookResult = ReturnType<typeof useGetLocalStateLazyQuery>;
export type GetLocalStateQueryResult = ApolloReactCommon.QueryResult<GetLocalStateQuery, GetLocalStateQueryVariables>;
export function refetchGetLocalStateQuery(variables?: GetLocalStateQueryVariables) {
      return { query: GetLocalStateDocument, variables: variables }
    }
export const GetInstructorsByIdDocument = gql`
    query getInstructorsById($ids: [ID!]!) {
  findInstructorByIds(instructorIds: $ids) {
    id
    firstname
    lastname
  }
}
    `;

/**
 * __useGetInstructorsByIdQuery__
 *
 * To run a query within a React component, call `useGetInstructorsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorsByIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetInstructorsByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetInstructorsByIdQuery, GetInstructorsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetInstructorsByIdQuery, GetInstructorsByIdQueryVariables>(GetInstructorsByIdDocument, options);
      }
export function useGetInstructorsByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstructorsByIdQuery, GetInstructorsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetInstructorsByIdQuery, GetInstructorsByIdQueryVariables>(GetInstructorsByIdDocument, options);
        }
export type GetInstructorsByIdQueryHookResult = ReturnType<typeof useGetInstructorsByIdQuery>;
export type GetInstructorsByIdLazyQueryHookResult = ReturnType<typeof useGetInstructorsByIdLazyQuery>;
export type GetInstructorsByIdQueryResult = ApolloReactCommon.QueryResult<GetInstructorsByIdQuery, GetInstructorsByIdQueryVariables>;
export function refetchGetInstructorsByIdQuery(variables?: GetInstructorsByIdQueryVariables) {
      return { query: GetInstructorsByIdDocument, variables: variables }
    }
export const RemoveCourseDocument = gql`
    mutation removeCourse($id: ID!) {
  removeCourse(id: $id)
}
    `;
export type RemoveCourseMutationFn = ApolloReactCommon.MutationFunction<RemoveCourseMutation, RemoveCourseMutationVariables>;

/**
 * __useRemoveCourseMutation__
 *
 * To run a mutation, you first call `useRemoveCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCourseMutation, { data, loading, error }] = useRemoveCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCourseMutation, RemoveCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveCourseMutation, RemoveCourseMutationVariables>(RemoveCourseDocument, options);
      }
export type RemoveCourseMutationHookResult = ReturnType<typeof useRemoveCourseMutation>;
export type RemoveCourseMutationResult = ApolloReactCommon.MutationResult<RemoveCourseMutation>;
export type RemoveCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCourseMutation, RemoveCourseMutationVariables>;
export const BookAttendeeGetLocalStateDocument = gql`
    query bookAttendeeGetLocalState {
  LocalState @client {
    bookAttendees {
      selectedAttendee {
        id
        firstname
        bookedCourseIds
      }
      selectedCourseId
    }
  }
}
    `;

/**
 * __useBookAttendeeGetLocalStateQuery__
 *
 * To run a query within a React component, call `useBookAttendeeGetLocalStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookAttendeeGetLocalStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookAttendeeGetLocalStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookAttendeeGetLocalStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookAttendeeGetLocalStateQuery, BookAttendeeGetLocalStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BookAttendeeGetLocalStateQuery, BookAttendeeGetLocalStateQueryVariables>(BookAttendeeGetLocalStateDocument, options);
      }
export function useBookAttendeeGetLocalStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookAttendeeGetLocalStateQuery, BookAttendeeGetLocalStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BookAttendeeGetLocalStateQuery, BookAttendeeGetLocalStateQueryVariables>(BookAttendeeGetLocalStateDocument, options);
        }
export type BookAttendeeGetLocalStateQueryHookResult = ReturnType<typeof useBookAttendeeGetLocalStateQuery>;
export type BookAttendeeGetLocalStateLazyQueryHookResult = ReturnType<typeof useBookAttendeeGetLocalStateLazyQuery>;
export type BookAttendeeGetLocalStateQueryResult = ApolloReactCommon.QueryResult<BookAttendeeGetLocalStateQuery, BookAttendeeGetLocalStateQueryVariables>;
export function refetchBookAttendeeGetLocalStateQuery(variables?: BookAttendeeGetLocalStateQueryVariables) {
      return { query: BookAttendeeGetLocalStateDocument, variables: variables }
    }
export const BookAttendeeGetSelectedAttendeeDocument = gql`
    query bookAttendeeGetSelectedAttendee {
  LocalState @client {
    bookAttendees {
      selectedAttendee {
        id
        firstname
        bookedCourseIds
      }
    }
  }
}
    `;

/**
 * __useBookAttendeeGetSelectedAttendeeQuery__
 *
 * To run a query within a React component, call `useBookAttendeeGetSelectedAttendeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookAttendeeGetSelectedAttendeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookAttendeeGetSelectedAttendeeQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookAttendeeGetSelectedAttendeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookAttendeeGetSelectedAttendeeQuery, BookAttendeeGetSelectedAttendeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BookAttendeeGetSelectedAttendeeQuery, BookAttendeeGetSelectedAttendeeQueryVariables>(BookAttendeeGetSelectedAttendeeDocument, options);
      }
export function useBookAttendeeGetSelectedAttendeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookAttendeeGetSelectedAttendeeQuery, BookAttendeeGetSelectedAttendeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BookAttendeeGetSelectedAttendeeQuery, BookAttendeeGetSelectedAttendeeQueryVariables>(BookAttendeeGetSelectedAttendeeDocument, options);
        }
export type BookAttendeeGetSelectedAttendeeQueryHookResult = ReturnType<typeof useBookAttendeeGetSelectedAttendeeQuery>;
export type BookAttendeeGetSelectedAttendeeLazyQueryHookResult = ReturnType<typeof useBookAttendeeGetSelectedAttendeeLazyQuery>;
export type BookAttendeeGetSelectedAttendeeQueryResult = ApolloReactCommon.QueryResult<BookAttendeeGetSelectedAttendeeQuery, BookAttendeeGetSelectedAttendeeQueryVariables>;
export function refetchBookAttendeeGetSelectedAttendeeQuery(variables?: BookAttendeeGetSelectedAttendeeQueryVariables) {
      return { query: BookAttendeeGetSelectedAttendeeDocument, variables: variables }
    }
export const BookPaymentMethodGetSelectedPaymentMethodDocument = gql`
    query bookPaymentMethodGetSelectedPaymentMethod {
  LocalState @client {
    bookPaymentMethods {
      selectedPaymentMethod {
        id
        type
        bookedCourseIds
      }
    }
  }
}
    `;

/**
 * __useBookPaymentMethodGetSelectedPaymentMethodQuery__
 *
 * To run a query within a React component, call `useBookPaymentMethodGetSelectedPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookPaymentMethodGetSelectedPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookPaymentMethodGetSelectedPaymentMethodQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookPaymentMethodGetSelectedPaymentMethodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookPaymentMethodGetSelectedPaymentMethodQuery, BookPaymentMethodGetSelectedPaymentMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BookPaymentMethodGetSelectedPaymentMethodQuery, BookPaymentMethodGetSelectedPaymentMethodQueryVariables>(BookPaymentMethodGetSelectedPaymentMethodDocument, options);
      }
export function useBookPaymentMethodGetSelectedPaymentMethodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookPaymentMethodGetSelectedPaymentMethodQuery, BookPaymentMethodGetSelectedPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BookPaymentMethodGetSelectedPaymentMethodQuery, BookPaymentMethodGetSelectedPaymentMethodQueryVariables>(BookPaymentMethodGetSelectedPaymentMethodDocument, options);
        }
export type BookPaymentMethodGetSelectedPaymentMethodQueryHookResult = ReturnType<typeof useBookPaymentMethodGetSelectedPaymentMethodQuery>;
export type BookPaymentMethodGetSelectedPaymentMethodLazyQueryHookResult = ReturnType<typeof useBookPaymentMethodGetSelectedPaymentMethodLazyQuery>;
export type BookPaymentMethodGetSelectedPaymentMethodQueryResult = ApolloReactCommon.QueryResult<BookPaymentMethodGetSelectedPaymentMethodQuery, BookPaymentMethodGetSelectedPaymentMethodQueryVariables>;
export function refetchBookPaymentMethodGetSelectedPaymentMethodQuery(variables?: BookPaymentMethodGetSelectedPaymentMethodQueryVariables) {
      return { query: BookPaymentMethodGetSelectedPaymentMethodDocument, variables: variables }
    }
export const BookPaymentMethodSetSelectedPaymentMethodDocument = gql`
    mutation bookPaymentMethodSetSelectedPaymentMethod($id: ID, $type: [String], $bookedCourseIds: [String]) {
  bookPaymentMethodSetSelectedPaymentMethod(
    id: $id
    type: $type
    bookedCourseIds: $bookedCourseIds
  ) @client
}
    `;
export type BookPaymentMethodSetSelectedPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<BookPaymentMethodSetSelectedPaymentMethodMutation, BookPaymentMethodSetSelectedPaymentMethodMutationVariables>;

/**
 * __useBookPaymentMethodSetSelectedPaymentMethodMutation__
 *
 * To run a mutation, you first call `useBookPaymentMethodSetSelectedPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookPaymentMethodSetSelectedPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookPaymentMethodSetSelectedPaymentMethodMutation, { data, loading, error }] = useBookPaymentMethodSetSelectedPaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      bookedCourseIds: // value for 'bookedCourseIds'
 *   },
 * });
 */
export function useBookPaymentMethodSetSelectedPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookPaymentMethodSetSelectedPaymentMethodMutation, BookPaymentMethodSetSelectedPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BookPaymentMethodSetSelectedPaymentMethodMutation, BookPaymentMethodSetSelectedPaymentMethodMutationVariables>(BookPaymentMethodSetSelectedPaymentMethodDocument, options);
      }
export type BookPaymentMethodSetSelectedPaymentMethodMutationHookResult = ReturnType<typeof useBookPaymentMethodSetSelectedPaymentMethodMutation>;
export type BookPaymentMethodSetSelectedPaymentMethodMutationResult = ApolloReactCommon.MutationResult<BookPaymentMethodSetSelectedPaymentMethodMutation>;
export type BookPaymentMethodSetSelectedPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<BookPaymentMethodSetSelectedPaymentMethodMutation, BookPaymentMethodSetSelectedPaymentMethodMutationVariables>;
export const BookAttendeeGetSelectedCourseDocument = gql`
    query bookAttendeeGetSelectedCourse {
  LocalState @client {
    bookAttendees {
      selectedCourseId
    }
  }
}
    `;

/**
 * __useBookAttendeeGetSelectedCourseQuery__
 *
 * To run a query within a React component, call `useBookAttendeeGetSelectedCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookAttendeeGetSelectedCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookAttendeeGetSelectedCourseQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookAttendeeGetSelectedCourseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookAttendeeGetSelectedCourseQuery, BookAttendeeGetSelectedCourseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BookAttendeeGetSelectedCourseQuery, BookAttendeeGetSelectedCourseQueryVariables>(BookAttendeeGetSelectedCourseDocument, options);
      }
export function useBookAttendeeGetSelectedCourseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookAttendeeGetSelectedCourseQuery, BookAttendeeGetSelectedCourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BookAttendeeGetSelectedCourseQuery, BookAttendeeGetSelectedCourseQueryVariables>(BookAttendeeGetSelectedCourseDocument, options);
        }
export type BookAttendeeGetSelectedCourseQueryHookResult = ReturnType<typeof useBookAttendeeGetSelectedCourseQuery>;
export type BookAttendeeGetSelectedCourseLazyQueryHookResult = ReturnType<typeof useBookAttendeeGetSelectedCourseLazyQuery>;
export type BookAttendeeGetSelectedCourseQueryResult = ApolloReactCommon.QueryResult<BookAttendeeGetSelectedCourseQuery, BookAttendeeGetSelectedCourseQueryVariables>;
export function refetchBookAttendeeGetSelectedCourseQuery(variables?: BookAttendeeGetSelectedCourseQueryVariables) {
      return { query: BookAttendeeGetSelectedCourseDocument, variables: variables }
    }
export const BookAttendeeSetSelectedAttendeeDocument = gql`
    mutation bookAttendeeSetSelectedAttendee($id: ID, $firstname: String, $bookedCourseIds: [String]) {
  bookAttendeeSetSelectedAttendee(
    id: $id
    firstname: $firstname
    bookedCourseIds: $bookedCourseIds
  ) @client
}
    `;
export type BookAttendeeSetSelectedAttendeeMutationFn = ApolloReactCommon.MutationFunction<BookAttendeeSetSelectedAttendeeMutation, BookAttendeeSetSelectedAttendeeMutationVariables>;

/**
 * __useBookAttendeeSetSelectedAttendeeMutation__
 *
 * To run a mutation, you first call `useBookAttendeeSetSelectedAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAttendeeSetSelectedAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAttendeeSetSelectedAttendeeMutation, { data, loading, error }] = useBookAttendeeSetSelectedAttendeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstname: // value for 'firstname'
 *      bookedCourseIds: // value for 'bookedCourseIds'
 *   },
 * });
 */
export function useBookAttendeeSetSelectedAttendeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookAttendeeSetSelectedAttendeeMutation, BookAttendeeSetSelectedAttendeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BookAttendeeSetSelectedAttendeeMutation, BookAttendeeSetSelectedAttendeeMutationVariables>(BookAttendeeSetSelectedAttendeeDocument, options);
      }
export type BookAttendeeSetSelectedAttendeeMutationHookResult = ReturnType<typeof useBookAttendeeSetSelectedAttendeeMutation>;
export type BookAttendeeSetSelectedAttendeeMutationResult = ApolloReactCommon.MutationResult<BookAttendeeSetSelectedAttendeeMutation>;
export type BookAttendeeSetSelectedAttendeeMutationOptions = ApolloReactCommon.BaseMutationOptions<BookAttendeeSetSelectedAttendeeMutation, BookAttendeeSetSelectedAttendeeMutationVariables>;
export const BookAttendeeSetSelectedCourseDocument = gql`
    mutation bookAttendeeSetSelectedCourse($id: ID) {
  bookAttendeeSetSelectedCourse(id: $id) @client
}
    `;
export type BookAttendeeSetSelectedCourseMutationFn = ApolloReactCommon.MutationFunction<BookAttendeeSetSelectedCourseMutation, BookAttendeeSetSelectedCourseMutationVariables>;

/**
 * __useBookAttendeeSetSelectedCourseMutation__
 *
 * To run a mutation, you first call `useBookAttendeeSetSelectedCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAttendeeSetSelectedCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAttendeeSetSelectedCourseMutation, { data, loading, error }] = useBookAttendeeSetSelectedCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookAttendeeSetSelectedCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookAttendeeSetSelectedCourseMutation, BookAttendeeSetSelectedCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BookAttendeeSetSelectedCourseMutation, BookAttendeeSetSelectedCourseMutationVariables>(BookAttendeeSetSelectedCourseDocument, options);
      }
export type BookAttendeeSetSelectedCourseMutationHookResult = ReturnType<typeof useBookAttendeeSetSelectedCourseMutation>;
export type BookAttendeeSetSelectedCourseMutationResult = ApolloReactCommon.MutationResult<BookAttendeeSetSelectedCourseMutation>;
export type BookAttendeeSetSelectedCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<BookAttendeeSetSelectedCourseMutation, BookAttendeeSetSelectedCourseMutationVariables>;
export const UpdateLocalLocationIdDocument = gql`
    mutation updateLocalLocationId($id: ID) {
  updateLocalLocationId(id: $id) @client
}
    `;
export type UpdateLocalLocationIdMutationFn = ApolloReactCommon.MutationFunction<UpdateLocalLocationIdMutation, UpdateLocalLocationIdMutationVariables>;

/**
 * __useUpdateLocalLocationIdMutation__
 *
 * To run a mutation, you first call `useUpdateLocalLocationIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocalLocationIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocalLocationIdMutation, { data, loading, error }] = useUpdateLocalLocationIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateLocalLocationIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLocalLocationIdMutation, UpdateLocalLocationIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateLocalLocationIdMutation, UpdateLocalLocationIdMutationVariables>(UpdateLocalLocationIdDocument, options);
      }
export type UpdateLocalLocationIdMutationHookResult = ReturnType<typeof useUpdateLocalLocationIdMutation>;
export type UpdateLocalLocationIdMutationResult = ApolloReactCommon.MutationResult<UpdateLocalLocationIdMutation>;
export type UpdateLocalLocationIdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLocalLocationIdMutation, UpdateLocalLocationIdMutationVariables>;
export const GetCourseListWithPaginationPublicDocument = gql`
    query getCourseListWithPaginationPublic($filters: CourseListFilters, $filterExpression: FilterExpressionInput, $options: ArrayDataOptions) {
  coursesWithPaginationPublic(
    filters: $filters
    filterExpression: $filterExpression
    options: $options
  ) {
    existsMore
    total
    items {
      id
      courseNumber
      prefixedCourseNumber
      freePlaces
      maxAttendees
      courseType {
        id
        name
        description
        color
        minAge
        maxAge
      }
      startDateTime
      endDateTime
      location {
        id
        city
        name
      }
      venue {
        id
        name
      }
      instructors {
        id
        firstname
        lastname
      }
      coursePrice {
        id
        grossPrice
        netPrice
        vat
        discount
        discountUnit
        isSpecial
        includedEntranceFee
      }
      showInWeb
      websiteInfo
      paymentInterval
      monthlySchedule
      paymentType
      lessonCount
      installmentRates {
        id
        installmentAmount
      }
      lessonCount
    }
  }
}
    `;

/**
 * __useGetCourseListWithPaginationPublicQuery__
 *
 * To run a query within a React component, call `useGetCourseListWithPaginationPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseListWithPaginationPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseListWithPaginationPublicQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      filterExpression: // value for 'filterExpression'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetCourseListWithPaginationPublicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseListWithPaginationPublicQuery, GetCourseListWithPaginationPublicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCourseListWithPaginationPublicQuery, GetCourseListWithPaginationPublicQueryVariables>(GetCourseListWithPaginationPublicDocument, options);
      }
export function useGetCourseListWithPaginationPublicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseListWithPaginationPublicQuery, GetCourseListWithPaginationPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCourseListWithPaginationPublicQuery, GetCourseListWithPaginationPublicQueryVariables>(GetCourseListWithPaginationPublicDocument, options);
        }
export type GetCourseListWithPaginationPublicQueryHookResult = ReturnType<typeof useGetCourseListWithPaginationPublicQuery>;
export type GetCourseListWithPaginationPublicLazyQueryHookResult = ReturnType<typeof useGetCourseListWithPaginationPublicLazyQuery>;
export type GetCourseListWithPaginationPublicQueryResult = ApolloReactCommon.QueryResult<GetCourseListWithPaginationPublicQuery, GetCourseListWithPaginationPublicQueryVariables>;
export function refetchGetCourseListWithPaginationPublicQuery(variables?: GetCourseListWithPaginationPublicQueryVariables) {
      return { query: GetCourseListWithPaginationPublicDocument, variables: variables }
    }
export const GetCoursesDocument = gql`
    query GetCourses {
  courses {
    id
    courseNumber
    prefixedCourseNumber
    bookingCount
    billedBookingCount
    freePlaces
    settledBookingCount
    courseType {
      id
      name
      description
      color
    }
    startDateTime
    endDateTime
    location {
      id
      city
      name
    }
    venue {
      id
      name
    }
    annotation
    lessons {
      id
      location {
        id
        city
      }
      venue {
        id
        name
      }
      startDateTime
      endDateTime
      instructors {
        id
        firstname
        lastname
      }
      bookings {
        id
        attendee {
          id
          firstname
          lastname
          birthday
        }
      }
    }
    instructors {
      id
      firstname
      lastname
    }
    maxAttendees
    coursePrice {
      id
      grossPrice
      netPrice
      vat
      discount
      discountUnit
      isSpecial
    }
    paymentInterval
    allowMonthlyPayment
  }
}
    `;

/**
 * __useGetCoursesQuery__
 *
 * To run a query within a React component, call `useGetCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoursesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCoursesQuery, GetCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCoursesQuery, GetCoursesQueryVariables>(GetCoursesDocument, options);
      }
export function useGetCoursesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCoursesQuery, GetCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCoursesQuery, GetCoursesQueryVariables>(GetCoursesDocument, options);
        }
export type GetCoursesQueryHookResult = ReturnType<typeof useGetCoursesQuery>;
export type GetCoursesLazyQueryHookResult = ReturnType<typeof useGetCoursesLazyQuery>;
export type GetCoursesQueryResult = ApolloReactCommon.QueryResult<GetCoursesQuery, GetCoursesQueryVariables>;
export function refetchGetCoursesQuery(variables?: GetCoursesQueryVariables) {
      return { query: GetCoursesDocument, variables: variables }
    }
export const GetCoursesPublicDocument = gql`
    query GetCoursesPublic($filterExpression: FilterExpressionInput, $ids: [String!], $instructorId: ID, $dayFilter: DayFilter, $courseCategoryId: ID, $showOnlyInWebActive: Boolean) {
  coursesPublic(
    filterExpression: $filterExpression
    ids: $ids
    dayFilter: $dayFilter
    instructorId: $instructorId
    courseCategoryId: $courseCategoryId
    showOnlyInWebActive: $showOnlyInWebActive
  ) {
    id
    courseNumber
    prefixedCourseNumber
    bookingCount
    freePlaces
    courseType {
      id
      name
      description
      color
      category {
        id
        name
      }
      minAge
      maxAge
    }
    startDateTime
    endDateTime
    location {
      id
      city
      name
    }
    venue {
      id
      name
    }
    lessons {
      id
      startDateTime
      endDateTime
    }
    instructors {
      id
      firstname
      lastname
    }
    maxAttendees
    coursePrice {
      id
      grossPrice
      netPrice
      vat
      discount
      discountUnit
      isSpecial
      includedEntranceFee
    }
    showInWeb
    websiteInfo
  }
}
    `;

/**
 * __useGetCoursesPublicQuery__
 *
 * To run a query within a React component, call `useGetCoursesPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesPublicQuery({
 *   variables: {
 *      filterExpression: // value for 'filterExpression'
 *      ids: // value for 'ids'
 *      instructorId: // value for 'instructorId'
 *      dayFilter: // value for 'dayFilter'
 *      courseCategoryId: // value for 'courseCategoryId'
 *      showOnlyInWebActive: // value for 'showOnlyInWebActive'
 *   },
 * });
 */
export function useGetCoursesPublicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCoursesPublicQuery, GetCoursesPublicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCoursesPublicQuery, GetCoursesPublicQueryVariables>(GetCoursesPublicDocument, options);
      }
export function useGetCoursesPublicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCoursesPublicQuery, GetCoursesPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCoursesPublicQuery, GetCoursesPublicQueryVariables>(GetCoursesPublicDocument, options);
        }
export type GetCoursesPublicQueryHookResult = ReturnType<typeof useGetCoursesPublicQuery>;
export type GetCoursesPublicLazyQueryHookResult = ReturnType<typeof useGetCoursesPublicLazyQuery>;
export type GetCoursesPublicQueryResult = ApolloReactCommon.QueryResult<GetCoursesPublicQuery, GetCoursesPublicQueryVariables>;
export function refetchGetCoursesPublicQuery(variables?: GetCoursesPublicQueryVariables) {
      return { query: GetCoursesPublicDocument, variables: variables }
    }
export const GetCourseByIdDocument = gql`
    query getCourseById($id: String!) {
  courseById(id: $id) {
    id
    courseType {
      id
      name
      description
    }
    courseNumber
    prefixedCourseNumber
    location {
      id
      city
      venues {
        id
        name
      }
    }
    venue {
      id
      name
    }
    instructors {
      id
      firstname
      lastname
    }
    coursePrice {
      id
      grossPrice
      vat
      discount
      discountUnit
      isSpecial
    }
    paymentInterval
    minAttendees
    maxAttendees
    startDateTime
    endDateTime
    cycle
    annotation
    websiteInfo
    allowMonthlyPayment
    startDateTime
    lessons {
      id
      locationId
      location {
        id
        city
      }
      venueId
      venue {
        id
        name
      }
      startDateTime
      endDateTime
      instructors {
        id
        firstname
        lastname
      }
    }
  }
}
    `;

/**
 * __useGetCourseByIdQuery__
 *
 * To run a query within a React component, call `useGetCourseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCourseByIdQuery, GetCourseByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCourseByIdQuery, GetCourseByIdQueryVariables>(GetCourseByIdDocument, options);
      }
export function useGetCourseByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseByIdQuery, GetCourseByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCourseByIdQuery, GetCourseByIdQueryVariables>(GetCourseByIdDocument, options);
        }
export type GetCourseByIdQueryHookResult = ReturnType<typeof useGetCourseByIdQuery>;
export type GetCourseByIdLazyQueryHookResult = ReturnType<typeof useGetCourseByIdLazyQuery>;
export type GetCourseByIdQueryResult = ApolloReactCommon.QueryResult<GetCourseByIdQuery, GetCourseByIdQueryVariables>;
export function refetchGetCourseByIdQuery(variables?: GetCourseByIdQueryVariables) {
      return { query: GetCourseByIdDocument, variables: variables }
    }
export const GetCourseByLocationIdDocument = gql`
    query GetCourseByLocationId($id: ID!) {
  courseByLocationId(id: $id) {
    id
    lessons {
      startDateTime
      endDateTime
    }
    courseType {
      name
      description
      annotation
      websiteInfo
    }
  }
}
    `;

/**
 * __useGetCourseByLocationIdQuery__
 *
 * To run a query within a React component, call `useGetCourseByLocationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseByLocationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseByLocationIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseByLocationIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCourseByLocationIdQuery, GetCourseByLocationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCourseByLocationIdQuery, GetCourseByLocationIdQueryVariables>(GetCourseByLocationIdDocument, options);
      }
export function useGetCourseByLocationIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseByLocationIdQuery, GetCourseByLocationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCourseByLocationIdQuery, GetCourseByLocationIdQueryVariables>(GetCourseByLocationIdDocument, options);
        }
export type GetCourseByLocationIdQueryHookResult = ReturnType<typeof useGetCourseByLocationIdQuery>;
export type GetCourseByLocationIdLazyQueryHookResult = ReturnType<typeof useGetCourseByLocationIdLazyQuery>;
export type GetCourseByLocationIdQueryResult = ApolloReactCommon.QueryResult<GetCourseByLocationIdQuery, GetCourseByLocationIdQueryVariables>;
export function refetchGetCourseByLocationIdQuery(variables?: GetCourseByLocationIdQueryVariables) {
      return { query: GetCourseByLocationIdDocument, variables: variables }
    }
export const UpdateCourseDocument = gql`
    mutation updateCourse($id: String!, $editCourseData: EditCourseInput!) {
  updateCourse(id: $id, editCourseData: $editCourseData) {
    id
    coursePrice {
      id
      vat
      grossPrice
      discount
      discountUnit
      courseId
    }
    paymentInterval
    minAttendees
    maxAttendees
    startDateTime
    endDateTime
    cycle
    annotation
    websiteInfo
    allowMonthlyPayment
  }
}
    `;
export type UpdateCourseMutationFn = ApolloReactCommon.MutationFunction<UpdateCourseMutation, UpdateCourseMutationVariables>;

/**
 * __useUpdateCourseMutation__
 *
 * To run a mutation, you first call `useUpdateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseMutation, { data, loading, error }] = useUpdateCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      editCourseData: // value for 'editCourseData'
 *   },
 * });
 */
export function useUpdateCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCourseMutation, UpdateCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateCourseMutation, UpdateCourseMutationVariables>(UpdateCourseDocument, options);
      }
export type UpdateCourseMutationHookResult = ReturnType<typeof useUpdateCourseMutation>;
export type UpdateCourseMutationResult = ApolloReactCommon.MutationResult<UpdateCourseMutation>;
export type UpdateCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCourseMutation, UpdateCourseMutationVariables>;
export const GetCitiesDocument = gql`
    query GetCities {
  cities {
    city
  }
}
    `;

/**
 * __useGetCitiesQuery__
 *
 * To run a query within a React component, call `useGetCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
      }
export function useGetCitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
        }
export type GetCitiesQueryHookResult = ReturnType<typeof useGetCitiesQuery>;
export type GetCitiesLazyQueryHookResult = ReturnType<typeof useGetCitiesLazyQuery>;
export type GetCitiesQueryResult = ApolloReactCommon.QueryResult<GetCitiesQuery, GetCitiesQueryVariables>;
export function refetchGetCitiesQuery(variables?: GetCitiesQueryVariables) {
      return { query: GetCitiesDocument, variables: variables }
    }
export const GetLocationsDocument = gql`
    query GetLocations {
  locations {
    id
    abbreviation
    city
    name
    venues {
      name
    }
    generalLocationContact {
      email
      phoneNumber
    }
    courses {
      id
      prefixedCourseNumber
    }
  }
}
    `;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
      }
export function useGetLocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = ApolloReactCommon.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export function refetchGetLocationsQuery(variables?: GetLocationsQueryVariables) {
      return { query: GetLocationsDocument, variables: variables }
    }
export const GetLocationsPublicDocument = gql`
    query GetLocationsPublic {
  locationsPublic {
    id
    abbreviation
    city
    name
    generalLocationContact {
      email
      phoneNumber
    }
  }
}
    `;

/**
 * __useGetLocationsPublicQuery__
 *
 * To run a query within a React component, call `useGetLocationsPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsPublicQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsPublicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationsPublicQuery, GetLocationsPublicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLocationsPublicQuery, GetLocationsPublicQueryVariables>(GetLocationsPublicDocument, options);
      }
export function useGetLocationsPublicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationsPublicQuery, GetLocationsPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLocationsPublicQuery, GetLocationsPublicQueryVariables>(GetLocationsPublicDocument, options);
        }
export type GetLocationsPublicQueryHookResult = ReturnType<typeof useGetLocationsPublicQuery>;
export type GetLocationsPublicLazyQueryHookResult = ReturnType<typeof useGetLocationsPublicLazyQuery>;
export type GetLocationsPublicQueryResult = ApolloReactCommon.QueryResult<GetLocationsPublicQuery, GetLocationsPublicQueryVariables>;
export function refetchGetLocationsPublicQuery(variables?: GetLocationsPublicQueryVariables) {
      return { query: GetLocationsPublicDocument, variables: variables }
    }
export const GetLocationsSortedByCityDocument = gql`
    query GetLocationsSortedByCity {
  locationsSortedByCity {
    city
    locations {
      id
      city
      name
      venues {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetLocationsSortedByCityQuery__
 *
 * To run a query within a React component, call `useGetLocationsSortedByCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsSortedByCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsSortedByCityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsSortedByCityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationsSortedByCityQuery, GetLocationsSortedByCityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLocationsSortedByCityQuery, GetLocationsSortedByCityQueryVariables>(GetLocationsSortedByCityDocument, options);
      }
export function useGetLocationsSortedByCityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationsSortedByCityQuery, GetLocationsSortedByCityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLocationsSortedByCityQuery, GetLocationsSortedByCityQueryVariables>(GetLocationsSortedByCityDocument, options);
        }
export type GetLocationsSortedByCityQueryHookResult = ReturnType<typeof useGetLocationsSortedByCityQuery>;
export type GetLocationsSortedByCityLazyQueryHookResult = ReturnType<typeof useGetLocationsSortedByCityLazyQuery>;
export type GetLocationsSortedByCityQueryResult = ApolloReactCommon.QueryResult<GetLocationsSortedByCityQuery, GetLocationsSortedByCityQueryVariables>;
export function refetchGetLocationsSortedByCityQuery(variables?: GetLocationsSortedByCityQueryVariables) {
      return { query: GetLocationsSortedByCityDocument, variables: variables }
    }
export const GetLocationsByCityDocument = gql`
    query GetLocationsByCity($city: String!) {
  locationsByCity(city: $city) {
    id
    name
    venues {
      id
      name
    }
  }
}
    `;

/**
 * __useGetLocationsByCityQuery__
 *
 * To run a query within a React component, call `useGetLocationsByCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsByCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsByCityQuery({
 *   variables: {
 *      city: // value for 'city'
 *   },
 * });
 */
export function useGetLocationsByCityQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetLocationsByCityQuery, GetLocationsByCityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLocationsByCityQuery, GetLocationsByCityQueryVariables>(GetLocationsByCityDocument, options);
      }
export function useGetLocationsByCityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationsByCityQuery, GetLocationsByCityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLocationsByCityQuery, GetLocationsByCityQueryVariables>(GetLocationsByCityDocument, options);
        }
export type GetLocationsByCityQueryHookResult = ReturnType<typeof useGetLocationsByCityQuery>;
export type GetLocationsByCityLazyQueryHookResult = ReturnType<typeof useGetLocationsByCityLazyQuery>;
export type GetLocationsByCityQueryResult = ApolloReactCommon.QueryResult<GetLocationsByCityQuery, GetLocationsByCityQueryVariables>;
export function refetchGetLocationsByCityQuery(variables?: GetLocationsByCityQueryVariables) {
      return { query: GetLocationsByCityDocument, variables: variables }
    }
export const GetInstructorsDocument = gql`
    query GetInstructors {
  instructors {
    id
    firstname
    lastname
  }
}
    `;

/**
 * __useGetInstructorsQuery__
 *
 * To run a query within a React component, call `useGetInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInstructorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInstructorsQuery, GetInstructorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetInstructorsQuery, GetInstructorsQueryVariables>(GetInstructorsDocument, options);
      }
export function useGetInstructorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstructorsQuery, GetInstructorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetInstructorsQuery, GetInstructorsQueryVariables>(GetInstructorsDocument, options);
        }
export type GetInstructorsQueryHookResult = ReturnType<typeof useGetInstructorsQuery>;
export type GetInstructorsLazyQueryHookResult = ReturnType<typeof useGetInstructorsLazyQuery>;
export type GetInstructorsQueryResult = ApolloReactCommon.QueryResult<GetInstructorsQuery, GetInstructorsQueryVariables>;
export function refetchGetInstructorsQuery(variables?: GetInstructorsQueryVariables) {
      return { query: GetInstructorsDocument, variables: variables }
    }
export const GetCourseTypesDocument = gql`
    query GetCourseTypes {
  courseTypes {
    id
    name
    description
  }
}
    `;

/**
 * __useGetCourseTypesQuery__
 *
 * To run a query within a React component, call `useGetCourseTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCourseTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseTypesQuery, GetCourseTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCourseTypesQuery, GetCourseTypesQueryVariables>(GetCourseTypesDocument, options);
      }
export function useGetCourseTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseTypesQuery, GetCourseTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCourseTypesQuery, GetCourseTypesQueryVariables>(GetCourseTypesDocument, options);
        }
export type GetCourseTypesQueryHookResult = ReturnType<typeof useGetCourseTypesQuery>;
export type GetCourseTypesLazyQueryHookResult = ReturnType<typeof useGetCourseTypesLazyQuery>;
export type GetCourseTypesQueryResult = ApolloReactCommon.QueryResult<GetCourseTypesQuery, GetCourseTypesQueryVariables>;
export function refetchGetCourseTypesQuery(variables?: GetCourseTypesQueryVariables) {
      return { query: GetCourseTypesDocument, variables: variables }
    }
export const GetCourseTypesPublicDocument = gql`
    query GetCourseTypesPublic {
  courseTypesPublic {
    id
    name
    description
    showInWeb
  }
}
    `;

/**
 * __useGetCourseTypesPublicQuery__
 *
 * To run a query within a React component, call `useGetCourseTypesPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseTypesPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseTypesPublicQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCourseTypesPublicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseTypesPublicQuery, GetCourseTypesPublicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCourseTypesPublicQuery, GetCourseTypesPublicQueryVariables>(GetCourseTypesPublicDocument, options);
      }
export function useGetCourseTypesPublicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseTypesPublicQuery, GetCourseTypesPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCourseTypesPublicQuery, GetCourseTypesPublicQueryVariables>(GetCourseTypesPublicDocument, options);
        }
export type GetCourseTypesPublicQueryHookResult = ReturnType<typeof useGetCourseTypesPublicQuery>;
export type GetCourseTypesPublicLazyQueryHookResult = ReturnType<typeof useGetCourseTypesPublicLazyQuery>;
export type GetCourseTypesPublicQueryResult = ApolloReactCommon.QueryResult<GetCourseTypesPublicQuery, GetCourseTypesPublicQueryVariables>;
export function refetchGetCourseTypesPublicQuery(variables?: GetCourseTypesPublicQueryVariables) {
      return { query: GetCourseTypesPublicDocument, variables: variables }
    }
export const GetCourseCategoriesPublicDocument = gql`
    query GetCourseCategoriesPublic {
  courseCategoriesPublic {
    id
    name
  }
}
    `;

/**
 * __useGetCourseCategoriesPublicQuery__
 *
 * To run a query within a React component, call `useGetCourseCategoriesPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseCategoriesPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseCategoriesPublicQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCourseCategoriesPublicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseCategoriesPublicQuery, GetCourseCategoriesPublicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCourseCategoriesPublicQuery, GetCourseCategoriesPublicQueryVariables>(GetCourseCategoriesPublicDocument, options);
      }
export function useGetCourseCategoriesPublicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseCategoriesPublicQuery, GetCourseCategoriesPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCourseCategoriesPublicQuery, GetCourseCategoriesPublicQueryVariables>(GetCourseCategoriesPublicDocument, options);
        }
export type GetCourseCategoriesPublicQueryHookResult = ReturnType<typeof useGetCourseCategoriesPublicQuery>;
export type GetCourseCategoriesPublicLazyQueryHookResult = ReturnType<typeof useGetCourseCategoriesPublicLazyQuery>;
export type GetCourseCategoriesPublicQueryResult = ApolloReactCommon.QueryResult<GetCourseCategoriesPublicQuery, GetCourseCategoriesPublicQueryVariables>;
export function refetchGetCourseCategoriesPublicQuery(variables?: GetCourseCategoriesPublicQueryVariables) {
      return { query: GetCourseCategoriesPublicDocument, variables: variables }
    }
export const CreateCourseDocument = gql`
    mutation CreateCourse($newCourseData: NewCourseInput!) {
  createCourse(newCourseData: $newCourseData) {
    id
  }
}
    `;
export type CreateCourseMutationFn = ApolloReactCommon.MutationFunction<CreateCourseMutation, CreateCourseMutationVariables>;

/**
 * __useCreateCourseMutation__
 *
 * To run a mutation, you first call `useCreateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseMutation, { data, loading, error }] = useCreateCourseMutation({
 *   variables: {
 *      newCourseData: // value for 'newCourseData'
 *   },
 * });
 */
export function useCreateCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCourseMutation, CreateCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateCourseMutation, CreateCourseMutationVariables>(CreateCourseDocument, options);
      }
export type CreateCourseMutationHookResult = ReturnType<typeof useCreateCourseMutation>;
export type CreateCourseMutationResult = ApolloReactCommon.MutationResult<CreateCourseMutation>;
export type CreateCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCourseMutation, CreateCourseMutationVariables>;
export const GetDetailedCourseTypesDocument = gql`
    query GetDetailedCourseTypes {
  courseTypes {
    id
    shortHandId
    name
    updateDate
    description
    minAge
    maxAge
    paymentInterval
    fee
    minAttendees
    maxAttendees
    annotation
    websiteInfo
    color
  }
}
    `;

/**
 * __useGetDetailedCourseTypesQuery__
 *
 * To run a query within a React component, call `useGetDetailedCourseTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailedCourseTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailedCourseTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDetailedCourseTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDetailedCourseTypesQuery, GetDetailedCourseTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDetailedCourseTypesQuery, GetDetailedCourseTypesQueryVariables>(GetDetailedCourseTypesDocument, options);
      }
export function useGetDetailedCourseTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDetailedCourseTypesQuery, GetDetailedCourseTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDetailedCourseTypesQuery, GetDetailedCourseTypesQueryVariables>(GetDetailedCourseTypesDocument, options);
        }
export type GetDetailedCourseTypesQueryHookResult = ReturnType<typeof useGetDetailedCourseTypesQuery>;
export type GetDetailedCourseTypesLazyQueryHookResult = ReturnType<typeof useGetDetailedCourseTypesLazyQuery>;
export type GetDetailedCourseTypesQueryResult = ApolloReactCommon.QueryResult<GetDetailedCourseTypesQuery, GetDetailedCourseTypesQueryVariables>;
export function refetchGetDetailedCourseTypesQuery(variables?: GetDetailedCourseTypesQueryVariables) {
      return { query: GetDetailedCourseTypesDocument, variables: variables }
    }
export const GetCourseTypeDocument = gql`
    query GetCourseType($id: String!) {
  courseTypeById(id: $id) {
    id
    isActive
    showInWeb
    name
    shortHandId
    category {
      id
    }
    description
    minAge
    maxAge
    paymentInterval
    minAttendees
    maxAttendees
    annotation
    websiteInfo
    inWeekPlanner
    bold
    color
  }
}
    `;

/**
 * __useGetCourseTypeQuery__
 *
 * To run a query within a React component, call `useGetCourseTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseTypeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCourseTypeQuery, GetCourseTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCourseTypeQuery, GetCourseTypeQueryVariables>(GetCourseTypeDocument, options);
      }
export function useGetCourseTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseTypeQuery, GetCourseTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCourseTypeQuery, GetCourseTypeQueryVariables>(GetCourseTypeDocument, options);
        }
export type GetCourseTypeQueryHookResult = ReturnType<typeof useGetCourseTypeQuery>;
export type GetCourseTypeLazyQueryHookResult = ReturnType<typeof useGetCourseTypeLazyQuery>;
export type GetCourseTypeQueryResult = ApolloReactCommon.QueryResult<GetCourseTypeQuery, GetCourseTypeQueryVariables>;
export function refetchGetCourseTypeQuery(variables?: GetCourseTypeQueryVariables) {
      return { query: GetCourseTypeDocument, variables: variables }
    }
export const RemoveCourseTypeDocument = gql`
    mutation RemoveCourseType($id: ID!) {
  removeCourseType(id: $id)
}
    `;
export type RemoveCourseTypeMutationFn = ApolloReactCommon.MutationFunction<RemoveCourseTypeMutation, RemoveCourseTypeMutationVariables>;

/**
 * __useRemoveCourseTypeMutation__
 *
 * To run a mutation, you first call `useRemoveCourseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCourseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCourseTypeMutation, { data, loading, error }] = useRemoveCourseTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCourseTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCourseTypeMutation, RemoveCourseTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveCourseTypeMutation, RemoveCourseTypeMutationVariables>(RemoveCourseTypeDocument, options);
      }
export type RemoveCourseTypeMutationHookResult = ReturnType<typeof useRemoveCourseTypeMutation>;
export type RemoveCourseTypeMutationResult = ApolloReactCommon.MutationResult<RemoveCourseTypeMutation>;
export type RemoveCourseTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCourseTypeMutation, RemoveCourseTypeMutationVariables>;
export const CreateCourseTypeDocument = gql`
    mutation CreateCourseType($newCourseTypeData: NewCourseTypeInput!) {
  createCourseType(newCourseTypeData: $newCourseTypeData) {
    id
    isActive
    showInWeb
    name
    shortHandId
    category {
      id
    }
    description
    minAge
    maxAge
    paymentInterval
    minAttendees
    maxAttendees
    annotation
    websiteInfo
    inWeekPlanner
    bold
    color
  }
}
    `;
export type CreateCourseTypeMutationFn = ApolloReactCommon.MutationFunction<CreateCourseTypeMutation, CreateCourseTypeMutationVariables>;

/**
 * __useCreateCourseTypeMutation__
 *
 * To run a mutation, you first call `useCreateCourseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseTypeMutation, { data, loading, error }] = useCreateCourseTypeMutation({
 *   variables: {
 *      newCourseTypeData: // value for 'newCourseTypeData'
 *   },
 * });
 */
export function useCreateCourseTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCourseTypeMutation, CreateCourseTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateCourseTypeMutation, CreateCourseTypeMutationVariables>(CreateCourseTypeDocument, options);
      }
export type CreateCourseTypeMutationHookResult = ReturnType<typeof useCreateCourseTypeMutation>;
export type CreateCourseTypeMutationResult = ApolloReactCommon.MutationResult<CreateCourseTypeMutation>;
export type CreateCourseTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCourseTypeMutation, CreateCourseTypeMutationVariables>;
export const UpdateCourseTypeDocument = gql`
    mutation UpdateCourseType($id: String!, $newCourseTypeData: NewCourseTypeInput!) {
  updateCourseType(id: $id, newCourseTypeData: $newCourseTypeData) {
    id
    isActive
    showInWeb
    name
    shortHandId
    category {
      id
    }
    description
    minAge
    maxAge
    paymentInterval
    minAttendees
    maxAttendees
    annotation
    websiteInfo
    inWeekPlanner
    bold
    color
  }
}
    `;
export type UpdateCourseTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateCourseTypeMutation, UpdateCourseTypeMutationVariables>;

/**
 * __useUpdateCourseTypeMutation__
 *
 * To run a mutation, you first call `useUpdateCourseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseTypeMutation, { data, loading, error }] = useUpdateCourseTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newCourseTypeData: // value for 'newCourseTypeData'
 *   },
 * });
 */
export function useUpdateCourseTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCourseTypeMutation, UpdateCourseTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateCourseTypeMutation, UpdateCourseTypeMutationVariables>(UpdateCourseTypeDocument, options);
      }
export type UpdateCourseTypeMutationHookResult = ReturnType<typeof useUpdateCourseTypeMutation>;
export type UpdateCourseTypeMutationResult = ApolloReactCommon.MutationResult<UpdateCourseTypeMutation>;
export type UpdateCourseTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCourseTypeMutation, UpdateCourseTypeMutationVariables>;
export const CourseTypeGetCourseCategoriesDocument = gql`
    query CourseTypeGetCourseCategories {
  courseCategories {
    id
    name
  }
}
    `;

/**
 * __useCourseTypeGetCourseCategoriesQuery__
 *
 * To run a query within a React component, call `useCourseTypeGetCourseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseTypeGetCourseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseTypeGetCourseCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCourseTypeGetCourseCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CourseTypeGetCourseCategoriesQuery, CourseTypeGetCourseCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CourseTypeGetCourseCategoriesQuery, CourseTypeGetCourseCategoriesQueryVariables>(CourseTypeGetCourseCategoriesDocument, options);
      }
export function useCourseTypeGetCourseCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CourseTypeGetCourseCategoriesQuery, CourseTypeGetCourseCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CourseTypeGetCourseCategoriesQuery, CourseTypeGetCourseCategoriesQueryVariables>(CourseTypeGetCourseCategoriesDocument, options);
        }
export type CourseTypeGetCourseCategoriesQueryHookResult = ReturnType<typeof useCourseTypeGetCourseCategoriesQuery>;
export type CourseTypeGetCourseCategoriesLazyQueryHookResult = ReturnType<typeof useCourseTypeGetCourseCategoriesLazyQuery>;
export type CourseTypeGetCourseCategoriesQueryResult = ApolloReactCommon.QueryResult<CourseTypeGetCourseCategoriesQuery, CourseTypeGetCourseCategoriesQueryVariables>;
export function refetchCourseTypeGetCourseCategoriesQuery(variables?: CourseTypeGetCourseCategoriesQueryVariables) {
      return { query: CourseTypeGetCourseCategoriesDocument, variables: variables }
    }
export const GetCourseCategoriesDocument = gql`
    query GetCourseCategories {
  courseCategories {
    id
    isActive
    name
    description
    linkedCourseTypes {
      id
      name
      color
    }
  }
}
    `;

/**
 * __useGetCourseCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCourseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCourseCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>(GetCourseCategoriesDocument, options);
      }
export function useGetCourseCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>(GetCourseCategoriesDocument, options);
        }
export type GetCourseCategoriesQueryHookResult = ReturnType<typeof useGetCourseCategoriesQuery>;
export type GetCourseCategoriesLazyQueryHookResult = ReturnType<typeof useGetCourseCategoriesLazyQuery>;
export type GetCourseCategoriesQueryResult = ApolloReactCommon.QueryResult<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>;
export function refetchGetCourseCategoriesQuery(variables?: GetCourseCategoriesQueryVariables) {
      return { query: GetCourseCategoriesDocument, variables: variables }
    }
export const GetCourseCategoryDocument = gql`
    query GetCourseCategory($id: String!) {
  courseCategoryById(id: $id) {
    id
    isActive
    name
    description
    linkedCourseTypes {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCourseCategoryQuery__
 *
 * To run a query within a React component, call `useGetCourseCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseCategoryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>(GetCourseCategoryDocument, options);
      }
export function useGetCourseCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>(GetCourseCategoryDocument, options);
        }
export type GetCourseCategoryQueryHookResult = ReturnType<typeof useGetCourseCategoryQuery>;
export type GetCourseCategoryLazyQueryHookResult = ReturnType<typeof useGetCourseCategoryLazyQuery>;
export type GetCourseCategoryQueryResult = ApolloReactCommon.QueryResult<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>;
export function refetchGetCourseCategoryQuery(variables?: GetCourseCategoryQueryVariables) {
      return { query: GetCourseCategoryDocument, variables: variables }
    }
export const CreateCourseCategoryDocument = gql`
    mutation CreateCourseCategory($newCourseCategoryData: NewCourseCategoryInput!) {
  createCourseCategory(newCourseCategoryData: $newCourseCategoryData) {
    id
    name
    isActive
    description
    linkedCourseTypes {
      id
    }
  }
}
    `;
export type CreateCourseCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables>;

/**
 * __useCreateCourseCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCourseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseCategoryMutation, { data, loading, error }] = useCreateCourseCategoryMutation({
 *   variables: {
 *      newCourseCategoryData: // value for 'newCourseCategoryData'
 *   },
 * });
 */
export function useCreateCourseCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables>(CreateCourseCategoryDocument, options);
      }
export type CreateCourseCategoryMutationHookResult = ReturnType<typeof useCreateCourseCategoryMutation>;
export type CreateCourseCategoryMutationResult = ApolloReactCommon.MutationResult<CreateCourseCategoryMutation>;
export type CreateCourseCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables>;
export const UpdateCourseCategoryDocument = gql`
    mutation UpdateCourseCategory($id: String!, $newCourseCategoryData: NewCourseCategoryInput!) {
  updateCourseCategory(id: $id, newCourseCategoryData: $newCourseCategoryData) {
    id
    name
    isActive
    description
    linkedCourseTypes {
      id
    }
  }
}
    `;
export type UpdateCourseCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables>;

/**
 * __useUpdateCourseCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCourseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseCategoryMutation, { data, loading, error }] = useUpdateCourseCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newCourseCategoryData: // value for 'newCourseCategoryData'
 *   },
 * });
 */
export function useUpdateCourseCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables>(UpdateCourseCategoryDocument, options);
      }
export type UpdateCourseCategoryMutationHookResult = ReturnType<typeof useUpdateCourseCategoryMutation>;
export type UpdateCourseCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateCourseCategoryMutation>;
export type UpdateCourseCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables>;
export const CourseGetInstructorsDocument = gql`
    query CourseGetInstructors {
  instructors {
    id
    firstname
    lastname
  }
}
    `;

/**
 * __useCourseGetInstructorsQuery__
 *
 * To run a query within a React component, call `useCourseGetInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseGetInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseGetInstructorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCourseGetInstructorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CourseGetInstructorsQuery, CourseGetInstructorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CourseGetInstructorsQuery, CourseGetInstructorsQueryVariables>(CourseGetInstructorsDocument, options);
      }
export function useCourseGetInstructorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CourseGetInstructorsQuery, CourseGetInstructorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CourseGetInstructorsQuery, CourseGetInstructorsQueryVariables>(CourseGetInstructorsDocument, options);
        }
export type CourseGetInstructorsQueryHookResult = ReturnType<typeof useCourseGetInstructorsQuery>;
export type CourseGetInstructorsLazyQueryHookResult = ReturnType<typeof useCourseGetInstructorsLazyQuery>;
export type CourseGetInstructorsQueryResult = ApolloReactCommon.QueryResult<CourseGetInstructorsQuery, CourseGetInstructorsQueryVariables>;
export function refetchCourseGetInstructorsQuery(variables?: CourseGetInstructorsQueryVariables) {
      return { query: CourseGetInstructorsDocument, variables: variables }
    }
export const CourseGetInstructorsPublicDocument = gql`
    query CourseGetInstructorsPublic {
  instructorsPublic {
    id
    firstname
    lastname
  }
}
    `;

/**
 * __useCourseGetInstructorsPublicQuery__
 *
 * To run a query within a React component, call `useCourseGetInstructorsPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseGetInstructorsPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseGetInstructorsPublicQuery({
 *   variables: {
 *   },
 * });
 */
export function useCourseGetInstructorsPublicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CourseGetInstructorsPublicQuery, CourseGetInstructorsPublicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CourseGetInstructorsPublicQuery, CourseGetInstructorsPublicQueryVariables>(CourseGetInstructorsPublicDocument, options);
      }
export function useCourseGetInstructorsPublicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CourseGetInstructorsPublicQuery, CourseGetInstructorsPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CourseGetInstructorsPublicQuery, CourseGetInstructorsPublicQueryVariables>(CourseGetInstructorsPublicDocument, options);
        }
export type CourseGetInstructorsPublicQueryHookResult = ReturnType<typeof useCourseGetInstructorsPublicQuery>;
export type CourseGetInstructorsPublicLazyQueryHookResult = ReturnType<typeof useCourseGetInstructorsPublicLazyQuery>;
export type CourseGetInstructorsPublicQueryResult = ApolloReactCommon.QueryResult<CourseGetInstructorsPublicQuery, CourseGetInstructorsPublicQueryVariables>;
export function refetchCourseGetInstructorsPublicQuery(variables?: CourseGetInstructorsPublicQueryVariables) {
      return { query: CourseGetInstructorsPublicDocument, variables: variables }
    }
export const CourseGetInstructorsByLocationDocument = gql`
    query CourseGetInstructorsByLocation($locationId: String!) {
  findInstructorsByLocation(locationId: $locationId) {
    id
    firstname
    lastname
  }
}
    `;

/**
 * __useCourseGetInstructorsByLocationQuery__
 *
 * To run a query within a React component, call `useCourseGetInstructorsByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseGetInstructorsByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseGetInstructorsByLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useCourseGetInstructorsByLocationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CourseGetInstructorsByLocationQuery, CourseGetInstructorsByLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CourseGetInstructorsByLocationQuery, CourseGetInstructorsByLocationQueryVariables>(CourseGetInstructorsByLocationDocument, options);
      }
export function useCourseGetInstructorsByLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CourseGetInstructorsByLocationQuery, CourseGetInstructorsByLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CourseGetInstructorsByLocationQuery, CourseGetInstructorsByLocationQueryVariables>(CourseGetInstructorsByLocationDocument, options);
        }
export type CourseGetInstructorsByLocationQueryHookResult = ReturnType<typeof useCourseGetInstructorsByLocationQuery>;
export type CourseGetInstructorsByLocationLazyQueryHookResult = ReturnType<typeof useCourseGetInstructorsByLocationLazyQuery>;
export type CourseGetInstructorsByLocationQueryResult = ApolloReactCommon.QueryResult<CourseGetInstructorsByLocationQuery, CourseGetInstructorsByLocationQueryVariables>;
export function refetchCourseGetInstructorsByLocationQuery(variables?: CourseGetInstructorsByLocationQueryVariables) {
      return { query: CourseGetInstructorsByLocationDocument, variables: variables }
    }
export const GetCourseDetailsByIdPublicDocument = gql`
    query GetCourseDetailsByIdPublic($courseId: String!) {
  courseByIdPublic(id: $courseId) {
    id
    courseType {
      id
      name
      color
      minAge
      maxAge
    }
    prefixedCourseNumber
    startDateTime
    endDateTime
    bookingCount
    freePlaces
    maxAttendees
    websiteInfo
    location {
      id
      name
      city
    }
    venue {
      id
      name
    }
    instructors {
      id
      firstname
      lastname
    }
    lessons {
      id
      startDateTime
      endDateTime
    }
    coursePrice {
      id
      netPrice
      grossPrice
      vat
      includedEntranceFee
    }
    paymentInterval
    monthlySchedule
    paymentType
    lessonCount
    installmentRates {
      id
      installmentAmount
      installmentDue
    }
    allowMonthlyPayment
    allowUpFrontPayment
  }
}
    `;

/**
 * __useGetCourseDetailsByIdPublicQuery__
 *
 * To run a query within a React component, call `useGetCourseDetailsByIdPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseDetailsByIdPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseDetailsByIdPublicQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseDetailsByIdPublicQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCourseDetailsByIdPublicQuery, GetCourseDetailsByIdPublicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCourseDetailsByIdPublicQuery, GetCourseDetailsByIdPublicQueryVariables>(GetCourseDetailsByIdPublicDocument, options);
      }
export function useGetCourseDetailsByIdPublicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseDetailsByIdPublicQuery, GetCourseDetailsByIdPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCourseDetailsByIdPublicQuery, GetCourseDetailsByIdPublicQueryVariables>(GetCourseDetailsByIdPublicDocument, options);
        }
export type GetCourseDetailsByIdPublicQueryHookResult = ReturnType<typeof useGetCourseDetailsByIdPublicQuery>;
export type GetCourseDetailsByIdPublicLazyQueryHookResult = ReturnType<typeof useGetCourseDetailsByIdPublicLazyQuery>;
export type GetCourseDetailsByIdPublicQueryResult = ApolloReactCommon.QueryResult<GetCourseDetailsByIdPublicQuery, GetCourseDetailsByIdPublicQueryVariables>;
export function refetchGetCourseDetailsByIdPublicQuery(variables?: GetCourseDetailsByIdPublicQueryVariables) {
      return { query: GetCourseDetailsByIdPublicDocument, variables: variables }
    }
export const GetCourseLessonsByCourseIdDocument = gql`
    query GetCourseLessonsByCourseId($courseId: ID!) {
  courseLessonsByCourseId(courseId: $courseId) {
    id
    startDateTime
    endDateTime
  }
}
    `;

/**
 * __useGetCourseLessonsByCourseIdQuery__
 *
 * To run a query within a React component, call `useGetCourseLessonsByCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseLessonsByCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseLessonsByCourseIdQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseLessonsByCourseIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCourseLessonsByCourseIdQuery, GetCourseLessonsByCourseIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCourseLessonsByCourseIdQuery, GetCourseLessonsByCourseIdQueryVariables>(GetCourseLessonsByCourseIdDocument, options);
      }
export function useGetCourseLessonsByCourseIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseLessonsByCourseIdQuery, GetCourseLessonsByCourseIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCourseLessonsByCourseIdQuery, GetCourseLessonsByCourseIdQueryVariables>(GetCourseLessonsByCourseIdDocument, options);
        }
export type GetCourseLessonsByCourseIdQueryHookResult = ReturnType<typeof useGetCourseLessonsByCourseIdQuery>;
export type GetCourseLessonsByCourseIdLazyQueryHookResult = ReturnType<typeof useGetCourseLessonsByCourseIdLazyQuery>;
export type GetCourseLessonsByCourseIdQueryResult = ApolloReactCommon.QueryResult<GetCourseLessonsByCourseIdQuery, GetCourseLessonsByCourseIdQueryVariables>;
export function refetchGetCourseLessonsByCourseIdQuery(variables?: GetCourseLessonsByCourseIdQueryVariables) {
      return { query: GetCourseLessonsByCourseIdDocument, variables: variables }
    }
export const GetCourseMinMaxAgeDocument = gql`
    query GetCourseMinMaxAge($courseId: String!) {
  getCourseMinMaxAge(courseId: $courseId)
}
    `;

/**
 * __useGetCourseMinMaxAgeQuery__
 *
 * To run a query within a React component, call `useGetCourseMinMaxAgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseMinMaxAgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseMinMaxAgeQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseMinMaxAgeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCourseMinMaxAgeQuery, GetCourseMinMaxAgeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCourseMinMaxAgeQuery, GetCourseMinMaxAgeQueryVariables>(GetCourseMinMaxAgeDocument, options);
      }
export function useGetCourseMinMaxAgeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseMinMaxAgeQuery, GetCourseMinMaxAgeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCourseMinMaxAgeQuery, GetCourseMinMaxAgeQueryVariables>(GetCourseMinMaxAgeDocument, options);
        }
export type GetCourseMinMaxAgeQueryHookResult = ReturnType<typeof useGetCourseMinMaxAgeQuery>;
export type GetCourseMinMaxAgeLazyQueryHookResult = ReturnType<typeof useGetCourseMinMaxAgeLazyQuery>;
export type GetCourseMinMaxAgeQueryResult = ApolloReactCommon.QueryResult<GetCourseMinMaxAgeQuery, GetCourseMinMaxAgeQueryVariables>;
export function refetchGetCourseMinMaxAgeQuery(variables?: GetCourseMinMaxAgeQueryVariables) {
      return { query: GetCourseMinMaxAgeDocument, variables: variables }
    }
export const CheckAttendeesAgeAtCourseStartDocument = gql`
    query CheckAttendeesAgeAtCourseStart($courseId: String!, $attendeeId: String!) {
  checkAttendeesAgeAtCourseStart(courseId: $courseId, attendeeId: $attendeeId)
}
    `;

/**
 * __useCheckAttendeesAgeAtCourseStartQuery__
 *
 * To run a query within a React component, call `useCheckAttendeesAgeAtCourseStartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAttendeesAgeAtCourseStartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAttendeesAgeAtCourseStartQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useCheckAttendeesAgeAtCourseStartQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CheckAttendeesAgeAtCourseStartQuery, CheckAttendeesAgeAtCourseStartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CheckAttendeesAgeAtCourseStartQuery, CheckAttendeesAgeAtCourseStartQueryVariables>(CheckAttendeesAgeAtCourseStartDocument, options);
      }
export function useCheckAttendeesAgeAtCourseStartLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckAttendeesAgeAtCourseStartQuery, CheckAttendeesAgeAtCourseStartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CheckAttendeesAgeAtCourseStartQuery, CheckAttendeesAgeAtCourseStartQueryVariables>(CheckAttendeesAgeAtCourseStartDocument, options);
        }
export type CheckAttendeesAgeAtCourseStartQueryHookResult = ReturnType<typeof useCheckAttendeesAgeAtCourseStartQuery>;
export type CheckAttendeesAgeAtCourseStartLazyQueryHookResult = ReturnType<typeof useCheckAttendeesAgeAtCourseStartLazyQuery>;
export type CheckAttendeesAgeAtCourseStartQueryResult = ApolloReactCommon.QueryResult<CheckAttendeesAgeAtCourseStartQuery, CheckAttendeesAgeAtCourseStartQueryVariables>;
export function refetchCheckAttendeesAgeAtCourseStartQuery(variables?: CheckAttendeesAgeAtCourseStartQueryVariables) {
      return { query: CheckAttendeesAgeAtCourseStartDocument, variables: variables }
    }
export const GetBadgesDocument = gql`
    query GetBadges {
  badges {
    id
    name
    description
  }
}
    `;

/**
 * __useGetBadgesQuery__
 *
 * To run a query within a React component, call `useGetBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBadgesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBadgesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBadgesQuery, GetBadgesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetBadgesQuery, GetBadgesQueryVariables>(GetBadgesDocument, options);
      }
export function useGetBadgesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBadgesQuery, GetBadgesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetBadgesQuery, GetBadgesQueryVariables>(GetBadgesDocument, options);
        }
export type GetBadgesQueryHookResult = ReturnType<typeof useGetBadgesQuery>;
export type GetBadgesLazyQueryHookResult = ReturnType<typeof useGetBadgesLazyQuery>;
export type GetBadgesQueryResult = ApolloReactCommon.QueryResult<GetBadgesQuery, GetBadgesQueryVariables>;
export function refetchGetBadgesQuery(variables?: GetBadgesQueryVariables) {
      return { query: GetBadgesDocument, variables: variables }
    }
export const CreateBookerDocument = gql`
    mutation CreateBooker($newBookerData: BookerInput!) {
  createBooker(newBookerData: $newBookerData) {
    id
    gender
    firstname
    lastname
    street
    houseNumber
    postCode
    city
    phoneNumber
    mobileNumber
    email
    birthday
    initialContact
    dsgvo
    newsletter
    healthInsuranceFund
    annotation
    paymentMethods {
      id
      cash
      creditCardAccountholder
      creditcardNumber
      expirationDate
      validFrom
      cvc
      paypalAccountholder
      email
      iban
      bank
      bic
      sepaAccountholder
    }
    attendees {
      id
      customer {
        id
      }
      gender
      lastname
      firstname
      birthday
      member
      defaultPaymentMethod {
        id
      }
      bookings {
        id
        courseLessons {
          id
        }
        paymentMethod {
          id
        }
        paymentType
        payDate
      }
    }
  }
}
    `;
export type CreateBookerMutationFn = ApolloReactCommon.MutationFunction<CreateBookerMutation, CreateBookerMutationVariables>;

/**
 * __useCreateBookerMutation__
 *
 * To run a mutation, you first call `useCreateBookerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookerMutation, { data, loading, error }] = useCreateBookerMutation({
 *   variables: {
 *      newBookerData: // value for 'newBookerData'
 *   },
 * });
 */
export function useCreateBookerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBookerMutation, CreateBookerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateBookerMutation, CreateBookerMutationVariables>(CreateBookerDocument, options);
      }
export type CreateBookerMutationHookResult = ReturnType<typeof useCreateBookerMutation>;
export type CreateBookerMutationResult = ApolloReactCommon.MutationResult<CreateBookerMutation>;
export type CreateBookerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBookerMutation, CreateBookerMutationVariables>;
export const UpdateBookerDocument = gql`
    mutation UpdateBooker($updateBookerData: BookerInput!, $fromEndUserApp: Boolean!) {
  updateBooker(
    updateBookerData: $updateBookerData
    fromEndUserApp: $fromEndUserApp
  ) {
    id
    gender
    firstname
    lastname
    street
    houseNumber
    postCode
    city
    phoneNumber
    mobileNumber
    email
    birthday
    initialContact
    dsgvo
    newsletter
    healthInsuranceFund
    annotation
    paymentMethods {
      id
      cash
      creditCardAccountholder
      creditcardNumber
      expirationDate
      validFrom
      cvc
      paypalAccountholder
      email
      iban
      bank
      bic
      sepaAccountholder
    }
    attendees {
      id
      customer {
        id
      }
      gender
      lastname
      firstname
      birthday
      member
      deletable
      startedCourses {
        id
        prefixedCourseNumber
      }
      defaultPaymentMethod {
        id
      }
      bookings {
        id
        courseLessons {
          id
        }
        paymentMethod {
          id
        }
        paymentType
        payDate
      }
    }
    userId
  }
}
    `;
export type UpdateBookerMutationFn = ApolloReactCommon.MutationFunction<UpdateBookerMutation, UpdateBookerMutationVariables>;

/**
 * __useUpdateBookerMutation__
 *
 * To run a mutation, you first call `useUpdateBookerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookerMutation, { data, loading, error }] = useUpdateBookerMutation({
 *   variables: {
 *      updateBookerData: // value for 'updateBookerData'
 *      fromEndUserApp: // value for 'fromEndUserApp'
 *   },
 * });
 */
export function useUpdateBookerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBookerMutation, UpdateBookerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateBookerMutation, UpdateBookerMutationVariables>(UpdateBookerDocument, options);
      }
export type UpdateBookerMutationHookResult = ReturnType<typeof useUpdateBookerMutation>;
export type UpdateBookerMutationResult = ApolloReactCommon.MutationResult<UpdateBookerMutation>;
export type UpdateBookerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBookerMutation, UpdateBookerMutationVariables>;
export const GetUpdatedBookerDocument = gql`
    query GetUpdatedBooker($id: String!) {
  booker(id: $id) {
    id
    firstname
    lastname
    gender
    createDate
    updateDate
    customerId
    addition
    street
    houseNumber
    postCode
    city
    phoneNumber
    mobileNumber
    email
    birthday
    initialContact
    dsgvo
    newsletter
    customer {
      id
    }
    paymentMethods {
      id
      cash
      creditCardAccountholder
      creditcardNumber
      expirationDate
      validFrom
      cvc
      paypalAccountholder
      email
      iban
      bank
      bic
      sepaAccountholder
      bankTransfer
      esr
    }
    attendees {
      id
      customer {
        id
      }
      gender
      lastname
      firstname
      birthday
      member
      defaultPaymentMethod {
        id
      }
      bookings {
        id
        courseLessons {
          id
        }
        paymentMethod {
          id
        }
        paymentType
        payDate
      }
    }
    healthInsuranceFund
    annotation
    createdBy
    userId
  }
}
    `;

/**
 * __useGetUpdatedBookerQuery__
 *
 * To run a query within a React component, call `useGetUpdatedBookerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdatedBookerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdatedBookerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUpdatedBookerQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetUpdatedBookerQuery, GetUpdatedBookerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUpdatedBookerQuery, GetUpdatedBookerQueryVariables>(GetUpdatedBookerDocument, options);
      }
export function useGetUpdatedBookerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUpdatedBookerQuery, GetUpdatedBookerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUpdatedBookerQuery, GetUpdatedBookerQueryVariables>(GetUpdatedBookerDocument, options);
        }
export type GetUpdatedBookerQueryHookResult = ReturnType<typeof useGetUpdatedBookerQuery>;
export type GetUpdatedBookerLazyQueryHookResult = ReturnType<typeof useGetUpdatedBookerLazyQuery>;
export type GetUpdatedBookerQueryResult = ApolloReactCommon.QueryResult<GetUpdatedBookerQuery, GetUpdatedBookerQueryVariables>;
export function refetchGetUpdatedBookerQuery(variables?: GetUpdatedBookerQueryVariables) {
      return { query: GetUpdatedBookerDocument, variables: variables }
    }
export const DeleteAttendeeDocument = gql`
    mutation DeleteAttendee($id: String!) {
  deleteAttendee(id: $id)
}
    `;
export type DeleteAttendeeMutationFn = ApolloReactCommon.MutationFunction<DeleteAttendeeMutation, DeleteAttendeeMutationVariables>;

/**
 * __useDeleteAttendeeMutation__
 *
 * To run a mutation, you first call `useDeleteAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttendeeMutation, { data, loading, error }] = useDeleteAttendeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAttendeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAttendeeMutation, DeleteAttendeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteAttendeeMutation, DeleteAttendeeMutationVariables>(DeleteAttendeeDocument, options);
      }
export type DeleteAttendeeMutationHookResult = ReturnType<typeof useDeleteAttendeeMutation>;
export type DeleteAttendeeMutationResult = ApolloReactCommon.MutationResult<DeleteAttendeeMutation>;
export type DeleteAttendeeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAttendeeMutation, DeleteAttendeeMutationVariables>;
export const BookPaymentMethodGetLocalStateDocument = gql`
    query bookPaymentMethodGetLocalState {
  LocalState @client {
    bookPaymentMethods {
      selectedPaymentMethod {
        id
        type
        bookedCourseIds
      }
      selectedCourseId
    }
  }
}
    `;

/**
 * __useBookPaymentMethodGetLocalStateQuery__
 *
 * To run a query within a React component, call `useBookPaymentMethodGetLocalStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookPaymentMethodGetLocalStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookPaymentMethodGetLocalStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookPaymentMethodGetLocalStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookPaymentMethodGetLocalStateQuery, BookPaymentMethodGetLocalStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BookPaymentMethodGetLocalStateQuery, BookPaymentMethodGetLocalStateQueryVariables>(BookPaymentMethodGetLocalStateDocument, options);
      }
export function useBookPaymentMethodGetLocalStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookPaymentMethodGetLocalStateQuery, BookPaymentMethodGetLocalStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BookPaymentMethodGetLocalStateQuery, BookPaymentMethodGetLocalStateQueryVariables>(BookPaymentMethodGetLocalStateDocument, options);
        }
export type BookPaymentMethodGetLocalStateQueryHookResult = ReturnType<typeof useBookPaymentMethodGetLocalStateQuery>;
export type BookPaymentMethodGetLocalStateLazyQueryHookResult = ReturnType<typeof useBookPaymentMethodGetLocalStateLazyQuery>;
export type BookPaymentMethodGetLocalStateQueryResult = ApolloReactCommon.QueryResult<BookPaymentMethodGetLocalStateQuery, BookPaymentMethodGetLocalStateQueryVariables>;
export function refetchBookPaymentMethodGetLocalStateQuery(variables?: BookPaymentMethodGetLocalStateQueryVariables) {
      return { query: BookPaymentMethodGetLocalStateDocument, variables: variables }
    }
export const GetCourseLessonStartDatesDocument = gql`
    query GetCourseLessonStartDates($ids: [String!]) {
  courseLessons(ids: $ids) {
    id
    startDateTime
    course {
      id
      prefixedCourseNumber
    }
  }
}
    `;

/**
 * __useGetCourseLessonStartDatesQuery__
 *
 * To run a query within a React component, call `useGetCourseLessonStartDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseLessonStartDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseLessonStartDatesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetCourseLessonStartDatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseLessonStartDatesQuery, GetCourseLessonStartDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCourseLessonStartDatesQuery, GetCourseLessonStartDatesQueryVariables>(GetCourseLessonStartDatesDocument, options);
      }
export function useGetCourseLessonStartDatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseLessonStartDatesQuery, GetCourseLessonStartDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCourseLessonStartDatesQuery, GetCourseLessonStartDatesQueryVariables>(GetCourseLessonStartDatesDocument, options);
        }
export type GetCourseLessonStartDatesQueryHookResult = ReturnType<typeof useGetCourseLessonStartDatesQuery>;
export type GetCourseLessonStartDatesLazyQueryHookResult = ReturnType<typeof useGetCourseLessonStartDatesLazyQuery>;
export type GetCourseLessonStartDatesQueryResult = ApolloReactCommon.QueryResult<GetCourseLessonStartDatesQuery, GetCourseLessonStartDatesQueryVariables>;
export function refetchGetCourseLessonStartDatesQuery(variables?: GetCourseLessonStartDatesQueryVariables) {
      return { query: GetCourseLessonStartDatesDocument, variables: variables }
    }
export const UpdateBookerAccountEndUserAppDocument = gql`
    mutation UpdateBookerAccountEndUserApp($updateBookerEndUserAppData: UpdateBookerAccountEndUserAppInput!, $fromEndUserApp: Boolean!) {
  updateBookerAccountEndUserApp(
    updateBookerEndUserAppData: $updateBookerEndUserAppData
    fromEndUserApp: $fromEndUserApp
  ) {
    id
    gender
    firstname
    lastname
    birthday
    street
    postCode
    city
    mobileNumber
    phoneNumber
    dsgvo
    newsletter
    email
  }
}
    `;
export type UpdateBookerAccountEndUserAppMutationFn = ApolloReactCommon.MutationFunction<UpdateBookerAccountEndUserAppMutation, UpdateBookerAccountEndUserAppMutationVariables>;

/**
 * __useUpdateBookerAccountEndUserAppMutation__
 *
 * To run a mutation, you first call `useUpdateBookerAccountEndUserAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookerAccountEndUserAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookerAccountEndUserAppMutation, { data, loading, error }] = useUpdateBookerAccountEndUserAppMutation({
 *   variables: {
 *      updateBookerEndUserAppData: // value for 'updateBookerEndUserAppData'
 *      fromEndUserApp: // value for 'fromEndUserApp'
 *   },
 * });
 */
export function useUpdateBookerAccountEndUserAppMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBookerAccountEndUserAppMutation, UpdateBookerAccountEndUserAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateBookerAccountEndUserAppMutation, UpdateBookerAccountEndUserAppMutationVariables>(UpdateBookerAccountEndUserAppDocument, options);
      }
export type UpdateBookerAccountEndUserAppMutationHookResult = ReturnType<typeof useUpdateBookerAccountEndUserAppMutation>;
export type UpdateBookerAccountEndUserAppMutationResult = ApolloReactCommon.MutationResult<UpdateBookerAccountEndUserAppMutation>;
export type UpdateBookerAccountEndUserAppMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBookerAccountEndUserAppMutation, UpdateBookerAccountEndUserAppMutationVariables>;
export const AddBookerAsAttendeeDocument = gql`
    mutation AddBookerAsAttendee($bookerId: ID!) {
  addBookerAsAttendee(bookerId: $bookerId) {
    id
    customerId
    firstname
    lastname
    birthday
    gender
    attendees {
      id
      customerId
      customer {
        firstname
        lastname
        gender
      }
      birthday
      defaultPaymentMethodId
    }
  }
}
    `;
export type AddBookerAsAttendeeMutationFn = ApolloReactCommon.MutationFunction<AddBookerAsAttendeeMutation, AddBookerAsAttendeeMutationVariables>;

/**
 * __useAddBookerAsAttendeeMutation__
 *
 * To run a mutation, you first call `useAddBookerAsAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBookerAsAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBookerAsAttendeeMutation, { data, loading, error }] = useAddBookerAsAttendeeMutation({
 *   variables: {
 *      bookerId: // value for 'bookerId'
 *   },
 * });
 */
export function useAddBookerAsAttendeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddBookerAsAttendeeMutation, AddBookerAsAttendeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddBookerAsAttendeeMutation, AddBookerAsAttendeeMutationVariables>(AddBookerAsAttendeeDocument, options);
      }
export type AddBookerAsAttendeeMutationHookResult = ReturnType<typeof useAddBookerAsAttendeeMutation>;
export type AddBookerAsAttendeeMutationResult = ApolloReactCommon.MutationResult<AddBookerAsAttendeeMutation>;
export type AddBookerAsAttendeeMutationOptions = ApolloReactCommon.BaseMutationOptions<AddBookerAsAttendeeMutation, AddBookerAsAttendeeMutationVariables>;
export const CreateBookingDocument = gql`
    mutation CreateBooking($newBookingData: NewBookingInput!, $sendMail: Boolean!) {
  createBooking(newBookingData: $newBookingData, sendMail: $sendMail) {
    id
    bookingType
  }
}
    `;
export type CreateBookingMutationFn = ApolloReactCommon.MutationFunction<CreateBookingMutation, CreateBookingMutationVariables>;

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      newBookingData: // value for 'newBookingData'
 *      sendMail: // value for 'sendMail'
 *   },
 * });
 */
export function useCreateBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBookingMutation, CreateBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateBookingMutation, CreateBookingMutationVariables>(CreateBookingDocument, options);
      }
export type CreateBookingMutationHookResult = ReturnType<typeof useCreateBookingMutation>;
export type CreateBookingMutationResult = ApolloReactCommon.MutationResult<CreateBookingMutation>;
export type CreateBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBookingMutation, CreateBookingMutationVariables>;
export const CreateAndRegisterThirdPartyUserAndBookerDocument = gql`
    mutation createAndRegisterThirdPartyUserAndBooker($newBookingData: BookerInput!, $thirdPartyUserInput: ThirdPartyUserInput!) {
  createAndRegisterThirdPartyUserAndBooker(
    newBookingData: $newBookingData
    thirdPartyUserInput: $thirdPartyUserInput
  ) {
    id
    roles
    email
    booker {
      id
      firstname
      lastname
      gender
      createDate
      updateDate
      customerId
      addition
      street
      houseNumber
      postCode
      city
      phoneNumber
      mobileNumber
      email
      birthday
      initialContact
      dsgvo
      newsletter
      paymentMethods {
        id
        cash
        creditCardAccountholder
        creditcardNumber
        expirationDate
        validFrom
        cvc
        paypalAccountholder
        email
        iban
        bank
        bic
        sepaAccountholder
        bankTransfer
        esr
      }
      attendees {
        id
        customer {
          id
        }
        gender
        lastname
        firstname
        birthday
        member
        defaultPaymentMethod {
          id
        }
        bookings {
          id
          courseLessons {
            id
          }
          paymentMethod {
            id
          }
          paymentType
          payDate
        }
      }
      healthInsuranceFund
      annotation
      createdBy
    }
    refreshToken
    accessToken
  }
}
    `;
export type CreateAndRegisterThirdPartyUserAndBookerMutationFn = ApolloReactCommon.MutationFunction<CreateAndRegisterThirdPartyUserAndBookerMutation, CreateAndRegisterThirdPartyUserAndBookerMutationVariables>;

/**
 * __useCreateAndRegisterThirdPartyUserAndBookerMutation__
 *
 * To run a mutation, you first call `useCreateAndRegisterThirdPartyUserAndBookerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndRegisterThirdPartyUserAndBookerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndRegisterThirdPartyUserAndBookerMutation, { data, loading, error }] = useCreateAndRegisterThirdPartyUserAndBookerMutation({
 *   variables: {
 *      newBookingData: // value for 'newBookingData'
 *      thirdPartyUserInput: // value for 'thirdPartyUserInput'
 *   },
 * });
 */
export function useCreateAndRegisterThirdPartyUserAndBookerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAndRegisterThirdPartyUserAndBookerMutation, CreateAndRegisterThirdPartyUserAndBookerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateAndRegisterThirdPartyUserAndBookerMutation, CreateAndRegisterThirdPartyUserAndBookerMutationVariables>(CreateAndRegisterThirdPartyUserAndBookerDocument, options);
      }
export type CreateAndRegisterThirdPartyUserAndBookerMutationHookResult = ReturnType<typeof useCreateAndRegisterThirdPartyUserAndBookerMutation>;
export type CreateAndRegisterThirdPartyUserAndBookerMutationResult = ApolloReactCommon.MutationResult<CreateAndRegisterThirdPartyUserAndBookerMutation>;
export type CreateAndRegisterThirdPartyUserAndBookerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAndRegisterThirdPartyUserAndBookerMutation, CreateAndRegisterThirdPartyUserAndBookerMutationVariables>;
export const ForgetPasswordDocument = gql`
    mutation forgetPassword($email: String!) {
  forgetPassword(email: $email)
}
    `;
export type ForgetPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgetPasswordMutation, ForgetPasswordMutationVariables>;

/**
 * __useForgetPasswordMutation__
 *
 * To run a mutation, you first call `useForgetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgetPasswordMutation, { data, loading, error }] = useForgetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgetPasswordMutation, ForgetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ForgetPasswordMutation, ForgetPasswordMutationVariables>(ForgetPasswordDocument, options);
      }
export type ForgetPasswordMutationHookResult = ReturnType<typeof useForgetPasswordMutation>;
export type ForgetPasswordMutationResult = ApolloReactCommon.MutationResult<ForgetPasswordMutation>;
export type ForgetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgetPasswordMutation, ForgetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logoutUser
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const AuthenticateDocument = gql`
    query Authenticate {
  authenticate {
    id
    email
    roles
    hasProfile
  }
}
    `;

/**
 * __useAuthenticateQuery__
 *
 * To run a query within a React component, call `useAuthenticateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticateQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthenticateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuthenticateQuery, AuthenticateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AuthenticateQuery, AuthenticateQueryVariables>(AuthenticateDocument, options);
      }
export function useAuthenticateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthenticateQuery, AuthenticateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AuthenticateQuery, AuthenticateQueryVariables>(AuthenticateDocument, options);
        }
export type AuthenticateQueryHookResult = ReturnType<typeof useAuthenticateQuery>;
export type AuthenticateLazyQueryHookResult = ReturnType<typeof useAuthenticateLazyQuery>;
export type AuthenticateQueryResult = ApolloReactCommon.QueryResult<AuthenticateQuery, AuthenticateQueryVariables>;
export function refetchAuthenticateQuery(variables?: AuthenticateQueryVariables) {
      return { query: AuthenticateDocument, variables: variables }
    }
export const PasswordlessLoginDocument = gql`
    mutation passwordlessLogin($loginData: PasswordlessLoginInputType!) {
  passwordlessLogin(loginData: $loginData)
}
    `;
export type PasswordlessLoginMutationFn = ApolloReactCommon.MutationFunction<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>;

/**
 * __usePasswordlessLoginMutation__
 *
 * To run a mutation, you first call `usePasswordlessLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordlessLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordlessLoginMutation, { data, loading, error }] = usePasswordlessLoginMutation({
 *   variables: {
 *      loginData: // value for 'loginData'
 *   },
 * });
 */
export function usePasswordlessLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>(PasswordlessLoginDocument, options);
      }
export type PasswordlessLoginMutationHookResult = ReturnType<typeof usePasswordlessLoginMutation>;
export type PasswordlessLoginMutationResult = ApolloReactCommon.MutationResult<PasswordlessLoginMutation>;
export type PasswordlessLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>;
export const CompletePasswordlessFlowDocument = gql`
    mutation completePasswordlessFlow($loginCode: String!) {
  completePasswordlessFlow(loginCode: $loginCode) {
    id
    roles
    email
  }
}
    `;
export type CompletePasswordlessFlowMutationFn = ApolloReactCommon.MutationFunction<CompletePasswordlessFlowMutation, CompletePasswordlessFlowMutationVariables>;

/**
 * __useCompletePasswordlessFlowMutation__
 *
 * To run a mutation, you first call `useCompletePasswordlessFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePasswordlessFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePasswordlessFlowMutation, { data, loading, error }] = useCompletePasswordlessFlowMutation({
 *   variables: {
 *      loginCode: // value for 'loginCode'
 *   },
 * });
 */
export function useCompletePasswordlessFlowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompletePasswordlessFlowMutation, CompletePasswordlessFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CompletePasswordlessFlowMutation, CompletePasswordlessFlowMutationVariables>(CompletePasswordlessFlowDocument, options);
      }
export type CompletePasswordlessFlowMutationHookResult = ReturnType<typeof useCompletePasswordlessFlowMutation>;
export type CompletePasswordlessFlowMutationResult = ApolloReactCommon.MutationResult<CompletePasswordlessFlowMutation>;
export type CompletePasswordlessFlowMutationOptions = ApolloReactCommon.BaseMutationOptions<CompletePasswordlessFlowMutation, CompletePasswordlessFlowMutationVariables>;
export const CheckEmailUniquenessDocument = gql`
    query checkEmailUniqueness($email: String!) {
  checkEmailUniqueness(email: $email)
}
    `;

/**
 * __useCheckEmailUniquenessQuery__
 *
 * To run a query within a React component, call `useCheckEmailUniquenessQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailUniquenessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEmailUniquenessQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckEmailUniquenessQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CheckEmailUniquenessQuery, CheckEmailUniquenessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CheckEmailUniquenessQuery, CheckEmailUniquenessQueryVariables>(CheckEmailUniquenessDocument, options);
      }
export function useCheckEmailUniquenessLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckEmailUniquenessQuery, CheckEmailUniquenessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CheckEmailUniquenessQuery, CheckEmailUniquenessQueryVariables>(CheckEmailUniquenessDocument, options);
        }
export type CheckEmailUniquenessQueryHookResult = ReturnType<typeof useCheckEmailUniquenessQuery>;
export type CheckEmailUniquenessLazyQueryHookResult = ReturnType<typeof useCheckEmailUniquenessLazyQuery>;
export type CheckEmailUniquenessQueryResult = ApolloReactCommon.QueryResult<CheckEmailUniquenessQuery, CheckEmailUniquenessQueryVariables>;
export function refetchCheckEmailUniquenessQuery(variables?: CheckEmailUniquenessQueryVariables) {
      return { query: CheckEmailUniquenessDocument, variables: variables }
    }
export const ChangeForgottenPasswordDocument = gql`
    mutation changeForgottenPassword($changePasswordId: String!, $newPassword: String!) {
  changeForgottenPassword(
    changePasswordId: $changePasswordId
    newPassword: $newPassword
  )
}
    `;
export type ChangeForgottenPasswordMutationFn = ApolloReactCommon.MutationFunction<ChangeForgottenPasswordMutation, ChangeForgottenPasswordMutationVariables>;

/**
 * __useChangeForgottenPasswordMutation__
 *
 * To run a mutation, you first call `useChangeForgottenPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeForgottenPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeForgottenPasswordMutation, { data, loading, error }] = useChangeForgottenPasswordMutation({
 *   variables: {
 *      changePasswordId: // value for 'changePasswordId'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangeForgottenPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeForgottenPasswordMutation, ChangeForgottenPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ChangeForgottenPasswordMutation, ChangeForgottenPasswordMutationVariables>(ChangeForgottenPasswordDocument, options);
      }
export type ChangeForgottenPasswordMutationHookResult = ReturnType<typeof useChangeForgottenPasswordMutation>;
export type ChangeForgottenPasswordMutationResult = ApolloReactCommon.MutationResult<ChangeForgottenPasswordMutation>;
export type ChangeForgottenPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeForgottenPasswordMutation, ChangeForgottenPasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($forgotPasswordWithStateInput: ForgotPasswordWithStateInput!) {
  forgotPassword(forgotPasswordWithStateInput: $forgotPasswordWithStateInput)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      forgotPasswordWithStateInput: // value for 'forgotPasswordWithStateInput'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const GetParticipationPrerequisitesByCourseTypeIdDocument = gql`
    query getParticipationPrerequisitesByCourseTypeId($courseTypeId: ID!) {
  participationPrerequisitesByCourseTypeId(courseTypeId: $courseTypeId) {
    id
    enabled
    groupDescription
    groupLabel
    minCheckCount
    optionalInputFields
    courseTypeId
  }
}
    `;

/**
 * __useGetParticipationPrerequisitesByCourseTypeIdQuery__
 *
 * To run a query within a React component, call `useGetParticipationPrerequisitesByCourseTypeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipationPrerequisitesByCourseTypeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipationPrerequisitesByCourseTypeIdQuery({
 *   variables: {
 *      courseTypeId: // value for 'courseTypeId'
 *   },
 * });
 */
export function useGetParticipationPrerequisitesByCourseTypeIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetParticipationPrerequisitesByCourseTypeIdQuery, GetParticipationPrerequisitesByCourseTypeIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetParticipationPrerequisitesByCourseTypeIdQuery, GetParticipationPrerequisitesByCourseTypeIdQueryVariables>(GetParticipationPrerequisitesByCourseTypeIdDocument, options);
      }
export function useGetParticipationPrerequisitesByCourseTypeIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetParticipationPrerequisitesByCourseTypeIdQuery, GetParticipationPrerequisitesByCourseTypeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetParticipationPrerequisitesByCourseTypeIdQuery, GetParticipationPrerequisitesByCourseTypeIdQueryVariables>(GetParticipationPrerequisitesByCourseTypeIdDocument, options);
        }
export type GetParticipationPrerequisitesByCourseTypeIdQueryHookResult = ReturnType<typeof useGetParticipationPrerequisitesByCourseTypeIdQuery>;
export type GetParticipationPrerequisitesByCourseTypeIdLazyQueryHookResult = ReturnType<typeof useGetParticipationPrerequisitesByCourseTypeIdLazyQuery>;
export type GetParticipationPrerequisitesByCourseTypeIdQueryResult = ApolloReactCommon.QueryResult<GetParticipationPrerequisitesByCourseTypeIdQuery, GetParticipationPrerequisitesByCourseTypeIdQueryVariables>;
export function refetchGetParticipationPrerequisitesByCourseTypeIdQuery(variables?: GetParticipationPrerequisitesByCourseTypeIdQueryVariables) {
      return { query: GetParticipationPrerequisitesByCourseTypeIdDocument, variables: variables }
    }
export const AddDefaultPaymentMethodDocument = gql`
    mutation addDefaultPaymentMethod($bookerId: String!, $paymentMethodType: String!, $sepaDebitIntput: SepaDebitIntput) {
  addPaymentMethod(
    bookerId: $bookerId
    paymentMethodType: $paymentMethodType
    sepaDebitIntput: $sepaDebitIntput
  ) {
    id
    type
    iban
    bank
    bic
    sepaAccountholder
    bankTransfer
    esr
  }
}
    `;
export type AddDefaultPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<AddDefaultPaymentMethodMutation, AddDefaultPaymentMethodMutationVariables>;

/**
 * __useAddDefaultPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddDefaultPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDefaultPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDefaultPaymentMethodMutation, { data, loading, error }] = useAddDefaultPaymentMethodMutation({
 *   variables: {
 *      bookerId: // value for 'bookerId'
 *      paymentMethodType: // value for 'paymentMethodType'
 *      sepaDebitIntput: // value for 'sepaDebitIntput'
 *   },
 * });
 */
export function useAddDefaultPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDefaultPaymentMethodMutation, AddDefaultPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddDefaultPaymentMethodMutation, AddDefaultPaymentMethodMutationVariables>(AddDefaultPaymentMethodDocument, options);
      }
export type AddDefaultPaymentMethodMutationHookResult = ReturnType<typeof useAddDefaultPaymentMethodMutation>;
export type AddDefaultPaymentMethodMutationResult = ApolloReactCommon.MutationResult<AddDefaultPaymentMethodMutation>;
export type AddDefaultPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDefaultPaymentMethodMutation, AddDefaultPaymentMethodMutationVariables>;
export const GetBankDataDocument = gql`
    query getBankData($iban: String!) {
  getBankData(iban: $iban)
}
    `;

/**
 * __useGetBankDataQuery__
 *
 * To run a query within a React component, call `useGetBankDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankDataQuery({
 *   variables: {
 *      iban: // value for 'iban'
 *   },
 * });
 */
export function useGetBankDataQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetBankDataQuery, GetBankDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetBankDataQuery, GetBankDataQueryVariables>(GetBankDataDocument, options);
      }
export function useGetBankDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankDataQuery, GetBankDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetBankDataQuery, GetBankDataQueryVariables>(GetBankDataDocument, options);
        }
export type GetBankDataQueryHookResult = ReturnType<typeof useGetBankDataQuery>;
export type GetBankDataLazyQueryHookResult = ReturnType<typeof useGetBankDataLazyQuery>;
export type GetBankDataQueryResult = ApolloReactCommon.QueryResult<GetBankDataQuery, GetBankDataQueryVariables>;
export function refetchGetBankDataQuery(variables?: GetBankDataQueryVariables) {
      return { query: GetBankDataDocument, variables: variables }
    }
export const ThirdPartyLoginDocument = gql`
    mutation thirdPartyLogin($thirdPartyLoginInput: ThirdPartyLoginInput!) {
  thirdPartyLogin(thirdPartyLoginInput: $thirdPartyLoginInput) {
    id
    roles
    email
    booker {
      id
      firstname
      lastname
      gender
      createDate
      updateDate
      customerId
      addition
      street
      houseNumber
      postCode
      city
      phoneNumber
      mobileNumber
      email
      birthday
      initialContact
      dsgvo
      newsletter
      paymentMethods {
        id
        cash
        creditCardAccountholder
        creditcardNumber
        expirationDate
        validFrom
        cvc
        paypalAccountholder
        email
        iban
        bank
        bic
        sepaAccountholder
        bankTransfer
        esr
      }
      attendees {
        id
        customer {
          id
        }
        gender
        lastname
        firstname
        birthday
        member
        defaultPaymentMethod {
          id
        }
        bookings {
          id
          courseLessons {
            id
          }
          paymentMethod {
            id
          }
          paymentType
          payDate
        }
      }
      healthInsuranceFund
      annotation
      createdBy
    }
    refreshToken
    accessToken
  }
}
    `;
export type ThirdPartyLoginMutationFn = ApolloReactCommon.MutationFunction<ThirdPartyLoginMutation, ThirdPartyLoginMutationVariables>;

/**
 * __useThirdPartyLoginMutation__
 *
 * To run a mutation, you first call `useThirdPartyLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useThirdPartyLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [thirdPartyLoginMutation, { data, loading, error }] = useThirdPartyLoginMutation({
 *   variables: {
 *      thirdPartyLoginInput: // value for 'thirdPartyLoginInput'
 *   },
 * });
 */
export function useThirdPartyLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ThirdPartyLoginMutation, ThirdPartyLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ThirdPartyLoginMutation, ThirdPartyLoginMutationVariables>(ThirdPartyLoginDocument, options);
      }
export type ThirdPartyLoginMutationHookResult = ReturnType<typeof useThirdPartyLoginMutation>;
export type ThirdPartyLoginMutationResult = ApolloReactCommon.MutationResult<ThirdPartyLoginMutation>;
export type ThirdPartyLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<ThirdPartyLoginMutation, ThirdPartyLoginMutationVariables>;
export const StartPasswordlessLoginDocument = gql`
    mutation startPasswordlessLogin($endUserPasswordlessLoginInput: EndUserPasswordlessLoginInputType!) {
  passwordlessLoginEndUserApp(
    endUserPasswordlessLoginInput: $endUserPasswordlessLoginInput
  )
}
    `;
export type StartPasswordlessLoginMutationFn = ApolloReactCommon.MutationFunction<StartPasswordlessLoginMutation, StartPasswordlessLoginMutationVariables>;

/**
 * __useStartPasswordlessLoginMutation__
 *
 * To run a mutation, you first call `useStartPasswordlessLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPasswordlessLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPasswordlessLoginMutation, { data, loading, error }] = useStartPasswordlessLoginMutation({
 *   variables: {
 *      endUserPasswordlessLoginInput: // value for 'endUserPasswordlessLoginInput'
 *   },
 * });
 */
export function useStartPasswordlessLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartPasswordlessLoginMutation, StartPasswordlessLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<StartPasswordlessLoginMutation, StartPasswordlessLoginMutationVariables>(StartPasswordlessLoginDocument, options);
      }
export type StartPasswordlessLoginMutationHookResult = ReturnType<typeof useStartPasswordlessLoginMutation>;
export type StartPasswordlessLoginMutationResult = ApolloReactCommon.MutationResult<StartPasswordlessLoginMutation>;
export type StartPasswordlessLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<StartPasswordlessLoginMutation, StartPasswordlessLoginMutationVariables>;
export const CompletePasswordlessLoginEndUserAppDocument = gql`
    mutation completePasswordlessLoginEndUserApp($loginCode: String!) {
  completePasswordlessFlowEndUserApp(loginCode: $loginCode) {
    id
    email
    roles
    hasProfile
    refreshToken
    accessToken
    booker {
      id
      firstname
      lastname
      gender
      createDate
      updateDate
      customerId
      addition
      street
      houseNumber
      postCode
      city
      phoneNumber
      mobileNumber
      email
      birthday
      initialContact
      dsgvo
      newsletter
      paymentMethods {
        id
        cash
        creditCardAccountholder
        creditcardNumber
        expirationDate
        validFrom
        cvc
        paypalAccountholder
        email
        iban
        bank
        bic
        sepaAccountholder
        bankTransfer
        esr
      }
      attendees {
        id
        customer {
          id
        }
        gender
        lastname
        firstname
        birthday
        member
        defaultPaymentMethod {
          id
        }
        bookings {
          id
          courseLessons {
            id
          }
          paymentMethod {
            id
          }
          paymentType
          payDate
        }
      }
      healthInsuranceFund
      annotation
      createdBy
    }
    firstName
    lastName
  }
}
    `;
export type CompletePasswordlessLoginEndUserAppMutationFn = ApolloReactCommon.MutationFunction<CompletePasswordlessLoginEndUserAppMutation, CompletePasswordlessLoginEndUserAppMutationVariables>;

/**
 * __useCompletePasswordlessLoginEndUserAppMutation__
 *
 * To run a mutation, you first call `useCompletePasswordlessLoginEndUserAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePasswordlessLoginEndUserAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePasswordlessLoginEndUserAppMutation, { data, loading, error }] = useCompletePasswordlessLoginEndUserAppMutation({
 *   variables: {
 *      loginCode: // value for 'loginCode'
 *   },
 * });
 */
export function useCompletePasswordlessLoginEndUserAppMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompletePasswordlessLoginEndUserAppMutation, CompletePasswordlessLoginEndUserAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CompletePasswordlessLoginEndUserAppMutation, CompletePasswordlessLoginEndUserAppMutationVariables>(CompletePasswordlessLoginEndUserAppDocument, options);
      }
export type CompletePasswordlessLoginEndUserAppMutationHookResult = ReturnType<typeof useCompletePasswordlessLoginEndUserAppMutation>;
export type CompletePasswordlessLoginEndUserAppMutationResult = ApolloReactCommon.MutationResult<CompletePasswordlessLoginEndUserAppMutation>;
export type CompletePasswordlessLoginEndUserAppMutationOptions = ApolloReactCommon.BaseMutationOptions<CompletePasswordlessLoginEndUserAppMutation, CompletePasswordlessLoginEndUserAppMutationVariables>;


export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AddPaymentMethodInput: AddPaymentMethodInput;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Admin: ResolverTypeWrapper<Admin>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  ArrayDataOptions: ArrayDataOptions;
  AssignedLocationIdInput: AssignedLocationIdInput;
  Attachment: Attachment;
  Attendee: ResolverTypeWrapper<Attendee>;
  AttendeeBadge: ResolverTypeWrapper<AttendeeBadge>;
  AttendeeWithPagination: ResolverTypeWrapper<AttendeeWithPagination>;
  Badge: ResolverTypeWrapper<Badge>;
  Base: ResolverTypeWrapper<Base>;
  BaseInput: BaseInput;
  BasicEmailVariables: BasicEmailVariables;
  BlankMailInputTypes: BlankMailInputTypes;
  BlankMailReceiversInput: BlankMailReceiversInput;
  BlankMailTypes: BlankMailTypes;
  BookAttendees: ResolverTypeWrapper<BookAttendees>;
  BookPaymentMethods: ResolverTypeWrapper<BookPaymentMethods>;
  BookedBy: BookedBy;
  Booker: ResolverTypeWrapper<Booker>;
  BookerAttendeeBookingInput: BookerAttendeeBookingInput;
  BookerAttendeeInput: BookerAttendeeInput;
  BookerCustomerInput: BookerCustomerInput;
  BookerCustomerUpdateInput: BookerCustomerUpdateInput;
  BookerInput: BookerInput;
  BookerPaymentMethodInput: BookerPaymentMethodInput;
  BookerWithPagination: ResolverTypeWrapper<BookerWithPagination>;
  Booking: ResolverTypeWrapper<Booking>;
  BookingDeleteAction: BookingDeleteAction;
  BookingDeleteActionType: BookingDeleteActionType;
  BookingHistory: ResolverTypeWrapper<BookingHistory>;
  BookingHistoryAction: BookingHistoryAction;
  BookingOptions: BookingOptions;
  BookingType: BookingType;
  BookingWithPagination: ResolverTypeWrapper<BookingWithPagination>;
  BookingsKo9: ResolverTypeWrapper<BookingsKo9>;
  CamtGeneratorInput: CamtGeneratorInput;
  CamtGeneratorTransactionInput: CamtGeneratorTransactionInput;
  CamtScheduledPaymentInput: CamtScheduledPaymentInput;
  ColorsInput: ColorsInput;
  CompanySetting: ResolverTypeWrapper<CompanySetting>;
  CompanySettingInput: CompanySettingInput;
  Course: ResolverTypeWrapper<Course>;
  CourseCategory: ResolverTypeWrapper<CourseCategory>;
  CourseCycle: CourseCycle;
  CourseDetailsInput: CourseDetailsInput;
  CourseIdInput: CourseIdInput;
  CourseInstructorIdInput: CourseInstructorIdInput;
  CourseLesson: ResolverTypeWrapper<CourseLesson>;
  CourseLessonBooking: ResolverTypeWrapper<CourseLessonBooking>;
  CourseLessonBookingAttendanceList: ResolverTypeWrapper<CourseLessonBookingAttendanceList>;
  CourseLessonIdInput: CourseLessonIdInput;
  CourseLessonWithPagination: ResolverTypeWrapper<CourseLessonWithPagination>;
  CourseListFilters: CourseListFilters;
  CourseNumberFormat: CourseNumberFormat;
  CoursePrice: ResolverTypeWrapper<CoursePrice>;
  CoursePriceIdInput: CoursePriceIdInput;
  CourseTableInput: CourseTableInput;
  CourseType: ResolverTypeWrapper<CourseType>;
  CourseWithPagination: ResolverTypeWrapper<CourseWithPagination>;
  CreateCourse: ResolverTypeWrapper<CreateCourse>;
  CreateDummySwissCamtByScheduledPaymentsInput: CreateDummySwissCamtByScheduledPaymentsInput;
  CreateInstallmentRateByCourseCreateInput: CreateInstallmentRateByCourseCreateInput;
  CreateMultipleSepaXmlInput: CreateMultipleSepaXmlInput;
  CreateSepaXmlByTimeRangeInput: CreateSepaXmlByTimeRangeInput;
  CreateSingleSepaXmlInput: CreateSingleSepaXmlInput;
  CreatedBy: CreatedBy;
  Currency: Currency;
  Customer: ResolverTypeWrapper<Customer>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DayFilter: DayFilter;
  DetailsCheckboxInput: DetailsCheckboxInput;
  DiscountUnit: DiscountUnit;
  EditAttendeeBadgeInput: EditAttendeeBadgeInput;
  EditBookingInput: EditBookingInput;
  EditCourseInput: EditCourseInput;
  EditCourseLessonInput: EditCourseLessonInput;
  EditCoursePriceInput: EditCoursePriceInput;
  EditInvoiceInput: EditInvoiceInput;
  EditLocationContactInput: EditLocationContactInput;
  EditLocationInput: EditLocationInput;
  EditScheduledPaymentInput: EditScheduledPaymentInput;
  EditTemplateInput: EditTemplateInput;
  EditVenueInput: EditVenueInput;
  EmailData: EmailData;
  EmailNotification: ResolverTypeWrapper<EmailNotification>;
  EmailNotificationWithPagination: ResolverTypeWrapper<EmailNotificationWithPagination>;
  EndUserPasswordlessLoginInputType: EndUserPasswordlessLoginInputType;
  ExportedSepaXml: ResolverTypeWrapper<ExportedSepaXml>;
  ExportedSepaXmlWithPagination: ResolverTypeWrapper<ExportedSepaXmlWithPagination>;
  FaUser: ResolverTypeWrapper<FaUser>;
  FilterExpressionInput: FilterExpressionInput;
  FilterInput: FilterInput;
  FilterOption: FilterOption;
  FiltersInput: FiltersInput;
  ForgotPasswordInput: ForgotPasswordInput;
  ForgotPasswordWithApiKeyInput: ForgotPasswordWithApiKeyInput;
  ForgotPasswordWithStateInput: ForgotPasswordWithStateInput;
  Gender: Gender;
  GeneralLocationContact: ResolverTypeWrapper<GeneralLocationContact>;
  GeneralLocationContactInput: GeneralLocationContactInput;
  GrossNet: GrossNet;
  ImportBookerInput: ImportBookerInput;
  ImportBookersInput: ImportBookersInput;
  InlinedAttachment: InlinedAttachment;
  InstallmentRate: ResolverTypeWrapper<InstallmentRate>;
  Instructor: ResolverTypeWrapper<Instructor>;
  InstructorsWithPagination: ResolverTypeWrapper<InstructorsWithPagination>;
  Invoice: ResolverTypeWrapper<Invoice>;
  InvoiceInput: InvoiceInput;
  InvoiceWithPagination: ResolverTypeWrapper<InvoiceWithPagination>;
  JSONObject: ResolverTypeWrapper<Scalars['JSONObject']>;
  KeyValue: KeyValue;
  KoOrganization: ResolverTypeWrapper<KoOrganization>;
  KoOrganizationInputType: KoOrganizationInputType;
  Language: Language;
  LocalState: ResolverTypeWrapper<LocalState>;
  Location: ResolverTypeWrapper<Location>;
  LocationCity: ResolverTypeWrapper<LocationCity>;
  LocationContact: ResolverTypeWrapper<LocationContact>;
  LocationContactIdInput: LocationContactIdInput;
  LocationGroupedByCityLocation: ResolverTypeWrapper<LocationGroupedByCityLocation>;
  LocationGroupedByCityVenue: ResolverTypeWrapper<LocationGroupedByCityVenue>;
  LocationsGroupedByCity: ResolverTypeWrapper<LocationsGroupedByCity>;
  LocationsWithPagination: ResolverTypeWrapper<LocationsWithPagination>;
  LogLevelType: LogLevelType;
  LoggingInput: LoggingInput;
  MailDataType: MailDataType;
  MailReplacementData: MailReplacementData;
  MailjetEmailTemplates: MailjetEmailTemplates;
  MailjetVariables: MailjetVariables;
  MjmlTemplate: ResolverTypeWrapper<MjmlTemplate>;
  ModifyScheduledPaymentInput: ModifyScheduledPaymentInput;
  MonthlySchedule: MonthlySchedule;
  Mutation: ResolverTypeWrapper<{}>;
  NewAttendeeBadgeByBadgeNameInput: NewAttendeeBadgeByBadgeNameInput;
  NewAttendeeBadgeInput: NewAttendeeBadgeInput;
  NewBadgeInput: NewBadgeInput;
  NewBookingInput: NewBookingInput;
  NewBookingsKo9Input: NewBookingsKo9Input;
  NewCourseCategoryInput: NewCourseCategoryInput;
  NewCourseInput: NewCourseInput;
  NewCourseLessonBookingInput: NewCourseLessonBookingInput;
  NewCourseLessonInput: NewCourseLessonInput;
  NewCoursePriceInput: NewCoursePriceInput;
  NewCourseTypeInput: NewCourseTypeInput;
  NewInstallmentPlanInput: NewInstallmentPlanInput;
  NewInstallmentRateInput: NewInstallmentRateInput;
  NewLocationContactInput: NewLocationContactInput;
  NewLocationInput: NewLocationInput;
  NewParticipationPrerequisitesInput: NewParticipationPrerequisitesInput;
  NewSepaXmlInput: NewSepaXmlInput;
  NewTemplateInput: NewTemplateInput;
  NewVenueInput: NewVenueInput;
  Operation: Operation;
  Operator: Operator;
  OptionalInputFieldsInput: OptionalInputFieldsInput;
  PaginationInput: PaginationInput;
  ParticipationPrerequisites: ResolverTypeWrapper<ParticipationPrerequisites>;
  PasswordlessLoginInputType: PasswordlessLoginInputType;
  Payment: ResolverTypeWrapper<Payment>;
  PaymentInput: PaymentInput;
  PaymentInterval: PaymentInterval;
  PaymentMethod: ResolverTypeWrapper<PaymentMethod>;
  PaymentTransferType: PaymentTransferType;
  PaymentType: PaymentType;
  Query: ResolverTypeWrapper<{}>;
  Rate: ResolverTypeWrapper<Rate>;
  RateDetail: ResolverTypeWrapper<RateDetail>;
  RentType: RentType;
  ResetPasswordStateType: ResetPasswordStateType;
  ResetScheduledPaymentsOfBookingResult: ResolverTypeWrapper<ResetScheduledPaymentsOfBookingResult>;
  ResetScheduledPaymentsOfCourseResult: ResolverTypeWrapper<ResetScheduledPaymentsOfCourseResult>;
  ReversalInvoice: ResolverTypeWrapper<ReversalInvoice>;
  ReversalInvoiceWithPagination: ResolverTypeWrapper<ReversalInvoiceWithPagination>;
  SalutationType: SalutationType;
  SaveAdminInput: SaveAdminInput;
  SaveInstructorInput: SaveInstructorInput;
  ScheduledPayment: ResolverTypeWrapper<ScheduledPayment>;
  ScheduledPaymentPreviewInput: ScheduledPaymentPreviewInput;
  ScheduledPaymentsWithPagination: ResolverTypeWrapper<ScheduledPaymentsWithPagination>;
  SecureIdentity: ResolverTypeWrapper<SecureIdentity>;
  SelectedAttendee: ResolverTypeWrapper<SelectedAttendee>;
  SelectedPaymentMethod: ResolverTypeWrapper<SelectedPaymentMethod>;
  SendParamsMessageInput: SendParamsMessageInput;
  SendParamsRecipient: SendParamsRecipient;
  SendTemplateInput: SendTemplateInput;
  SepaDebitIntput: SepaDebitIntput;
  SepaXmlPaymentInput: SepaXmlPaymentInput;
  SepaXmlPositionInput: SepaXmlPositionInput;
  Setting: ResolverTypeWrapper<Setting>;
  SettingEndUserApp: ResolverTypeWrapper<SettingEndUserApp>;
  SettingEndUserAppInput: SettingEndUserAppInput;
  SettingInput: SettingInput;
  SortOrder: SortOrder;
  Staff: ResolverTypeWrapper<Staff>;
  SubscriptionType: SubscriptionType;
  SwissQRFile: ResolverTypeWrapper<SwissQrFile>;
  SwissQRFileRecord: ResolverTypeWrapper<SwissQrFileRecord>;
  TableColumnsInput: TableColumnsInput;
  TablesEnum: TablesEnum;
  Template: ResolverTypeWrapper<Template>;
  TemplateType: TemplateType;
  TextEntriesInput: TextEntriesInput;
  ThirdPartyLoginInput: ThirdPartyLoginInput;
  ThirdPartyUserInput: ThirdPartyUserInput;
  UpdateBadgeInput: UpdateBadgeInput;
  UpdateBookerAccountEndUserAppInput: UpdateBookerAccountEndUserAppInput;
  UpdateCourseLessonBookingInput: UpdateCourseLessonBookingInput;
  UpdateInstallmentRateInput: UpdateInstallmentRateInput;
  UpdateParticipationPrerequisitesInput: UpdateParticipationPrerequisitesInput;
  UpdateScheduledPaymentByBookingInput: UpdateScheduledPaymentByBookingInput;
  UpdateSettingEndUserAppInput: UpdateSettingEndUserAppInput;
  UpdateTableColumnSettingsInput: UpdateTableColumnSettingsInput;
  Upload: ResolverTypeWrapper<Scalars['Upload']>;
  UserInput: UserInput;
  UserRegistration: ResolverTypeWrapper<UserRegistration>;
  UserSetting: ResolverTypeWrapper<UserSetting>;
  UserSettingsInputTypes: UserSettingsInputTypes;
  UserType: ResolverTypeWrapper<UserType>;
  Venue: ResolverTypeWrapper<Venue>;
  filterCompareOperation: FilterCompareOperation;
  filterOperation: FilterOperation;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AddPaymentMethodInput: AddPaymentMethodInput;
  ID: Scalars['ID'];
  Boolean: Scalars['Boolean'];
  String: Scalars['String'];
  Admin: Admin;
  Float: Scalars['Float'];
  ArrayDataOptions: ArrayDataOptions;
  AssignedLocationIdInput: AssignedLocationIdInput;
  Attachment: Attachment;
  Attendee: Attendee;
  AttendeeBadge: AttendeeBadge;
  AttendeeWithPagination: AttendeeWithPagination;
  Badge: Badge;
  Base: Base;
  BaseInput: BaseInput;
  BasicEmailVariables: BasicEmailVariables;
  BlankMailInputTypes: BlankMailInputTypes;
  BlankMailReceiversInput: BlankMailReceiversInput;
  BookAttendees: BookAttendees;
  BookPaymentMethods: BookPaymentMethods;
  Booker: Booker;
  BookerAttendeeBookingInput: BookerAttendeeBookingInput;
  BookerAttendeeInput: BookerAttendeeInput;
  BookerCustomerInput: BookerCustomerInput;
  BookerCustomerUpdateInput: BookerCustomerUpdateInput;
  BookerInput: BookerInput;
  BookerPaymentMethodInput: BookerPaymentMethodInput;
  BookerWithPagination: BookerWithPagination;
  Booking: Booking;
  BookingDeleteAction: BookingDeleteAction;
  BookingHistory: BookingHistory;
  BookingOptions: BookingOptions;
  BookingWithPagination: BookingWithPagination;
  BookingsKo9: BookingsKo9;
  CamtGeneratorInput: CamtGeneratorInput;
  CamtGeneratorTransactionInput: CamtGeneratorTransactionInput;
  CamtScheduledPaymentInput: CamtScheduledPaymentInput;
  ColorsInput: ColorsInput;
  CompanySetting: CompanySetting;
  CompanySettingInput: CompanySettingInput;
  Course: Course;
  CourseCategory: CourseCategory;
  CourseDetailsInput: CourseDetailsInput;
  CourseIdInput: CourseIdInput;
  CourseInstructorIdInput: CourseInstructorIdInput;
  CourseLesson: CourseLesson;
  CourseLessonBooking: CourseLessonBooking;
  CourseLessonBookingAttendanceList: CourseLessonBookingAttendanceList;
  CourseLessonIdInput: CourseLessonIdInput;
  CourseLessonWithPagination: CourseLessonWithPagination;
  CourseListFilters: CourseListFilters;
  CoursePrice: CoursePrice;
  CoursePriceIdInput: CoursePriceIdInput;
  CourseTableInput: CourseTableInput;
  CourseType: CourseType;
  CourseWithPagination: CourseWithPagination;
  CreateCourse: CreateCourse;
  CreateDummySwissCamtByScheduledPaymentsInput: CreateDummySwissCamtByScheduledPaymentsInput;
  CreateInstallmentRateByCourseCreateInput: CreateInstallmentRateByCourseCreateInput;
  CreateMultipleSepaXmlInput: CreateMultipleSepaXmlInput;
  CreateSepaXmlByTimeRangeInput: CreateSepaXmlByTimeRangeInput;
  CreateSingleSepaXmlInput: CreateSingleSepaXmlInput;
  Customer: Customer;
  DateTime: Scalars['DateTime'];
  DetailsCheckboxInput: DetailsCheckboxInput;
  EditAttendeeBadgeInput: EditAttendeeBadgeInput;
  EditBookingInput: EditBookingInput;
  EditCourseInput: EditCourseInput;
  EditCourseLessonInput: EditCourseLessonInput;
  EditCoursePriceInput: EditCoursePriceInput;
  EditInvoiceInput: EditInvoiceInput;
  EditLocationContactInput: EditLocationContactInput;
  EditLocationInput: EditLocationInput;
  EditScheduledPaymentInput: EditScheduledPaymentInput;
  EditTemplateInput: EditTemplateInput;
  EditVenueInput: EditVenueInput;
  EmailData: EmailData;
  EmailNotification: EmailNotification;
  EmailNotificationWithPagination: EmailNotificationWithPagination;
  EndUserPasswordlessLoginInputType: EndUserPasswordlessLoginInputType;
  ExportedSepaXml: ExportedSepaXml;
  ExportedSepaXmlWithPagination: ExportedSepaXmlWithPagination;
  FaUser: FaUser;
  FilterExpressionInput: FilterExpressionInput;
  FilterInput: FilterInput;
  FilterOption: FilterOption;
  FiltersInput: FiltersInput;
  ForgotPasswordInput: ForgotPasswordInput;
  ForgotPasswordWithApiKeyInput: ForgotPasswordWithApiKeyInput;
  ForgotPasswordWithStateInput: ForgotPasswordWithStateInput;
  GeneralLocationContact: GeneralLocationContact;
  GeneralLocationContactInput: GeneralLocationContactInput;
  ImportBookerInput: ImportBookerInput;
  ImportBookersInput: ImportBookersInput;
  InlinedAttachment: InlinedAttachment;
  InstallmentRate: InstallmentRate;
  Instructor: Instructor;
  InstructorsWithPagination: InstructorsWithPagination;
  Invoice: Invoice;
  InvoiceInput: InvoiceInput;
  InvoiceWithPagination: InvoiceWithPagination;
  JSONObject: Scalars['JSONObject'];
  KeyValue: KeyValue;
  KoOrganization: KoOrganization;
  KoOrganizationInputType: KoOrganizationInputType;
  LocalState: LocalState;
  Location: Location;
  LocationCity: LocationCity;
  LocationContact: LocationContact;
  LocationContactIdInput: LocationContactIdInput;
  LocationGroupedByCityLocation: LocationGroupedByCityLocation;
  LocationGroupedByCityVenue: LocationGroupedByCityVenue;
  LocationsGroupedByCity: LocationsGroupedByCity;
  LocationsWithPagination: LocationsWithPagination;
  LoggingInput: LoggingInput;
  MailReplacementData: MailReplacementData;
  MailjetVariables: MailjetVariables;
  MjmlTemplate: MjmlTemplate;
  ModifyScheduledPaymentInput: ModifyScheduledPaymentInput;
  Mutation: {};
  NewAttendeeBadgeByBadgeNameInput: NewAttendeeBadgeByBadgeNameInput;
  NewAttendeeBadgeInput: NewAttendeeBadgeInput;
  NewBadgeInput: NewBadgeInput;
  NewBookingInput: NewBookingInput;
  NewBookingsKo9Input: NewBookingsKo9Input;
  NewCourseCategoryInput: NewCourseCategoryInput;
  NewCourseInput: NewCourseInput;
  NewCourseLessonBookingInput: NewCourseLessonBookingInput;
  NewCourseLessonInput: NewCourseLessonInput;
  NewCoursePriceInput: NewCoursePriceInput;
  NewCourseTypeInput: NewCourseTypeInput;
  NewInstallmentPlanInput: NewInstallmentPlanInput;
  NewInstallmentRateInput: NewInstallmentRateInput;
  NewLocationContactInput: NewLocationContactInput;
  NewLocationInput: NewLocationInput;
  NewParticipationPrerequisitesInput: NewParticipationPrerequisitesInput;
  NewSepaXmlInput: NewSepaXmlInput;
  NewTemplateInput: NewTemplateInput;
  NewVenueInput: NewVenueInput;
  OptionalInputFieldsInput: OptionalInputFieldsInput;
  PaginationInput: PaginationInput;
  ParticipationPrerequisites: ParticipationPrerequisites;
  PasswordlessLoginInputType: PasswordlessLoginInputType;
  Payment: Payment;
  PaymentInput: PaymentInput;
  PaymentMethod: PaymentMethod;
  Query: {};
  Rate: Rate;
  RateDetail: RateDetail;
  ResetPasswordStateType: ResetPasswordStateType;
  ResetScheduledPaymentsOfBookingResult: ResetScheduledPaymentsOfBookingResult;
  ResetScheduledPaymentsOfCourseResult: ResetScheduledPaymentsOfCourseResult;
  ReversalInvoice: ReversalInvoice;
  ReversalInvoiceWithPagination: ReversalInvoiceWithPagination;
  SaveAdminInput: SaveAdminInput;
  SaveInstructorInput: SaveInstructorInput;
  ScheduledPayment: ScheduledPayment;
  ScheduledPaymentPreviewInput: ScheduledPaymentPreviewInput;
  ScheduledPaymentsWithPagination: ScheduledPaymentsWithPagination;
  SecureIdentity: SecureIdentity;
  SelectedAttendee: SelectedAttendee;
  SelectedPaymentMethod: SelectedPaymentMethod;
  SendParamsMessageInput: SendParamsMessageInput;
  SendParamsRecipient: SendParamsRecipient;
  SendTemplateInput: SendTemplateInput;
  SepaDebitIntput: SepaDebitIntput;
  SepaXmlPaymentInput: SepaXmlPaymentInput;
  SepaXmlPositionInput: SepaXmlPositionInput;
  Setting: Setting;
  SettingEndUserApp: SettingEndUserApp;
  SettingEndUserAppInput: SettingEndUserAppInput;
  SettingInput: SettingInput;
  Staff: Staff;
  SwissQRFile: SwissQrFile;
  SwissQRFileRecord: SwissQrFileRecord;
  TableColumnsInput: TableColumnsInput;
  Template: Template;
  TextEntriesInput: TextEntriesInput;
  ThirdPartyLoginInput: ThirdPartyLoginInput;
  ThirdPartyUserInput: ThirdPartyUserInput;
  UpdateBadgeInput: UpdateBadgeInput;
  UpdateBookerAccountEndUserAppInput: UpdateBookerAccountEndUserAppInput;
  UpdateCourseLessonBookingInput: UpdateCourseLessonBookingInput;
  UpdateInstallmentRateInput: UpdateInstallmentRateInput;
  UpdateParticipationPrerequisitesInput: UpdateParticipationPrerequisitesInput;
  UpdateScheduledPaymentByBookingInput: UpdateScheduledPaymentByBookingInput;
  UpdateSettingEndUserAppInput: UpdateSettingEndUserAppInput;
  UpdateTableColumnSettingsInput: UpdateTableColumnSettingsInput;
  Upload: Scalars['Upload'];
  UserInput: UserInput;
  UserRegistration: UserRegistration;
  UserSetting: UserSetting;
  UserSettingsInputTypes: UserSettingsInputTypes;
  UserType: UserType;
  Venue: Venue;
};

export type AdminResolvers<ContextType = any, ParentType extends ResolversParentTypes['Admin'] = ResolversParentTypes['Admin']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salutation?: Resolver<Maybe<ResolversTypes['SalutationType']>, ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  birthDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthPlace?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  annotation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  houseNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobileNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  joinDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leaveDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  socialSecurityNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  healthInsuranceFund?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  vat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  accountHolder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iban?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttendeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attendee'] = ResolversParentTypes['Attendee']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Gender'], ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  birthday?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  member?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  defaultPaymentMethod?: Resolver<ResolversTypes['PaymentMethod'], ParentType, ContextType>;
  defaultPaymentMethodId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  booker?: Resolver<Maybe<ResolversTypes['Booker']>, ParentType, ContextType>;
  bookerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  bookings?: Resolver<Maybe<Array<ResolversTypes['Booking']>>, ParentType, ContextType>;
  startedCourses?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType>;
  deletable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['CreatedBy']>, ParentType, ContextType>;
  addition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  annotation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  badgeXAttendee?: Resolver<Maybe<Array<ResolversTypes['AttendeeBadge']>>, ParentType, ContextType>;
  bookingsKo9?: Resolver<Maybe<Array<ResolversTypes['BookingsKo9']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttendeeBadgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttendeeBadge'] = ResolversParentTypes['AttendeeBadge']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  badgeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  attendeeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateOfAchivement?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  badge?: Resolver<ResolversTypes['Badge'], ParentType, ContextType>;
  attendee?: Resolver<ResolversTypes['Attendee'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttendeeWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttendeeWithPagination'] = ResolversParentTypes['AttendeeWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Attendee']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BadgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Badge'] = ResolversParentTypes['Badge']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  rank?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  badgeXAttendee?: Resolver<Maybe<Array<ResolversTypes['AttendeeBadge']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Base'] = ResolversParentTypes['Base']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookAttendeesResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookAttendees'] = ResolversParentTypes['BookAttendees']> = {
  selectedAttendee?: Resolver<Maybe<ResolversTypes['SelectedAttendee']>, ParentType, ContextType>;
  selectedCourseId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookPaymentMethodsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookPaymentMethods'] = ResolversParentTypes['BookPaymentMethods']> = {
  selectedPaymentMethod?: Resolver<Maybe<ResolversTypes['SelectedPaymentMethod']>, ParentType, ContextType>;
  selectedCourseId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Booker'] = ResolversParentTypes['Booker']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  customerNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  houseNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobileNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  initialContact?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dsgvo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  newsletter?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  healthInsuranceFund?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  annotation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nonDisclosureNotice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  birthday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  paymentMethods?: Resolver<Maybe<Array<ResolversTypes['PaymentMethod']>>, ParentType, ContextType>;
  attendees?: Resolver<Maybe<Array<ResolversTypes['Attendee']>>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['CreatedBy']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookerWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookerWithPagination'] = ResolversParentTypes['BookerWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Booking'] = ResolversParentTypes['Booking']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookingNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  bookingNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  noticeBooker?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  additionalInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  noticeStaff?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attendanceListNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookingType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  courseLessons?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType>;
  courseLessonBookings?: Resolver<Maybe<Array<ResolversTypes['CourseLessonBooking']>>, ParentType, ContextType>;
  attendee?: Resolver<Maybe<ResolversTypes['Attendee']>, ParentType, ContextType>;
  attendeeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  paymentMethod?: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>;
  paymentMethodId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  paymentType?: Resolver<Maybe<ResolversTypes['PaymentType']>, ParentType, ContextType>;
  payDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  coursePrice?: Resolver<ResolversTypes['CoursePrice'], ParentType, ContextType>;
  coursePriceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isSpecial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  vat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  netPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  grossPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  invoice?: Resolver<Maybe<ResolversTypes['Invoice']>, ParentType, ContextType>;
  reversalInvoice?: Resolver<Maybe<ResolversTypes['ReversalInvoice']>, ParentType, ContextType>;
  canceled?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  scheduledPayments?: Resolver<Maybe<Array<ResolversTypes['ScheduledPayment']>>, ParentType, ContextType>;
  bookedBy?: Resolver<ResolversTypes['BookedBy'], ParentType, ContextType>;
  emailNotifications?: Resolver<Maybe<Array<ResolversTypes['EmailNotification']>>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  monthlySchedule?: Resolver<ResolversTypes['MonthlySchedule'], ParentType, ContextType>;
  creditedBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  agbConfirmed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  privacyStatementConfirmed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  instructors?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingHistory'] = ResolversParentTypes['BookingHistory']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookingId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  bookingNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bookingNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bookingType?: Resolver<ResolversTypes['BookingType'], ParentType, ContextType>;
  startDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  attendeeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  bookerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  netPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  grossPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  canceled?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  bookedBy?: Resolver<ResolversTypes['BookedBy'], ParentType, ContextType>;
  action?: Resolver<ResolversTypes['BookingHistoryAction'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingWithPagination'] = ResolversParentTypes['BookingWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingsKo9Resolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingsKo9'] = ResolversParentTypes['BookingsKo9']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attendee?: Resolver<Maybe<ResolversTypes['Attendee']>, ParentType, ContextType>;
  attendeeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  teilnahmeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courseNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courseName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstCourseLesson?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanySettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanySetting'] = ResolversParentTypes['CompanySetting']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  businessForm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  agb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  privacyStatement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerFirstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerLastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerStreet?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerStreetNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerZip?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerCity?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerCountry?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  industry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerFax?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sendFromEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  localCourt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commercialRegister?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bankAccountHolder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IBAN?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BIC?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  qrIban?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  host?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Course'] = ResolversParentTypes['Course']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<ResolversTypes['CourseType'], ParentType, ContextType>;
  courseNumberFormat?: Resolver<ResolversTypes['CourseNumberFormat'], ParentType, ContextType>;
  customCourseNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courseNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  prefixedCourseNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  locationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  venue?: Resolver<ResolversTypes['Venue'], ParentType, ContextType>;
  venueId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instructors?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  paymentInterval?: Resolver<Maybe<ResolversTypes['PaymentInterval']>, ParentType, ContextType>;
  minAttendees?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  maxAttendees?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  freePlaces?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  startDateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  endDateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  cycle?: Resolver<ResolversTypes['CourseCycle'], ParentType, ContextType>;
  annotation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  websiteInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lessons?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType>;
  coursePrices?: Resolver<Array<ResolversTypes['CoursePrice']>, ParentType, ContextType>;
  allowMonthlyPayment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowUpFrontPayment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  monthlySchedule?: Resolver<ResolversTypes['MonthlySchedule'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  showInWeb?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  installmentRates?: Resolver<Maybe<Array<ResolversTypes['InstallmentRate']>>, ParentType, ContextType>;
  paymentType?: Resolver<Maybe<ResolversTypes['PaymentType']>, ParentType, ContextType>;
  lessonCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  coursePrice?: Resolver<Array<ResolversTypes['CoursePrice']>, ParentType, ContextType>;
  bookingCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  bookingCountWithoutWaitlist?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  billedBookingCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  settledBookingCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  settledBookings?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType>;
  unSettledBookings?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseCategory'] = ResolversParentTypes['CourseCategory']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  linkedCourseTypes?: Resolver<Array<ResolversTypes['CourseType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseLessonResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseLesson'] = ResolversParentTypes['CourseLesson']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  course?: Resolver<ResolversTypes['Course'], ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  locationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  venue?: Resolver<ResolversTypes['Venue'], ParentType, ContextType>;
  venueId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instructors?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  startDateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  endDateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  bookings?: Resolver<Maybe<Array<ResolversTypes['Booking']>>, ParentType, ContextType>;
  courseLessonBookings?: Resolver<Array<ResolversTypes['CourseLessonBooking']>, ParentType, ContextType>;
  indexOfLessonInCourse?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseLessonBookingResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseLessonBooking'] = ResolversParentTypes['CourseLessonBooking']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attendance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseLessonId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  bookingId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  booking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType>;
  courseLesson?: Resolver<ResolversTypes['CourseLesson'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseLessonBookingAttendanceListResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseLessonBookingAttendanceList'] = ResolversParentTypes['CourseLessonBookingAttendanceList']> = {
  bookingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  attendee?: Resolver<ResolversTypes['Attendee'], ParentType, ContextType>;
  booker?: Resolver<ResolversTypes['Booker'], ParentType, ContextType>;
  courseLessonBooking?: Resolver<Array<ResolversTypes['CourseLessonBooking']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseLessonWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseLessonWithPagination'] = ResolversParentTypes['CourseLessonWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursePriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoursePrice'] = ResolversParentTypes['CoursePrice']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isSpecial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  vat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  netPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  grossPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discountUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookings?: Resolver<Maybe<Array<ResolversTypes['Booking']>>, ParentType, ContextType>;
  course?: Resolver<ResolversTypes['Course'], ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  includedEntranceFee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseType'] = ResolversParentTypes['CourseType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  showInWeb?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortHandId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['CourseCategory']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minAge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxAge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentInterval?: Resolver<Maybe<ResolversTypes['PaymentInterval']>, ParentType, ContextType>;
  vat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minAttendees?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxAttendees?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  annotation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  websiteInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inWeekPlanner?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  bold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  color?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courses?: Resolver<Maybe<Array<ResolversTypes['Course']>>, ParentType, ContextType>;
  participationPrerequisites?: Resolver<Maybe<Array<ResolversTypes['ParticipationPrerequisites']>>, ParentType, ContextType>;
  courseCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseWithPagination'] = ResolversParentTypes['CourseWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCourseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCourse'] = ResolversParentTypes['CreateCourse']> = {
  locationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Gender'], ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type EmailNotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailNotification'] = ResolversParentTypes['EmailNotification']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  templateType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mailjetTemplateId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  messageID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  receiverName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  templateName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  html?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  messageHref?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  booking?: Resolver<Maybe<ResolversTypes['Booking']>, ParentType, ContextType>;
  bookerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailNotificationWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailNotificationWithPagination'] = ResolversParentTypes['EmailNotificationWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['EmailNotification']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportedSepaXmlResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExportedSepaXml'] = ResolversParentTypes['ExportedSepaXml']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  object?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  xml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportedSepaXmlWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExportedSepaXmlWithPagination'] = ResolversParentTypes['ExportedSepaXmlWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['ExportedSepaXml']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FaUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['FaUser'] = ResolversParentTypes['FaUser']> = {
  breachedPasswordLastCheckedInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connectorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  encryptionScheme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passwordChangeReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passwordChangeRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  passwordLastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uniqueUsername?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  birthDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cleanSpeakId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobilePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parentEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferredLanguages?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  registrations?: Resolver<Maybe<Array<ResolversTypes['UserRegistration']>>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeneralLocationContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneralLocationContact'] = ResolversParentTypes['GeneralLocationContact']> = {
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobileNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fax?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstallmentRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['InstallmentRate'] = ResolversParentTypes['InstallmentRate']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  course?: Resolver<ResolversTypes['Course'], ParentType, ContextType>;
  installmentAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  installmentDue?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstructorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Instructor'] = ResolversParentTypes['Instructor']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  level?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  showInWeb?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  assignedLocations?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType>;
  salutation?: Resolver<ResolversTypes['SalutationType'], ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  birthDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthPlace?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  annotation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  houseNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobileNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  joinDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leaveDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  socialSecurityNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  healthInsuranceFund?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  vat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  accountHolder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iban?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  activeCourseCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstructorsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['InstructorsWithPagination'] = ResolversParentTypes['InstructorsWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Invoice'] = ResolversParentTypes['Invoice']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  invoiceNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  invoiceNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  billed?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  paymentDue?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  booking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType>;
  bookingId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  scheduledPayments?: Resolver<Maybe<Array<ResolversTypes['ScheduledPayment']>>, ParentType, ContextType>;
  qrReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  koOrganization?: Resolver<ResolversTypes['KoOrganization'], ParentType, ContextType>;
  invoiceBody?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  invoiceFileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceWithPagination'] = ResolversParentTypes['InvoiceWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonObjectScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export type KoOrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['KoOrganization'] = ResolversParentTypes['KoOrganization']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  settings?: Resolver<ResolversTypes['Setting'], ParentType, ContextType>;
  settingsId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  companySettings?: Resolver<ResolversTypes['CompanySetting'], ParentType, ContextType>;
  companySettingsId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subscriptionType?: Resolver<ResolversTypes['SubscriptionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocalStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocalState'] = ResolversParentTypes['LocalState']> = {
  createCourse?: Resolver<ResolversTypes['CreateCourse'], ParentType, ContextType>;
  bookAttendees?: Resolver<ResolversTypes['BookAttendees'], ParentType, ContextType>;
  bookPaymentMethods?: Resolver<ResolversTypes['BookPaymentMethods'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPublished?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  zipCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  street?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  abbreviation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venues?: Resolver<Array<ResolversTypes['Venue']>, ParentType, ContextType>;
  locationContacts?: Resolver<Array<ResolversTypes['LocationContact']>, ParentType, ContextType>;
  courses?: Resolver<Maybe<Array<ResolversTypes['Course']>>, ParentType, ContextType>;
  lessons?: Resolver<Maybe<Array<ResolversTypes['CourseLesson']>>, ParentType, ContextType>;
  generalLocationContact?: Resolver<ResolversTypes['GeneralLocationContact'], ParentType, ContextType>;
  instructors?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationCityResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationCity'] = ResolversParentTypes['LocationCity']> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationContact'] = ResolversParentTypes['LocationContact']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salutation?: Resolver<ResolversTypes['SalutationType'], ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobileNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fax?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locations?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationGroupedByCityLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationGroupedByCityLocation'] = ResolversParentTypes['LocationGroupedByCityLocation']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  venues?: Resolver<Array<ResolversTypes['LocationGroupedByCityVenue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationGroupedByCityVenueResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationGroupedByCityVenue'] = ResolversParentTypes['LocationGroupedByCityVenue']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationsGroupedByCityResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationsGroupedByCity'] = ResolversParentTypes['LocationsGroupedByCity']> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locations?: Resolver<Array<ResolversTypes['LocationGroupedByCityLocation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationsWithPagination'] = ResolversParentTypes['LocationsWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MjmlTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MjmlTemplate'] = ResolversParentTypes['MjmlTemplate']> = {
  Count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  Data?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addBookerAsAttendee?: Resolver<ResolversTypes['Booker'], ParentType, ContextType, RequireFields<MutationAddBookerAsAttendeeArgs, 'bookerId'>>;
  addFaUserByBookerId?: Resolver<ResolversTypes['FaUser'], ParentType, ContextType, RequireFields<MutationAddFaUserByBookerIdArgs, 'bookerId'>>;
  addPaymentMethod?: Resolver<ResolversTypes['PaymentMethod'], ParentType, ContextType, RequireFields<MutationAddPaymentMethodArgs, 'paymentMethodType' | 'bookerId'>>;
  addRoleToOrganization?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAddRoleToOrganizationArgs, 'role'>>;
  billMultipleBookings?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<MutationBillMultipleBookingsArgs, 'ids'>>;
  bookAttendeeSetSelectedAttendee?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationBookAttendeeSetSelectedAttendeeArgs, never>>;
  bookAttendeeSetSelectedCourse?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationBookAttendeeSetSelectedCourseArgs, never>>;
  bookPaymentMethodSetSelectedPaymentMethod?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationBookPaymentMethodSetSelectedPaymentMethodArgs, never>>;
  cancelBookingAndGenerateReversalInvoice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCancelBookingAndGenerateReversalInvoiceArgs, 'id'>>;
  changeForgottenPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationChangeForgottenPasswordArgs, 'newPassword' | 'changePasswordId'>>;
  completePasswordlessFlow?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationCompletePasswordlessFlowArgs, 'loginCode'>>;
  completePasswordlessFlowEndUserApp?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationCompletePasswordlessFlowEndUserAppArgs, 'loginCode'>>;
  confirmAGB?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationConfirmAgbArgs, 'agbConfirmed' | 'bookingId'>>;
  confirmPrivacyStatement?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationConfirmPrivacyStatementArgs, 'privacyStatementConfirmed' | 'bookingId'>>;
  convertBookingToWaitlist?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationConvertBookingToWaitlistArgs, 'bookingId'>>;
  convertWaitlistToBooking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationConvertWaitlistToBookingArgs, 'bookingId'>>;
  copyAttendeeToCourse?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationCopyAttendeeToCourseArgs, 'targetCourseId' | 'bookingId'>>;
  createAndRegisterAdmin?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationCreateAndRegisterAdminArgs, 'tenantId' | 'userInput'>>;
  createAndRegisterThirdPartyUser?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationCreateAndRegisterThirdPartyUserArgs, 'tenantId' | 'thirdPartyUserInput'>>;
  createAndRegisterThirdPartyUserAndBooker?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationCreateAndRegisterThirdPartyUserAndBookerArgs, 'newBookingData' | 'thirdPartyUserInput'>>;
  createAttendeeBadge?: Resolver<ResolversTypes['AttendeeBadge'], ParentType, ContextType, RequireFields<MutationCreateAttendeeBadgeArgs, 'newAttendeeBadgeInput'>>;
  createAttendeeBadgeByBadgeName?: Resolver<ResolversTypes['AttendeeBadge'], ParentType, ContextType, RequireFields<MutationCreateAttendeeBadgeByBadgeNameArgs, 'newAttendeeBadgeByBadgeNameInput'>>;
  createBadge?: Resolver<ResolversTypes['Badge'], ParentType, ContextType, RequireFields<MutationCreateBadgeArgs, 'newBadgeInput'>>;
  createBooker?: Resolver<ResolversTypes['Booker'], ParentType, ContextType, RequireFields<MutationCreateBookerArgs, 'newBookerData'>>;
  createBooking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationCreateBookingArgs, 'sendMail' | 'newBookingData'>>;
  createCourse?: Resolver<ResolversTypes['Course'], ParentType, ContextType, RequireFields<MutationCreateCourseArgs, 'newCourseData'>>;
  createCourseCategory?: Resolver<ResolversTypes['CourseCategory'], ParentType, ContextType, RequireFields<MutationCreateCourseCategoryArgs, 'newCourseCategoryData'>>;
  createCourseLesson?: Resolver<ResolversTypes['CourseLesson'], ParentType, ContextType, RequireFields<MutationCreateCourseLessonArgs, 'newCourseLessonData'>>;
  createCourseLessonBooking?: Resolver<ResolversTypes['CourseLessonBooking'], ParentType, ContextType, RequireFields<MutationCreateCourseLessonBookingArgs, 'newCourseLessonBookingInput'>>;
  createCourseLessonBookingsByCourseId?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationCreateCourseLessonBookingsByCourseIdArgs, 'courseId'>>;
  createCourseLessons?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType, RequireFields<MutationCreateCourseLessonsArgs, 'newCourseLessonData'>>;
  createCourseType?: Resolver<ResolversTypes['CourseType'], ParentType, ContextType, RequireFields<MutationCreateCourseTypeArgs, 'newCourseTypeData'>>;
  createDummyCamtFileForMultiplePayments?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationCreateDummyCamtFileForMultiplePaymentsArgs, 'createDummySwissCamtByScheduledPaymentsInput'>>;
  createFaUserWithOrAddEndUserRights?: Resolver<ResolversTypes['FaUser'], ParentType, ContextType, RequireFields<MutationCreateFaUserWithOrAddEndUserRightsArgs, 'email'>>;
  createInstallmentRate?: Resolver<ResolversTypes['InstallmentRate'], ParentType, ContextType, RequireFields<MutationCreateInstallmentRateArgs, 'newInstallmentRateInput'>>;
  createInvoice?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType, RequireFields<MutationCreateInvoiceArgs, 'sendMail' | 'newInvoiceData'>>;
  createInvoicesByBookingIds?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<MutationCreateInvoicesByBookingIdsArgs, 'sendMail' | 'bookingIds'>>;
  createInvoicesByCourseId?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<MutationCreateInvoicesByCourseIdArgs, 'sendMail' | 'courseId'>>;
  createLocation?: Resolver<ResolversTypes['Location'], ParentType, ContextType, RequireFields<MutationCreateLocationArgs, 'newLocationData'>>;
  createLocationContact?: Resolver<ResolversTypes['LocationContact'], ParentType, ContextType, RequireFields<MutationCreateLocationContactArgs, 'newLocationContactData'>>;
  createOrganization?: Resolver<ResolversTypes['KoOrganization'], ParentType, ContextType, RequireFields<MutationCreateOrganizationArgs, 'koOrganizationData'>>;
  createParticipationPrerequisite?: Resolver<ResolversTypes['ParticipationPrerequisites'], ParentType, ContextType, RequireFields<MutationCreateParticipationPrerequisiteArgs, 'newParticipationPrerequisitesInput'>>;
  createSepaXmlById?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationCreateSepaXmlByIdArgs, 'createSingleSepaXmlInput'>>;
  createSepaXmlByTimeRange?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationCreateSepaXmlByTimeRangeArgs, 'createSepaXmlByTimeRangeInput'>>;
  createSettingEndUserApp?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationCreateSettingEndUserAppArgs, 'settingEndUserAppInput'>>;
  createSettingEndUserAppWithDefaultValues?: Resolver<ResolversTypes['SettingEndUserApp'], ParentType, ContextType>;
  createTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationCreateTemplateArgs, 'newTemplateData'>>;
  deleteAttendee?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteAttendeeArgs, 'id'>>;
  deleteAttendeeBadge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteAttendeeBadgeArgs, 'id'>>;
  deleteBadge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteBadgeArgs, 'id'>>;
  deleteBooker?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteBookerArgs, 'id'>>;
  deleteCategoryById?: Resolver<ResolversTypes['CourseCategory'], ParentType, ContextType, RequireFields<MutationDeleteCategoryByIdArgs, 'id'>>;
  deleteCourseLessonBooking?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteCourseLessonBookingArgs, 'id'>>;
  deleteFusionAuthUserByIds?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteFusionAuthUserByIdsArgs, 'koOrganizationId' | 'userIds'>>;
  deleteFusionAuthUsersByEmails?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationDeleteFusionAuthUsersByEmailsArgs, 'emailAddresses'>>;
  deleteInstallmentRate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteInstallmentRateArgs, 'id'>>;
  deleteInstallmentRatesByCourseId?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<MutationDeleteInstallmentRatesByCourseIdArgs, 'courseId'>>;
  deleteTemplate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteTemplateArgs, 'id'>>;
  disablePasswordlessLoginEndUserApp?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  enablePasswordlessLoginEndUserApp?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationEnablePasswordlessLoginEndUserAppArgs, 'passwordlessIdCodeLength'>>;
  forgetPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationForgetPasswordArgs, 'email'>>;
  forgotPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationForgotPasswordArgs, 'forgotPasswordWithStateInput'>>;
  generatePaymentSchedulePreview?: Resolver<Array<ResolversTypes['JSONObject']>, ParentType, ContextType, RequireFields<MutationGeneratePaymentSchedulePreviewArgs, 'paymentType' | 'monthlySchedule' | 'bookingPrice' | 'lessonDates'>>;
  generateScheduledPaymentsForSettledBooking?: Resolver<Array<ResolversTypes['ScheduledPayment']>, ParentType, ContextType, RequireFields<MutationGenerateScheduledPaymentsForSettledBookingArgs, 'bookingId'>>;
  generateUpdatedSchedulesNewBookingPrice?: Resolver<Array<ResolversTypes['ScheduledPayment']>, ParentType, ContextType, RequireFields<MutationGenerateUpdatedSchedulesNewBookingPriceArgs, 'bookingPrice' | 'scheduledPayments'>>;
  importBookers?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationImportBookersArgs, 'importBookersInput'>>;
  logging?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLoggingArgs, 'input'>>;
  logoutUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mergeBookers?: Resolver<ResolversTypes['Booker'], ParentType, ContextType, RequireFields<MutationMergeBookersArgs, 'mergedBookerId' | 'updateBookerData'>>;
  modifyScheduledPayment?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationModifyScheduledPaymentArgs, 'modifyScheduledPaymentInput'>>;
  passwordlessLogin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationPasswordlessLoginArgs, 'loginData'>>;
  passwordlessLoginEndUserApp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationPasswordlessLoginEndUserAppArgs, 'endUserPasswordlessLoginInput'>>;
  reOrderBadges?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationReOrderBadgesArgs, 'targetBadgeId' | 'movedBadgeId'>>;
  refreshAccessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationRefreshAccessTokenArgs, 'refreshToken'>>;
  removeAllBookersOfTenant?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  removeAllBookings?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  removeBooking?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveBookingArgs, 'id'>>;
  removeBookings?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveBookingsArgs, 'ids'>>;
  removeCourse?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveCourseArgs, 'id'>>;
  removeCourseLesson?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveCourseLessonArgs, 'id'>>;
  removeCourseType?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveCourseTypeArgs, 'id'>>;
  removeInstructor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveInstructorArgs, 'id'>>;
  removeLocation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveLocationArgs, 'id'>>;
  removeLocationContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveLocationContactArgs, 'id'>>;
  removeParticipationPrerequisite?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveParticipationPrerequisiteArgs, 'id'>>;
  resendBookingConfirmation?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationResendBookingConfirmationArgs, 'bookingId'>>;
  resendEmailVerification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationResendEmailVerificationArgs, 'userId'>>;
  resetAllUnsetteledScheduledPaymentsOfCourse?: Resolver<ResolversTypes['ResetScheduledPaymentsOfCourseResult'], ParentType, ContextType, RequireFields<MutationResetAllUnsetteledScheduledPaymentsOfCourseArgs, 'courseId'>>;
  resetScheduledPaymentsOfBooking?: Resolver<ResolversTypes['ResetScheduledPaymentsOfBookingResult'], ParentType, ContextType, RequireFields<MutationResetScheduledPaymentsOfBookingArgs, 'bookingId'>>;
  rollbackPaymentStatus?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRollbackPaymentStatusArgs, 'id'>>;
  saveAdmin?: Resolver<ResolversTypes['Admin'], ParentType, ContextType, RequireFields<MutationSaveAdminArgs, 'saveAdminData'>>;
  saveInstructor?: Resolver<ResolversTypes['Instructor'], ParentType, ContextType, RequireFields<MutationSaveInstructorArgs, 'saveInstructorData'>>;
  sendBlankMailToMultipleBookers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendBlankMailToMultipleBookersArgs, 'input'>>;
  sendBookingConfirmation?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendBookingConfirmationArgs, 'bookingId'>>;
  sendBookingInvoice?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendBookingInvoiceArgs, 'bookingId'>>;
  sendBookingInvoicesByBookingIds?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendBookingInvoicesByBookingIdsArgs, 'bookingIds'>>;
  sendEmailTemplate?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendEmailTemplateArgs, 'sendTemplateInput'>>;
  sendTestMail?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendTestMailArgs, never>>;
  sendTestMailBooker?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSendTestMailBookerArgs, 'bookerId'>>;
  setNewCustomerNumber?: Resolver<ResolversTypes['Customer'], ParentType, ContextType, RequireFields<MutationSetNewCustomerNumberArgs, 'newCustomerNumber'>>;
  setNewPaymentAmount?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationSetNewPaymentAmountArgs, 'paymentAmount' | 'id'>>;
  setNewPaymentDoneDate?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationSetNewPaymentDoneDateArgs, 'paymentDoneDate' | 'id'>>;
  setScheduledPaymentPaid?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationSetScheduledPaymentPaidArgs, 'id'>>;
  setScheduledPaymentUnpaid?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationSetScheduledPaymentUnpaidArgs, 'id'>>;
  setSepaClearance?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationSetSepaClearanceArgs, 'sepaClearance' | 'id'>>;
  singleUpload?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationSingleUploadArgs, 'file'>>;
  softDeleteBadge?: Resolver<ResolversTypes['Badge'], ParentType, ContextType, RequireFields<MutationSoftDeleteBadgeArgs, 'id'>>;
  thirdPartyLogin?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationThirdPartyLoginArgs, 'thirdPartyLoginInput'>>;
  transferAttendeeToBooker?: Resolver<ResolversTypes['Attendee'], ParentType, ContextType, RequireFields<MutationTransferAttendeeToBookerArgs, 'newBookerId' | 'attendeeId'>>;
  transferAttendeeToCourse?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationTransferAttendeeToCourseArgs, 'targetCourseId' | 'bookingId'>>;
  unBillMultipleBookings?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<MutationUnBillMultipleBookingsArgs, 'ids'>>;
  updateAttendanceListNote?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationUpdateAttendanceListNoteArgs, 'bookingId'>>;
  updateAttendeeBadge?: Resolver<ResolversTypes['AttendeeBadge'], ParentType, ContextType, RequireFields<MutationUpdateAttendeeBadgeArgs, 'editAttendeeBadgeInput'>>;
  updateBadge?: Resolver<ResolversTypes['Badge'], ParentType, ContextType, RequireFields<MutationUpdateBadgeArgs, 'updateBadgeInput'>>;
  updateBooker?: Resolver<ResolversTypes['Booker'], ParentType, ContextType, RequireFields<MutationUpdateBookerArgs, 'fromEndUserApp' | 'updateBookerData'>>;
  updateBookerAccountEndUserApp?: Resolver<ResolversTypes['Booker'], ParentType, ContextType, RequireFields<MutationUpdateBookerAccountEndUserAppArgs, 'fromEndUserApp' | 'updateBookerEndUserAppData'>>;
  updateBooking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationUpdateBookingArgs, 'editBookingData' | 'id'>>;
  updateBookingPaymentMethod?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationUpdateBookingPaymentMethodArgs, 'paymentMethodId' | 'bookingId'>>;
  updateCompanyAgb?: Resolver<ResolversTypes['CompanySetting'], ParentType, ContextType, RequireFields<MutationUpdateCompanyAgbArgs, 'agb'>>;
  updateCompanyPrivacyStatement?: Resolver<ResolversTypes['CompanySetting'], ParentType, ContextType, RequireFields<MutationUpdateCompanyPrivacyStatementArgs, 'privacyStatement'>>;
  updateCompanySettings?: Resolver<ResolversTypes['CompanySetting'], ParentType, ContextType, RequireFields<MutationUpdateCompanySettingsArgs, 'newCompanyData'>>;
  updateCourse?: Resolver<ResolversTypes['Course'], ParentType, ContextType, RequireFields<MutationUpdateCourseArgs, 'editCourseData' | 'id'>>;
  updateCourseCategory?: Resolver<ResolversTypes['CourseCategory'], ParentType, ContextType, RequireFields<MutationUpdateCourseCategoryArgs, 'newCourseCategoryData' | 'id'>>;
  updateCourseLessonBooking?: Resolver<ResolversTypes['CourseLessonBooking'], ParentType, ContextType, RequireFields<MutationUpdateCourseLessonBookingArgs, 'updateCourseLessonBookingInput'>>;
  updateCourseType?: Resolver<ResolversTypes['CourseType'], ParentType, ContextType, RequireFields<MutationUpdateCourseTypeArgs, 'newCourseTypeData' | 'id'>>;
  updateInstallmentRate?: Resolver<ResolversTypes['InstallmentRate'], ParentType, ContextType, RequireFields<MutationUpdateInstallmentRateArgs, 'updateInstallmentRateInput'>>;
  updateInvoice?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType, RequireFields<MutationUpdateInvoiceArgs, 'editInvoiceData' | 'id'>>;
  updateLocalLocationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationUpdateLocalLocationIdArgs, never>>;
  updateLocation?: Resolver<ResolversTypes['Location'], ParentType, ContextType, RequireFields<MutationUpdateLocationArgs, 'editLocationData'>>;
  updateLocationContact?: Resolver<ResolversTypes['LocationContact'], ParentType, ContextType, RequireFields<MutationUpdateLocationContactArgs, 'editLocationContactData'>>;
  updateManyScheduledPaymentIds?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationUpdateManyScheduledPaymentIdsArgs, 'editScheduledPaymentData' | 'paymentIds'>>;
  updateNoticeStaff?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<MutationUpdateNoticeStaffArgs, 'noticeStaff' | 'id'>>;
  updateParticipationPrerequisite?: Resolver<ResolversTypes['ParticipationPrerequisites'], ParentType, ContextType, RequireFields<MutationUpdateParticipationPrerequisiteArgs, 'updateParticipationPrerequisitesInput'>>;
  updateScheduledPayment?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<MutationUpdateScheduledPaymentArgs, 'editScheduledPaymentData' | 'id'>>;
  updateSettingEndUserApp?: Resolver<ResolversTypes['SettingEndUserApp'], ParentType, ContextType, RequireFields<MutationUpdateSettingEndUserAppArgs, 'updateSettingEndUserAppInput'>>;
  updateSettings?: Resolver<ResolversTypes['Setting'], ParentType, ContextType, RequireFields<MutationUpdateSettingsArgs, 'newSettingsData'>>;
  updateTableColumnSettings?: Resolver<ResolversTypes['UserSetting'], ParentType, ContextType, RequireFields<MutationUpdateTableColumnSettingsArgs, 'updateTableColumnSettingsInput'>>;
  updateTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationUpdateTemplateArgs, 'editTemplateData' | 'id'>>;
  userSettingSetOptimizedCourseCalendar?: Resolver<ResolversTypes['UserSetting'], ParentType, ContextType, RequireFields<MutationUserSettingSetOptimizedCourseCalendarArgs, 'optimize'>>;
  userSettingsUpdateOrCreate?: Resolver<ResolversTypes['UserSetting'], ParentType, ContextType, RequireFields<MutationUserSettingsUpdateOrCreateArgs, 'userSettingsInput'>>;
  verifyBookersData?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<MutationVerifyBookersDataArgs, 'importBookersInput'>>;
};

export type ParticipationPrerequisitesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParticipationPrerequisites'] = ResolversParentTypes['ParticipationPrerequisites']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  groupDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  optionalInputFields?: Resolver<Maybe<Array<ResolversTypes['JSONObject']>>, ParentType, ContextType>;
  minCheckCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  courseTypeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  courseType?: Resolver<ResolversTypes['CourseType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Payment'] = ResolversParentTypes['Payment']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  swissQRFile?: Resolver<Maybe<ResolversTypes['SwissQRFile']>, ParentType, ContextType>;
  valutaDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  scheduledPayment?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentMethod'] = ResolversParentTypes['PaymentMethod']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cash?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  esr?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  creditCardAccountholder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditcardNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  cvc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paypalAccountholder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iban?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sepaAccountholder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['PaymentTransferType']>, ParentType, ContextType>;
  bookings?: Resolver<Maybe<Array<ResolversTypes['Booking']>>, ParentType, ContextType>;
  scheduledPayments?: Resolver<Maybe<Array<ResolversTypes['ScheduledPayment']>>, ParentType, ContextType>;
  booker?: Resolver<Maybe<ResolversTypes['Booker']>, ParentType, ContextType>;
  bookerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  bankTransfer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['CreatedBy']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  LocalState?: Resolver<ResolversTypes['LocalState'], ParentType, ContextType>;
  attendanceListByCourse?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryAttendanceListByCourseArgs, 'courseId'>>;
  attendee?: Resolver<ResolversTypes['Attendee'], ParentType, ContextType, RequireFields<QueryAttendeeArgs, 'id'>>;
  attendeeCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryAttendeeCountArgs, never>>;
  attendees?: Resolver<ResolversTypes['AttendeeWithPagination'], ParentType, ContextType, RequireFields<QueryAttendeesArgs, never>>;
  attendeesByBookerFirstLast?: Resolver<ResolversTypes['AttendeeWithPagination'], ParentType, ContextType, RequireFields<QueryAttendeesByBookerFirstLastArgs, 'searchKey'>>;
  authenticate?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  badge?: Resolver<ResolversTypes['Badge'], ParentType, ContextType, RequireFields<QueryBadgeArgs, 'id'>>;
  badges?: Resolver<Array<ResolversTypes['Badge']>, ParentType, ContextType>;
  bookedAttendeesByCourseId?: Resolver<Array<ResolversTypes['Attendee']>, ParentType, ContextType, RequireFields<QueryBookedAttendeesByCourseIdArgs, 'courseId'>>;
  bookedSpots?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryBookedSpotsArgs, 'courseId'>>;
  booker?: Resolver<ResolversTypes['Booker'], ParentType, ContextType, RequireFields<QueryBookerArgs, 'id'>>;
  bookers?: Resolver<ResolversTypes['BookerWithPagination'], ParentType, ContextType, RequireFields<QueryBookersArgs, never>>;
  booking?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryBookingArgs, 'id'>>;
  bookingById?: Resolver<ResolversTypes['Booking'], ParentType, ContextType, RequireFields<QueryBookingByIdArgs, 'id'>>;
  bookings?: Resolver<ResolversTypes['BookingWithPagination'], ParentType, ContextType, RequireFields<QueryBookingsArgs, never>>;
  bookingsByUser?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryBookingsByUserArgs, 'userId'>>;
  bookingsKo9?: Resolver<Array<ResolversTypes['BookingsKo9']>, ParentType, ContextType>;
  bookingsKo9ByAttendeeId?: Resolver<Array<ResolversTypes['BookingsKo9']>, ParentType, ContextType, RequireFields<QueryBookingsKo9ByAttendeeIdArgs, 'attendeeId'>>;
  canBecomeInstructor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryCanBecomeInstructorArgs, 'email'>>;
  checkAttendeesAgeAtCourseStart?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryCheckAttendeesAgeAtCourseStartArgs, 'attendeeId' | 'courseId'>>;
  checkEmailUniqueness?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryCheckEmailUniquenessArgs, 'email'>>;
  checkIfIsBookedIntoCourse?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryCheckIfIsBookedIntoCourseArgs, 'attendeeId' | 'courseId'>>;
  checkIfTemplateOfTypeExists?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryCheckIfTemplateOfTypeExistsArgs, 'templateType'>>;
  cities?: Resolver<Array<ResolversTypes['LocationCity']>, ParentType, ContextType>;
  companyInfo?: Resolver<ResolversTypes['KoOrganization'], ParentType, ContextType>;
  companyPublic?: Resolver<ResolversTypes['CompanySetting'], ParentType, ContextType>;
  companySettings?: Resolver<ResolversTypes['CompanySetting'], ParentType, ContextType>;
  courseById?: Resolver<ResolversTypes['Course'], ParentType, ContextType, RequireFields<QueryCourseByIdArgs, 'id'>>;
  courseByIdPublic?: Resolver<ResolversTypes['Course'], ParentType, ContextType, RequireFields<QueryCourseByIdPublicArgs, 'id'>>;
  courseByLocationId?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType, RequireFields<QueryCourseByLocationIdArgs, 'id'>>;
  courseCategories?: Resolver<Array<ResolversTypes['CourseCategory']>, ParentType, ContextType>;
  courseCategoriesPublic?: Resolver<Array<ResolversTypes['CourseType']>, ParentType, ContextType>;
  courseCategoryById?: Resolver<ResolversTypes['CourseCategory'], ParentType, ContextType, RequireFields<QueryCourseCategoryByIdArgs, 'id'>>;
  courseHasAtLeastOneCourseLessonBooking?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryCourseHasAtLeastOneCourseLessonBookingArgs, 'courseId'>>;
  courseLessonBooking?: Resolver<ResolversTypes['CourseLessonBooking'], ParentType, ContextType, RequireFields<QueryCourseLessonBookingArgs, 'id'>>;
  courseLessonBookingByCourseLessonAndBooking?: Resolver<ResolversTypes['CourseLessonBooking'], ParentType, ContextType, RequireFields<QueryCourseLessonBookingByCourseLessonAndBookingArgs, 'bookingId' | 'courseLessonId'>>;
  courseLessonBookingsByCourse?: Resolver<Array<ResolversTypes['CourseLessonBooking']>, ParentType, ContextType, RequireFields<QueryCourseLessonBookingsByCourseArgs, 'courseId'>>;
  courseLessonById?: Resolver<ResolversTypes['CourseLesson'], ParentType, ContextType, RequireFields<QueryCourseLessonByIdArgs, 'id'>>;
  courseLessons?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType, RequireFields<QueryCourseLessonsArgs, never>>;
  courseLessonsByCourseId?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType, RequireFields<QueryCourseLessonsByCourseIdArgs, 'courseId'>>;
  courseLessonsByCourseIdPublic?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType, RequireFields<QueryCourseLessonsByCourseIdPublicArgs, 'courseId'>>;
  courseLessonsByDate?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType, RequireFields<QueryCourseLessonsByDateArgs, 'date'>>;
  courseLessonsForMonthView?: Resolver<Array<ResolversTypes['CourseLesson']>, ParentType, ContextType, RequireFields<QueryCourseLessonsForMonthViewArgs, 'year' | 'month'>>;
  courseLessonsWithPagination?: Resolver<ResolversTypes['CourseLessonWithPagination'], ParentType, ContextType, RequireFields<QueryCourseLessonsWithPaginationArgs, never>>;
  courseTypeById?: Resolver<ResolversTypes['CourseType'], ParentType, ContextType, RequireFields<QueryCourseTypeByIdArgs, 'id'>>;
  courseTypeStateById?: Resolver<ResolversTypes['CourseType'], ParentType, ContextType, RequireFields<QueryCourseTypeStateByIdArgs, 'id'>>;
  courseTypes?: Resolver<Array<ResolversTypes['CourseType']>, ParentType, ContextType>;
  courseTypesPublic?: Resolver<Array<ResolversTypes['CourseType']>, ParentType, ContextType>;
  courses?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType, RequireFields<QueryCoursesArgs, never>>;
  coursesPublic?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType, RequireFields<QueryCoursesPublicArgs, never>>;
  coursesWithPagination?: Resolver<ResolversTypes['CourseWithPagination'], ParentType, ContextType, RequireFields<QueryCoursesWithPaginationArgs, never>>;
  coursesWithPaginationPublic?: Resolver<ResolversTypes['CourseWithPagination'], ParentType, ContextType, RequireFields<QueryCoursesWithPaginationPublicArgs, 'filters'>>;
  customerById?: Resolver<ResolversTypes['Customer'], ParentType, ContextType, RequireFields<QueryCustomerByIdArgs, 'id'>>;
  emailNotificationById?: Resolver<ResolversTypes['EmailNotification'], ParentType, ContextType, RequireFields<QueryEmailNotificationByIdArgs, 'id'>>;
  emailNotifications?: Resolver<ResolversTypes['EmailNotificationWithPagination'], ParentType, ContextType, RequireFields<QueryEmailNotificationsArgs, never>>;
  exportedSepaXml?: Resolver<ResolversTypes['ExportedSepaXml'], ParentType, ContextType, RequireFields<QueryExportedSepaXmlArgs, 'id'>>;
  exportedSepaXmls?: Resolver<ResolversTypes['ExportedSepaXmlWithPagination'], ParentType, ContextType, RequireFields<QueryExportedSepaXmlsArgs, never>>;
  faUserByEmail?: Resolver<ResolversTypes['FaUser'], ParentType, ContextType, RequireFields<QueryFaUserByEmailArgs, 'email'>>;
  faUserByUserId?: Resolver<Maybe<ResolversTypes['FaUser']>, ParentType, ContextType, RequireFields<QueryFaUserByUserIdArgs, 'userId'>>;
  findByCourseId?: Resolver<ResolversTypes['Instructor'], ParentType, ContextType, RequireFields<QueryFindByCourseIdArgs, 'id'>>;
  findDuplicateBookersUnperformant?: Resolver<Array<Array<ResolversTypes['Booker']>>, ParentType, ContextType, RequireFields<QueryFindDuplicateBookersUnperformantArgs, never>>;
  findDuplicateBookersWithoutEmail?: Resolver<Array<Array<ResolversTypes['Booker']>>, ParentType, ContextType, RequireFields<QueryFindDuplicateBookersWithoutEmailArgs, never>>;
  findInstructorById?: Resolver<ResolversTypes['Instructor'], ParentType, ContextType, RequireFields<QueryFindInstructorByIdArgs, 'instructorId'>>;
  findInstructorByIds?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType, RequireFields<QueryFindInstructorByIdsArgs, 'instructorIds'>>;
  findInstructorsByLocation?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType, RequireFields<QueryFindInstructorsByLocationArgs, 'locationId'>>;
  getAllCoursesWithAllowMonthlyPayment?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType>;
  getAttendeeBadge?: Resolver<ResolversTypes['AttendeeBadge'], ParentType, ContextType, RequireFields<QueryGetAttendeeBadgeArgs, 'id'>>;
  getAttendeeBadges?: Resolver<Array<ResolversTypes['AttendeeBadge']>, ParentType, ContextType>;
  getAttendeeBadgesByAttendeeId?: Resolver<Array<ResolversTypes['AttendeeBadge']>, ParentType, ContextType, RequireFields<QueryGetAttendeeBadgesByAttendeeIdArgs, 'attendeeId'>>;
  getAttendeeBadgesByBadgeId?: Resolver<Array<ResolversTypes['AttendeeBadge']>, ParentType, ContextType, RequireFields<QueryGetAttendeeBadgesByBadgeIdArgs, 'badgeId'>>;
  getBankData?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetBankDataArgs, 'iban'>>;
  getBilledBookings?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryGetBilledBookingsArgs, never>>;
  getBookerByEmail?: Resolver<Maybe<ResolversTypes['Booker']>, ParentType, ContextType, RequireFields<QueryGetBookerByEmailArgs, 'email'>>;
  getBookersByBookingIds?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType, RequireFields<QueryGetBookersByBookingIdsArgs, 'bookingIds'>>;
  getBookersByCourseIds?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType, RequireFields<QueryGetBookersByCourseIdsArgs, 'courseIds'>>;
  getBookersByCourseWithBookingType?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType, RequireFields<QueryGetBookersByCourseWithBookingTypeArgs, 'courseId'>>;
  getBookingsAsCsv?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGetBookingsAsCsvArgs, 'bookingIds' | 'filterFields'>>;
  getBookingsByAttendeeId?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryGetBookingsByAttendeeIdArgs, 'id'>>;
  getBookingsByAttendeeIdWithOptions?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryGetBookingsByAttendeeIdWithOptionsArgs, 'id'>>;
  getBookingsByCourse?: Resolver<ResolversTypes['BookingWithPagination'], ParentType, ContextType, RequireFields<QueryGetBookingsByCourseArgs, 'id'>>;
  getBookingsByCourseIds?: Resolver<ResolversTypes['BookingWithPagination'], ParentType, ContextType, RequireFields<QueryGetBookingsByCourseIdsArgs, 'ids'>>;
  getBookingsByIds?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryGetBookingsByIdsArgs, 'ids'>>;
  getBookingsByInvoiceIds?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryGetBookingsByInvoiceIdsArgs, 'invoiceIds'>>;
  getCanceledBookings?: Resolver<ResolversTypes['BookingWithPagination'], ParentType, ContextType, RequireFields<QueryGetCanceledBookingsArgs, never>>;
  getColumnsForTable?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryGetColumnsForTableArgs, 'table'>>;
  getCourseMinMaxAge?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetCourseMinMaxAgeArgs, 'courseId'>>;
  getDuplicateBookers?: Resolver<Array<Array<ResolversTypes['Booker']>>, ParentType, ContextType, RequireFields<QueryGetDuplicateBookersArgs, never>>;
  getEmailTemplateById?: Resolver<ResolversTypes['MjmlTemplate'], ParentType, ContextType, RequireFields<QueryGetEmailTemplateByIdArgs, 'templateId'>>;
  getInfoUserByUserId?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<QueryGetInfoUserByUserIdArgs, 'userId'>>;
  getInstallmentPlanPreview?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetInstallmentPlanPreviewArgs, 'newInstallmentPlanInput'>>;
  getInvoiceDummyPreview?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetInvoiceDummyPreviewArgs, never>>;
  getInvoicePreview?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetInvoicePreviewArgs, 'invoiceId'>>;
  getLastCustomer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  getMonthlyPaymentPreview?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetMonthlyPaymentPreviewArgs, 'monthlyPaymentPreviewOptions'>>;
  getPasswordlessLoginSettings?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  getPaymentMethodTypeById?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGetPaymentMethodTypeByIdArgs, 'paymentMethodId'>>;
  getPaymentMethodsByBookerId?: Resolver<Array<ResolversTypes['PaymentMethod']>, ParentType, ContextType, RequireFields<QueryGetPaymentMethodsByBookerIdArgs, 'bookerId'>>;
  getSentMailjetMessage?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryGetSentMailjetMessageArgs, 'messageId'>>;
  getSettledBookings?: Resolver<ResolversTypes['BookingWithPagination'], ParentType, ContextType, RequireFields<QueryGetSettledBookingsArgs, never>>;
  getUnBilledBookings?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryGetUnBilledBookingsArgs, never>>;
  getUnSettledBookings?: Resolver<ResolversTypes['BookingWithPagination'], ParentType, ContextType, RequireFields<QueryGetUnSettledBookingsArgs, never>>;
  getUserRoles?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  installmentRate?: Resolver<ResolversTypes['InstallmentRate'], ParentType, ContextType, RequireFields<QueryInstallmentRateArgs, 'id'>>;
  installmentRates?: Resolver<Array<ResolversTypes['InstallmentRate']>, ParentType, ContextType>;
  installmentRatesByCourseId?: Resolver<Array<ResolversTypes['InstallmentRate']>, ParentType, ContextType, RequireFields<QueryInstallmentRatesByCourseIdArgs, 'courseId'>>;
  instructor?: Resolver<ResolversTypes['Instructor'], ParentType, ContextType, RequireFields<QueryInstructorArgs, 'id'>>;
  instructors?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  instructorsPublic?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  instructorsWithPagination?: Resolver<ResolversTypes['InstructorsWithPagination'], ParentType, ContextType, RequireFields<QueryInstructorsWithPaginationArgs, never>>;
  invoice?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType, RequireFields<QueryInvoiceArgs, 'id'>>;
  invoiceBodyByBookingId?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType, RequireFields<QueryInvoiceBodyByBookingIdArgs, 'bookingId'>>;
  invoiceBodyById?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType, RequireFields<QueryInvoiceBodyByIdArgs, 'id'>>;
  invoices?: Resolver<ResolversTypes['InvoiceWithPagination'], ParentType, ContextType, RequireFields<QueryInvoicesArgs, never>>;
  invoicesWithRelations?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType>;
  isBadgeNameUnique?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryIsBadgeNameUniqueArgs, 'name'>>;
  isCourseNameUnique?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryIsCourseNameUniqueArgs, 'courseId' | 'customCourseNumberPrefix'>>;
  isInstructor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isUniqueEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryIsUniqueEmailArgs, 'email'>>;
  isUserEmailVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryIsUserEmailVerifiedArgs, 'userId'>>;
  koOrganizations?: Resolver<Array<ResolversTypes['KoOrganization']>, ParentType, ContextType>;
  locationById?: Resolver<ResolversTypes['Location'], ParentType, ContextType, RequireFields<QueryLocationByIdArgs, 'id'>>;
  locationContactById?: Resolver<ResolversTypes['LocationContact'], ParentType, ContextType, RequireFields<QueryLocationContactByIdArgs, 'id'>>;
  locationContacts?: Resolver<Array<ResolversTypes['LocationContact']>, ParentType, ContextType>;
  locationContactsByLocation?: Resolver<Array<ResolversTypes['LocationContact']>, ParentType, ContextType, RequireFields<QueryLocationContactsByLocationArgs, 'locationId'>>;
  locations?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType>;
  locationsByCity?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<QueryLocationsByCityArgs, 'city'>>;
  locationsByLocationContactId?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<QueryLocationsByLocationContactIdArgs, 'id'>>;
  locationsPublic?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType>;
  locationsSortedByCity?: Resolver<Array<ResolversTypes['LocationsGroupedByCity']>, ParentType, ContextType>;
  locationsWithPagination?: Resolver<ResolversTypes['LocationsWithPagination'], ParentType, ContextType, RequireFields<QueryLocationsWithPaginationArgs, never>>;
  notificationsByBookerOrEmail?: Resolver<ResolversTypes['EmailNotificationWithPagination'], ParentType, ContextType, RequireFields<QueryNotificationsByBookerOrEmailArgs, 'bookerId'>>;
  notificationsByBooking?: Resolver<Array<ResolversTypes['EmailNotification']>, ParentType, ContextType, RequireFields<QueryNotificationsByBookingArgs, 'bookingId'>>;
  ownerEmail?: Resolver<ResolversTypes['CompanySetting'], ParentType, ContextType>;
  paidScheduledPayments?: Resolver<ResolversTypes['ScheduledPaymentsWithPagination'], ParentType, ContextType, RequireFields<QueryPaidScheduledPaymentsArgs, never>>;
  participationPrerequisite?: Resolver<ResolversTypes['ParticipationPrerequisites'], ParentType, ContextType, RequireFields<QueryParticipationPrerequisiteArgs, 'id'>>;
  participationPrerequisites?: Resolver<Array<ResolversTypes['ParticipationPrerequisites']>, ParentType, ContextType>;
  participationPrerequisitesByCourseTypeId?: Resolver<Array<ResolversTypes['ParticipationPrerequisites']>, ParentType, ContextType, RequireFields<QueryParticipationPrerequisitesByCourseTypeIdArgs, 'courseTypeId'>>;
  paymentMethod?: Resolver<ResolversTypes['PaymentMethod'], ParentType, ContextType, RequireFields<QueryPaymentMethodArgs, 'id'>>;
  paymentMethods?: Resolver<Array<ResolversTypes['PaymentMethod']>, ParentType, ContextType>;
  payments?: Resolver<Array<ResolversTypes['Payment']>, ParentType, ContextType>;
  reversalInvoiceBodyById?: Resolver<ResolversTypes['ReversalInvoice'], ParentType, ContextType, RequireFields<QueryReversalInvoiceBodyByIdArgs, 'id'>>;
  reversalInvoices?: Resolver<ResolversTypes['ReversalInvoiceWithPagination'], ParentType, ContextType, RequireFields<QueryReversalInvoicesArgs, never>>;
  scheduledPayment?: Resolver<Array<ResolversTypes['ScheduledPayment']>, ParentType, ContextType, RequireFields<QueryScheduledPaymentArgs, 'id'>>;
  scheduledPaymentById?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType, RequireFields<QueryScheduledPaymentByIdArgs, 'id'>>;
  scheduledPayments?: Resolver<ResolversTypes['ScheduledPaymentsWithPagination'], ParentType, ContextType, RequireFields<QueryScheduledPaymentsArgs, never>>;
  scheduledPaymentsByBookerId?: Resolver<Array<ResolversTypes['ScheduledPayment']>, ParentType, ContextType, RequireFields<QueryScheduledPaymentsByBookerIdArgs, 'bookerId'>>;
  scheduledPaymentsByBookingId?: Resolver<Array<ResolversTypes['ScheduledPayment']>, ParentType, ContextType, RequireFields<QueryScheduledPaymentsByBookingIdArgs, 'bookingId'>>;
  searchAttendeesByFullName?: Resolver<Array<ResolversTypes['Attendee']>, ParentType, ContextType, RequireFields<QuerySearchAttendeesByFullNameArgs, 'name'>>;
  searchBilledPayments?: Resolver<Array<ResolversTypes['PaymentMethod']>, ParentType, ContextType, RequireFields<QuerySearchBilledPaymentsArgs, 'searchKey'>>;
  searchBookerByStreetAndHouseNumberAndPpostCodeAndCity?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType, RequireFields<QuerySearchBookerByStreetAndHouseNumberAndPpostCodeAndCityArgs, 'searchKey'>>;
  searchBookersByFirstAndLastName?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType, RequireFields<QuerySearchBookersByFirstAndLastNameArgs, 'searchKey'>>;
  searchBookersByStreetAndHouseNumberAndPostCodeAndCity?: Resolver<Array<ResolversTypes['Booker']>, ParentType, ContextType, RequireFields<QuerySearchBookersByStreetAndHouseNumberAndPostCodeAndCityArgs, 'searchKey'>>;
  searchBookingByBookingNumberAndBookingNumberPrefix?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QuerySearchBookingByBookingNumberAndBookingNumberPrefixArgs, 'searchKey'>>;
  searchBookingByCourseLocationPrefixAndCourseNumber?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QuerySearchBookingByCourseLocationPrefixAndCourseNumberArgs, 'searchKey'>>;
  searchCourseByCourseLocationPrefixAndCourseNumber?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType, RequireFields<QuerySearchCourseByCourseLocationPrefixAndCourseNumberArgs, 'searchKey'>>;
  searchCourseByInstructorFirstNameAndLastName?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType, RequireFields<QuerySearchCourseByInstructorFirstNameAndLastNameArgs, 'searchKey'>>;
  searchCustomerByCustomerNumberPrefixAndCustomerNumber?: Resolver<Array<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<QuerySearchCustomerByCustomerNumberPrefixAndCustomerNumberArgs, 'searchKey'>>;
  searchCustomerByFirstNameAndLastName?: Resolver<Array<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<QuerySearchCustomerByFirstNameAndLastNameArgs, 'searchKey'>>;
  searchInvoicesByCourseNumber?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<QuerySearchInvoicesByCourseNumberArgs, 'courseNumber'>>;
  searchInvoicesByInvoiceNumberAndInvoiceNumberPrefix?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<QuerySearchInvoicesByInvoiceNumberAndInvoiceNumberPrefixArgs, 'searchKey'>>;
  searchLocationsByName?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<QuerySearchLocationsByNameArgs, 'name'>>;
  searchLocationsByPhoneNumberAndEmail?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<QuerySearchLocationsByPhoneNumberAndEmailArgs, 'searchKey'>>;
  searchStaffByFullnameAndAddress?: Resolver<Array<ResolversTypes['Staff']>, ParentType, ContextType, RequireFields<QuerySearchStaffByFullnameAndAddressArgs, 'searchKey'>>;
  searchUnBilledPayments?: Resolver<Array<ResolversTypes['PaymentMethod']>, ParentType, ContextType, RequireFields<QuerySearchUnBilledPaymentsArgs, 'searchKey'>>;
  searchUnSettledBooking?: Resolver<Array<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QuerySearchUnSettledBookingArgs, 'searchKey'>>;
  sentEmailStatusFromMailjet?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QuerySentEmailStatusFromMailjetArgs, 'messageID'>>;
  settingEndUserApp?: Resolver<ResolversTypes['SettingEndUserApp'], ParentType, ContextType>;
  settingEndUserAppPublic?: Resolver<ResolversTypes['SettingEndUserApp'], ParentType, ContextType>;
  settings?: Resolver<ResolversTypes['Setting'], ParentType, ContextType>;
  swissQRFiles?: Resolver<Array<ResolversTypes['SwissQRFile']>, ParentType, ContextType>;
  template?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<QueryTemplateArgs, 'id'>>;
  templateByType?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<QueryTemplateByTypeArgs, 'templateType'>>;
  templates?: Resolver<Array<ResolversTypes['Template']>, ParentType, ContextType>;
  testFusioAuthFunctions?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType, RequireFields<QueryTestFusioAuthFunctionsArgs, 'userId'>>;
  unpaidScheduledPayments?: Resolver<ResolversTypes['ScheduledPaymentsWithPagination'], ParentType, ContextType, RequireFields<QueryUnpaidScheduledPaymentsArgs, never>>;
  userSettings?: Resolver<Maybe<ResolversTypes['UserSetting']>, ParentType, ContextType>;
};

export type RateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Rate'] = ResolversParentTypes['Rate']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['RateDetail'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateDetail'] = ResolversParentTypes['RateDetail']> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  due?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResetScheduledPaymentsOfBookingResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResetScheduledPaymentsOfBookingResult'] = ResolversParentTypes['ResetScheduledPaymentsOfBookingResult']> = {
  bookingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bookingNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bookingPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  coursePrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  creditedBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currentScheduledPaymentsCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  newScheduledPaymentsCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  currentScheduledPaymnetsCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  installmentPlanAmountsAndDueDates?: Resolver<Array<ResolversTypes['Rate']>, ParentType, ContextType>;
  newScheduledPaymentsAmountsAndDueDates?: Resolver<Array<ResolversTypes['Rate']>, ParentType, ContextType>;
  currentScheduledPaymentsAmountsAndDueDates?: Resolver<Array<ResolversTypes['Rate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResetScheduledPaymentsOfCourseResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResetScheduledPaymentsOfCourseResult'] = ResolversParentTypes['ResetScheduledPaymentsOfCourseResult']> = {
  courseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courseNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  coursePrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  courseDueDates?: Resolver<Array<ResolversTypes['Rate']>, ParentType, ContextType>;
  bookings?: Resolver<Array<ResolversTypes['ResetScheduledPaymentsOfBookingResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReversalInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReversalInvoice'] = ResolversParentTypes['ReversalInvoice']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  invoiceNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  invoiceNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  booking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType>;
  bookingId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  scheduledPayments?: Resolver<Maybe<Array<ResolversTypes['ScheduledPayment']>>, ParentType, ContextType>;
  qrReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reversalInvoiceBody?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  koOrganization?: Resolver<ResolversTypes['KoOrganization'], ParentType, ContextType>;
  reversalInvoiceFileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReversalInvoiceWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReversalInvoiceWithPagination'] = ResolversParentTypes['ReversalInvoiceWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['ReversalInvoice']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledPayment'] = ResolversParentTypes['ScheduledPayment']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookingId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  booking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType>;
  paymentAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  openAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentDue?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  sepaClearance?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  paymentDone?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  invoiceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  invoice?: Resolver<Maybe<ResolversTypes['Invoice']>, ParentType, ContextType>;
  paymentTransferType?: Resolver<Maybe<ResolversTypes['PaymentTransferType']>, ParentType, ContextType>;
  paymentMethod?: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>;
  paymentMethodId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledPaymentsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledPaymentsWithPagination'] = ResolversParentTypes['ScheduledPaymentsWithPagination']> = {
  existsMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['ScheduledPayment']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SecureIdentityResolvers<ContextType = any, ParentType extends ResolversParentTypes['SecureIdentity'] = ResolversParentTypes['SecureIdentity']> = {
  breachedPasswordLastCheckedInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connectorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  encryptionScheme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passwordChangeReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passwordChangeRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  passwordLastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uniqueUsername?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SelectedAttendeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SelectedAttendee'] = ResolversParentTypes['SelectedAttendee']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bookedCourseIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SelectedPaymentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['SelectedPaymentMethod'] = ResolversParentTypes['SelectedPaymentMethod']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bookedCourseIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Setting'] = ResolversParentTypes['Setting']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  courseNumberFormat?: Resolver<ResolversTypes['CourseNumberFormat'], ParentType, ContextType>;
  customerNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  invoiceNumberPrefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  grossNet?: Resolver<ResolversTypes['GrossNet'], ParentType, ContextType>;
  defaultVat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  cash?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  bankTransfer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sepaDebit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  esr?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  invoiceDueDays?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstInstallmentDayOfMonth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstInstallmentMonth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SettingEndUserAppResolvers<ContextType = any, ParentType extends ResolversParentTypes['SettingEndUserApp'] = ResolversParentTypes['SettingEndUserApp']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  colors?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  courseTable?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  courseDetails?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  filters?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  textEntries?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  optionalInputFields?: Resolver<Maybe<Array<ResolversTypes['JSONObject']>>, ParentType, ContextType>;
  hideAfterDays?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  passwordlessLoginEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  passwordlessIdCodeLength?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaffResolvers<ContextType = any, ParentType extends ResolversParentTypes['Staff'] = ResolversParentTypes['Staff']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  salutation?: Resolver<Maybe<ResolversTypes['SalutationType']>, ParentType, ContextType>;
  lastname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  birthDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthPlace?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  annotation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  houseNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobileNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  joinDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leaveDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  socialSecurityNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  healthInsuranceFund?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  vat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  accountHolder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iban?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwissQrFileResolvers<ContextType = any, ParentType extends ResolversParentTypes['SwissQRFile'] = ResolversParentTypes['SwissQRFile']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fileId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileContent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  records?: Resolver<Maybe<Array<ResolversTypes['SwissQRFileRecord']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwissQrFileRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['SwissQRFileRecord'] = ResolversParentTypes['SwissQRFileRecord']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  valutaDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  swissQrRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  successful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  payment?: Resolver<ResolversTypes['Payment'], ParentType, ContextType>;
  scheduledPayment?: Resolver<ResolversTypes['ScheduledPayment'], ParentType, ContextType>;
  swissQRFileId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  swissQRFile?: Resolver<Maybe<ResolversTypes['SwissQRFile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Template'] = ResolversParentTypes['Template']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  templateType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  headerText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  footerText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mailDataType?: Resolver<Maybe<ResolversTypes['MailDataType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserRegistrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRegistration'] = ResolversParentTypes['UserRegistration']> = {
  applicationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  authenticationToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cleanSpeakId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredLanguages?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSetting'] = ResolversParentTypes['UserSetting']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['Language']>, ParentType, ContextType>;
  profilePictureUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  columnSettings?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  optimizedCourseCalendar?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserType'] = ResolversParentTypes['UserType']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  hasProfile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  refreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  booker?: Resolver<Maybe<ResolversTypes['Booker']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueResolvers<ContextType = any, ParentType extends ResolversParentTypes['Venue'] = ResolversParentTypes['Venue']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  rent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  courses?: Resolver<Maybe<Array<ResolversTypes['Course']>>, ParentType, ContextType>;
  lessons?: Resolver<Maybe<Array<ResolversTypes['CourseLesson']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Admin?: AdminResolvers<ContextType>;
  Attendee?: AttendeeResolvers<ContextType>;
  AttendeeBadge?: AttendeeBadgeResolvers<ContextType>;
  AttendeeWithPagination?: AttendeeWithPaginationResolvers<ContextType>;
  Badge?: BadgeResolvers<ContextType>;
  Base?: BaseResolvers<ContextType>;
  BookAttendees?: BookAttendeesResolvers<ContextType>;
  BookPaymentMethods?: BookPaymentMethodsResolvers<ContextType>;
  Booker?: BookerResolvers<ContextType>;
  BookerWithPagination?: BookerWithPaginationResolvers<ContextType>;
  Booking?: BookingResolvers<ContextType>;
  BookingHistory?: BookingHistoryResolvers<ContextType>;
  BookingWithPagination?: BookingWithPaginationResolvers<ContextType>;
  BookingsKo9?: BookingsKo9Resolvers<ContextType>;
  CompanySetting?: CompanySettingResolvers<ContextType>;
  Course?: CourseResolvers<ContextType>;
  CourseCategory?: CourseCategoryResolvers<ContextType>;
  CourseLesson?: CourseLessonResolvers<ContextType>;
  CourseLessonBooking?: CourseLessonBookingResolvers<ContextType>;
  CourseLessonBookingAttendanceList?: CourseLessonBookingAttendanceListResolvers<ContextType>;
  CourseLessonWithPagination?: CourseLessonWithPaginationResolvers<ContextType>;
  CoursePrice?: CoursePriceResolvers<ContextType>;
  CourseType?: CourseTypeResolvers<ContextType>;
  CourseWithPagination?: CourseWithPaginationResolvers<ContextType>;
  CreateCourse?: CreateCourseResolvers<ContextType>;
  Customer?: CustomerResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  EmailNotification?: EmailNotificationResolvers<ContextType>;
  EmailNotificationWithPagination?: EmailNotificationWithPaginationResolvers<ContextType>;
  ExportedSepaXml?: ExportedSepaXmlResolvers<ContextType>;
  ExportedSepaXmlWithPagination?: ExportedSepaXmlWithPaginationResolvers<ContextType>;
  FaUser?: FaUserResolvers<ContextType>;
  GeneralLocationContact?: GeneralLocationContactResolvers<ContextType>;
  InstallmentRate?: InstallmentRateResolvers<ContextType>;
  Instructor?: InstructorResolvers<ContextType>;
  InstructorsWithPagination?: InstructorsWithPaginationResolvers<ContextType>;
  Invoice?: InvoiceResolvers<ContextType>;
  InvoiceWithPagination?: InvoiceWithPaginationResolvers<ContextType>;
  JSONObject?: GraphQLScalarType;
  KoOrganization?: KoOrganizationResolvers<ContextType>;
  LocalState?: LocalStateResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  LocationCity?: LocationCityResolvers<ContextType>;
  LocationContact?: LocationContactResolvers<ContextType>;
  LocationGroupedByCityLocation?: LocationGroupedByCityLocationResolvers<ContextType>;
  LocationGroupedByCityVenue?: LocationGroupedByCityVenueResolvers<ContextType>;
  LocationsGroupedByCity?: LocationsGroupedByCityResolvers<ContextType>;
  LocationsWithPagination?: LocationsWithPaginationResolvers<ContextType>;
  MjmlTemplate?: MjmlTemplateResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  ParticipationPrerequisites?: ParticipationPrerequisitesResolvers<ContextType>;
  Payment?: PaymentResolvers<ContextType>;
  PaymentMethod?: PaymentMethodResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Rate?: RateResolvers<ContextType>;
  RateDetail?: RateDetailResolvers<ContextType>;
  ResetScheduledPaymentsOfBookingResult?: ResetScheduledPaymentsOfBookingResultResolvers<ContextType>;
  ResetScheduledPaymentsOfCourseResult?: ResetScheduledPaymentsOfCourseResultResolvers<ContextType>;
  ReversalInvoice?: ReversalInvoiceResolvers<ContextType>;
  ReversalInvoiceWithPagination?: ReversalInvoiceWithPaginationResolvers<ContextType>;
  ScheduledPayment?: ScheduledPaymentResolvers<ContextType>;
  ScheduledPaymentsWithPagination?: ScheduledPaymentsWithPaginationResolvers<ContextType>;
  SecureIdentity?: SecureIdentityResolvers<ContextType>;
  SelectedAttendee?: SelectedAttendeeResolvers<ContextType>;
  SelectedPaymentMethod?: SelectedPaymentMethodResolvers<ContextType>;
  Setting?: SettingResolvers<ContextType>;
  SettingEndUserApp?: SettingEndUserAppResolvers<ContextType>;
  Staff?: StaffResolvers<ContextType>;
  SwissQRFile?: SwissQrFileResolvers<ContextType>;
  SwissQRFileRecord?: SwissQrFileRecordResolvers<ContextType>;
  Template?: TemplateResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  UserRegistration?: UserRegistrationResolvers<ContextType>;
  UserSetting?: UserSettingResolvers<ContextType>;
  UserType?: UserTypeResolvers<ContextType>;
  Venue?: VenueResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
