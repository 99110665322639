// import { gql } from "@apollo/client"
import gql from "graphql-tag"
export const CREATE_BOOKER = gql`
    mutation CreateBooker($newBookerData: BookerInput!) {
        createBooker(newBookerData: $newBookerData) {
            id
            gender
            firstname
            lastname
            street
            houseNumber
            postCode
            city
            phoneNumber
            mobileNumber
            email
            birthday
            initialContact
            dsgvo
            newsletter
            healthInsuranceFund
            annotation
            paymentMethods {
                id
                cash
                creditCardAccountholder
                creditcardNumber
                expirationDate
                validFrom
                cvc
                paypalAccountholder
                email
                iban
                bank
                bic
                sepaAccountholder
            }
            attendees {
                id
                customer {
                    id
                }
                gender
                lastname
                firstname
                birthday
                member
                defaultPaymentMethod {
                    id
                }
                bookings {
                    id
                    courseLessons {
                        id
                    }
                    paymentMethod {
                        id
                    }
                    paymentType
                    payDate
                }
            }
        }
    }
`

export const UPDATE_BOOKER = gql`
    mutation UpdateBooker($updateBookerData: BookerInput!, $fromEndUserApp: Boolean!) {
        updateBooker(updateBookerData: $updateBookerData, fromEndUserApp: $fromEndUserApp) {
            id
            gender
            firstname
            lastname
            street
            houseNumber
            postCode
            city
            phoneNumber
            mobileNumber
            email
            birthday
            initialContact
            dsgvo
            newsletter
            healthInsuranceFund
            annotation
            paymentMethods {
                id
                cash
                creditCardAccountholder
                creditcardNumber
                expirationDate
                validFrom
                cvc
                paypalAccountholder
                email
                iban
                bank
                bic
                sepaAccountholder
            }
            attendees {
                id
                customer {
                    id
                }
                gender
                lastname
                firstname
                birthday
                member
                deletable
                startedCourses {
                    id
                    prefixedCourseNumber
                }
                defaultPaymentMethod {
                    id
                }
                bookings {
                    id
                    courseLessons {
                        id
                    }
                    paymentMethod {
                        id
                    }
                    paymentType
                    payDate
                }
            }
            userId
        }
    }
`

export const GET_UPDATED_BOOKER = gql`
    query GetUpdatedBooker($id: String!) {
        booker(id: $id) {
            id
            firstname
            lastname
            gender
            createDate
            updateDate
            customerId
            addition
            street
            houseNumber
            postCode
            city
            phoneNumber
            mobileNumber
            email
            birthday
            initialContact
            dsgvo
            newsletter
            customer {
                id
            }
            paymentMethods {
                id
                cash
                creditCardAccountholder
                creditcardNumber
                expirationDate
                validFrom
                cvc
                paypalAccountholder
                email
                iban
                bank
                bic
                sepaAccountholder
                bankTransfer
                esr
            }
            attendees {
                id
                customer {
                    id
                }
                gender
                lastname
                firstname
                birthday
                member
                defaultPaymentMethod {
                    id
                }
                bookings {
                    id
                    courseLessons {
                        id
                    }
                    paymentMethod {
                        id
                    }
                    paymentType
                    payDate
                }
            }
            healthInsuranceFund
            annotation
            createdBy
            userId
        }
    }
`

export const DELETE_ATTENDEE = gql`
    mutation DeleteAttendee($id: String!) {
        deleteAttendee(id: $id)
    }
`

export const BOOK_PAYMENT_METHOD_LOCAL_STATE = gql`
    query bookPaymentMethodGetLocalState {
        LocalState @client {
            bookPaymentMethods {
                selectedPaymentMethod {
                    id
                    type
                    bookedCourseIds
                }
                selectedCourseId
            }
        }
    }
`

export const GET_COURSE_LESSON_START_DATES = gql`
    query GetCourseLessonStartDates($ids: [String!]) {
        courseLessons(ids: $ids) {
            id
            startDateTime
            course {
                id
                prefixedCourseNumber
            }
        }
    }
`

export const UPDATE_BOOKER_ACCOUNT_ENDUSERAPP = gql`
    mutation UpdateBookerAccountEndUserApp(
        $updateBookerEndUserAppData: UpdateBookerAccountEndUserAppInput!
        $fromEndUserApp: Boolean!
    ) {
        updateBookerAccountEndUserApp(
            updateBookerEndUserAppData: $updateBookerEndUserAppData
            fromEndUserApp: $fromEndUserApp
        ) {
            id
            gender
            firstname
            lastname
            birthday
            street
            postCode
            city
            mobileNumber
            phoneNumber
            dsgvo
            newsletter
            email
        }
    }
`

export const ADD_BOOKER_AS_ATTENDEE = gql`
    mutation AddBookerAsAttendee($bookerId: ID!) {
        addBookerAsAttendee(bookerId: $bookerId) {
            id
            customerId
            firstname
            lastname
            birthday
            gender
            attendees {
                id
                customerId
                customer {
                    firstname
                    lastname
                    gender
                }
                birthday
                defaultPaymentMethodId
            }
        }
    }
`

// export const UPDATE_BOOKER_NEWSLETTER = gql`
//     mutation UpdateBookerNewsletter($id: String!, $newsletter: Boolean!, $fromEndUserApp: Boolean!) {
//         updateBookerNewsletter(id: $id, newsletter: $newsletter, fromEndUserApp: $fromEndUserApp) {
//             id
//             newsletter
//             firstname
//         }
//     }
// `

// export const GET_BOOKER_BADGES_NEWSLETTER = gql`
//     query GetUpdatedBooker($id: String!) {
//         booker(id: $id) {
//             id
//             newsletter
//         }
//     }
//`
