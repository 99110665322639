import React, { FC, memo } from "react"
import css from "./RequiredAlert.module.css"
import { List, Space } from "antd"
import { CloseCircleTwoTone } from "@ant-design/icons"
import { red } from "@ant-design/colors"

type RequiredAlertProps = { disabledText: () => Array<string>; small?: boolean }

const RequiredAlert: FC<RequiredAlertProps> = ({ disabledText, small }) => {
    return (
        <>
            <List className={small ? css.root : css.rootSmall} size='small'>
                {disabledText().map((helperText) => {
                    return (
                        <List.Item style={{ padding: 0 }} className={css.listItem} key={helperText}>
                            <Space size={5}>
                                <CloseCircleTwoTone style={{ paddingTop: 2 }} twoToneColor={red[3]} />
                                <div style={{ padding: 1 }}>{helperText}</div>
                            </Space>
                        </List.Item>
                    )
                })}
            </List>
        </>
    )
}

export default memo(RequiredAlert)
