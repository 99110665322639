import React from "react"
import ReactDOM from "react-dom"
import ApolloWrapper from "./containers/ApolloWrapper/ApolloWrapper"
import "./polyfills"
import { ConfigProvider, notification } from "antd"
import deDE from "antd/lib/locale/de_DE"
// import enEN from "antd/lib/locale/en_EN"
import enUS from "antd/lib/locale/en_US"
import { InCommingAttributes } from "./types/filters"
import App from "./app/App"
import packageJson from "../package.json"
// import moment from "moment"
// import moment as momentWithoutOffset from "moment-timezone"
import moment from "moment-timezone"

// SETUP "Europe/Berlin" as fixed timeZone -----------------------------------
const timeZontToUse = "Europe/Berlin"
moment.tz.setDefault(timeZontToUse)

// SETUP locale for dates to show in the right language ----------------------
const locale = window.navigator.language
moment.locale(locale)

// console.log("locale:", locale)
// ---------------------------------------------------------------------------

const cleanUp = (str: string) => {
    const cleanStr = str.replace(/\+/g, " ")
    return cleanStr.replaceAll("%22", "").replaceAll("+", " ")
}

const getUmlauts = (str: string) => {
    const umlauts: { [key: string]: string } = {
        "%C3%84": "Ä",
        "%C3%A4": "ä",
        "%C3%96": "Ö",
        "%C3%B6": "ö",
        "%C3%9C": "Ü",
        "%C3%BC": "ü",
        "%C3%9F": "ß",
    }
    for (const key in umlauts) {
        if (Object.prototype.hasOwnProperty.call(umlauts, key)) {
            const element = umlauts[key]
            str = str.replaceAll(key, element)
        }
    }
    return str
}

const getSpaces = (str: string) => {
    const spaces: { [key: string]: string } = {
        "%20": " ",
    }
    for (const key in spaces) {
        if (Object.prototype.hasOwnProperty.call(spaces, key)) {
            const element = spaces[key]
            str = str.replaceAll(key, element)
        }
    }
    return str
}

const getParamObject = (paramNames: Array<Extract<keyof InCommingAttributes, string>>) => {
    try {
        let url = cleanUp(window.location.search.substring(1)) //get rid of "?" in querystring
        url = getUmlauts(getSpaces(url))
        const qArray = url.split("&") //get key-value pairs
        let iframeParmeters: { [key: string]: string } = {}
        for (let i = 0; i < qArray.length; i++) {
            const pArr = qArray[i].split("=") //split key and value
            iframeParmeters[pArr[0]] = pArr[1]
        }
        return iframeParmeters
    } catch (error) {
        console.error(error)
        return {}
    }
}

const iframeParameters: Partial<InCommingAttributes> = getParamObject([
    "city",
    "instructorId",
    "courseTypeId",
    "courseTypeIds",
    "locationId",
    "dayFilter",
    "courseCategoryId",
    "showFilterMenu",
    "showOnlyInWebActive",
    "i18n",
    "parentUrl",
]) as InCommingAttributes

// console.log("buildDate:", moment(packageJson.buildDate).format("DD.MM.YYYY HH:mm:ss"))

const start = async () => {
    ReactDOM.render(
        <ApolloWrapper>
            <ConfigProvider locale={{ locale: locale === "de-DE" ? deDE.locale : enUS.locale }}>
                <App parameters={iframeParameters} version={packageJson.version} />
            </ConfigProvider>
        </ApolloWrapper>,
        document.getElementById("app")
    )
}
start().catch((error) => {
    notification.error({
        message: error.message,
    })
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
