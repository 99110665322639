import React, { FC, memo, useContext, useEffect, useState } from "react"
import { Card, Descriptions, List, Space, Table } from "antd"
import {
    ArrayDataOptions,
    CourseListFilters,
    FilterInput,
    GetCoursesPublicQuery,
    useGetCourseListWithPaginationPublicQuery,
    GetCourseListWithPaginationPublicQuery,
    TableColumnsInput,
} from "../../generated/graphql"
import css from "./CourseTable.module.css"
import { rowKey, useCourseTableColumns } from "./CourseTableColumns"
import { useMediaSize } from "../../layouts/Main/GetWindowDimensions/GetWindowDimensions"
import { margin } from "../../styles/layout"
import { renderCourseType } from "../../views/TableColumnRenderers/TableColumnRenderers"
import BookingButton from "./BookingButton"
import { getTextColor } from "../../helpers/getTextColor"
import { blue } from "@ant-design/colors"
import classnames from "classnames"
import { SpecialFilters } from "./CourseTableContainer"
import { colorLightGrey } from "../../styles/colors"
import { SettingsContext } from "../../layouts/Main/MainLayout"

export type TableType = GetCoursesPublicQuery["coursesPublic"][0]

type CourseTableProps = {
    forceReload?: boolean
    filterInput?: Array<FilterInput>
    specialFilters?: SpecialFilters
    // specialFilters?: CourseListFilters
    courseListFilters?: CourseListFilters
    selectedId: Function
    style?: React.CSSProperties
}

const tableConfig = {
    defaultPageSize: 10,
}

const defaultOptions: ArrayDataOptions = {
    limit: 10,
    offset: 0,
    // sortColumn: "id",
    // sortOrder: SortOrder.Ascending,
    // filter: [testFilter],
}

const CourseTable: FC<CourseTableProps> = ({
    forceReload,
    filterInput,
    courseListFilters,
    specialFilters,
    selectedId,
    style,
}) => {
    const mediaSize = useMediaSize()
    const { media } = mediaSize
    const [options, setOptions] = useState<ArrayDataOptions>(defaultOptions)

    const pollIntervalInMinutes = 10
    const pollInterval = pollIntervalInMinutes * 60 * 1000

    const {
        data: courseData,
        loading: loadingCourses,
        error: errorCourses,
        // refetch: refetchCourses,
    } = useGetCourseListWithPaginationPublicQuery({
        // variables: { options: { limit: 10, offset: 0, sortColumn: "location.city", sortOrder: SortOrder.Ascending } },
        variables: { options, filters: courseListFilters },
        fetchPolicy: "cache-and-network",
        pollInterval,
    })

    // console.group("CourseTable")
    // console.log("courseListFilters", courseListFilters)
    // console.groupEnd()

    // useEffect(() => {
    //     refetchCourses()
    // }, [courseListFilters])

    type TableDataType = {
        items: GetCourseListWithPaginationPublicQuery["coursesWithPaginationPublic"]["items"] | undefined
        loading: boolean
        error: any
        total: GetCourseListWithPaginationPublicQuery["coursesWithPaginationPublic"]["total"] | undefined
        existsMore: GetCourseListWithPaginationPublicQuery["coursesWithPaginationPublic"]["existsMore"] | undefined
    }

    const tableData: TableDataType = {
        items: courseData?.coursesWithPaginationPublic?.items,
        loading: loadingCourses,
        error: errorCourses,
        total: courseData?.coursesWithPaginationPublic?.total,
        existsMore: courseData?.coursesWithPaginationPublic?.existsMore,
    }

    const [columnsCount, setColumnsCount] = useState(0)

    const columns = useCourseTableColumns({ columnsCount: columnsCount })

    // CUSTOMER COLUMNS ----------------------------------------------------------------------------------

    const courseTable = useContext(SettingsContext).courseTable
    const customerColumns = courseTable.columns

    // create an array of all enabled customerColumn names
    const enabledColumns = customerColumns
        ?.filter((column: TableColumnsInput) => column.enabled === true)
        .map((column: TableColumnsInput) => column.name)

    useEffect(() => {
        setColumnsCount(enabledColumns?.length ?? 0)
    }, [enabledColumns])

    const columnsToUse = columns.filter((column) => enabledColumns?.includes(column.key))
    // console.group("CourseTable")
    // console.log("columnsToUse", columnsToUse)
    // console.log("enabledColumns", enabledColumns)
    // console.groupEnd()

    // LAYOUT LARGE DEVICES ------------------------------------------------------------------------------

    if (media !== "xs") {
        return (
            <Table
                // style={{ ...style }}
                // tableLayout='fixed'
                className={css.courseTable}
                // style={{ maxWidth: "100%", ...style }
                // columns={columns}
                columns={columnsToUse}
                dataSource={tableData.items}
                size={"small"}
                rowKey={rowKey}
                onChange={(pagination, filters, sorter) => {
                    const current = pagination.current ? pagination.current - 1 : 0
                    const pageSize = pagination.pageSize ?? tableConfig.defaultPageSize
                    setOptions({
                        limit: pagination.pageSize,
                        offset: current * pageSize,
                        // sortOrder: SortOrder.Descending,
                        // sortColumn: "lessons",
                    })
                }}
                pagination={{
                    showSizeChanger: false,
                    defaultPageSize: tableConfig.defaultPageSize,
                    total: tableData.total,
                }}
                // scroll={isTouchDevice() ? { x: "max-content" } : undefined}
                footer={(currentPageData) => (
                    <Space
                        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
                    >{`Gesamtanzahl ${tableData.total}`}</Space>
                )}
                locale={{
                    emptyText: (
                        <Space style={{ minHeight: 100 }}>
                            <h3 style={{ color: colorLightGrey }}>
                                Für diese Auswahl stehen derzeit keine Kurse zur Verfügung.
                            </h3>
                        </Space>
                    ),
                }}
            />
        )
    }

    // LAYOUT MOBILE DEVICES --------------------------------------------------

    const CardHeader = ({
        courseType: { name, color, style },
        prefixedCourseNumber,
    }: {
        courseType: {
            name: string
            color?: string
            style?: React.CSSProperties
        }
        prefixedCourseNumber?: string
    }) => {
        const actualStyle: React.CSSProperties = {
            padding: 0,
            fontSize: "0.95rem",
            minWidth: "50%",
            ...style,
        }
        return (
            <Space size='small' className={css.cardHeader}>
                <div className={css.cardHeaderItem}>{renderCourseType({ name, color, style: actualStyle })}</div>
                <div
                    style={{
                        color: getTextColor({ name: color, value: color ? color : blue[3] }),
                    }}
                    className={classnames(css.cardHeaderItem, css.subCardHeader)}
                >
                    {prefixedCourseNumber}
                </div>
            </Space>
        )
    }

    const descriptionStyle = {
        bordered: true,
        contentStyle: { paddingLeft: 10 },
        labelStyle: { paddingLeft: 10, fontWeight: 600 },
    }

    type CourseCardProps = {
        course: GetCourseListWithPaginationPublicQuery["coursesWithPaginationPublic"]["items"][0]
    }

    const CourseCard: FC<CourseCardProps> = ({ course }) => {
        return (
            <Card
                title={
                    <CardHeader
                        courseType={{
                            name: course.courseType.name,
                            color: course.courseType.color,
                        }}
                        prefixedCourseNumber={course.prefixedCourseNumber ?? ""}
                    />
                }
                key={course.id}
                bordered={false}
                className={css.card}
                size='small'
                headStyle={{
                    background: course.courseType.color,
                }}
            >
                <Descriptions
                    bordered={descriptionStyle.bordered}
                    size='small'
                    contentStyle={descriptionStyle.contentStyle}
                    labelStyle={descriptionStyle.labelStyle}
                    className={css.descriptions}
                >
                    {columnsToUse.map((column) => {
                        const label = column.title
                        const dataIndex = column.key ?? ""
                        const ommitedColumns = ["courseType", "actionColumn", "prefixedCourseNumber"]
                        if (!ommitedColumns.includes(dataIndex as string)) {
                            if (column?.render) {
                                return (
                                    <Descriptions.Item key={dataIndex} label={label}>
                                        {column.render("_", course, 1)}
                                    </Descriptions.Item>
                                )
                            }
                        }

                        return null
                    })}
                </Descriptions>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                    }}
                >
                    <BookingButton
                        block
                        record={course}
                        style={{
                            margin: 0 - 5,
                            marginTop: margin.s,
                            justifySelf: "center",
                            flex: 1,
                            width: "100%",
                        }}
                    />
                </div>
            </Card>
        )
    }

    return (
        <div className={css.cardContainer}>
            {/* <div>{`Gesamt ${tableData.total} Kurse`}</div> */}
            <List
                style={{ width: "100%" }}
                dataSource={tableData.items}
                renderItem={(course) => <CourseCard course={course} />}
                pagination={{
                    position: "both",
                    defaultPageSize: tableConfig.defaultPageSize,
                    total: tableData.total,
                    hideOnSinglePage: true,
                    style: {
                        paddingRight: 15,
                        display: "flex",
                        justifyContent: "center",
                    },
                    onChange: (page, pageSize) => {
                        setOptions({
                            limit: pageSize,
                            offset: (page - 1) * pageSize,
                            // sortOrder: SortOrder.Ascending,
                            // sortColumn: "lessons",
                        })
                    },
                }}
                locale={{
                    emptyText: (
                        <Space style={{ minHeight: 100 }}>
                            <h3 style={{ color: colorLightGrey }}>
                                Für diese Auswahl stehen derzeit keine Kurse zur Verfügung.
                            </h3>
                        </Space>
                    ),
                }}
            />
            <div>{`Gesamtanzahl ${tableData.total} Kurse`}</div>
        </div>
    )
}

export default memo(CourseTable)
