// this file is used to log the state of the app to the console
// it uses a custom hook to log the state of the app to a file

import { LogLevelType, useLoggerMutation } from "../generated/graphql"
import { useCallback } from "react"

export type UseLoggerProps = {
    logLevel: LogLevelType
    msg?: string
    additionalInfo?: { [key: string]: string | boolean | number | undefined }
}

const useLogger = () => {
    const [loggerMutation] = useLoggerMutation()

    const logger = useCallback(({ logLevel, msg, additionalInfo }: UseLoggerProps) => {
        const additionalInfoString = additionalInfo ? JSON.stringify(additionalInfo) : ""

        loggerMutation({
            variables: {
                input: {
                    level: logLevel,
                    msg: `=> EndUserApp => ${msg}, ${additionalInfoString}`,
                },
            },
        })
    }, []) // empty dependency array

    return logger
}

export default useLogger
