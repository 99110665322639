import { Translation } from "../translations/types"
import { Locale, /* translations, */ locales } from "../translations"

let current: object | null = null
let path = ""

let translation: Translation | null = null

const proxy = new Proxy(() => undefined, {
    get: (target, key) => {
        if (translation !== null) {
            if (current === null) {
                current = translation
            }
            if (Reflect.has(current, key)) {
                current = Reflect.get(current, key)
            }
        }
        path += "." + key.toString()

        return proxy
    },
    apply: (target, context, args) => {
        const result =
            translation !== null && typeof current === "function"
                ? current.apply(context, args)
                : `i18n${path}(${args.length === 0 ? "" : JSON.stringify(args)})`

        current = null
        path = ""

        return result
    },
}) as unknown as Translation
const i18n = proxy

export const isSupportedLocale = (locale: string): locale is Locale => {
    return locales.includes(locale)
}

export const setTranslation = (newTranslation: Translation) => {
    translation = newTranslation
}

// export const fetchTranslation = async (newLocale: Locale) => {
//     return await translation[newLocale]();
// }

export default i18n
