import { layoutScale } from "./scales"

export const layoutPaddingSmall = layoutScale(2)
export const layoutPaddingRegular = layoutScale(4)
export const layoutPaddingBig = layoutScale(8)

export const maxContentWidth = 1200

// Ant overrides
// See defaults here: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
export const layoutHeaderHeight = layoutScale(12.5)
export const contextButtonSpacing = layoutScale(7.5)

export const cardPaddingBase = layoutPaddingSmall
export const cardHeadPadding = layoutPaddingSmall

export const btnHeightSm = "26px"
export const btnHeightBase = "28px"
export const formItemMarginBottom = "24px"

// Also maintain in app.less !!!
export const mediaSizes = {
    xs: 576,
    sm: 768,
    md: 992,
    xl: 1200,
    xxl: 1600,
}

export const margin = {
    min: 3.75,
    xs: 7.5,
    s: 15,
    std: 30,
    l: 60,
    xl: 90,
}

export const roundnesFactor = 1
export const radiusXs = roundnesFactor * 3.75
export const radiusS = roundnesFactor * 7.5
export const radius = roundnesFactor * 15
export const radiusL = roundnesFactor * 30

// FORM -----------------------------------------------------------------------

// Label
export const formVerticalLabelPadding = "0px"
// Input
export const inputPaddingVerticalBase = "0px"
export const inputBorderColor = "red"
export const inputBg = "blue"

// -----------------------------------------------------------------------------

// export const marginMin = 3.75
// export const marginXs = 7.5
// export const marginS = 5
// export const margin = 30
// export const marginL = 0
// export const marginXl = 90

export const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor /* || window.opera */

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone"
    }

    if (/android/i.test(userAgent)) {
        return "Android"
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //     return "iOS"
    // }
    function iOS() {
        return (
            ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
                navigator.platform
            ) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        )
    }
    if (iOS()) {
        return "iOS"
    }

    return "unknown"
}
