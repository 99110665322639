import React, { FC, memo } from "react"
import { Form, Input, Button } from "antd"
// @ts-ignore
import { Formik, FormikActions } from "formik"
import { createInputHelpers } from "../../../../helpers/createInputHelpers"
import LayoutForm from "../../../../views/LayoutForm/LayoutForm"
import { isDefined } from "../../../../helpers/typeScriptHelpers"
import { BookerPaymentMethodInput } from "../../../../generated/graphql"
import { v4 as uuidv4 } from "uuid"
import { colorBackgroundBase } from "../../../../styles/colors"
import { margin, radiusS } from "../../../../styles/layout"
import { SepaDebitValidationSchema } from "./SepaDebitValidationSchema"
import { red } from "@ant-design/colors"

export type ModalDataType = Pick<BookerPaymentMethodInput, "sepaAccountholder" | "iban" | "bic" | "bank" | "id">

type SepaDebitPaymentMethodModalProps = {
    initialValues?: ModalDataType
    onSubmit?: (values: ModalDataType, formikActions: FormikActions<ModalDataType>) => void
    onCancel?: () => void
}

const defaultInitialValues: ModalDataType = {
    sepaAccountholder: "",
    iban: "",
    bic: "",
    bank: "",
    id: uuidv4(),
}

const SepaDebitPaymentMethodModal: FC<SepaDebitPaymentMethodModalProps> = ({
    initialValues = defaultInitialValues,
    onSubmit = () => undefined,
    onCancel = () => undefined,
}) => {
    type SubmitButtonProps = {
        handleSubmit: () => void
        style: React.CSSProperties
        disabled?: boolean
        danger?: boolean
    }

    const Submitbutton: FC<SubmitButtonProps> = ({ handleSubmit, style, disabled, danger }) => {
        return (
            <Button
                type='primary'
                block
                onClick={handleSubmit}
                style={style}
                disabled={disabled === true}
                danger={danger === true}
            >
                Lastschriftdaten Speichern
            </Button>
        )
    }

    return (
        <Formik<ModalDataType>
            validationSchema={SepaDebitValidationSchema}
            initialValues={initialValues}
            enableReinitialize={false}
            onSubmit={(values, formikActions) => {
                console.log("submit ID:", values.id)
                onSubmit(values, formikActions)
            }}
        >
            {
                (formikProps) => {
                    const { values, handleSubmit } = formikProps

                    const { handleBlur, handleTextInputChange, handleIbanInputChange } = createInputHelpers(formikProps)
                    const ibanError = formikProps.errors.iban && formikProps.touched.iban
                    const sepaAccountholderError =
                        formikProps.errors.sepaAccountholder && formikProps.touched.sepaAccountholder
                    const dataComplete = Boolean(
                        values.sepaAccountholder && values.iban && values.sepaAccountholder !== "" && values.iban !== ""
                    )
                    const formIsValid = Boolean(formikProps.isValid)

                    return (
                        <>
                            <LayoutForm
                                columns={2}
                                style={{
                                    background: colorBackgroundBase,
                                    border: "1px solid lightgrey",
                                    borderRadius: radiusS,
                                    paddingBottom: margin.xs,
                                }}
                            >
                                <Input type='hidden' name='id' value={values.id} />
                                <Form.Item
                                    label='Kontoinhaber'
                                    // validateStatus={validateStatus("sepaAccountholder")}
                                    // help={help("sepaAccountholder")}
                                    style={{ gridColumn: "span 2" }}
                                    required
                                >
                                    <Input
                                        data-testid='SepaDebitPaymentMethodModalAccountHolder'
                                        onChange={handleTextInputChange("sepaAccountholder")}
                                        onBlur={handleBlur("sepaAccountholder")}
                                        value={isDefined(values.sepaAccountholder) ? values.sepaAccountholder : ""}
                                        // onKeyUp={(e) => {
                                        //     // console.log("keyUp: ", e)
                                        //     // console.log("keyUp: ", e.key)
                                        //     // console.log("values.sepaAccountholder: ", values.sepaAccountholder)
                                        //     const updatedValues = values
                                        //     updatedValues.sepaAccountholder = e.currentTarget.value
                                        //     // submitAfterDelay({ field: "sepaAccountholder", value: e.currentTarget.value })
                                        //     // setFormValues(updatedValues)
                                        //     if (autosubmit && e.key === "Enter") submitForm()
                                        // }}
                                    />
                                </Form.Item>
                                <div
                                    style={{
                                        opacity: sepaAccountholderError ? 1 : 0,
                                        gridColumn: "span 2",
                                        fontSize: "0.9em",
                                        padding: "0.5em",
                                        paddingBottom: 0,
                                        paddingTop: 0,
                                        marginTop: -10,
                                        // border: "1px solid red",
                                        color: red[4],
                                    }}
                                >
                                    Bitte gib Vor- und Nachname des Kontoinhabers an.
                                </div>
                                {/* <div>test test </div> */}
                                <Form.Item
                                    label='IBAN'
                                    // validateStatus={ibanError ? "error" : "success"}
                                    // help={help("iban")}
                                    style={{ gridColumn: "span 2" }}
                                    required
                                >
                                    <Input
                                        data-testid='SepaDebitPaymentMethodModalIban'
                                        onChange={handleIbanInputChange("iban")}
                                        // onChange={handleTextInputChange("iban")}
                                        onBlur={handleBlur("iban")}
                                        value={isDefined(values.iban) ? values.iban : ""}
                                    />
                                    <div
                                        style={{
                                            opacity: ibanError ? 1 : 0,
                                            gridColumn: "span 2",
                                            fontSize: "0.9em",
                                            padding: "0.5em",
                                            paddingBottom: 0,
                                            color: red[4],
                                        }}
                                    >
                                        Bitte gib eine gültige IBAN ein. Bsp.: "DE 11 5205 1373 5120 7101 31, AT 61 1904
                                        3002 3457 3201"
                                    </div>
                                </Form.Item>
                                {/* <Form.Item
                                label='BIC'
                                validateStatus={validateStatus("bic")}
                                help={help("bic")}
                                style={{ gridColumn: "span 2" }}
                                required
                            >
                                <Input
                                    data-testid='SepaDebitPaymentMethodModalBic'
                                    onChange={handleTextInputChange("bic")}
                                    onBlur={handleBlur("bic")}
                                    value={isDefined(values.bic) ? values.bic : ""}
                                />
                            </Form.Item> */}
                                {/* <Form.Item
                                label='Bank / Institut'
                                validateStatus={validateStatus("bank")}
                                help={help("bank")}
                                style={{ gridColumn: "span 2" }}
                                required
                            >
                                <Input
                                    data-testid='SepaDebitPaymentMethodModalBank'
                                    onChange={handleTextInputChange("bank")}
                                    onBlur={handleBlur("bank")}
                                    value={isDefined(values.bank) ? values.bank : ""}
                                />
                            </Form.Item> */}
                                <Submitbutton
                                    handleSubmit={handleSubmit}
                                    style={{
                                        gridColumn: "span 2",
                                        color: !formIsValid || !dataComplete ? red[3] : undefined,
                                        borderColor: !formIsValid || !dataComplete ? red[3] : undefined,
                                    }}
                                    disabled={!formIsValid || !dataComplete}
                                    danger={!formIsValid || !dataComplete}
                                />
                            </LayoutForm>
                        </>
                    )
                }

                // return <>{renderContent({ autosubmit: true })}</>
                // }}
            }
        </Formik>
    )
}

export default memo(SepaDebitPaymentMethodModal)
