export const colorBlack = "#111111"

export const colorWhite = "#ffffff"

// Kursorganizer custom colors
export const colorPrimaryBackground = "#0760FF"

// Based on https://material.io/tools/color/#!/?view.left=0&view.right=1&primary.color=ee293d&secondary.color=1071cc
export const colorRed = "#ee293d"
export const colorRedLight = "#ff6668"
export const colorRedDark = "#b40016"

export const colorTurquoise = "#00b6ff"
export const colorBlue = colorPrimaryBackground
export const colorBlueLight = "#4588FF" // "#5472d3";
export const colorBlueSlight = "#80C2FF"
export const colorBlueDark = "#002171"

export const colorDeepDarkGrey = "#545454"
export const colorDarkGrey = "#979ea5"
export const colorGrey = "#545454"
export const colorLightGrey = "#929292"
export const colorSuperLightGrey = "#b8b8b8"
export const colorSlightGrey = "#d2d2d2"
export const colorBackgroundBase = "#f5f5f5"

// Ant overrides
// See defaults here: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

export const primaryColor = colorBlue // primary color for all components
export const linkColor = colorBlue // link color
export const successColor = colorBlue // success state color
export const warningColor = colorRedLight // warning state color
export const errorColor = colorRed // error state color

export const primary75 = "tint(@primary-color, 25%)"
export const primary50 = "tint(@primary-color, 50%)"
export const primary25 = "tint(@primary-color, 75%)"
export const primary5 = "tint(@primary-color, 95%)"
export const primary1 = "tint(@primary-color, 95%)"

export const borderColorBase = colorGrey // major border color

export const layoutBodyBackground = colorPrimaryBackground
export const layoutMainAreaBackground = "whitesmoke"
export const layoutHeaderBackground = "transparent"
