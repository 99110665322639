import React from "react"
import { ColumnsType } from "antd/lib/table/Table"
import { GetCourseListWithPaginationPublicQuery } from "../../generated/graphql"
import {
    renderCoursePrice,
    renderCourseType,
    renderEntranceFee,
    renderFirstCourseLesson,
    renderFreePlaces,
    renderMonthAsAge,
} from "../../views/TableColumnRenderers/TableColumnRenderers"
import BookingButton from "./BookingButton"
import { useMediaSize } from "../../layouts/Main/GetWindowDimensions/GetWindowDimensions"
// import type { ColumnsType } from "antd/es/table"

export type CourseTableType = GetCourseListWithPaginationPublicQuery["coursesWithPaginationPublic"]["items"][number]

export const rowKey: (course: CourseTableType) => CourseTableType["id"] = (course) => course.id

export const useCourseTableColumns = ({
    columnsCount = 0,
    actionColumn,
}: {
    columnsCount?: number
    actionColumn?: Function
}) => {
    // export type CourseTableType = GetCoursesQuery["courses"][0] & ExtraColumns
    const mediaSize = useMediaSize()
    // const { media } = mediaSize

    // const columns: ColumnsType<CourseTableType> = [
    const columns: ColumnsType<CourseTableType> = [
        {
            title: "Kursname",
            dataIndex: "courseType",
            key: "courseType",
            // render: (record: { name: any }) => record.name,
            // fixed: "left",
            // fixed: isTouchDevice() ? true : false,
            render: (text, record: CourseTableType) =>
                renderCourseType({
                    name: record.courseType.name,
                    color: record.courseType.color,
                    style: { minWidth: 100, whiteSpace: "nowrap" },
                }),
        },
        {
            title: "Ort",
            dataIndex: "city",
            key: "city",
            responsive: columnsCount > 6 ? ["lg"] : undefined,
            render: (text, record) => record.location.city,
        },

        {
            title: "Kursnummer",
            dataIndex: "prefixedCourseNumber",
            key: "prefixedCourseNumber",
            responsive: columnsCount > 8 ? ["lg"] : undefined,
            render: (_, record) => record.prefixedCourseNumber,
        },
        {
            title: "Bad",
            dataIndex: "location",
            key: "location",
            // ellipsis: true,
            // style:  white-space: "pre-wrap" ,
            // ellipsis: media === "md" ? true : false,
            render: (text, record: CourseTableType) => (
                <div style={{ whiteSpace: "pre-wrap" }}>{record.location.name}</div>
            ),
        },

        // {
        //     title: "Kursnummer",
        //     dataIndex: "prefixedCourseNumber",
        //     key: "prefixedCourseNumber",
        //     render: (record: any) => record,
        // },
        {
            title: "Kursbeginn",
            dataIndex: "lessons",
            key: "startDateTime",
            // width: "120px",
            render: (text, record: CourseTableType) =>
                renderFirstCourseLesson(record.startDateTime, record.endDateTime),
        },
        {
            title: "Alter",
            dataIndex: "age",
            key: "age",
            // width: "70px",
            render: (text, record: CourseTableType) =>
                `${renderMonthAsAge(record.courseType.minAge)} - ${renderMonthAsAge(record.courseType.maxAge)}`,
        },
        {
            title: "Freie Plätze",
            dataIndex: "freePlaces",
            key: "freePlaces",
            align: "center",
            render: (text, record) => renderFreePlaces(record.freePlaces),
        },

        {
            title: "Kursgebühr",
            // title: media === "sm" ? "Kurs-gebühr" : "Kursgebühr",
            dataIndex: "coursePrice",
            key: "coursePrice",
            align: "right",
            render: (_, record) => renderCoursePrice(record),
            // render: (_, record) =>
            //     renderCoursePrice(record.coursePrice[0].grossPrice, record.lessonsCount, record.paymentInterval),
        },
        {
            title: "Anz. Kurseinheiten",
            align: "center",
            dataIndex: "lessonCount",
            key: "lessonCount",
            responsive: columnsCount > 4 ? ["md"] : undefined,
            render: (text, record) => record.lessonCount,
        },
        {
            title: "Badeintritt / Einheit",
            // align: "center",
            dataIndex: "includedEntranceFee",
            key: "includedEntranceFee",
            responsive: columnsCount > 4 ? ["md"] : undefined,
            render: (text, record) => renderEntranceFee(record.coursePrice?.[0].includedEntranceFee, mediaSize),
        },
        {
            title: "",
            dataIndex: "actionColumn",
            key: "actionColumn",
            // width: 70,
            fixed: "right",
            render: (text, record) => renderActions(record, actionColumn),
        },
    ]

    // if (media !== "sm") {
    //     columns.splice(1, 0, {
    //         title: "Ort",
    //         dataIndex: "city",
    //         key: "city",
    //         render: (text, record) => record.location.city,
    //     })

    //     columns.splice(2, 0, {
    //         title: "Kursnummer",
    //         dataIndex: "prefixedCourseNumber",
    //         key: "prefixedCourseNumber",
    //         render: (record: any) => record,
    //     })
    // }

    // columns.push({
    //     title: "",
    //     dataIndex: "actionColumn",
    //     key: "actionColumn",
    //     // width: 70,
    //     fixed: "right",
    //     render: (text, record) => renderActions(record, actionColumn),
    // })

    return columns
}

// ACTION BUTTONS---------------------------------------------------------------------------

const renderActions = (record: CourseTableType, actionColumn?: Function): JSX.Element => {
    return <BookingButton record={record} block />
}
