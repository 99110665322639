import React, { FC, useContext } from "react"
import { CourseTableType } from "./CourseTableColumns"
import css from "./BookingButton.module.css"
import { Link } from "react-router-dom"
import { Button } from "antd"
import { CoursesContext } from "./CourseTableContainer"
import { URL_COURSE_DETAILS } from "../../models/url"
import { blue } from "@ant-design/colors"
import { useMediaSize } from "../../layouts/Main/GetWindowDimensions/GetWindowDimensions"

type BookingButtonProps = { record: CourseTableType; block?: boolean; style?: React.CSSProperties }

const BookingButton: FC<BookingButtonProps> = ({ record, block, style }) => {
    const mediaSize = useMediaSize()
    const { media } = mediaSize
    const { handleOpen } = useContext(CoursesContext)

    const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        // console.group("BookingButton")
        // console.log("handleClick")
        // console.log(e)
        // console.log(e.target)
        // console.log(e.currentTarget)
        // console.log(record)
        // console.groupEnd()
        // const locationState: any = history.location.state
        // localStorage.setItem("course", JSON.stringify(locationState.data))
        if (handleOpen) handleOpen(record)
    }

    const courseDetailsLink = URL_COURSE_DETAILS.replace(":id", record.id)

    return (
        <div id='bookCourseButton' className={css.bookingButton} style={style}>
            <Link
                className={css.bookingButton}
                to={{
                    // pathname: `/details/${record.id}`,
                    pathname: courseDetailsLink,
                    state: { data: record },
                }}
            >
                {
                    <Button
                        type='primary'
                        block={block}
                        style={{
                            backgroundColor: record.freePlaces <= 0 ? blue[3] : blue[5],
                            borderColor: record.freePlaces <= 0 ? blue[3] : blue[5],
                        }}
                        size={media === "sm" ? "small" : "middle"}
                        onClick={(e) => handleClick(e)}
                    >
                        {record.freePlaces <= 0 ? "Warteliste" : media === "sm" ? "Buchen" : "Kurs buchen"}
                    </Button>
                }
            </Link>
        </div>
    )
}

export default BookingButton
