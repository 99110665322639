import { useEffect, useState } from "react"
import { Currency, useGetAppSettingsQuery } from "../generated/graphql"

export type CurrencySettings = {
    long: string
    short: string
    symbol: string
    decimalSeperator: string
}

export const useCurrency = (): CurrencySettings => {
    const [currency, setCurrency] = useState<Currency>(Currency.Eur)
    const [currencySettings, setCurrencySettings] = useState<CurrencySettings>({
        long: "long",
        short: "short",
        symbol: "symbol",
        decimalSeperator: "decimalSeperator",
    })

    const { data: appSettings } = useGetAppSettingsQuery()

    const currencySetting: Currency = appSettings?.settings.currency
        ? Currency[appSettings.settings.currency]
        : Currency.Eur

    useEffect(() => {
        setCurrency(currencySetting)
        setCurrencySettings({
            long: "long",
            short: "short",
            symbol: "symbol",
            decimalSeperator: "decimalSeperator",
        })
    }, [currency, currencySetting])

    return currencySettings
}
