import { Button, Divider, Form, Input, Space } from "antd"
import { Formik } from "formik"
import React, { FC, useContext, useEffect, useState } from "react"
import { useHistory } from "react-router"
import {
    EndUserPasswordlessLoginInputType,
    ThirdPartyLoginMutation,
    useStartPasswordlessLoginMutation,
    useThirdPartyLoginMutation,
} from "../../generated/graphql"
import { createInputHelpers } from "../../helpers/createInputHelpers"
import { MainContext, SettingsContext } from "../../layouts/Main/MainLayout"
import {
    URL_BASE,
    URL_FORGOT_PASSWORD,
    URL_LOGIN_COMPLETE_PASSWORDLESS_EMAIL,
    URL_LOGIN_PARAMS,
    URL_REGISTER,
} from "../../models/url"
import LayoutForm from "../../views/LayoutForm/LayoutForm"
import LoginContainer from "../../views/LoginContainer/LoginContainer"
import { LoginPanelValidationSchema } from "./LoginPanelValidationSchema"
import { blue, grey } from "@ant-design/colors"
import { SendOutlined } from "@ant-design/icons"
import ErrorPanel, { KnownError } from "../../errors/ErrorPanel"

const maxPasswordTries = 3

type LoginPanelProps = { setToken: (token: string) => void; boxed?: boolean; email?: string }

const LoginPanel: FC<LoginPanelProps> = ({ setToken, boxed = true, email }) => {
    const history = useHistory()
    const [wrongPasswordCount, setWrongPasswordCount] = useState(0)
    const { userAuthDate } = useContext(MainContext)
    const isLoggedIn = !!localStorage.getItem("loggedInUser")
    const [error, setError] = useState<string | undefined>()

    const appSettingsFromContext = useContext(SettingsContext)

    const { passwordlessLoginEnabled } = appSettingsFromContext

    useEffect(() => {
        if (wrongPasswordCount >= maxPasswordTries) {
            setError("errorPassword")
        }
    }, [wrongPasswordCount])

    const [userLogin, { data: userLoginData, loading: userLoginLoading }] = useThirdPartyLoginMutation()

    const thirdPartyLogin: ThirdPartyLoginMutation["thirdPartyLogin"] | undefined = userLoginData?.thirdPartyLogin

    useEffect(() => {
        if (thirdPartyLogin) {
            const { accessToken, refreshToken, booker, id } = thirdPartyLogin

            const attendee = booker?.attendees?.[0]

            if (accessToken && refreshToken && booker && id) {
                setToken(accessToken)
                // console.log("TOKEN WAS SET => ", thirdPartyLogin.accessToken)
                localStorage.setItem("accessToken", accessToken)
                localStorage.setItem("refreshToken", refreshToken)
                localStorage.setItem("booker", JSON.stringify(booker))
                localStorage.setItem("loggedInUser", id)
                if (attendee) {
                    localStorage.setItem("attendeeId", JSON.stringify(attendee.id))
                }
                if (userAuthDate) {
                    userAuthDate(new Date())
                }
                const state: any = history?.location?.state
                const referer = state?.referer
                console.log("referer: ", referer)
                if (referer === URL_REGISTER) {
                    history.push(URL_BASE)
                } else {
                    history.push(URL_BASE)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thirdPartyLogin])

    useEffect(() => {
        if (isLoggedIn) {
            history.push(URL_BASE)
        }
    }, [history, isLoggedIn])

    const knownErrors: Array<KnownError> = [
        {
            key: "User not found",
            title: "Benutzer nicht gefunden",
            msg: "Wir haben leider keine(n) Nutzer:in mit dieser E-Mail-Adresse gefunden.",
        },
        { key: "errorNotFound", title: "Unbekannter Fehler", msg: "Es gab einen unbekannten Fehler beim Login" },
        { key: "userLoginError", title: "Fehler beim Login", msg: "Es tut uns leid, es gab einen Fehler beim Login" },
        { key: "wrongPassword", title: "Falsches Passwort", msg: "Das eingegebene Passwort ist falsch." },
        {
            key: "userNotFound",
            title: "Benutzer nicht gefunden",
            msg: "Wir haben leider keine(n) Nutzer:in mit dieser E-Mail-Adresse gefunden.",
        },
        { key: "missingUserName", title: "Benutzername fehlt", msg: "Bitte geben Sie Ihren Benutzernamen ein." },
        {
            key: "missingPassword",
            title: "Passwort fehlt",
            msg: "Bitte geben Sie Ihr Passwort ein um sich anzumelden.",
        },
        { key: "missingBoth", title: "Keine Daten", msg: "Bitte gib Daten ein." },
        {
            key: "errorPassword",
            title: "Zu viele Fehlversuche",
            msg: `Du hast dein Passwort ${maxPasswordTries} mal falsch eingegeben.\nBitte versuche dein Passwort zurückzusetzen.`,
        },
    ]

    const [startPasswordLessLoginFlow] = useStartPasswordlessLoginMutation()

    const startPasswordlessLogin = async ({ username }: { username: string }) => {
        console.log("startPasswordlessLogin")
        if (!username || username === "") {
            setError("missingUserName")
            return
        }

        const endUserPasswordlessLoginInput: EndUserPasswordlessLoginInputType = {
            companyName: "test",
            redirectUrl: URL_LOGIN_PARAMS,
            email: username,
        }

        startPasswordLessLoginFlow({
            variables: {
                endUserPasswordlessLoginInput,
            },
        })
            .then((res) => {
                if (res.data?.passwordlessLoginEndUserApp) {
                    history.push(URL_LOGIN_COMPLETE_PASSWORDLESS_EMAIL.replace(":email", username))
                }
            })
            .catch((error: any) => {
                const rawErrorMessage = JSON.stringify(error.graphQLErrors[0].message, null, 4)
                if (rawErrorMessage.includes("User not found")) {
                    setError("User not found")
                }
            })
    }

    const inactiveStyle = (isMed: boolean) => {
        if (!isMed) {
            return { color: blue[5], borderColor: blue[3] }
        }

        return undefined
    }

    const renderContent = () => {
        return (
            <>
                <Formik
                    initialValues={{
                        username: email || "",
                        // username: "",
                        password: "",
                    }}
                    validationSchema={LoginPanelValidationSchema}
                    onSubmit={async ({ username, password }) => {
                        try {
                            await userLogin({
                                variables: {
                                    thirdPartyLoginInput: { username, password },
                                },
                            })
                        } catch (error: any) {
                            console.log("error => ", error)
                            const rawErrorMessage = JSON.stringify(error.graphQLErrors[0].message, null, 4)
                            if (rawErrorMessage.includes("You must specify the [password] property.")) {
                                setError("missingPassword")
                            } else if (rawErrorMessage.includes("User not found")) {
                                setError("userNotFound")
                            } else if (rawErrorMessage.includes("Wrong password")) {
                                setWrongPasswordCount(wrongPasswordCount + 1)
                                if (wrongPasswordCount >= maxPasswordTries) {
                                    setError("errorPassword")
                                } else {
                                    setError("wrongPassword")
                                }
                            } else {
                                setError("errorNotFound")
                            }
                        }
                    }}
                >
                    {(formikProps) => {
                        const { handleSubmit, setFieldValue } = formikProps

                        // const { values } = formikProps
                        const { handleBlur, handleTextInputChange, validateStatus, help } =
                            createInputHelpers(formikProps)

                        const loginFields = Array<"username" | "password">("username", "password")

                        const isValidEmail =
                            formikProps.values.username &&
                            formikProps.values.username !== "" &&
                            formikProps.errors.username === undefined

                        return (
                            <LayoutForm onSubmitCapture={handleSubmit} columns={1}>
                                <Space direction='vertical' /* size='middle' */ size={7}>
                                    {/* {showErrorPanel(errorType)} */}
                                    {error && <ErrorPanel error={error} knownErrors={knownErrors} />}
                                    {wrongPasswordCount <= maxPasswordTries && (
                                        <>
                                            <Form.Item
                                                validateStatus={validateStatus("username")}
                                                help={help("username")}
                                                label='E-Mail-Adresse'
                                                required
                                            >
                                                <Input
                                                    // placeholder='Benutzername (e-mail)'
                                                    value={formikProps.values.username ?? email}
                                                    placeholder='E-Mail-Adresse'
                                                    name='username'
                                                    onChange={handleTextInputChange("username")}
                                                    status={validateStatus(loginFields)}
                                                    onBlur={handleBlur("username")}
                                                />
                                            </Form.Item>
                                            {/* {isValidEmail && ( */}
                                            <>
                                                {passwordlessLoginEnabled && isValidEmail && (
                                                    <>
                                                        <Button
                                                            onClick={async () =>
                                                                startPasswordlessLogin({
                                                                    username: formikProps.values.username,
                                                                })
                                                            }
                                                            style={{
                                                                marginTop: 5,
                                                                ...inactiveStyle(Boolean(formikProps.values.username)),
                                                            }}
                                                            // type='primary'
                                                            type={
                                                                Boolean(formikProps.values.username)
                                                                    ? "primary"
                                                                    : "default"
                                                            }
                                                            block
                                                        >
                                                            Passwortloser Login
                                                            <SendOutlined />
                                                        </Button>
                                                        <Divider
                                                            children={"oder"}
                                                            plain
                                                            style={{
                                                                lineHeight: 0,
                                                                borderColor: "lightgrey",
                                                                color: grey[5],
                                                                marginTop: 20,
                                                            }}
                                                        />
                                                    </>
                                                )}
                                                {((passwordlessLoginEnabled && isValidEmail) ||
                                                    !passwordlessLoginEnabled) && (
                                                    <div>
                                                        <Button
                                                            size='small'
                                                            type='link'
                                                            style={{
                                                                fontSize: "0.8rem",
                                                                fontWeight: 400,
                                                                marginBottom: -35,
                                                                marginTop: 15,
                                                                display: "flex",
                                                                width: "100%",
                                                                justifyContent: "flex-end",
                                                            }}
                                                            onClick={() => history.push(URL_FORGOT_PASSWORD)}
                                                        >
                                                            Passwort vergessen?
                                                        </Button>
                                                        <Form.Item
                                                            style={{ marginTop: 10 }}
                                                            colon={false}
                                                            validateStatus={validateStatus("password")}
                                                            help={help("password")}
                                                            // required
                                                            label='Passwort'
                                                        >
                                                            <Input
                                                                placeholder='Passwort'
                                                                name='password'
                                                                type='password'
                                                                onChange={(event) =>
                                                                    setFieldValue("password", event.target.value, true)
                                                                }
                                                                onBlur={handleBlur("password")}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                )}
                                                <Button
                                                    type={
                                                        Boolean(
                                                            formikProps.values.username && formikProps.values.password
                                                        )
                                                            ? "primary"
                                                            : "default"
                                                    }
                                                    htmlType='submit'
                                                    block
                                                    loading={userLoginLoading}
                                                    style={{
                                                        marginTop: 10,
                                                        ...inactiveStyle(
                                                            Boolean(
                                                                formikProps.values.username &&
                                                                    formikProps.values.password
                                                            )
                                                        ),
                                                    }}
                                                >
                                                    Mit Passwort anmelden
                                                </Button>
                                            </>
                                            {/* )} */}
                                        </>
                                    )}
                                </Space>
                                {wrongPasswordCount < maxPasswordTries ? (
                                    <>
                                        <Divider
                                            children={"Neu bei uns"}
                                            plain
                                            style={{
                                                lineHeight: 0,
                                                borderColor: "lightgrey",
                                                color: grey[5],
                                                border: "1px dotted red;",
                                                marginTop: 8,
                                            }}
                                        />
                                        <Button
                                            type='link'
                                            style={{
                                                fontWeight: 600,
                                                fontSize: "0.9rem",
                                                marginBottom: 4,
                                                marginTop: 8,
                                            }}
                                            onClick={() => history.push(URL_REGISTER)}
                                        >
                                            Jetzt registrieren
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button type='link' onClick={() => history.push(URL_FORGOT_PASSWORD)}>
                                            Passwort zurücksetzen?
                                        </Button>
                                    </>
                                )}
                            </LayoutForm>
                        )
                    }}
                </Formik>
            </>
        )
    }
    if (!boxed) {
        return renderContent()
    }

    return <LoginContainer title={"Login"} size='xs' content={renderContent()} />
}

export default LoginPanel
