// import {gql} from "@apollo/client";
import gql from "graphql-tag"

export const CREATE_AND_REGISTER_USER_AND_BOOKER = gql`
    mutation createAndRegisterThirdPartyUserAndBooker(
        $newBookingData: BookerInput!
        $thirdPartyUserInput: ThirdPartyUserInput!
    ) {
        createAndRegisterThirdPartyUserAndBooker(
            newBookingData: $newBookingData
            thirdPartyUserInput: $thirdPartyUserInput
        ) {
            id
            roles
            email
            booker {
                id
                firstname
                lastname
                gender
                createDate
                updateDate
                customerId
                addition
                street
                houseNumber
                postCode
                city
                phoneNumber
                mobileNumber
                email
                birthday
                initialContact
                dsgvo
                newsletter
                paymentMethods {
                    id
                    cash
                    creditCardAccountholder
                    creditcardNumber
                    expirationDate
                    validFrom
                    cvc
                    paypalAccountholder
                    email
                    iban
                    bank
                    bic
                    sepaAccountholder
                    bankTransfer
                    esr
                }
                attendees {
                    id
                    customer {
                        id
                    }
                    gender
                    lastname
                    firstname
                    birthday
                    member
                    defaultPaymentMethod {
                        id
                    }
                    bookings {
                        id
                        courseLessons {
                            id
                        }
                        paymentMethod {
                            id
                        }
                        paymentType
                        payDate
                    }
                }
                healthInsuranceFund
                annotation
                createdBy
            }
            refreshToken
            accessToken
        }
    }
`

export const FORGOT_USER_PASSWORD = gql`
    mutation forgetPassword($email: String!) {
        forgetPassword(email: $email)
    }
`

export const LOGOUT_USER = gql`
    mutation Logout {
        logoutUser
    }
`
