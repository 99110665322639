import { Table } from "antd"
import React, { FC, memo, useEffect, useState } from "react"
import { ColumnProps } from "antd/lib/table"
import {
    BookerPaymentMethodInput,
    GetCompanySettingsPaymentmethodsQuery,
    GetUpdatedBookerDocument,
    GetUpdatedBookerQuery,
    Maybe,
    PaymentMethod,
    useUpdateBookerMutation,
} from "../../generated/graphql"
import PaymentMethodBadge from "../PaymentMethodBadge/PaymentMethodBadge"
import css from "./PaymentMethodTable.module.css"
import { getPaymentMethodType, PaymentMethodData, PaymentMethodType } from "../../helpers/getPaymentMethodType"
import { Key } from "antd/lib/table/interface"
import SepaDebitPaymentMethodModal, {
    ModalDataType,
} from "../../containers/CreateBooking/Steps/paymentMethodForms/SepaDebitPaymentMethodModal"
import { omit } from "../../helpers/omit"
import { v4 } from "uuid"
import { CompanyMethodsWithIds } from "../../containers/CreateBooking/Steps/StepSummary/SummaryRight"
import { margin, radius } from "../../styles/layout"
import { red } from "@ant-design/colors"

type PaymentMethodTableProps = {
    paymentMethods: Array<Partial<PaymentMethod>>
    paymentMethodId: Maybe<string>
    setSelectedPayment: Function
    showHeader?: boolean
    booker: GetUpdatedBookerQuery["booker"]
    companyMethodsWithIds: Array<CompanyMethodsWithIds>
    liftSepaDebitData: Function
}

type CombinedTableData = Partial<PaymentMethod>

export type CompanyMethods = GetCompanySettingsPaymentmethodsQuery["settings"]

// type PaymentMethodTableData = { method: Extract<keyof CompanyMethods, PaymentMethodType>; active: boolean }

const initialPaymentMethodId = v4() as PaymentMethod["id"]

const PaymentMethodTable: FC<PaymentMethodTableProps> = ({
    paymentMethods,
    paymentMethodId,
    setSelectedPayment,
    showHeader = true,
    booker,
    companyMethodsWithIds,
    liftSepaDebitData,
}) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<Key>>([])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedRows, setSelectedRows] = useState<Array<CombinedTableData>>([])
    const [availablePaymentMethodWithIds, setAvailablePaymentMethodWithIds] =
        useState<Array<CompanyMethodsWithIds>>(companyMethodsWithIds)
    const [newPaymentMethodId, setNewPaymentMethodId] = useState<PaymentMethod["id"]>(initialPaymentMethodId)

    // const { data: companyPaymentMethods } = useGetCompanySettingsPaymentmethodsQuery()
    const [updateBookerDb, { loading: updatingBooker }] = useUpdateBookerMutation()
    // const companyMethods: CompanyMethods | undefined = companyPaymentMethods?.settings

    useEffect(() => {
        if (companyMethodsWithIds.length > 0) {
            // console.group("companyMethodsWithIds")
            const methods: Array<CompanyMethodsWithIds> = []
            companyMethodsWithIds.forEach((method) => {
                if (method.active && method.method !== "id") {
                    methods.push({ method: method.method, active: method.active, id: method.id })
                }
            })
            setAvailablePaymentMethodWithIds(methods)
            // console.log("methods: ", methods)
            // console.groupEnd()
        }
    }, [companyMethodsWithIds])

    useEffect(() => {
        if (paymentMethodId) {
            // setSelectedRowKeys([paymentMethodId])
            const selectedPaymentMethod = paymentMethods.filter((method) => method.id === paymentMethodId)
            if (selectedPaymentMethod[0]) {
                const selectedPaymentMethodType = getPaymentMethodType(selectedPaymentMethod[0])
                const otherPaymentMethods = paymentMethods.filter((method) => getPaymentMethodType(method) !== "none")
                if (selectedPaymentMethodType === "none") {
                    console.log("IS NONE!")
                    console.log("ALTERNATIVE: ", otherPaymentMethods[0].id)
                    if (otherPaymentMethods[0].id) {
                        setSelectedRowKeys([otherPaymentMethods[0].id])
                        setSelectedRows([otherPaymentMethods[0]])
                        setSelectedPayment([otherPaymentMethods[0].id])
                    } else {
                        setSelectedRowKeys([paymentMethodId])
                    }
                } else if (selectedPaymentMethodType === "sepaDebit") {
                    setSelectedRowKeys([paymentMethodId])
                    const bookersPaymentMethods = booker?.paymentMethods || []
                    const bookerHasValidSepaAccount = Boolean(
                        bookersPaymentMethods.find((m) => m.iban && m.iban !== "")
                    )
                    console.log("bookerHasValidSepaAccount:::", bookerHasValidSepaAccount)
                    if (bookerHasValidSepaAccount) {
                        liftSepaDebitData(true)
                    } else {
                        liftSepaDebitData(false)
                    }
                } else {
                    setSelectedRowKeys([paymentMethodId])
                }
                // console.log("selectedPaymentMethod: ", selectedPaymentMethod)
                // console.log("selectedPaymentMethodType: ", selectedPaymentMethodType)
            }
        }
    }, [booker.paymentMethods, liftSepaDebitData, paymentMethodId, paymentMethods, setSelectedPayment])

    // THIS TRIGGERS EVERY RENDER!
    useEffect(() => {
        setSelectedPayment(selectedRowKeys[0] ?? "")
    }, [selectedRowKeys, setSelectedPayment])

    // const createPaymentMethod = async (newPaymentMethodData: BookerPaymentMethodInput | ModalDataType) => {
    const createPaymentMethod = async (newPaymentMethodData: Partial<PaymentMethod>) => {
        const currentBookerData = booker
        const omitedEntries: Array<Extract<keyof GetUpdatedBookerQuery["booker"], string>> = [
            "createDate",
            "updateDate",
            "customerId",
            "addition",
            "annotation",
            // "createdByUserId",
            "updateDate",
            "createdBy",
        ]
        const updateBookerData = omit(["bookerId", ...omitedEntries], currentBookerData)

        updateBookerData.customer = { id: currentBookerData.customerId }

        const newPaymentMethodType = getPaymentMethodType(newPaymentMethodData)
        const existingPaymentMethodTypes = currentBookerData.paymentMethods?.map((paymentMethod) =>
            getPaymentMethodType(paymentMethod)
        )
        if (existingPaymentMethodTypes?.includes(newPaymentMethodType as PaymentMethodType)) {
            console.log("skipping paymentMethod creation because it already exists")
        } else {
            console.log("add new paymentMethod")
            console.log("newPaymentMethodData: ", newPaymentMethodData)
            updateBookerData.paymentMethods.push(newPaymentMethodData)
        }

        try {
            await updateBookerDb({
                variables: {
                    updateBookerData,
                    fromEndUserApp: false,
                },
                refetchQueries: [
                    { query: GetUpdatedBookerDocument, variables: { skip: !booker?.id, id: booker?.id ?? "" } },
                ],
            })
        } catch (error) {
            console.log("error on createPaymentMethod: ", error)
        }
    }

    const updateSepaDebitData = async (newPaymentMethodData: BookerPaymentMethodInput | ModalDataType) => {
        try {
            const currentBookerData = booker
            const omitedEntries: Array<Extract<keyof GetUpdatedBookerQuery["booker"], string>> = [
                "createDate",
                "updateDate",
                "customerId",
                "addition",
                "annotation",
                // "createdByUserId",
                "updateDate",
                "createdBy",
            ]
            const updateBookerData = omit(["bookerId", ...omitedEntries], currentBookerData)

            updateBookerData.customer = { id: currentBookerData.customerId }

            const existingPaymentIds = currentBookerData.paymentMethods?.map((paymentMethod) => paymentMethod.id)

            if (existingPaymentIds?.includes(newPaymentMethodData.id)) {
                currentBookerData.paymentMethods?.find((pm, i) => {
                    if (pm.id === newPaymentMethodData.id) {
                        // @ts-ignore
                        currentBookerData.paymentMethods[i] = { ...newPaymentMethodData }
                        return true // stop searching
                    }
                    return false
                })
            } else {
                updateBookerData.paymentMethods.push(newPaymentMethodData)
            }

            await updateBookerDb({
                variables: {
                    updateBookerData,
                    fromEndUserApp: false,
                },
                refetchQueries: [
                    { query: GetUpdatedBookerDocument, variables: { skip: !booker.id, id: booker.id ?? "" } },
                ],
            })
                .then(() => {
                    // resetForm()
                    if (!updatingBooker) {
                        setNewPaymentMethodId(v4() as PaymentMethod["id"])
                        //     onOk(newAttendeeData)
                        // console.log("onOk")
                    }
                })
                .then(() => liftSepaDebitData(true))
        } catch (error) {
            liftSepaDebitData(false)
            console.error(`Error on updateBooker(updateSepaDebitData): ${error}`)

            return error
        } finally {
            // resetForm()
        }
    }

    const renderBadge = (paymentMethod: PaymentMethodData) => {
        return <PaymentMethodBadge type={getPaymentMethodType(paymentMethod)} />
    }

    const columns: Array<ColumnProps<PaymentMethodData>> = [
        {
            title: "Verfahren",
            dataIndex: "id",
            render: (text, record: PaymentMethodData) => renderBadge(record),
        },
        {
            title: "Konoinhaber:in",
            dataIndex: "sepaAccountholder",
        },
        {
            title: "IBAN",
            dataIndex: "iban",
        },
        {
            title: "Bank / Institut",
            dataIndex: "bank",
        },
        // {
        //     title: "id",
        //     dataIndex: "id",
        // },
    ]

    const combinedTableData = (): Array<CombinedTableData> => {
        const combinedData: Array<CombinedTableData> = []
        const bookersMethodsStringArray: Array<PaymentMethodType> = []
        const bookersPaymentMethods = paymentMethods.map((paymentMethod) => {
            const methodType = getPaymentMethodType(paymentMethod)
            const companyMethod = availablePaymentMethodWithIds.find((method) => method.method === methodType)
            const companyMethodActive = companyMethod?.active
            bookersMethodsStringArray.push(getPaymentMethodType(paymentMethod))
            return { ...paymentMethod, active: companyMethodActive }
        })

        // remove paymentMethods that are not available for the company
        const filteredBookersPaymentMethods = bookersPaymentMethods.filter((method) => method.active)

        combinedData.push(...filteredBookersPaymentMethods)

        const companyPaymentMethods = availablePaymentMethodWithIds

        const missingMethods = companyPaymentMethods.filter((method) => {
            if (method.method === "id") {
                return false
            }
            return !bookersMethodsStringArray.includes(method.method)
        })

        missingMethods.forEach((method, index) => {
            const missingMethod: CombinedTableData = {
                // id: newPaymentMethodId,
                // id: v4() as PaymentMethod["id"],
                bank: null,
                bankTransfer: false,
                bic: null,
                cash: true,
                creditCardAccountholder: null,
                creditcardNumber: null,
                cvc: null,
                email: null,
                expirationDate: null,
                iban: null,
                paypalAccountholder: null,
                sepaAccountholder: null,
                validFrom: null,
                esr: null,
            }

            switch (method.method) {
                case "esr":
                    missingMethod.id = method.id
                    missingMethod.esr = true
                    missingMethod.cash = null
                    break
                case "cash":
                    missingMethod.id = method.id
                    missingMethod.cash = true
                    break
                case "bankTransfer":
                    missingMethod.id = method.id
                    missingMethod.cash = null
                    missingMethod.bankTransfer = true
                    break

                case "sepaDebit":
                    missingMethod.id = method.id
                    missingMethod.cash = null
                    missingMethod.sepaAccountholder = ""
                    missingMethod.iban = ""
                    missingMethod.bank = ""
                    missingMethod.bic = ""
                    break

                default:
                    break
            }

            combinedData.push(missingMethod)
        })

        return combinedData
    }

    const paymentMethodIsMissing: boolean = selectedRowKeys[0] === undefined

    const warningStyle: React.CSSProperties = {
        border: "3px solid red",
        borderColor: red[2],
        borderRadius: radius,
        padding: margin.xs,
    }

    const CombinedTable = () => {
        return (
            <>
                <Table<CombinedTableData>
                    style={paymentMethodIsMissing ? warningStyle : {}}
                    rowKey='id'
                    showHeader={showHeader}
                    columns={columns}
                    dataSource={combinedTableData()}
                    pagination={false}
                    size='small'
                    rowSelection={{ type: "radio", ...rowSelection }}
                    bordered={false}
                    expandIcon={() => null}
                    expandRowByClick={true}
                    expandIconColumnIndex={-1}
                    expandedRowKeys={selectedRowKeys}
                    expandable={{
                        expandedRowRender: (record) => (
                            <div
                                style={{
                                    margin: 0,
                                }}
                            >
                                <>
                                    <SepaDebitPaymentMethodModal
                                        onSubmit={updateSepaDebitData}
                                        initialValues={{
                                            id: record.id ?? newPaymentMethodId,
                                            sepaAccountholder: record.sepaAccountholder,
                                            iban: record.iban,
                                            bank: record.bank,
                                            bic: record.bic,
                                        }}
                                    />
                                </>
                            </div>
                        ),
                        rowExpandable: (record) => record.sepaAccountholder !== null,
                    }}
                />
            </>
        )
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: Array<Key> | Array<number>, selectedRows: Array<CombinedTableData>) => {
            setSelectedRowKeys(selectedRowKeys)
            setSelectedRows(selectedRows)
            setSelectedPayment(selectedRows[0])
            console.groupEnd()
            createPaymentMethod(selectedRows[0])
        },
    }

    return (
        <div className={css.root}>
            <CombinedTable />
        </div>
    )
}

export default memo(PaymentMethodTable)
