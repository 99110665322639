import { PaymentMethod } from "../generated/graphql"

export type PaymentMethodType = "cash" | "creditCard" | "payPal" | "sepaDebit" | "bankTransfer" | "esr" | "none"
export enum PaymentMethodTypeEnum {
    cash = "cash",
    creditCard = "creditCard",
    payPal = "payPal",
    sepaDebit = "sepaDebit",
    bankTransfer = "bankTransfer",
    esr = "esr",
    none = "none",
}

export type PaymentMethodData = Pick<
    PaymentMethod,
    | "cash"
    | "creditCardAccountholder"
    | "creditcardNumber"
    | "expirationDate"
    | "validFrom"
    | "cvc"
    | "paypalAccountholder"
    | "email"
    | "iban"
    | "bank"
    | "bic"
    | "sepaAccountholder"
    | "bankTransfer"
    | "esr"
>

export const getPaymentMethodType = ({
    cash,
    creditcardNumber,
    creditCardAccountholder,
    expirationDate,
    sepaAccountholder,
    iban,
    bic,
    bank,
    paypalAccountholder,
    email,
    bankTransfer,
    esr,
}: PaymentMethodData): PaymentMethodType => {
    if (esr === true) {
        return "esr"
    }
    if (cash !== null && cash !== undefined) {
        return "cash"
    }

    if (bankTransfer === true) {
        return "bankTransfer"
    }

    if (
        creditcardNumber !== null &&
        creditcardNumber !== undefined &&
        creditCardAccountholder !== null &&
        creditCardAccountholder !== undefined &&
        expirationDate !== null &&
        expirationDate !== undefined
    ) {
        return "creditCard"
    }

    if (
        sepaAccountholder !== null &&
        sepaAccountholder !== undefined &&
        iban !== null &&
        iban !== undefined &&
        bic !== null &&
        bic !== undefined &&
        bank !== null &&
        bank !== undefined
    ) {
        return "sepaDebit"
    }

    if (paypalAccountholder !== null && paypalAccountholder !== undefined && email !== null && email !== undefined) {
        return "payPal"
    }

    return "none"
}
