import { Button, Card, Space } from "antd"
import React, { FC, memo } from "react"
import css from "./LoginContainer.module.css"
import Logo from "../../assets/logo.svg"
import { margin } from "../../styles/layout"
import { Link, useHistory } from "react-router-dom"
import { URL_BASE } from "../../models/url"
import classnames from "classnames"
import { LeftOutlined } from "@ant-design/icons"

type LoginContainerProps = {
    title?: string | React.ReactElement
    content: React.ReactElement
    size?: "xs" | "s" | "l"
    backToUrl?: string
    style?: React.CSSProperties
}

const LoginContainer: FC<LoginContainerProps> = ({ content, title, size = "default", backToUrl, style }) => {
    const history = useHistory()
    type LoginHeaderProps = { title?: string | React.ReactElement }

    const LoginHeader: FC<LoginHeaderProps> = ({ title }) => {
        return (
            <Space direction='vertical' align='center' style={{ width: "100%", paddingTop: margin.std }}>
                <img src={Logo} alt='logo' style={{ width: 60, height: 60 }} />
                <h1 style={{ fontWeight: 700, fontSize: "2rem" }}>{title}</h1>
            </Space>
        )
    }

    // const panelSizeClass = `${css.card}_${size}`
    const panelSizeClass = () => {
        switch (size) {
            case "xs":
                return css.card_xs
            case "s":
                return css.card_s
            case "l":
                return css.card_l
            default:
                return css.card_default
        }
    }
    // console.log("panelSizeClass: ", panelSizeClass)

    return (
        <Space className={css.root} direction='vertical' style={{ width: "100%" }}>
            <Card
                className={classnames(css.card, panelSizeClass())}
                size='small'
                title={
                    backToUrl && (
                        <div style={{ textAlign: "left" }}>
                            <Button type='link' size='small' onClick={() => history.push(backToUrl)}>
                                <LeftOutlined />
                                Zurück zum Login
                            </Button>
                        </div>
                    )
                }
                bodyStyle={style}
            >
                <Space direction='vertical' style={{ width: "100%" }}>
                    <LoginHeader title={title} />
                    <div>{content}</div>
                </Space>
            </Card>
            <Link to={URL_BASE}>
                <Button type='link'>Zur Kurssuche</Button>
            </Link>
            {/* <Button type='link' onClick={() => history.goBack()}>
                Zurück
            </Button> */}
        </Space>
    )
}

export default memo(LoginContainer)
