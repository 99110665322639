import classNames from "classnames"
import React, { FC, memo } from "react"
import css from "./SummarySection.module.css"

type SummarySectionProps = {
    title?: string | React.ReactNode
    extra?: string | React.ReactNode
    underLine?: boolean
    content?: React.ReactNode
    style?: React.CSSProperties
}

const SummarySection: FC<SummarySectionProps> = ({ title, extra, underLine = true, content, style }) => {
    const actualStyle = { paddingTop: 10, paddingBottom: 10, ...style }

    return (
        <div className={css.root} style={actualStyle}>
            {title ? (
                <div className={underLine ? classNames(css.header, css.underLine) : classNames(css.header)}>
                    <div>{title}</div>
                    <div>{extra}</div>
                </div>
            ) : null}
            <div className={css.content}>{content}</div>
        </div>
    )
}

export default memo(SummarySection)
