import React, {FC, memo} from "react";
import {Button as AntButton} from "antd";
// @ts-ignore
import classnames from "classnames";
import {useHistory} from "react-router-dom";
import {ButtonProps as AntButtonProps} from "antd/lib/button/button";
// @ts-ignore
import css from "./Button.less";

type ButtonProps = AntButtonProps;

const RouterCompatibleAntButton: FC<ButtonProps> = ({
    children = null,
    onClick,
    className,
    ...restProps
}) => {
    const history = useHistory();
    const {href, target} = restProps;

    const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (onClick !== undefined) {
            onClick(event);
        }

        const prevented = event.defaultPrevented;
        const isLeftMouseButton = event.button === 0;
        const isTargetSelf = target === undefined || target === "_self";
        const modifierKeyPressed =
            event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;

        if (
            href !== undefined &&
            !prevented &&
            isLeftMouseButton &&
            isTargetSelf &&
            !modifierKeyPressed
        ) {
            event.preventDefault();
            history.push(href);
        }
    };

    return (
        <AntButton {...restProps} onClick={handleClick}>
            {children}
        </AntButton>
    );
};

const Button: FC<AntButtonProps> = ({className, ...restProps}) => {
    const ButtonComponent =
        restProps.href === undefined ? AntButton : RouterCompatibleAntButton;

    return (
        <ButtonComponent
            className={classnames(css.root, css[className ?? ""])}
            {...restProps}
        />
    );
};

export default memo(Button);
