import * as Yup from "yup"
import { apolloClient } from "../../../ApolloWrapper/ApolloWrapper"
import { GET_BANK_DATA } from "../../../sepaDebitValidation/sepaDebit.graphql"
import { ModalDataType } from "./SepaDebitPaymentMethodModal"

const { string, object } = Yup

// type ModalDataValidationType = {[key in keyof ModalDataType]: Yup.MixedSchema<any>}
type ModalDataValidationType = {
    [key in keyof Pick<ModalDataType, "sepaAccountholder" | "iban" | "bank">]: any
}

export const SepaDebitValidationSchema = object().shape<ModalDataValidationType>({
    sepaAccountholder: string()
        .matches(
            /([A-Za-z\u00e4\u00fc\u00f6]{2,})+\s+([A-Za-z\u00e4\u00fc\u00f6]{2,})/,
            "Bitte gib Vor- und Nachname an."
        )
        .required("Bitte gib einen Kontoinhaber an."),
    // iban: string()
    //     .matches(
    //         /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/,
    //         'Bitte gib eine gültige IBAN ein. Bsp.: "DE 11 5205 1373 5120 7101 31, CH93 0076 2011 6238 5295 7, AT61 1904 3002 3457 3201"'
    //     )
    //     .required("Bitte gib eine IBAN ein."),
    iban: string()
        .test(
            "validatingIban",
            'Bitte gib eine gültige IBAN ein. Bsp.: "DE 11 5205 1373 5120 7101 31, CH93 0076 2011 6238 5295 7, AT61 1904 3002 3457 3201"',
            async (iban) => {
                console.log("===> iban:::", iban)
                if (iban !== undefined) {
                    console.log("===> iban:::", iban)
                    const { data, errors } = await apolloClient.query({
                        query: GET_BANK_DATA,
                        variables: { iban },
                    })

                    if (errors) {
                        console.log("validatingIban errors: ", errors)
                    }

                    return data.getBankData.valid
                }
            }
        )
        .required("Bitte gib eine IBAN ein."),
    // bank: string()
    //     .matches(/([a-zA-Z0-9]{3,})/, "Bitte gib einen gültigen Banknamen ein (ohne Sonderzeichen)")
    //     // .required("Bitte gib eine Bank ein."),
    //     .notRequired(),
})

// Internationale IBAN
// /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/

// Deutsche IBAN
// /^DE\s*\d{2}\s*([0-9a-zA-Z]{4}\s*){4}[0-9a-zA-Z]{2}$/
