import React, { createContext, FC, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { CourseListFilters } from "../../generated/graphql"
import { margin } from "../../styles/layout"
import { InCommingAttributes } from "../../types/filters"
import ForgotPassword from "../../views/ForgotPassword/ForgotPassword"
import LoginError from "../../views/LoginError/LoginError"
import LoginForm from "../../views/LoginForm/LoginForm"
import CourseFilters from "./CourseFilters"
import CoursesTable from "./CoursesTable"

type CourseTableContainerProps = {
    incommingFilters: InCommingAttributes
    otherSettings: Partial<InCommingAttributes>
}

export type SpecialFilters = {
    instructorId?: string
    dayFilter?: string
    courseCategoryId?: string
    showOnlyInWebActive?: boolean
}

// We define our type for the context properties right here
type ContextProps = {
    visible: boolean
    record: any
    handleCancel: () => void
    forgotPassword: () => void
    loginForm: () => void
    handleOpen: (record: any) => void
    showError: (message: string) => void
}

// we initialise them without default values, to make that happen, we
// apply the Partial helper type.
export const CoursesContext = createContext<Partial<ContextProps>>({})

const CourseTableContainer: FC<CourseTableContainerProps> = ({ incommingFilters, otherSettings }) => {
    const [courseListFilters, setCourseListFilters] = React.useState<CourseListFilters>({})
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedId, setSelectedId] = React.useState<string | null>(null)

    // AUTHENTICATION ---------------------------------------------------------------------------------------------------------
    const isLoggedIn = !!localStorage.getItem("loggedInUser")
    const history = useHistory()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isForgotModal, setIsForgotModal] = useState(false)
    const [isErrorModal, setIsErrorModal] = useState(false)
    const [isLoginForm, setIsLoginForm] = useState(true)
    const [forceReload, setForceReload] = useState(false)
    const [record, setRecord] = useState({})
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        const { location } = history
        if (location.state) {
            // @ts-ignore
            const { openLoginModal, reload } = location.state
            // @ts-ignore
            if (!isLoggedIn && openLoginModal) {
                if (openLoginModal) {
                    setIsModalVisible(true)
                    loginForm()
                }
            }
            // @ts-ignore
            if (reload) {
                setForceReload(true)
            }
        }
    }, [isLoggedIn, history])

    const handleCancel = () => {
        setIsModalVisible(false)
        setIsForgotModal(false)
        setIsLoginForm(true)
        setIsErrorModal(false)
    }
    const handleOpen = (data: any) => {
        setRecord(data)
        setIsModalVisible(true)
    }
    const forgotPassword = () => {
        setIsForgotModal(true)
        setIsLoginForm(false)
        setIsErrorModal(false)
    }
    const loginForm = () => {
        setIsForgotModal(false)
        setIsLoginForm(true)
        setIsErrorModal(false)
    }
    const showError = (message: React.SetStateAction<string>) => {
        setIsForgotModal(false)
        setIsLoginForm(false)
        setIsErrorModal(true)
        setErrorMessage(message)
    }

    // RETURN ------------------------------------------------------------------------------------------------------------------

    // console.group("CourseTableContainer")
    // console.log("courseListFilters", courseListFilters)
    // console.log("incommingFilters", incommingFilters)
    // console.groupEnd()

    return (
        <>
            <CoursesContext.Provider
                value={{
                    visible: isModalVisible,
                    record: record,
                    handleCancel: handleCancel,
                    handleOpen: handleOpen,
                    forgotPassword: forgotPassword,
                    loginForm: loginForm,
                    showError: showError,
                }}
            >
                <CourseFilters
                    otherSettings={otherSettings}
                    courseFilterValues={(e) => setCourseListFilters(e)}
                    incommingFilters={incommingFilters}
                    style={{ marginBottom: margin.s, marginTop: margin.s }}
                />
                <CoursesTable
                    courseListFilters={courseListFilters}
                    selectedId={(courseId: React.SetStateAction<string | null>) => setSelectedId(courseId)}
                    forceReload={forceReload}
                />
                {isLoginForm && <LoginForm />}
                {isForgotModal && <ForgotPassword />}
                {isErrorModal && <LoginError message={errorMessage} />}
            </CoursesContext.Provider>
        </>
    )
}

export default CourseTableContainer
