export const naturalLanguageAge = (ageInMonths: number) => {
    const years = Math.floor(ageInMonths / 12)
    const months = ageInMonths % 12

    if (ageInMonths < 24) {
        const year = years > 0 ? `${years}J` : ""

        if (months === 0) {
            return `${year}`
        }

        return `${year} ${months}M`
    }

    return `${years} J`
}

export const ageInFullJears = (ageInMonths: number | undefined | null) => {
    if (typeof ageInMonths === "number") {
        const years = Math.floor(ageInMonths / 12)

        return years
    }

    return undefined
}
