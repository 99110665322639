import { Alert, Button, Divider, Input, Space } from "antd"
import { Formik } from "formik"
import React, { FC, useState } from "react"
import { useHistory } from "react-router"
import { useForgotPasswordMutation, ForgotPasswordWithStateInput } from "../../generated/graphql"
import { URL_LOGIN, URL_REGISTER } from "../../models/url"
import { margin } from "../../styles/layout"
import LayoutForm from "../../views/LayoutForm/LayoutForm"
import LoginContainer from "../../views/LoginContainer/LoginContainer"

type ForgotPasswordErrorProps = {
    title: string
    msg: string
}

type ErrorType = { [key: string]: ForgotPasswordErrorProps }

type ForgotPasswordPanelProps = {
    parentUrl: string
    boxed?: boolean
}

const ForgotPasswordPanel: FC<ForgotPasswordPanelProps> = ({ boxed = true, parentUrl }) => {
    const history = useHistory()
    const [errorType, setErrorType] = useState<keyof ErrorType>("errorNotFound")
    const [emailWasSent, setEmailWasSent] = useState(false)

    const [forgotPassword, { loading: forgotPasswordResetLoading, error: forgotPasswordResetError }] =
        useForgotPasswordMutation()

    const errors: ErrorType = {
        errorNotFound: { title: "Fehler", msg: "Ein unbekannter Fehler ist aufgetreten." },
        userNotFound: {
            title: "Benutzer nicht gefunden",
            msg: "Wir haben leider keine(n) Nutzer:in mit dieser E-Mail-Adresse gefunden.",
        },
    }

    const showErrorPanel = (errorType: keyof ErrorType) => {
        if (forgotPasswordResetError)
            return (
                <Alert
                    style={{ textAlign: "center" }}
                    type='error'
                    message={errors[errorType].title}
                    description={errors[errorType].msg}
                />
            )
        return false
    }

    const renderContent = (emailWasSent: boolean) => {
        if (emailWasSent) {
            return (
                <Space direction='vertical' size='large' style={{ margin: margin.s }}>
                    <Alert
                        style={{ textAlign: "center" }}
                        // showIcon
                        // banner
                        type='success'
                        message={"Wir haben dir eine E-Mail mit dem Link zum Zurücksetzen deines Passworts gesendet."}
                        description={
                            "Falls du keine E-Mail erhalten hast, überprüfe bitte auch deinen Spam-Ordner oder probiere es gegebenenfalls erneut."
                        }
                    />
                    <Button type='primary' onClick={() => history.push(URL_LOGIN)}>
                        Zurück zum Login
                    </Button>
                </Space>
            )
        }
        return (
            <>
                {showErrorPanel(errorType) !== false ? (
                    showErrorPanel(errorType)
                ) : (
                    <div style={{ padding: margin.xs }}>
                        <p>Bitte trage hier deine E-Mail-Adresse ein.</p>Falls du bereits bei uns im Buchungssystem
                        bist, senden wir dir einen Link zu, mit dem du dein Passwort zurücksetzen kannst.
                    </div>
                )}
                <Formik
                    initialValues={{ email: "" }}
                    onSubmit={async ({ email }) => {
                        console.log("start forgot password with email => ", email)
                        const forgotPasswordWithStateInput: ForgotPasswordWithStateInput = {
                            loginId: email,
                            state: { resetPasswordUrl: parentUrl },
                            sendForgotPasswordEmail: true,
                        }
                        try {
                            await forgotPassword({ variables: { forgotPasswordWithStateInput } }).then((data) => {
                                console.log("forgotPassword data: ", data)
                                setEmailWasSent(true)
                                // history.push(URL_FORGOT_PASSWORD_SUCCESS)
                            })
                        } catch (error: any) {
                            console.group("ERRORS")
                            console.log("error => ", error)
                            if (error.message && error.message.includes("404")) {
                                setErrorType("userNotFound")
                            } else {
                                setErrorType("errorNotFound")
                            }
                            console.log(error)
                            setEmailWasSent(false)
                        }
                    }}
                >
                    {({ handleSubmit, setFieldValue }) => (
                        <LayoutForm onSubmitCapture={handleSubmit} columns={1}>
                            <Space direction='vertical' size='large'>
                                <Input
                                    placeholder='Benutzername (e-mail)'
                                    name='username'
                                    type='email'
                                    onChange={(event) => setFieldValue("email", event.target.value, true)}
                                />
                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    // onClick={login}
                                    block
                                    loading={forgotPasswordResetLoading}
                                >
                                    Link anfordern
                                </Button>
                            </Space>
                            <Button type='link' onClick={() => history.push(URL_LOGIN)}>
                                Zurück zum Login
                            </Button>
                            <Divider style={{ marginTop: margin.s }} />
                            <Button type='default' onClick={() => history.push(URL_REGISTER)}>
                                Jetzt registrieren
                            </Button>
                        </LayoutForm>
                    )}
                </Formik>
            </>
        )
    }

    if (!boxed) {
        return renderContent(emailWasSent)
    }

    return (
        <LoginContainer
            title={emailWasSent ? "E-Mail wurde versendet" : "Passwort zurücksetzen"}
            size={emailWasSent ? "s" : "xs"}
            content={renderContent(emailWasSent)}
        />
    )
}

export default ForgotPasswordPanel
