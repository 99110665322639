import React, { FC, useContext } from "react"
import { Form, Button, Modal } from "antd"
import "antd/dist/antd.css"
import "./style.css"
import { useMediaSize } from "../../layouts/Main/GetWindowDimensions/GetWindowDimensions"
import { Link } from "react-router-dom"
import { SendOutlined } from "@ant-design/icons"
import { red } from "@ant-design/colors"
import { radiusXs } from "../../styles/layout"
import { CoursesContext } from "../../containers/CoursesTable/CourseTableContainer"

type LoginErrorProps = {
    message: string
}

const LoginError: FC<LoginErrorProps> = ({ message }) => {
    const mediaSize = useMediaSize()
    const { media } = mediaSize
    const { visible, handleCancel, loginForm } = useContext(CoursesContext)
    const [form] = Form.useForm()

    type ButtonMailToProps = {
        mailto: any
        label: string
    }

    const ButtonMailto: FC<ButtonMailToProps> = ({ mailto, label }) => {
        return (
            <Button type='primary' ghost style={{ marginTop: 15 }} icon={<SendOutlined style={{ paddingRight: 7 }} />}>
                <Link
                    to='#'
                    onClick={(e) => {
                        window.location = mailto
                        e.preventDefault()
                    }}
                >
                    {label}
                </Link>
            </Button>
        )
    }

    return (
        <>
            <Modal
                title='Fehler'
                visible={visible}
                footer={
                    <Button type='link' onClick={loginForm} style={{ margin: "auto" }}>
                        Zurück zum Login
                    </Button>
                }
                onOk={handleCancel}
                onCancel={handleCancel}
                width={350}
                centered={media !== "xs"}
            >
                <Form form={form} layout='vertical'>
                    <div style={{ textAlign: "center" }}>
                        <span>Es tut uns leid, ein Fehler ist aufgetreten.</span>
                        <div
                            style={{
                                padding: 5,
                                marginTop: 10,
                                marginBottom: 10,
                                textAlign: "center",
                                border: `1px solid ${red[1]}`,
                                borderRadius: radiusXs,
                            }}
                            className='ant-form-item-explain ant-form-item-explain-error'
                        >
                            <div role='alert'>{message}</div>
                        </div>
                        <div>
                            Bitte versuche es erneut. Falls der Fehler erneut auftreten sollte, schreibe uns eine Mail
                            an
                        </div>
                        <ButtonMailto mailto={"mailto:info@kursorganizer.com"} label={"info@kursorganizer.com"} />
                    </div>
                </Form>
            </Modal>
        </>
    )
}

export default LoginError
