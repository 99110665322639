import { Currency } from "../generated/graphql"

export type CurrencyShort = "EUR" | "CHF" | "USD" | "GBP"

export const renderAmount = ({
    amount,
    short,
    currency,
    localeOverwrite,
}: {
    amount: number
    short?: boolean
    currency?: Currency | CurrencyShort
    localeOverwrite?: string
}) => {
    const currencyCode = () => {
        switch (currency) {
            case Currency.Eur:
                return "EUR"
            case "EUR":
                return "EUR"
            case "GBP":
                return "GBP"
            case Currency.Gbp:
                return "GBP"
            case "USD":
                return "USD"
            case Currency.Usd:
                return "USD"
            case "CHF":
                return "CHF"
            case Currency.Chf:
                return "CHF"

            default:
                return "EUR"
        }
    }

    const browserLocale = navigator.language
    const locales = localeOverwrite ?? browserLocale

    // console.log("locales", locales);
    const digits = short ? (amount % 1 === 0 ? 0 : 2) : 2
    const formatter = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currencyCode(),
        minimumFractionDigits: digits,
    })

    return formatter.format(amount)
}

export const useRenderAmount = ({
    amount,
    short,
    currency,
    localeOverwrite,
}: {
    amount: number
    short?: boolean
    currency?: Currency
    localeOverwrite?: string
}) => {
    // console.log("formatSettings", formatSettings)

    const browserLocale = navigator.language
    const digits = short ? (amount % 1 === 0 ? 0 : 2) : 2

    const formatSettings: Intl.NumberFormatOptions = {
        style: "currency",
        currency: currency ?? "EUR",
        minimumFractionDigits: digits,
        currencyDisplay: "code",
    }

    console.log("formatSettings", formatSettings)

    const formatter = new Intl.NumberFormat(localeOverwrite ?? browserLocale, formatSettings)

    return formatter.format(amount)
}
