import * as Yup from "yup"
import { Gender, BookerAttendeeInput } from "../../../../generated/graphql"
import { BaseInputValidationSchema } from "../../../common/BaseInputValidationSchema"

const { string, object, mixed, boolean, date } = Yup

// @ts-ignore
export const BookerAttendeeInputValidationSchema = object().shape<BookerAttendeeInput>({
    id: string().required(),
    customer: BaseInputValidationSchema.required(),
    gender: mixed().oneOf(Object.values(Gender)).required("Geschlecht ist ein Pflichtfeld"),
    firstname: string().min(3, "Der Vorname muss mindestens 3 Zeichen haben.").required("Vorname ist ein Pflichtfeld"),
    lastname: string().min(3, "Der Nachname muss mindestens 3 Zeichen haben.").required("Nachname ist ein Pflichtfeld"),
    courseId: string().notRequired().nullable(), // only needed for the min-max age check
    // birthday: date()
    //     .required()
    //     .test(
    //         "isOldEnough",
    //         "Der Teilnehmer hat das Mindestalter für diesen Kurs nicht erreicht",
    //         async function (value) {
    //             if (!value) return false
    //             const { data } = await apolloClient.query({
    //                 query: GET_COURSE_MIN_MAX_AGE,
    //                 variables: {
    //                     courseId: this.parent.courseId,
    //                 },
    //             })

    //             const result: GetCourseMinMaxAgeQuery["getCourseMinMaxAge"] = data.getCourseMinMaxAge
    //             const { minAge, maxAge } = result

    //             const ageInMonths = Math.floor(
    //                 (new Date().getTime() - new Date(value).getTime()) / 1000 / 60 / 60 / 24 / 30
    //             )

    //             console.log("hasRightAge:::", "alter: ", ageInMonths, "min: ", minAge, "max: ", maxAge)

    //             if (ageInMonths > minAge || minAge === null) {
    //                 return true
    //             }

    //             return false
    //         }
    //     )
    //     .test("isOldEnough", "Der Teilnehmer ist leider zu alt für diesen Kurs", async function (value) {
    //         if (!value) return false
    //         const { data } = await apolloClient.query({
    //             query: GET_COURSE_MIN_MAX_AGE,
    //             variables: {
    //                 courseId: this.parent.courseId,
    //             },
    //         })

    //         const result: GetCourseMinMaxAgeQuery["getCourseMinMaxAge"] = data.getCourseMinMaxAge
    //         const { minAge, maxAge } = result

    //         const ageInMonths = Math.floor(
    //             (new Date().getTime() - new Date(value).getTime()) / 1000 / 60 / 60 / 24 / 30
    //         )

    //         if (ageInMonths < maxAge || maxAge === null) {
    //             return true
    //         }

    //         return false
    //     }),
    birthday: date()
        .required("Geburtsdatum ist ein Pflichtfeld")
        .test("dateIsBeforeToday", "Das Geburtsdatum darf nicht in der Zukunft liegen", async function (value) {
            if (!value) return false

            // if date is in the future
            if (new Date().getTime() >= new Date(value).getTime()) {
                return true
            }

            console.log("FALSE")

            return false
        }),
    member: boolean().required(),
    defaultPaymentMethod: BaseInputValidationSchema.notRequired()
        .nullable()
        // defaults to {} without a default of undefined and acts as if it would be required because of that
        // see https://github.com/jquense/yup/issues/26 for more info.
        .default(undefined),
})
