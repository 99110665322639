import { Button, ButtonProps, Result, ResultProps } from "antd"
import React, { FC, useState } from "react"
import { useHistory } from "react-router-dom"
import { useLogoutMutation } from "../../generated/graphql"
import { URL_BASE, URL_LOGIN } from "../../models/url"
import { ReloadOutlined } from "@ant-design/icons"

export type ResultKeys =
    | "unknown"
    | "success"
    | "test"
    | "succesfullyBooked"
    | "successfullyOnWaitingList"
    | "loggedOut"
    | "alreadyEnrolled"
    | "courseNotFound"
    | "notDeletableHasBookings"
    | "errorFetchingData"

const knownResultKeys: Array<ResultKeys> = [
    "unknown",
    "success",
    "test",
    "succesfullyBooked",
    "successfullyOnWaitingList",
    "loggedOut",
    "alreadyEnrolled",
    "courseNotFound",
    "notDeletableHasBookings",
    "errorFetchingData",
]

type ResultScreenProps = {
    resultType?: ResultKeys | string
    errorMsg?: string
    customButton?: React.ReactNode
}

const standardErrorTitle = "Unbekannter Fehler"
const standardErrorMessage = "Es tut uns leid, da ist etwas schief gelaufen. Bitte versuchen Sie es später noch einmal."

const ResultScreen: FC<ResultScreenProps> = ({ resultType, errorMsg = standardErrorMessage, customButton }) => {
    const history = useHistory()
    const [logoutLoading, setLogoutLoading] = useState(false)
    const [logoutUser] = useLogoutMutation()
    // const forceRefresh = () => {
    //     window.location.reload()
    // }

    if (resultType === "unknown" && errorMsg !== standardErrorMessage) {
        console.log("==> errorMsg: ", errorMsg)
    }

    const isOfTypeResultKeys = (keyInput: string): keyInput is ResultKeys => {
        return knownResultKeys.includes(keyInput as ResultKeys)
    }

    const StandardButton = ({ type }: { type?: ButtonProps["type"] }) => {
        return (
            <Button type={type ?? "primary"} onClick={() => history.push(URL_BASE)}>
                Zurück zur Kurssuche
            </Button>
        )
    }

    const BackButton = ({ type }: { type?: ButtonProps["type"] }) => {
        return (
            <Button type={type ?? "primary"} onClick={() => history.goBack()}>
                Zurück
            </Button>
        )
    }

    const logout = async ({ goToLink }: { goToLink: string }) => {
        setLogoutLoading(true)
        try {
            const { data } = await logoutUser()
            console.log("logoutUser data: ", data)
            // setLogoutLoading(false)
            if (data?.logoutUser) {
                localStorage.removeItem("accessToken")
                localStorage.removeItem("refreshToken")
                localStorage.removeItem("booker")
                localStorage.removeItem("attendeeId")
                localStorage.removeItem("loggedInUser")
                localStorage.removeItem("bookingData")
                localStorage.removeItem("course")
            }
            setLogoutLoading(false)
        } catch (error) {
            setLogoutLoading(false)
            console.log("err====>>>>", error)
        }
        history.push(goToLink)
    }

    const resultProps: { [key in ResultKeys]: { resultProps: ResultProps } } = {
        succesfullyBooked: {
            resultProps: {
                status: "success",
                title: "Die Buchung wurde erfolgreich durchgeführt.",
                subTitle: "Du solltest in Kürze eine Bestätigungsmail erhalten.",
                extra: [<StandardButton />],
            },
        },
        successfullyOnWaitingList: {
            resultProps: {
                status: "success",
                title: <p>Wir haben dein Kind auf die Warteliste gesetzt.</p>,
                subTitle: (
                    <p>
                        Da dieser Kurs bereits ausgebucht ist, haben wir dich bzw. dein Kind, auf die Warteliste
                        gesetzt.
                        <br />
                        Du solltest in Kürze eine Bestätigungsmail erhalten.
                    </p>
                ),
                extra: [<StandardButton />],
            },
        },
        loggedOut: {
            resultProps: {
                status: "warning",
                title: "Du wurdest ausgeloggt.",
                subTitle: "Bitte logge dich erneut ein.",
                extra: [
                    <Button
                        key='login'
                        type='primary'
                        onClick={() => logout({ goToLink: URL_LOGIN })}
                        loading={logoutLoading}
                    >
                        Erneut anmelden
                    </Button>,
                    <Button key='backToSearch' type='ghost' onClick={() => logout({ goToLink: URL_BASE })}>
                        Zurück zur Kurssuche
                    </Button>,
                ],
            },
        },
        success: {
            resultProps: {
                status: "success",
                title: "Erfolgreich",
                subTitle: "Die Buchung wurde erfolgreich durchgeführt.",
                extra: [<StandardButton />],
            },
        },
        test: {
            resultProps: {
                status: "success",
                title: "Test",
                subTitle: "Das ist ein Test.",
                extra: [<StandardButton />],
            },
        },
        alreadyEnrolled: {
            resultProps: {
                status: "warning",
                title: "Doppelbuchung",
                subTitle: "Teilnehmer:in ist bereits in diesen Kurs gebucht.",
                extra: [<StandardButton />],
            },
        },
        courseNotFound: {
            resultProps: {
                status: "warning",
                title: "Kurs nicht gefunden",
                subTitle: "Leider haben wir den Kurs nicht gefunden. Eventuell ist dieser nicht mehr aktuell.",
                extra: [<StandardButton />],
            },
        },
        notDeletableHasBookings: {
            resultProps: {
                status: "warning",
                title: "Teilnehmer kann nicht gelöscht werden",
                subTitle: "Da dieser Teilnehmer bereits in einen Kurs gebucht ist, kann er nicht gelöscht werden.",
                extra: [<BackButton />],
            },
        },
        errorFetchingData: {
            resultProps: {
                status: "warning",
                title: "Fehler beim Laden der Daten",
                subTitle: "Es gab eine Fehler beim Abrufen der Daten. Bitte versuche es in ein paar Minuten erneut",
                extra: [
                    <StandardButton />,
                    <Button icon={<ReloadOutlined />} onClick={() => window.location.reload()}>
                        Seite neu laden
                    </Button>,
                ],
            },
        },
        unknown: {
            resultProps: {
                status: "warning",
                title: standardErrorTitle,
                subTitle: errorMsg,
                extra: [<StandardButton />],
            },
        },
    }

    let props = resultProps.unknown.resultProps

    if (!resultType) return <div>Error</div>
    if (!isOfTypeResultKeys(resultType as string)) {
        props = resultProps.unknown.resultProps
    } else {
        props = resultProps[resultType as ResultKeys]?.resultProps
    }

    return (
        <Result
            status={props.status}
            title={props.title}
            subTitle={props.subTitle}
            extra={props.extra}
            style={props.style}
        >
            {props.children}
            {customButton}
        </Result>
    )
}

export default ResultScreen
