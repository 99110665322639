import React, { FC, memo, ReactNode } from "react"
import { Spin as AntdSpin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import css from "./Spin.less"

const icon = <LoadingOutlined className={css.icon} spin />

type SpinProps = {
    children?: ReactNode
}

const Spin: FC<SpinProps> = ({ children }) => {
    return (
        <AntdSpin className={css.root} indicator={icon} delay={100}>
            {children}
        </AntdSpin>
    )
}

export default memo(Spin)
