import { Table } from "antd"
import { ColumnProps } from "antd/lib/table"
import React, { FC, memo } from "react"
import {
    Course,
    GetCourseLessonsByCourseIdQuery,
    PaymentInterval,
    useGetCourseLessonsByCourseIdQuery,
} from "../../generated/graphql"
import i18n from "../../services/i18n"
import {
    renderDateAsWeekdayTag,
    renderDateFormatted,
    renderDuration,
    renderStartEndTime,
} from "../TableColumnRenderers/TableColumnRenderers"
import css from "./CourseLessonTable.module.css"
import { sortByStartDateTime } from "../../helpers/sortingFunctions"
import { QueryItem } from "../../types"

// type CourseLessonType = Pick<CourseLesson, "id" | "startDateTime" | "endDateTime" | "locationId">

type CourseLessonTableProps = {
    courseId: Course["id"]
    totalPrice?: number
    paymentInterval?: PaymentInterval
    freePlaces?: number
}

type GetCourseLessonsByCourseIdQueryItem = QueryItem<GetCourseLessonsByCourseIdQuery["courseLessonsByCourseId"]>

type TableType = GetCourseLessonsByCourseIdQuery["courseLessonsByCourseId"]["0"]

const CourseLessonTable: FC<CourseLessonTableProps> = ({
    courseId,
    // courseLessons,
    totalPrice,
    paymentInterval,
    freePlaces,
}) => {
    const pageSize = 10
    const [currentTablePage, setCurrentTablePage] = React.useState(0)
    const { data, loading } = useGetCourseLessonsByCourseIdQuery({
        variables: { courseId },
    })

    const courseLessons: Array<GetCourseLessonsByCourseIdQueryItem> = data?.courseLessonsByCourseId ?? []

    const columns: Array<ColumnProps<TableType>> = [
        {
            title: "#",
            dataIndex: "number",
            render: (text, record, index) => index + currentTablePage * pageSize + 1,
        },
        {
            title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
            dataIndex: "dayOfTheWeek",
            align: "center",
            render: (text, record) =>
                renderDateAsWeekdayTag({
                    value: record.startDateTime,
                    style: {
                        width: "100%",
                        textAlign: "center",
                        fontWeight: 600,
                    },
                }),
        },
        {
            title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
            dataIndex: "startDateTime",
            className: css.dateColumn,
            align: "center",
            render: (text, record) => renderDateFormatted(record.startDateTime, "DD. MMM YYYY"),
            // defaultSortOrder: "descend",
            sorter: sortByStartDateTime,
            sortDirections: [],
            sortOrder: "ascend",
        },
        {
            title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
            dataIndex: "times",
            align: "center",
            render: (text, record) =>
                renderStartEndTime({
                    startTime: record.startDateTime,
                    endTime: record.endDateTime,
                }),
        },
        {
            title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
            dataIndex: "duration",
            align: "right",
            render: (text, record) =>
                renderDuration({
                    startTime: record.startDateTime,
                    endTime: record.endDateTime,
                }),
        },
    ]

    // if (freePlaces) {
    //     columns.push({
    //         title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
    //         dataIndex: "freePlaces",
    //         render: (text, record) => renderFreePlaces(freePlaces ?? 0),
    //     })
    // }

    // if (totalPrice && paymentInterval) {
    //     const totalPriceColumn = {
    //         title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
    //         dataIndex: "lessonPrice",
    //         render: () => (paymentInterval === PaymentInterval.PerLesson ? renderAmount(pricePerLesson) : null),
    //     }

    //     columns.splice(3, 0, totalPriceColumn)
    // }

    return (
        <div className={css.root}>
            <Table
                className={css.courseLessonTable}
                id='courseLessonTable'
                loading={loading}
                showHeader={false}
                size='small'
                rowKey='id'
                columns={columns}
                dataSource={courseLessons}
                pagination={{
                    pageSize,
                    hideOnSinglePage: true,
                    position: ["bottomCenter"],
                    size: "small",
                    showSizeChanger: false,
                    showQuickJumper: false,
                    showTotal: () => null,
                    onChange: (page) => {
                        window.scrollTo({ top: 0, behavior: "smooth" })
                        console.log("page:::", page)
                        setCurrentTablePage(page - 1)
                    },
                }}
                rowClassName={css.extraSmallRow}
            />
        </div>
    )
}

export default memo(CourseLessonTable)
