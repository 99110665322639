import { blue } from "@ant-design/colors"
import { DownCircleTwoTone, PlusCircleOutlined, RightCircleFilled, SyncOutlined } from "@ant-design/icons"
import { Button, Collapse, message, Space } from "antd"
import classNames from "classnames"
import moment from "moment"
import React, { FC, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {
    Attendee,
    Booker,
    BookerAttendeeInput,
    GetUpdatedBookerDocument,
    NewBookingInput,
    useAddBookerAsAttendeeMutation,
} from "../../../../generated/graphql"
import { handleGenderText } from "../../../../helpers/handleGenderText"
import { useMediaSize } from "../../../../layouts/Main/GetWindowDimensions/GetWindowDimensions"
import { URL_RESULTS } from "../../../../models/url"
import { margin } from "../../../../styles/layout"
import CreateEditAttendee from "../../../../views/CreateEditAttendee/CreateEditAttendee"
import PanelCard from "../../../../views/PanelCard/PanelCard"
import ResultScreen from "../../../../views/ResultScreens/ResultScreen"
import SelectAttendeeTable from "../../../../views/SelectAttendeeTable/SelectAttendeeTable"
import SummarySection from "../../../../views/SummarySection/SummarySection"
import { NewBookingInputKeys } from "../../BookingSummary/BookingSummary"
import css from "../StepSummary2.module.css"
import AttendeeAlert from "./AttendeeAlert"
import classnames from "classnames"

const { Panel } = Collapse

type SummaryLeftProps = {
    booker: Booker
    loadingBooker: boolean
    attendee?: Attendee
    bookingData?: NewBookingInput
    updateBookingData: (key: NewBookingInputKeys, value: any) => void
    // updateBookerData: (updatedBookerData: Booker) => void
    refetchBooker: () => void
    attendeeAgeDoesFitCourse: (arg0: boolean) => void
    creatingAttendee: (arg0: boolean) => void
    showAlert?: boolean
}

type SelectedAttendeeProps = { attendee: BookerAttendeeInput }

export const SelectedAttendee: FC<SelectedAttendeeProps> = ({ attendee }) => {
    return (
        <>
            <div className={css.extraBig}>
                <span className={css.primary}>{attendee.firstname}</span>
                <span className={css.secondary}>{attendee.lastname}</span>
            </div>
            <div className={css.subTitle}>Geburtsdatum {moment(attendee.birthday).format("DD.MM.YY")}</div>
        </>
    )
}

const SummaryLeft: FC<SummaryLeftProps> = ({
    booker,
    loadingBooker,
    attendee,
    bookingData,
    updateBookingData,
    // updateBookerData,
    refetchBooker,
    attendeeAgeDoesFitCourse,
    creatingAttendee,
    showAlert,
}) => {
    const history = useHistory()
    const mediaSize = useMediaSize()
    const { media } = mediaSize
    const [createEditAttendee, setCreateEditAttendee] = useState(false)
    const [editAttendeeMode, setEditAttendeeMode] = useState(false)
    const [attendeeToEdit, setAttendeeToEdit] = useState<BookerAttendeeInput>()
    const [selectedAttendeeId, setSelectedAttendeeId] = useState<string>()
    const labelStyle = { gridColumn: "span 1" }
    const entryStyle = {
        gridColumn: "span 2",
        margin: "0 5px 0 0",
    }

    const bookerHasAttendees = Boolean(booker.attendees?.length && booker.attendees.length > 0)
    const attendeeIsSelected = Boolean(selectedAttendeeId !== undefined)

    // HANDLE THE CASE WHEN NO ATTENDEE IS SELECTED
    useEffect(() => {
        if (booker.attendees?.length && booker.attendees?.length > 0 && selectedAttendeeId === undefined) {
            setSelectedAttendeeId(booker.attendees[0].id)
            updateBookingData("attendeeId", booker.attendees[0].id)
        }
    }, [booker.attendees, selectedAttendeeId, updateBookingData])

    useEffect(() => {
        creatingAttendee(createEditAttendee)
    }, [createEditAttendee, creatingAttendee])

    const handleEditAttendee = (attendee: BookerAttendeeInput) => {
        // console.log("editAttendee:", attendee)
        setAttendeeToEdit(attendee)
        setEditAttendeeMode(true)
        setCreateEditAttendee(true)
    }

    const handleOk = (data: Pick<BookerAttendeeInput, "id" | "firstname">) => {
        console.log("handleOk")
        console.log("data: ", data)
        setEditAttendeeMode(false)
        setAttendeeToEdit(undefined)
        setCreateEditAttendee(false)
        setSelectedAttendeeId(data.id)
        updateBookingData("attendeeId", data.id)
        message.success(`Teilnehmer ${data.firstname} wurde gespeichert`)
    }

    const handleCancel = () => {
        setEditAttendeeMode(false)
        setAttendeeToEdit(undefined)
        setCreateEditAttendee(false)
        message.success("Änderungen nicht gespeichert")
    }

    const Booker = () => {
        return (
            <>
                {/* <div className={css.extraBig} style={{ overflowWrap: "break-word", maxWidth: "100%" }}>
                    <span className={css.primary}>{booker.firstname}</span>
                    <span className={css.secondary}>{booker.lastname}</span>
                </div> */}
                <PanelCard
                    title={"Anschrift"}
                    columns={1}
                    style={{
                        fontWeight: 500,
                        borderColor: "lightgrey",
                    }}
                    smallTitle
                    gapAfter
                >
                    <div>{booker.street + " " + booker.houseNumber}</div>
                    {/* <div>{attendee.booker?.street + " " + attendee.booker?.houseNumber}</div> */}
                    <div>{booker.postCode + " " + booker.city}</div>
                </PanelCard>
                <PanelCard
                    title={"Kontaktdaten"}
                    columns={3}
                    style={{
                        fontWeight: 500,
                        borderColor: "lightgrey",
                    }}
                    smallTitle
                >
                    <div style={labelStyle}>{"Telefon"}</div>
                    <div style={{ overflowWrap: "break-word", ...entryStyle }}>{booker.phoneNumber}</div>
                    <div style={labelStyle}>{"Mobil"}</div>
                    <div style={{ overflowWrap: "break-word", ...entryStyle }}>{booker.mobileNumber}</div>
                    <div style={labelStyle}>{"Email"}</div>
                    <div style={{ overflowWrap: "break-word", ...entryStyle }}>{booker.email}</div>
                </PanelCard>
            </>
        )
    }

    const BookerAsAttendee = () => {
        const [addBookerAsAttendee, { loading: addingAttendee }] = useAddBookerAsAttendeeMutation()

        const setBookerAsAttendee = async () => {
            try {
                addBookerAsAttendee({
                    variables: { bookerId: booker.id },
                    refetchQueries: [
                        { query: GetUpdatedBookerDocument, variables: { skip: !booker?.id, id: booker?.id ?? "" } },
                    ],
                }).then((res) => {
                    if (res.data?.addBookerAsAttendee?.id) {
                        const newAttendeeData = res.data.addBookerAsAttendee
                        const createdAttendee = newAttendeeData.attendees?.find(
                            (a) => a.customerId === newAttendeeData.customerId
                        )
                        if (createdAttendee) {
                            handleOk({ id: createdAttendee.id, firstname: createdAttendee.customer.firstname })
                        }
                    }
                })
            } catch (error: any) {
                console.log("setBookerAsAttendee error: ", error)
                handleCancel()
                console.log("setBookerAsAttendee error: ", error)
                const rawErrorMessage = JSON.stringify(error.graphQLErrors[0].message, null, 4)
                if (rawErrorMessage.includes("Access token not valid")) {
                    history.push(URL_RESULTS.replace(":resultType", "loggedOut"))
                } else {
                    if (rawErrorMessage) {
                        return <ResultScreen resultType='unknown' errorMsg={`[1015]: ${rawErrorMessage}`} />
                    } else {
                        return <ResultScreen resultType='unknown' errorMsg={`Fehler: [1015]`} />
                    }
                }
            }
        }

        const attendeeIds = booker.attendees?.map((attendee) => attendee.id)
        const bookerId = booker.id
        const bookerAlreadyAttendee = attendeeIds?.includes(bookerId)

        if (bookerAlreadyAttendee) {
            return null
        }
        return (
            <>
                <Button
                    type='primary'
                    ghost
                    block
                    icon={<PlusCircleOutlined />}
                    loading={addingAttendee}
                    onClick={() => setBookerAsAttendee()}
                    style={{ marginTop: margin.s }}
                >
                    Bucher als Teilnehmer verwenden
                </Button>
            </>
        )
    }

    const attendeesFirstName = booker.attendees
        ?.filter((attendee) => {
            return attendee.id === bookingData?.attendeeId
        })
        .map((attendee) => `${attendee.firstname}`)[0]

    const attendeesLastName = booker.attendees
        ?.filter((attendee) => {
            return attendee.id === bookingData?.attendeeId
        })
        .map((attendee) => `${attendee.lastname}`)[0]

    const renderAttendee = () => {
        return (
            <div style={{ paddingBottom: margin.s }}>
                <>
                    {createEditAttendee ? (
                        <>
                            {attendeeToEdit ? (
                                <SummarySection
                                    title={editAttendeeMode ? "Teilnehmer bearbeiten" : "Teilnehmer anlegen"}
                                    style={{ marginBottom: 0 }}
                                    content={<SelectedAttendee attendee={attendeeToEdit} />}
                                />
                            ) : (
                                <SummarySection title={"Teilnehmer anlegen"} style={{ marginBottom: 0 }} />
                            )}
                            <SummarySection
                                style={{ marginBottom: 0 }}
                                content={
                                    <PanelCard
                                        columns={1}
                                        style={{
                                            fontWeight: 500,
                                            borderColor: "lightgrey",
                                            display: "flex",
                                            flexDirection: "column",
                                            marginTop: 0,
                                            paddingTop: 0,
                                        }}
                                        smallTitle
                                        gapAfter
                                    >
                                        <CreateEditAttendee
                                            editMode={editAttendeeMode}
                                            attendeeToEdit={attendeeToEdit}
                                            onOk={handleOk}
                                            onCancel={handleCancel}
                                            booker={booker}
                                            courseId={bookingData?.courseId ?? undefined}
                                            showAlert={showAlert}
                                        />
                                    </PanelCard>
                                }
                            />
                        </>
                    ) : (
                        <>
                            <PanelCard
                                title={
                                    bookerHasAttendees ? (
                                        <Space style={{ justifyContent: "space-between" }}>
                                            <span>Teilnehmer wählen</span>
                                            <Button
                                                type='text'
                                                // onClick={() => updateBookerData(booker)}
                                                onClick={() => refetchBooker()}
                                                icon={<SyncOutlined style={{ color: blue[5] }} />}
                                            ></Button>
                                        </Space>
                                    ) : null
                                }
                                columns={1}
                                style={{
                                    fontWeight: 500,
                                    borderColor: "lightgrey",
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: bookerHasAttendees ? undefined : 0,
                                    margin: bookerHasAttendees ? undefined : 0,
                                }}
                                smallTitle
                                gapAfter
                            >
                                {bookerHasAttendees ? (
                                    <SelectAttendeeTable
                                        booker={booker}
                                        loadingBooker={loadingBooker}
                                        refetchBooker={refetchBooker}
                                        attendees={booker.attendees}
                                        selectedAttendeeId={selectedAttendeeId}
                                        setSelectedAttendee={(attendeeId) => setSelectedAttendeeId(attendeeId)}
                                        updateBookingData={(key, value) => updateBookingData(key, value)}
                                        editAttendee={(attendee) => handleEditAttendee(attendee)}
                                        courseId={bookingData?.courseId ?? undefined}
                                        attendeeAgeDoesFitCourse={attendeeAgeDoesFitCourse}
                                    />
                                ) : null}
                                <Button
                                    type='primary'
                                    ghost
                                    block
                                    icon={<PlusCircleOutlined />}
                                    onClick={() => setCreateEditAttendee(true)}
                                    style={{ marginTop: bookerHasAttendees ? margin.s : 0 }}
                                >
                                    Teilnehmer neu anlegen
                                </Button>
                                <BookerAsAttendee />
                            </PanelCard>
                        </>
                    )}
                </>
            </div>
        )
    }

    return (
        <>
            <Collapse
                ghost
                expandIconPosition='end'
                // defaultActiveKey={["attendee"]}
                defaultActiveKey={media === "xs" ? ["attendee"] : ["attendee", "booker"]}
                className={css.collapse}
                expandIcon={(panelProps) => {
                    return (
                        <div className={css.collapseIcon}>
                            {panelProps.isActive ? (
                                <DownCircleTwoTone className={css.collapseIcon} style={{ marginTop: 20 }} />
                            ) : (
                                <RightCircleFilled
                                    style={{ marginTop: 20, color: blue[5] }}
                                    className={css.collapseIcon}
                                />
                            )}
                        </div>
                    )
                }}
            >
                <Panel
                    className={
                        !bookerHasAttendees || !attendeeIsSelected
                            ? classnames(css.collapsePanel, css.alertPanel)
                            : css.collapsePanel
                    }
                    collapsible='disabled'
                    showArrow={false}
                    // showArrow={bookerHasAttendees && attendeeIsSelected ? true : false}
                    key='attendee'
                    header={
                        !bookerHasAttendees || !attendeeIsSelected ? (
                            <AttendeeAlert selectedAttendeeId={selectedAttendeeId} attendees={booker.attendees} />
                        ) : (
                            <>
                                <div className={classNames(css.header, css.underLine)}>
                                    {handleGenderText({ type: "attendee", gender: attendee?.gender })}
                                </div>
                                <div className={css.extraBig}>
                                    <span className={css.primary}>{attendeesFirstName}</span>
                                    <span className={css.secondary}>{attendeesLastName}</span>
                                </div>
                                <div className={css.subTitle}>
                                    Geburtsdatum {moment(attendee?.birthday).format("DD.MM.YY")}
                                </div>
                            </>
                        )
                    }
                >
                    <div className={css.innerPanelContainer}>
                        <SummarySection
                            // title={handleGenderText({ type: "attendee", gender: attendee?.gender })}
                            style={{ marginBottom: 0, padding: 0, paddingBottom: 0 }}
                            content={renderAttendee()}
                        />
                    </div>
                </Panel>
                <Panel
                    className={css.collapsePanel}
                    key='booker'
                    // extra={<Button type='link' icon={<InfoCircleTwoTone />} />}
                    header={
                        <>
                            <div className={classNames(css.header, css.underLine)}>
                                {handleGenderText({ type: "booker", gender: booker.gender ?? undefined })}
                            </div>
                            <div className={css.extraBig} style={{ overflowWrap: "break-word", maxWidth: "100%" }}>
                                <span className={css.primary}>{booker.firstname}</span>
                                <span className={css.secondary}>{booker.lastname}</span>
                            </div>
                        </>
                    }
                >
                    <div className={css.innerPanelContainer}>
                        <SummarySection
                            title={handleGenderText({ type: "booker", gender: booker.gender ?? undefined })}
                            style={{ marginBottom: 0 }}
                            content={<Booker />}
                        />
                    </div>
                </Panel>
            </Collapse>
        </>
    )
}

export default SummaryLeft
