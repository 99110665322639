import { Alert, Button, Collapse, Select, Spin } from "antd"
import FormItem from "antd/lib/form/FormItem"
import TextArea from "antd/lib/input/TextArea"
import React, { FC, memo, useEffect, useState } from "react"
import {
    GetCourseDetailsByIdPublicQuery,
    GetUpdatedBookerQuery,
    LogLevelType,
    NewBookingInput,
    PaymentInterval,
    SettingEndUserAppQuery,
    useGetCompanySettingsPaymentmethodsQuery,
} from "../../../../generated/graphql"
import { getPaymentMethodType, PaymentMethodType } from "../../../../helpers/getPaymentMethodType"
import { margin } from "../../../../styles/layout"
import CourseLessonTable from "../../../../views/CourseLessonTable/CourseLessonTable"
import PaymentMethodTable, { CompanyMethods } from "../../../../views/PaymentMethodTable/PaymentMethodTable"
import SummarySection from "../../../../views/SummarySection/SummarySection"
import { ModeType, NewBookingInputKeys } from "../../BookingSummary/BookingSummary"
import css from "../StepSummary2.module.css"
import { v4 } from "uuid"
import { useHistory } from "react-router-dom"
import { URL_RESULTS } from "../../../../models/url"
import { DownCircleTwoTone, ReloadOutlined, RightCircleFilled } from "@ant-design/icons"
import { blue } from "@ant-design/colors"
import { useMediaSize } from "../../../../layouts/Main/GetWindowDimensions/GetWindowDimensions"
import OptionalInputFields from "./OptionalInputFields"
import LayoutForm from "../../../../views/LayoutForm/LayoutForm"
import ParticipationPrerequisites from "./ParticipationPrerequisites/ParticipationPrerequisites"
import BookingPricePreview from "../../BookingSummary/BookingPricePreview/BookingPricePreview"
import ResultScreen from "../../../../views/ResultScreens/ResultScreen"
import useLogger from "../../../../helpers/useLogger"

export type CompanyMethodsWithIds = { method: Extract<keyof CompanyMethods, string>; active: boolean; id: string }

type SummaryRightProps = {
    booker: GetUpdatedBookerQuery["booker"]
    // course: Course
    course: GetCourseDetailsByIdPublicQuery["courseByIdPublic"]
    mode: ModeType
    updateBookingData: (key: NewBookingInputKeys, value: any) => void
    bookingData?: NewBookingInput
    liftSepaDebitData: (isValid: boolean) => void
    setCheckedOptions: (checkedOptions: { [id: string]: Array<string> }) => void
    checkedOptions: { [id: string]: Array<string> }
    setRequermentGroupsMet: (requermentGroupsMet: { [id: string]: boolean }) => void
    requermentGroupsMet: { [id: string]: boolean }
    showAlert?: boolean
    appSettings?: SettingEndUserAppQuery["settingEndUserAppPublic"]
}

const SummaryRight: FC<SummaryRightProps> = ({
    booker,
    course,
    mode,
    updateBookingData,
    bookingData,
    liftSepaDebitData,
    setCheckedOptions,
    checkedOptions,
    setRequermentGroupsMet,
    requermentGroupsMet,
    showAlert,
    appSettings,
}) => {
    const mediaSize = useMediaSize()
    const { media } = mediaSize
    const history = useHistory()
    const logger = useLogger()

    if (!booker) {
        console.log("SummaryRight: booker not found")
        history.push(URL_RESULTS.replace(":resultType", "loggedOut"))
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [methodType, setMethodType] = useState<PaymentMethodType>()
    const paymentMethod = booker?.paymentMethods?.find((pm) => pm.id === bookingData?.paymentMethodId)

    useEffect(() => {
        if (paymentMethod) {
            if (getPaymentMethodType(paymentMethod) !== "sepaDebit") {
                liftSepaDebitData(true)
            }
            setMethodType(getPaymentMethodType(paymentMethod))
        }
    }, [liftSepaDebitData, paymentMethod])

    const {
        data: companyPaymentMethods,
        error: companySettingsError,
        loading: companySettingsLoading,
        refetch: companySettingRefetch,
    } = useGetCompanySettingsPaymentmethodsQuery({ fetchPolicy: "no-cache" }) // no cache because we want to refetch the data if there is an error
    const companyMethods: CompanyMethods | undefined = companyPaymentMethods?.settings

    const [companyMethodsWithIds, setCompanyMethodsWithIds] = useState<Array<CompanyMethodsWithIds>>([])

    useEffect(() => {
        // console.log("companyMethods", companyMethods)
        if (companyMethods) {
            const updatedCompanyMethodsWithIds: Array<CompanyMethodsWithIds> = []
            Object.entries(companyMethods).forEach(([key, value]) => {
                const typedKey = key as keyof Partial<CompanyMethods>
                const methodWithId: CompanyMethodsWithIds = {
                    method: typedKey,
                    active: Boolean(value),
                    id: v4(),
                }

                updatedCompanyMethodsWithIds.push(methodWithId)
            })

            setCompanyMethodsWithIds(updatedCompanyMethodsWithIds)
        }
    }, [companyMethods /* companySettingsError */])

    // REFETCH COMPANY SETTINGS IF THERE IS AN ERROR -----------------------------------------------------------------------------------------------------------

    const secondsToRetry = 10
    const maxRetryCount = 5
    const [retryCount, setRetryCount] = useState(0)
    const [countdown, setCountdown] = useState(0)

    useEffect(() => {
        if (retryCount < maxRetryCount && companySettingsError) {
            const timeout = setTimeout(() => {
                console.log("refetching companySettings")
                companySettingRefetch()
                setRetryCount((prevCount) => prevCount + 1)
            }, secondsToRetry * 1000)

            let remainingTime = secondsToRetry
            setCountdown(remainingTime)
            setCountdown(remainingTime)

            // Update the countdown every second
            const countdownInterval = setInterval(() => {
                remainingTime--
                setCountdown(remainingTime)
            }, 1000)

            // Clear the timeout and countdown interval on component unmount or retry limit reached
            return () => {
                clearTimeout(timeout)
                clearInterval(countdownInterval)
            }
        }
    }, [companySettingRefetch, companySettingsError, retryCount])

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------

    const PaymentMethodSection = () => {
        if (!companyMethodsWithIds || companyMethodsWithIds.length === 0) {
            if (retryCount === maxRetryCount) {
                if (companySettingsError) {
                    logger({
                        logLevel: LogLevelType.Error,
                        msg: companySettingsError.message,
                        additionalInfo: { stack: companySettingsError.stack },
                    })
                }
                return (
                    <>
                        <ResultScreen resultType='errorFetchingData' errorMsg={companySettingsError?.message} />
                    </>
                )
            }

            return (
                <Alert
                    style={{ textAlign: "center" }}
                    message={
                        <>
                            {companySettingsLoading || (countdown === 1 && <Spin />)}
                            <h3>Fehler beim Laden der Unternehmensdaten</h3>
                            <div>{`Wir versuchen es erneut in ${countdown} Sekunden`}</div>
                            <Button type='link' icon={<ReloadOutlined />} onClick={() => window.location.reload()}>
                                Seite jetzt neu laden
                            </Button>
                        </>
                    }
                    type='error'
                />
            )
        }
        if (!booker.paymentMethods) {
            return <>"PaymentMethods not found"</>
        }

        const handlePamentMethodChange = (e: { id: string }) => {
            updateBookingData("paymentMethodId", e.id)
        }

        // return <h3>{`To be done ==> ${attendee.firstname} ${attendee.lastname}`}</h3>
        return (
            <PaymentMethodTable
                paymentMethods={booker.paymentMethods}
                paymentMethodId={bookingData?.paymentMethodId ?? ""}
                setSelectedPayment={(e: { id: string }) => handlePamentMethodChange(e)}
                showHeader={false}
                booker={booker}
                companyMethodsWithIds={companyMethodsWithIds}
                liftSepaDebitData={liftSepaDebitData}
            />
        )
    }

    const notesSection = () => {
        // const [noticeBooker, setNoticeBooker] = useState<string>(bookingData?.noticeBooker ?? "")

        const { Option } = Select

        return (
            <LayoutForm columns={1}>
                <FormItem label={"Schreibe uns eine Nachricht"}>
                    {/* <TextArea rows={4} maxLength={256} allowClear onChange={(e) => setNoticeBooker(e.target.value)} /> */}
                    <TextArea
                        rows={4}
                        maxLength={256}
                        allowClear
                        value={bookingData?.noticeBooker ?? ""}
                        onChange={(e) => updateBookingData("noticeBooker", e.target.value)}
                        onBlur={(e) => updateBookingData("noticeBooker", e.target.value)}
                    />
                </FormItem>
                <FormItem label={"Wie sind Sie auf uns aufmerksam geworden?"}>
                    <Select
                        style={{ width: "100%" }}
                        value={bookingData?.foundUs}
                        onChange={(value) => updateBookingData("foundUs", value)}
                    >
                        <Option key='keine Angabe' value='keine Angabe'>
                            keine Angabe
                        </Option>
                        <Option key='Facebook' value='Facebook'>
                            Facebook
                        </Option>
                        <Option key='Arzt' value='Arzt'>
                            Arzt
                        </Option>
                        <Option key='Instagram' value='Instagram'>
                            Instagram
                        </Option>
                        <Option key='Internetrecherche' value='Internetrecherche'>
                            Internetrecherche
                        </Option>
                        <Option key='Plakat / Flyer' value='Plakat / Flyer'>
                            Plakat / Flyer
                        </Option>
                    </Select>
                </FormItem>
            </LayoutForm>
        )
    }

    if (course === undefined) {
        return <div>No course found</div>
    }

    const sectionGap = margin.s

    return (
        <>
            <Collapse
                ghost
                expandIconPosition='end'
                defaultActiveKey={media === "xs" ? [] : ["courseDetails"]}
                className={css.collapse}
                expandIcon={(panelProps) => {
                    return (
                        <div className={css.collapseIcon}>
                            {panelProps.isActive ? (
                                <DownCircleTwoTone className={css.collapseIcon} /* style={{ marginTop: 20 }} */ />
                            ) : (
                                <RightCircleFilled
                                    style={{ /* marginTop: 20, */ color: blue[5] }}
                                    className={css.collapseIcon}
                                />
                            )}
                        </div>
                    )
                }}
            >
                <SummarySection
                    style={{ paddingTop: sectionGap }}
                    title={"KursInformationen"}
                    content={
                        <h1
                            style={{
                                fontWeight: 700,
                                color: "rgba(0,0,0,.65)",
                                margin: 0,
                            }}
                        >
                            {course.courseType.name}
                        </h1>
                    }
                    extra={course.prefixedCourseNumber}
                />
                <SummarySection
                    style={{ paddingTop: sectionGap }}
                    title={"Kurseinheiten"}
                    content={
                        <>
                            <CourseLessonTable
                                courseId={course.id}
                                totalPrice={course.coursePrice[0].grossPrice}
                                paymentInterval={course.paymentInterval ?? PaymentInterval.PerLesson}
                            />
                        </>
                    }
                />
                {booker.attendees && booker.attendees.length > 0 && (
                    <SummarySection
                        style={{ paddingTop: sectionGap }}
                        title={"Bezahlmethode"}
                        content={<PaymentMethodSection />}
                    />
                )}
                {course && bookingData && appSettings && (
                    <SummarySection
                        style={{ paddingTop: sectionGap }}
                        title='Preisübersicht'
                        content={
                            <BookingPricePreview
                                course={course}
                                updateBookingData={updateBookingData}
                                bookingData={bookingData}
                            />
                        }
                    />
                )}
                {booker && (
                    <>
                        <ParticipationPrerequisites
                            courseTypeId={course.courseType.id}
                            setCheckedOptions={setCheckedOptions}
                            checkedOptions={checkedOptions}
                            setRequermentGroupsMet={setRequermentGroupsMet}
                            requermentGroupsMet={requermentGroupsMet}
                            sectionGap={sectionGap}
                        />
                        <SummarySection style={{ paddingTop: sectionGap }} title='Notizen' content={notesSection()} />
                        <SummarySection
                            style={{ paddingTop: sectionGap }}
                            content={
                                <OptionalInputFields
                                    booker={booker}
                                    bookingData={bookingData}
                                    updateBookingData={updateBookingData}
                                    appSettings={appSettings}
                                    showAlert={showAlert}
                                />
                            }
                            title='Weitere Angaben'
                        />
                    </>
                )}
            </Collapse>
        </>
    )
}

export default memo(SummaryRight)
