import React, { CSSProperties, FC, memo } from "react"
import { GetCompanyQuery, NewBookingInput, OptionalInputFieldsInput } from "../../../../../generated/graphql"
import FormItem from "antd/lib/form/FormItem"
import { Button, Checkbox } from "antd"
import css from "../../StepSummary2.module.css"
import { NewBookingInputKeys } from "../../StepSummary2"
import classnames from "classnames"
import { LinkOutlined } from "@ant-design/icons"
import { blue } from "@ant-design/colors"

type PrivacyStatementInputProps = {
    field: OptionalInputFieldsInput
    bookingData?: NewBookingInput
    updateBookingData: (key: NewBookingInputKeys, value: any) => void
    style?: CSSProperties
    showAlert?: boolean
    companySettings?: GetCompanyQuery["companyPublic"]
}

const PrivacyStatementInput: FC<PrivacyStatementInputProps> = ({
    field,
    bookingData,
    updateBookingData,
    style,
    showAlert,
    companySettings,
}) => {
    const updatePrivacyStatementConfirmation = (checked: boolean) => {
        updateBookingData("privacyStatementConfirmed", checked)
    }

    const PrivacyStatementLink = ({ label, icon }: { label?: string; icon?: boolean }) => {
        if (!companySettings?.privacyStatement) return null
        return (
            <Button style={{ paddingLeft: 0, fontWeight: 500 }} type='link' size='small'>
                <a href={companySettings.privacyStatement} target='_blank' rel='noopener noreferrer'>
                    {label ?? label}
                    {icon && <LinkOutlined style={{ color: blue[5], marginLeft: 3, fontSize: "small" }} />}
                </a>
            </Button>
        )
    }

    const alertAndNotChecked = showAlert && !Boolean(bookingData?.privacyStatementConfirmed)

    return (
        <>
            <FormItem style={style} key={field.name}>
                <Checkbox
                    className={alertAndNotChecked ? classnames(css.inputLabel, css.alert) : css.inputLabel}
                    onChange={(e) => updatePrivacyStatementConfirmation(e.target.checked)}
                    checked={Boolean(bookingData?.privacyStatementConfirmed)}
                >
                    <PrivacyStatementLink label={field.label ?? undefined} />
                </Checkbox>
                <div className={css.inputDescription}>
                    {field.description}
                    <PrivacyStatementLink icon />
                </div>
            </FormItem>
        </>
    )
}

export default memo(PrivacyStatementInput)
