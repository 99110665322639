import gql from "graphql-tag"

export const GET_LOCAL_STATE = gql`
    query getLocalState {
        LocalState @client {
            createCourse {
                locationId
            }
            bookAttendees {
                selectedAttendee {
                    id
                    firstname
                    bookedCourseIds
                }
                selectedCourseId
            }
        }
    }
`

export const GET_INSTRUCTORS_BY_ID = gql`
    query getInstructorsById($ids: [ID!]!) {
        findInstructorByIds(instructorIds: $ids) {
            id
            firstname
            lastname
        }
    }
`

export const REMOVE_COURSE_BY_ID = gql`
    mutation removeCourse($id: ID!) {
        removeCourse(id: $id)
    }
`

export const BOOK_ATTENDEE_LOCAL_STATE = gql`
    query bookAttendeeGetLocalState {
        LocalState @client {
            bookAttendees {
                selectedAttendee {
                    id
                    firstname
                    bookedCourseIds
                }
                selectedCourseId
            }
        }
    }
`

export const BOOK_ATTENDEE_GET_SELECTED_ATTENDEE = gql`
    query bookAttendeeGetSelectedAttendee {
        LocalState @client {
            bookAttendees {
                selectedAttendee {
                    id
                    firstname
                    bookedCourseIds
                }
            }
        }
    }
`

///// export const BookAttendeeGetSelectedAttendeeDocument = gql`
/////     query bookAttendeeGetSelectedAttendee {
/////         LocalState @client {
/////             bookAttendees {
/////                 selectedAttendee {
/////                     id
/////                     firstname
/////                     bookedCourseIds
/////                 }
/////             }
/////         }
/////     }
///// `;

// export const BookPaymentMethodGetSelectedPaymentMethodDocument = gql`
//     query bookPaymentMethodGetSelectedPaymentMethod {
//         LocalState @client {
//             bookPaymentMethods {
//                 selectedPaymentMethod {
//                     id
//                     type
//                     bookedCourseIds
//                 }
//             }
//         }
//     }
// `;

export const BOOK_PAYMENTMETHOD_GET_SELECTED_PAYMENTMETHOD = gql`
    query bookPaymentMethodGetSelectedPaymentMethod {
        LocalState @client {
            bookPaymentMethods {
                selectedPaymentMethod {
                    id
                    type
                    bookedCourseIds
                }
            }
        }
    }
`

export const BOOK_PAYMENTMETHOD_SET_SELECTED_PAYMENTMETHOD = gql`
    mutation bookPaymentMethodSetSelectedPaymentMethod($id: ID, $type: [String], $bookedCourseIds: [String]) {
        bookPaymentMethodSetSelectedPaymentMethod(id: $id, type: $type, bookedCourseIds: $bookedCourseIds) @client
    }
`

export const BOOK_ATTENDEE_GET_SELECTED_COURSE = gql`
    query bookAttendeeGetSelectedCourse {
        LocalState @client {
            bookAttendees {
                selectedCourseId
            }
        }
    }
`

export const BOOK_ATTENDEE_SET_SELECTED_ATTENDEE = gql`
    mutation bookAttendeeSetSelectedAttendee($id: ID, $firstname: String, $bookedCourseIds: [String]) {
        bookAttendeeSetSelectedAttendee(id: $id, firstname: $firstname, bookedCourseIds: $bookedCourseIds) @client
    }
`

export const BOOK_ATTENDEE_SET_SELECTED_COURSE = gql`
    mutation bookAttendeeSetSelectedCourse($id: ID) {
        bookAttendeeSetSelectedCourse(id: $id) @client
    }
`

export const UPDATE_LOCAL_LOCATION_ID = gql`
    mutation updateLocalLocationId($id: ID) {
        updateLocalLocationId(id: $id) @client
    }
`

export const GET_COURSE_LIST_WITH_PAGINATION_PUBIC = gql`
    query getCourseListWithPaginationPublic(
        $filters: CourseListFilters
        $filterExpression: FilterExpressionInput
        $options: ArrayDataOptions
    ) {
        coursesWithPaginationPublic(filters: $filters, filterExpression: $filterExpression, options: $options) {
            existsMore
            total
            items {
                id
                courseNumber
                prefixedCourseNumber
                freePlaces
                maxAttendees
                courseType {
                    id
                    name
                    description
                    color
                    minAge
                    maxAge
                }
                startDateTime
                endDateTime
                location {
                    id
                    city
                    name
                }
                venue {
                    id
                    name
                }
                instructors {
                    id
                    firstname
                    lastname
                }
                coursePrice {
                    id
                    grossPrice
                    netPrice
                    vat
                    discount
                    discountUnit
                    isSpecial
                    includedEntranceFee
                }
                showInWeb
                websiteInfo
                paymentInterval
                monthlySchedule
                paymentType
                lessonCount
                installmentRates {
                    id
                    installmentAmount
                }
                lessonCount
            }
        }
    }
`

export const GET_COURSES = gql`
    query GetCourses {
        courses {
            id
            courseNumber
            prefixedCourseNumber
            bookingCount
            billedBookingCount
            freePlaces
            settledBookingCount
            courseType {
                id
                name
                description
                color
            }
            startDateTime
            endDateTime
            location {
                id
                city
                name
            }
            venue {
                id
                name
            }
            annotation
            lessons {
                id
                location {
                    id
                    city
                }
                venue {
                    id
                    name
                }
                startDateTime
                endDateTime
                instructors {
                    id
                    firstname
                    lastname
                }
                bookings {
                    id
                    attendee {
                        id
                        firstname
                        lastname
                        birthday
                    }
                }
            }
            instructors {
                id
                firstname
                lastname
            }
            maxAttendees
            coursePrice {
                id
                grossPrice
                netPrice
                vat
                discount
                discountUnit
                isSpecial
            }
            paymentInterval
            allowMonthlyPayment
        }
    }
`

export const GET_COURSES_PUBLIC = gql`
    query GetCoursesPublic(
        $filterExpression: FilterExpressionInput
        $ids: [String!]
        $instructorId: ID
        $dayFilter: DayFilter
        $courseCategoryId: ID
        $showOnlyInWebActive: Boolean
    ) {
        coursesPublic(
            filterExpression: $filterExpression
            ids: $ids
            dayFilter: $dayFilter
            instructorId: $instructorId
            courseCategoryId: $courseCategoryId
            showOnlyInWebActive: $showOnlyInWebActive
        ) {
            id
            courseNumber
            prefixedCourseNumber
            bookingCount
            #billedBookingCount
            freePlaces
            #settledBookingCount
            courseType {
                id
                name
                description
                color
                category {
                    id
                    name
                }
                minAge
                maxAge
            }
            startDateTime
            endDateTime
            location {
                id
                city
                name
            }
            venue {
                id
                name
            }
            #annotation
            lessons {
                id
                #location {
                #    id
                #    city
                #}
                #venue {
                #    id
                #    name
                #}
                startDateTime
                endDateTime
                #instructors {
                #    id
                #    firstname
                #    lastname
                #}
                #bookings {
                #    id
                #    attendee {
                #        id
                #        firstname
                #        lastname
                #        birthday
                #    }
                #}
            }
            instructors {
                id
                firstname
                lastname
            }
            maxAttendees
            coursePrice {
                id
                grossPrice
                netPrice
                vat
                discount
                discountUnit
                isSpecial
                includedEntranceFee
            }
            #paymentInterval
            #allowMonthlyPayment
            showInWeb
            #isActive
            websiteInfo
        }
    }
`

export const GET_COURSE_BY_ID = gql`
    query getCourseById($id: String!) {
        courseById(id: $id) {
            id
            courseType {
                id
                name
                description
            }
            courseNumber
            prefixedCourseNumber
            location {
                id
                city
                venues {
                    id
                    name
                }
            }
            venue {
                id
                name
            }
            instructors {
                id
                firstname
                lastname
            }
            coursePrice {
                id
                grossPrice
                vat
                discount
                discountUnit
                isSpecial
            }
            paymentInterval
            minAttendees
            maxAttendees
            startDateTime
            endDateTime
            cycle
            annotation
            websiteInfo
            allowMonthlyPayment
            startDateTime
            lessons {
                id
                locationId
                location {
                    id
                    city
                }
                venueId
                venue {
                    id
                    name
                }
                startDateTime
                endDateTime
                instructors {
                    id
                    firstname
                    lastname
                }
            }
        }
    }
`

export const GET_COURSE_BY_LOCATION_ID = gql`
    query GetCourseByLocationId($id: ID!) {
        courseByLocationId(id: $id) {
            id
            lessons {
                startDateTime
                endDateTime
            }
            courseType {
                name
                description
                annotation
                websiteInfo
            }
        }
    }
`
export const UPDATE_COURSE = gql`
    mutation updateCourse($id: String!, $editCourseData: EditCourseInput!) {
        updateCourse(id: $id, editCourseData: $editCourseData) {
            id
            coursePrice {
                id
                vat
                grossPrice
                discount
                discountUnit
                courseId
            }
            paymentInterval
            minAttendees
            maxAttendees
            startDateTime
            endDateTime
            cycle
            annotation
            websiteInfo
            allowMonthlyPayment
        }
    }
`

export const GET_CITIES = gql`
    query GetCities {
        cities {
            city
        }
    }
`

export const GET_LOCATIONS = gql`
    query GetLocations {
        locations {
            id
            abbreviation
            city
            name
            venues {
                name
            }
            generalLocationContact {
                email
                phoneNumber
            }
            courses {
                id
                prefixedCourseNumber
            }
        }
    }
`

export const GET_LOCATIONS_PUBLIC = gql`
    query GetLocationsPublic {
        locationsPublic {
            id
            abbreviation
            city
            name
            #venues {
            #    id
            #    name
            #}
            generalLocationContact {
                email
                phoneNumber
            }
            #courses {
            #    id
            #    prefixedCourseNumber
            #}
        }
    }
`

export const GET_LOCATIONS_SORTED_BY_CITY = gql`
    query GetLocationsSortedByCity {
        locationsSortedByCity {
            city
            locations {
                id
                city
                name
                venues {
                    id
                    name
                }
            }
        }
    }
`

export const GET_LOCATIONS_BY_CITY = gql`
    query GetLocationsByCity($city: String!) {
        locationsByCity(city: $city) {
            id
            name
            venues {
                id
                name
            }
        }
    }
`

export const GET_INSTRUCTORS = gql`
    query GetInstructors {
        instructors {
            id
            firstname
            lastname
        }
    }
`

export const GET_COURSE_TYPES = gql`
    query GetCourseTypes {
        courseTypes {
            id
            name
            description
            #courseCount
        }
    }
`

export const GET_COURSE_TYPES_PUBLIC = gql`
    query GetCourseTypesPublic {
        courseTypesPublic {
            id
            name
            description
            #courseCount
            showInWeb
        }
    }
`

export const GET_COURSE_CATEGORIES_PUBLIC = gql`
    query GetCourseCategoriesPublic {
        courseCategoriesPublic {
            id
            name
        }
    }
`

export const CREATE_COURSE = gql`
    mutation CreateCourse($newCourseData: NewCourseInput!) {
        createCourse(newCourseData: $newCourseData) {
            id
        }
    }
`

export const GET_DETAILED_COURSE_TYPES = gql`
    query GetDetailedCourseTypes {
        courseTypes {
            id
            #courseCount
            shortHandId
            name
            updateDate
            description
            minAge
            maxAge
            paymentInterval
            fee
            minAttendees
            maxAttendees
            annotation
            websiteInfo
            color
        }
    }
`

export const GET_COURSE_TYPE = gql`
    query GetCourseType($id: String!) {
        courseTypeById(id: $id) {
            id
            isActive
            showInWeb
            name
            shortHandId
            category {
                id
            }
            description
            minAge
            maxAge
            paymentInterval
            minAttendees
            maxAttendees
            annotation
            websiteInfo
            inWeekPlanner
            bold
            color
        }
    }
`

export const REMOVE_COURSETYPE_BY_ID = gql`
    mutation RemoveCourseType($id: ID!) {
        removeCourseType(id: $id)
    }
`

export const CREATE_COURSE_TYPE = gql`
    mutation CreateCourseType($newCourseTypeData: NewCourseTypeInput!) {
        createCourseType(newCourseTypeData: $newCourseTypeData) {
            id
            isActive
            showInWeb
            name
            shortHandId
            category {
                id
            }
            description
            minAge
            maxAge
            paymentInterval
            minAttendees
            maxAttendees
            annotation
            websiteInfo
            inWeekPlanner
            bold
            color
        }
    }
`

export const EDIT_COURSE_TYPE = gql`
    mutation UpdateCourseType($id: String!, $newCourseTypeData: NewCourseTypeInput!) {
        updateCourseType(id: $id, newCourseTypeData: $newCourseTypeData) {
            id
            isActive
            showInWeb
            name
            shortHandId
            category {
                id
            }
            description
            minAge
            maxAge
            paymentInterval
            minAttendees
            maxAttendees
            annotation
            websiteInfo
            inWeekPlanner
            bold
            color
        }
    }
`

export const COURSE_TYPE_GET_COURSE_CATEGORIES = gql`
    query CourseTypeGetCourseCategories {
        courseCategories {
            id
            name
        }
    }
`

export const GET_COURSE_CATEGORIES = gql`
    query GetCourseCategories {
        courseCategories {
            id
            isActive
            name
            description
            linkedCourseTypes {
                id
                name
                color
            }
        }
    }
`

export const GET_COURSE_CATEGORY = gql`
    query GetCourseCategory($id: String!) {
        courseCategoryById(id: $id) {
            id
            isActive
            name
            description
            linkedCourseTypes {
                id
                name
            }
        }
    }
`

export const CREATE_COURSE_CATEGORY = gql`
    mutation CreateCourseCategory($newCourseCategoryData: NewCourseCategoryInput!) {
        createCourseCategory(newCourseCategoryData: $newCourseCategoryData) {
            id
            name
            isActive
            description
            linkedCourseTypes {
                id
            }
        }
    }
`

export const EDIT_COURSE_CATEGORY = gql`
    mutation UpdateCourseCategory($id: String!, $newCourseCategoryData: NewCourseCategoryInput!) {
        updateCourseCategory(id: $id, newCourseCategoryData: $newCourseCategoryData) {
            id
            name
            isActive
            description
            linkedCourseTypes {
                id
            }
        }
    }
`

export const COURSE_GET_INSTRUCTORS = gql`
    query CourseGetInstructors {
        instructors {
            id
            firstname
            lastname
        }
    }
`
export const COURSE_GET_INSTRUCTORS_PUBLIC = gql`
    query CourseGetInstructorsPublic {
        instructorsPublic {
            id
            firstname
            lastname
        }
    }
`

export const COURSE_GET_INSTRUCTORS_FOR_LOCATION = gql`
    query CourseGetInstructorsByLocation($locationId: String!) {
        findInstructorsByLocation(locationId: $locationId) {
            id
            firstname
            lastname
        }
    }
`

export const GET_COURSE_DETAILS_BY_ID_PUBLIC = gql`
    query GetCourseDetailsByIdPublic($courseId: String!) {
        courseByIdPublic(id: $courseId) {
            id
            courseType {
                id
                name
                color
                minAge
                maxAge
            }
            prefixedCourseNumber
            startDateTime
            endDateTime
            bookingCount
            freePlaces
            maxAttendees
            websiteInfo
            location {
                id
                name
                city
            }
            venue {
                id
                name
            }
            instructors {
                id
                firstname
                lastname
            }
            lessons {
                id
                startDateTime
                endDateTime
            }
            coursePrice {
                id
                netPrice
                grossPrice
                vat
                includedEntranceFee
            }
            paymentInterval
            monthlySchedule
            paymentType
            lessonCount
            installmentRates {
                id
                installmentAmount
                installmentDue
            }
            allowMonthlyPayment
            allowUpFrontPayment
        }
    }
`

export const GET_COURSE_LESSONS_BY_COURSE_ID = gql`
    query GetCourseLessonsByCourseId($courseId: ID!) {
        courseLessonsByCourseId(courseId: $courseId) {
            id
            startDateTime
            endDateTime
        }
    }
`

export const GET_COURSE_MIN_MAX_AGE = gql`
    query GetCourseMinMaxAge($courseId: String!) {
        getCourseMinMaxAge(courseId: $courseId)
    }
`

// checkAttendeesAgeAtCourseStart
export const CHECK_ATTENDEES_AGE_AT_COURSE_START = gql`
    query CheckAttendeesAgeAtCourseStart($courseId: String!, $attendeeId: String!) {
        checkAttendeesAgeAtCourseStart(courseId: $courseId, attendeeId: $attendeeId)
    }
`
