import { Alert, Button, Checkbox, Divider, Form, Input, message, Radio, Space, Steps } from "antd"
import { Formik, FormikProps } from "formik"
import moment from "moment"
import React, { FC, useContext, useEffect, useState } from "react"
import { useHistory } from "react-router"
import {
    BookerInput,
    Gender,
    ThirdPartyLoginInput,
    ThirdPartyUserInput,
    UpdateBookerAccountEndUserAppInput,
    useCheckEmailUniquenessLazyQuery,
    useCreateAndRegisterThirdPartyUserAndBookerMutation,
    useCreateBookerMutation,
    useGetUpdatedBookerQuery,
    useUpdateBookerAccountEndUserAppMutation,
} from "../../generated/graphql"
import { createInputHelpers } from "../../helpers/createInputHelpers"
import { useMediaSize } from "../../layouts/Main/GetWindowDimensions/GetWindowDimensions"
import { URL_BASE, URL_FORGOT_PASSWORD, URL_LOGIN } from "../../models/url"
import { margin } from "../../styles/layout"
import LayoutForm from "../../views/LayoutForm/LayoutForm"
import LoginContainer from "../../views/LoginContainer/LoginContainer"
import {
    BookerInputValidationSchema,
    registrationPanelValidationInitialValues,
    RegistrationPanelValidationSchema,
    RegistrationPanelValidationType,
} from "./RegistrationPanelValidationSchema"
import css from "../../styles/FormStyles.module.css"
import { MainContext } from "../../layouts/Main/MainLayout"
import { omit } from "../../helpers/omit"
import { ObjectUtils } from "../../helpers/deepDiffMapper"
import { isDefined } from "../../helpers/typeScriptHelpers"
import { Link } from "react-router-dom"

type LoginErrorProps = {
    title: string
    msg: string | React.ReactNode
}
type ErrorType = { [key: string]: LoginErrorProps }

type RegistrationPanelProps = { useCase?: "registration" | "account" }

const RegistrationPanel: FC<RegistrationPanelProps> = ({ useCase = "registration" }) => {
    const mediaSize = useMediaSize()
    const { media } = mediaSize
    const history = useHistory()
    const { userAuthDate } = useContext(MainContext)
    const [errorType, setErrorType] = useState<keyof ErrorType>("errorNotFound")
    const [emailIsUnique, setEmailIsUnique] = useState(true)
    const [step, setStep] = useState(useCase === "registration" ? 0 : 1)
    const [isLoading, setIsLoading] = useState(false)

    const useCaseLabel = useCase === "registration" ? "Registrieren" : "Account bearbeiten"

    const { Step } = Steps

    const [createAndRegister, { error: createAndRegisterError }] = useCreateAndRegisterThirdPartyUserAndBookerMutation()
    const [createBooker, { data: createBookerData, error: createBookerError, loading: createBookerLoading }] =
        useCreateBookerMutation({
            // refetchQueries: [{ query: GetCoursesDocument }], // refetch courses so delete dependencies are up to date
        })
    const [updateBooker, { loading: updateBookerLoading }] = useUpdateBookerAccountEndUserAppMutation()

    // HANDLE BOOKERDATA TO WORK WITH -------------------------------------------------------------------------------------------

    const loggedInUser = localStorage.getItem("loggedInUser")
    const isLoggedIn = loggedInUser

    const [currentBookerData, setCurrentBookerData] = useState<RegistrationPanelValidationType>(
        JSON.parse(localStorage.getItem("booker") as string)
    )

    const { data: fetchedBookerData, loading: loadingFetchedBookerData } = useGetUpdatedBookerQuery({
        skip: !isLoggedIn || !currentBookerData?.id,
        variables: { id: currentBookerData?.id },
        pollInterval: 120000,
        // pollInterval: 10000,
        // fetchPolicy: "network-only",
    })

    // THIS PART DESTROYS THE BIRTHDAY
    // useEffect(() => {
    //     const localStorageBooker = JSON.parse(localStorage.getItem("booker") as string)
    //     const updatedBooker = { ...localStorageBooker, ...fetchedBookerData?.booker }
    //     setCurrentBookerData(updatedBooker)
    // }, [fetchedBookerData])

    const refetchBookerData = (formikProps: FormikProps<RegistrationPanelValidationType>) => {
        if (isLoggedIn && currentBookerData?.id) {
            const updatedBookerData = { ...fetchedBookerData?.booker, ...currentBookerData }
            setCurrentBookerData(updatedBookerData)
            formikProps.setValues(updatedBookerData)
        }
    }

    // useEffect(() => {
    //     console.log("currentBookerData.birthday: ", currentBookerData.birthday)
    //     if (currentBookerData) {
    //         localStorage.setItem("booker", JSON.stringify(currentBookerData))
    //     }
    // }, [currentBookerData])

    useEffect(() => {
        if (currentBookerData && isLoggedIn) {
            console.log("currentBookerData.birthday: ", currentBookerData.birthday)
            localStorage.setItem("booker", JSON.stringify(currentBookerData))
        }
    }, [currentBookerData, isLoggedIn])
    const updateCurrentBookerData = (
        values: RegistrationPanelValidationType | BookerInput | undefined | any,
        intitiator?: string
    ) => {
        if (values) {
            if (currentBookerData === null) {
                setCurrentBookerData(values)
            } else {
                const updatedBookerData = { ...currentBookerData, ...values }
                const diff = ObjectUtils.diff(currentBookerData, updatedBookerData)
                Object.entries(diff.added).forEach(([key, value]) => {
                    delete updatedBookerData[key]
                })
                setCurrentBookerData(updatedBookerData)
            }
        }
    }

    // LOADING STATUS ------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (createBookerLoading || updateBookerLoading) {
            setIsLoading(true)
        } else {
            setIsLoading(false)
        }
    }, [createBookerLoading, updateBookerLoading])

    // EMAIL UNIQUESS ------------------------------------------------------------------------------------------------------------

    const [checkUniquess, { data: isUnique, loading: checkUniquenessLoading, error: checkUniquessError }] =
        useCheckEmailUniquenessLazyQuery({ fetchPolicy: "network-only" })

    useEffect(() => {
        if (isUnique?.checkEmailUniqueness !== undefined) {
            setEmailIsUnique(isUnique.checkEmailUniqueness)
        }
    }, [checkUniquenessLoading, checkUniquessError, isUnique])

    // ERROR HANDLING ------------------------------------------------------------------------------------------------------------

    // testbooker2@kursorganizer.com
    const renderIsNotUniqueMsg = () => {
        return (
            <Space direction='vertical' style={{ paddingTop: 20, paddingBottom: 20 }}>
                <p>
                    Falls du schon einmal einen Kurs bei uns gebucht hast, haben wir deine Daten bereits in unser neues
                    Buchungssystem übernommen.
                </p>
                <p>Du kannst dich hier anmelden</p>
                <Button type='primary' ghost block>
                    <Link to={{ pathname: URL_LOGIN, state: { referer: history.location.pathname } }}>Anmelden</Link>
                </Button>
                <p>
                    Falls du dein Passwort noch nicht weißt, oder vergessen hast, kannst du dir hier ein neues anlegen.
                </p>
                <div>
                    <Button type='primary' ghost block style={{ marginBottom: 20 }}>
                        <Link
                            to={{
                                pathname: URL_FORGOT_PASSWORD,
                                state: { referer: history.location.pathname },
                            }}
                        >
                            Passwort erhalten
                        </Link>
                    </Button>
                </div>
            </Space>
        )
    }

    const errors: ErrorType = {
        errorNotFound: { title: "Unbekannter Fehler", msg: "Es gab einen unbekannten Fehler beim Registrieren" },
        userLoginError: {
            title: "Fehler beim Registrieren",
            msg: "Es tut uns leid, es gab einen Fehler beim Registrieren",
        },
        wrongPassword: { title: "Falsches Passwort", msg: "Das eingegebene Passwort ist falsch." },
        userNotFound: {
            title: "Benutzer nicht gefunden",
            msg: "Wir haben leider keine(n) Nutzer:in mit dieser E-Mail-Adresse gefunden.",
        },
        missingUserName: { title: "Benutzername fehlt", msg: "Bitte geben Sie Ihren Benutzernamen ein." },
        missingPassword: { title: "Passwort fehlt", msg: "Bitte geben Sie Ihr Passwort ein." },
        missingBoth: { title: "Keine Daten", msg: "Bitte gib Daten ein." },
        emailIsNotUnique: {
            title: "E-Mail Adresse bereits vergeben",
            msg: renderIsNotUniqueMsg(),
        },
    }

    const showErrorPanel = (errorType: keyof ErrorType) => {
        // Modal.warning({ title: errors[errorType].title, content: errors[errorType].msg })
        if (createBookerError)
            return (
                <Alert
                    style={{ textAlign: "center" }}
                    // showIcon
                    // banner
                    type='error'
                    message={errors[errorType].title}
                    description={errors[errorType].msg}
                />
            )
        if (createAndRegisterError) {
            return (
                <Alert
                    style={{ textAlign: "center" }}
                    // showIcon
                    // banner
                    type='error'
                    message={errors[errorType].title}
                    description={errors[errorType].msg}
                />
            )
        }

        if (emailIsUnique === false) {
            return (
                <Alert
                    style={{ textAlign: "center" }}
                    type='error'
                    message={"Hey, diese Email-Adresse kennen wir bereits 😊"}
                    description={renderIsNotUniqueMsg()}
                />
            )
        }
    }

    type FieldErrorsType = { [key in keyof Partial<BookerInput>]: any }

    // METHODS ------------------------------------------------------------------------------------------------------------

    const nextStep = (
        errors: FieldErrorsType,
        thirdPartyUserFields: Array<keyof ThirdPartyLoginInput | "passwordConfirmation">,
        formikProps: FormikProps<RegistrationPanelValidationType>
    ) => {
        const thirdPartyErrorFields: Array<any> = []

        Object.keys(errors).forEach((key) => {
            //@ts-ignore
            if (thirdPartyUserFields.includes(key)) {
                thirdPartyErrorFields.push(key)
            }
        })

        if (Object.keys(thirdPartyErrorFields).length) {
            console.log("prevented nextStep")
        } else {
            console.log("nextStep")
            setStep(1)
        }
    }

    const createOrUpdate = async (formikProps: FormikProps<RegistrationPanelValidationType>) => {
        console.log(":::::::createOrUpdate:::::::")
        const errors = formikProps.errors
        if (Object.keys(errors).length) {
            console.group("errors")
            console.log(errors)
            Object.entries(errors).forEach(([key, value]) => {
                console.log(`${key}: ${value}`)
            })
            console.groupEnd()
        } else {
            const { id, email, password, username } = formikProps.values

            const formikValues = formikProps.values

            const thirdPartyUserInput: ThirdPartyUserInput = {
                username: username,
                email: email,
                password: password,
            }

            const newBookerData: BookerInput = {
                id: formikValues.id,
                customer: formikValues.customer,
                gender: formikValues.gender,
                firstname: formikValues.firstname,
                lastname: formikValues.lastname,
                addition: formikValues.addition,
                street: formikValues.street,
                houseNumber: formikValues.houseNumber,
                postCode: formikValues.postCode,
                city: formikValues.city,
                phoneNumber: formikValues.phoneNumber,
                mobileNumber: formikValues.mobileNumber,
                email: formikValues.email,
                initialContact: formikValues.initialContact,
                birthday: formikValues.birthday,
                dsgvo: formikValues.dsgvo,
                newsletter: formikValues.newsletter,
                healthInsuranceFund: formikValues.healthInsuranceFund,
                annotation: formikValues.annotation,
                nonDisclosureNotice: formikValues.nonDisclosureNotice,
                credit: formikValues.credit,
                paymentMethods: formikValues.paymentMethods,
                attendees: formikValues.attendees,
                createdBy: formikValues.createdBy,
            }
            // console.log("newBookerData:", newBookerData)

            if (isLoggedIn) {
                console.log("1. isLoggedIn")
                if (id === undefined) {
                    try {
                        console.log("1.1 createBooker()")
                        await createBooker({
                            variables: { newBookerData },
                        })
                            .then(() => updateCurrentBookerData(createBookerData?.createBooker, "createBooker"))
                            .then(message.success("Bucher wurde erfolgreich angelegt", 3))
                    } catch (error: any) {
                        console.group("ERROR createBooker")
                        console.log("ERROR: ", error)
                        console.groupEnd()
                    } finally {
                        formikProps.setSubmitting(false)
                    }
                } else {
                    try {
                        console.log("1.2 updateBooker()")
                        const rawUpdateBookerData = omit(["password", "passwordConfirmation", "createdBy"], {
                            ...newBookerData,
                            ...formikProps.values,
                        })
                        const booker = rawUpdateBookerData
                        const updateBookerEndUserAppData: UpdateBookerAccountEndUserAppInput = {
                            id: booker.id ?? "",
                            customer: { id: booker.customerId },
                            gender: booker.gender,
                            firstname: booker.firstname,
                            lastname: booker.lastname,
                            street: booker.street,
                            houseNumber: booker.houseNumber,
                            postCode: booker.postCode,
                            city: booker.city,
                            phoneNumber: booker.phoneNumber,
                            mobileNumber: booker.mobileNumber,
                            birthday: booker.birthday,
                            dsgvo: booker.dsgvo,
                        }

                        try {
                            await updateBooker({
                                variables: {
                                    updateBookerEndUserAppData,
                                    fromEndUserApp: true,
                                },
                            })
                                // .then(() => localStorage.setItem("booker", JSON.stringify(updateBookerData)))
                                // .then(() => setCurrentBookerData(updateBookerData))
                                .then((res) => {
                                    const updatedBookerResult = res?.data?.updateBookerAccountEndUserApp
                                    if (updatedBookerResult) {
                                        setCurrentBookerData(formikProps.values)
                                    }
                                })
                                // .then(() => updateCurrentBookerData(updateBookerData, "updateBooker"))
                                .then(() => message.success("Daten erfolgreich gespeichert.", 3))
                                .then(() => formikProps.setSubmitting(false))
                        } catch (error: any) {
                            message.error("Es gab eine Fehler beim Speichern der Daten.", 3)
                        }
                    } catch (error: any) {
                        console.group("ERROR updateBooker")
                        console.log("ERROR: ", error)
                        // console.log("returned updateBookerData: ", updateBookerData)
                        console.groupEnd()
                        message
                            .error("Es gab eine Fehler beim Speichern der Daten.", 3)
                            .then(() => formikProps.setSubmitting(false))
                    } finally {
                        // formikProps.setSubmitting(false)
                    }
                }
            } else {
                try {
                    const { data } = await createAndRegister({
                        variables: {
                            newBookingData: newBookerData,
                            thirdPartyUserInput,
                        },
                    })

                    const registeredUser = await data?.createAndRegisterThirdPartyUserAndBooker

                    if (registeredUser?.accessToken && registeredUser?.refreshToken) {
                        // setCurrentBookerData(newBookerData)
                        updateCurrentBookerData(registeredUser.booker, "createAndRegisterThirdPartyUserAndBooker")
                        localStorage.setItem("accessToken", registeredUser.accessToken)
                        localStorage.setItem("refreshToken", registeredUser.refreshToken)
                        localStorage.setItem("booker", JSON.stringify(registeredUser.booker))
                        localStorage.setItem("loggedInUser", registeredUser.id)
                        if (userAuthDate) {
                            userAuthDate(new Date())
                        }
                        console.log("2.1 User is registered and logged in")
                        message.success("Account wurde erfolgreich angelegt.", 3)
                        history.push(URL_BASE)
                        // stepUp()
                    } else {
                        console.log("2.2 User is registered and logged in")
                        // history.push("/")
                    }
                } catch (error: any) {
                    console.error("error createAndRegisterUserAndBooker: ", error)
                    const rawErrorMessage = JSON.stringify(error.graphQLErrors[0].message, null, 4)
                    console.log("rawErrorMessage:", rawErrorMessage)
                    if (error instanceof Error) {
                        console.log("instanceof Error")
                        // const statusCode = error.message
                        const msg = error.message
                        console.log("msg: ", msg)
                        if (msg.includes('statusCode": 400')) {
                            console.log("statuscode = 400")
                            setErrorType("emailIsNotUnique")
                        }
                    }
                } finally {
                    formikProps.setSubmitting(false)
                }
            }
        }
        console.groupEnd()
    }

    const getInitialValues = () => {
        if (useCase === "registration") {
            return registrationPanelValidationInitialValues
        }

        return currentBookerData
    }

    const content = (
        // return (
        <>
            <Formik<RegistrationPanelValidationType>
                enableReinitialize
                initialValues={useCase === "registration" ? getInitialValues() : currentBookerData}
                validationSchema={
                    useCase === "account" ? BookerInputValidationSchema : RegistrationPanelValidationSchema
                }
                onSubmit={async (data, { resetForm, setSubmitting }) => {
                    console.log("onSubmit")
                }}
            >
                {(formikProps) => {
                    const { handleSubmit, setFieldValue, values } = formikProps
                    const { handleBlur, handleTextInputChange, handleCheckboxChange, validateStatus, help } =
                        createInputHelpers(formikProps)
                    const bookerDataFields: Array<keyof BookerInput> = [
                        "gender",
                        "birthday",
                        "firstname",
                        "lastname",
                        "street",
                        "postCode",
                        "city",
                        "mobileNumber",
                        "dsgvo",
                    ]
                    const noBookerDataFieldIsTouched = bookerDataFields.every((field) => !formikProps.touched[field])
                    const thirdPartyUserFields: Array<keyof ThirdPartyLoginInput | "passwordConfirmation"> = [
                        "username",
                        "password",
                        "passwordConfirmation",
                    ]

                    const checkIfExists = (field: string, value: string, shouldValidate: boolean) => {
                        setFieldValue("email", value, false)
                        handleBlur("username")
                        checkUniquess({ variables: { email: value } })
                        // checkAgain({ email: value })
                        setFieldValue(field, value, shouldValidate)
                    }

                    const handleDateInput = (field: string, e: { target: { value: moment.MomentInput } }) => {
                        formikProps.setFieldTouched("birthday", true)

                        setFieldValue(field, moment(e.target.value, "YYYY-MM-DD").toISOString(), true)
                    }

                    return (
                        <>
                            <LayoutForm onSubmitCapture={handleSubmit} columns={1}>
                                {useCase === "registration" && (
                                    <Steps
                                        style={{ padding: "0 10px" }}
                                        /* direction='vertical' */ size='small'
                                        current={step}
                                    >
                                        <Step
                                            title={<span style={{ cursor: "pointer" }}>Account</span>}
                                            status={validateStatus(thirdPartyUserFields)}
                                            onClick={() => setStep(0)}
                                        />
                                        <Step title={"Kontaktdaten"} status={validateStatus(bookerDataFields)} />
                                    </Steps>
                                )}
                                {showErrorPanel(errorType)}
                                {step === 0 && (
                                    <>
                                        <Form.Item
                                            colon={false}
                                            validateStatus={validateStatus("username")}
                                            help={help("username")}
                                            required
                                            validateTrigger='blur'
                                        >
                                            <Input
                                                value={values.username}
                                                placeholder='Benutzername (e-mail)'
                                                name='username'
                                                type='email'
                                                onChange={handleTextInputChange("username")}
                                                onBlur={(event) => checkIfExists("username", event.target.value, true)}
                                                onKeyUp={(event) => {
                                                    if (event.key === "Enter") {
                                                        console.log("!!! event.key: ", event.key)
                                                        checkUniquess({
                                                            variables: { email: values.username },
                                                        })
                                                    }
                                                }}
                                                autoFocus
                                            />
                                            <Input
                                                name='email'
                                                type='hidden'
                                                value={values.username}
                                                onChange={handleTextInputChange("email")}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            validateStatus={validateStatus("password")}
                                            help={help("password")}
                                            required
                                        >
                                            <Input
                                                value={values.password}
                                                placeholder='Passwort'
                                                name='password'
                                                type='password'
                                                onChange={handleTextInputChange("password")}
                                                status={validateStatus(thirdPartyUserFields)}
                                                onBlur={handleBlur("password")}
                                                onFocus={(event) => checkIfExists("username", values.username, true)}
                                                //
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            validateStatus={validateStatus("passwordConfirmation")}
                                            help={help("passwordConfirmation")}
                                            required
                                        >
                                            <Input
                                                value={values.passwordConfirmation}
                                                placeholder='Passwort wiederholen'
                                                name='passwordConfirmation'
                                                type='password'
                                                onChange={handleTextInputChange("passwordConfirmation")}
                                                onBlur={handleBlur("passwordConfirmation")}
                                                onFocus={(event) => checkIfExists("username", values.username, true)}
                                            />
                                        </Form.Item>
                                        {/* </LayoutForm> */}
                                    </>
                                )}
                                {step === 1 && (
                                    <>
                                        <Form.Item
                                            label={media !== "xs" ? "Anrede" : ""}
                                            // name='gender'
                                            // valuePropName='value'
                                            required
                                        >
                                            <Radio.Group
                                                value={(values?.gender as Gender) ?? Gender.NotDefined}
                                                onChange={(e) => setFieldValue("gender", e.target.value)}
                                            >
                                                <Radio value={Gender.Female}>Frau</Radio>
                                                <Radio value={Gender.Male}>Herr</Radio>
                                                <Radio value={Gender.Diverse}>Divers</Radio>
                                                <Radio value={Gender.NotDefined}>Keine Angabe</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            label='Vorname'
                                            validateStatus={validateStatus("firstname")}
                                            help={help("firstname")}
                                            required
                                            validateTrigger='onBlur'
                                            // validateTrigger='onChange'
                                            // validateTrigger='onKeyUp'
                                        >
                                            <Input
                                                value={values.firstname}
                                                placeholder='Vorname'
                                                name='firstname'
                                                onChange={handleTextInputChange("firstname")}
                                                onBlur={handleBlur("firstname")}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            label='Nachname'
                                            validateStatus={validateStatus("lastname")}
                                            help={help("lastname")}
                                            required
                                            validateTrigger='blur'
                                        >
                                            <Input
                                                value={values.lastname}
                                                placeholder='Nachname'
                                                name='lastname'
                                                onChange={handleTextInputChange("lastname")}
                                                onBlur={handleBlur("lastname")}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            className={css.formItem}
                                            label={"Geburtsdatum"}
                                            validateStatus={validateStatus("birthday")}
                                            help={help("birthday")}
                                            required
                                        >
                                            <Input
                                                data-testid='birthday'
                                                type='date'
                                                onChange={(e) => handleDateInput("birthday", e)}
                                                // onBlur={(e) => handleDateInput("birthday", e)}
                                                placeholder='Datum wählen'
                                                value={
                                                    isDefined(values.birthday)
                                                        ? moment(values.birthday).format("YYYY-MM-DD").toString()
                                                        : undefined
                                                }
                                                status={
                                                    formikProps.values.birthday && formikProps.errors.birthday
                                                        ? "error"
                                                        : undefined
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            label='Straße, Hausnummer'
                                            validateStatus={validateStatus("street")}
                                            help={help("street")}
                                            required
                                            validateTrigger='blur'
                                        >
                                            <Input
                                                value={values.street ?? ""}
                                                placeholder='Straße, Hausnummer'
                                                name='street'
                                                onChange={handleTextInputChange("street")}
                                                onBlur={handleBlur("street")}
                                            />
                                        </Form.Item>
                                        <Input.Group compact>
                                            <Form.Item
                                                colon={false}
                                                label='PLZ'
                                                validateStatus={validateStatus("postCode")}
                                                help={help("postCode")}
                                                required
                                                validateTrigger='blur'
                                                style={{ width: "30%", marginBottom: 0 }}
                                            >
                                                <Input
                                                    value={values.postCode ?? ""}
                                                    placeholder='PLZ'
                                                    name='postCode'
                                                    onChange={handleTextInputChange("postCode")}
                                                    onBlur={handleBlur("postCode")}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                colon={false}
                                                label='Ort'
                                                validateStatus={validateStatus("city")}
                                                help={help("city")}
                                                required
                                                validateTrigger='blur'
                                                style={{ width: "70%", marginBottom: 0 }}
                                            >
                                                <Input
                                                    value={values.city ?? ""}
                                                    placeholder='Ort'
                                                    name='city'
                                                    onChange={handleTextInputChange("city")}
                                                    onBlur={handleBlur("city")}
                                                />
                                            </Form.Item>
                                        </Input.Group>
                                        <Form.Item
                                            colon={false}
                                            label='Handy Nr.'
                                            validateStatus={validateStatus("mobileNumber")}
                                            help={help("mobileNumber")}
                                            required
                                            validateTrigger='blur'
                                        >
                                            <Input
                                                value={values.mobileNumber ?? ""}
                                                placeholder='Handy Nr.'
                                                name='mobileNumber'
                                                onChange={handleTextInputChange("mobileNumber")}
                                                onBlur={handleBlur("mobileNumber")}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            label='Festnetz'
                                            validateStatus={validateStatus("phoneNumber")}
                                            help={help("phoneNumber")}
                                            validateTrigger='blur'
                                        >
                                            <Input
                                                value={values.phoneNumber ?? ""}
                                                placeholder='Festnetz'
                                                name='phoneNumber'
                                                onChange={handleTextInputChange("phoneNumber")}
                                                onBlur={handleBlur("phoneNumber")}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            validateStatus={validateStatus("dsgvo")}
                                            help={help("dsgvo")}
                                            required
                                        >
                                            <Checkbox
                                                name='dsgvo'
                                                checked={values.dsgvo}
                                                onChange={handleCheckboxChange("dsgvo")}
                                            >
                                                AGBs gelesen und akzeptiert
                                            </Checkbox>
                                        </Form.Item>
                                    </>
                                )}
                                {step === 0 ? (
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        // htmlType='button'
                                        onClick={() => nextStep(formikProps.errors, thirdPartyUserFields, formikProps)}
                                        block
                                        disabled={
                                            validateStatus(thirdPartyUserFields) === "error" || emailIsUnique === false
                                        }
                                    >
                                        Weiter
                                    </Button>
                                ) : (
                                    <div style={{ width: "100%" }}>
                                        <Button
                                            type='primary'
                                            htmlType='submit'
                                            block
                                            // style={{ width: "100%" }}
                                            disabled={
                                                validateStatus(bookerDataFields) === "error" ||
                                                emailIsUnique === false ||
                                                noBookerDataFieldIsTouched ||
                                                formikProps.values.dsgvo === false
                                            }
                                            loading={isLoading}
                                            onClick={() => createOrUpdate(formikProps)}
                                        >
                                            {useCase === "registration" ? useCaseLabel : "speichern"}
                                        </Button>
                                        {useCase === "account" && (
                                            <Button
                                                style={{ marginTop: 5 }}
                                                type='link'
                                                block
                                                loading={loadingFetchedBookerData}
                                                onClick={() => refetchBookerData(formikProps)}
                                            >
                                                Werte Zurücksetzen
                                            </Button>
                                        )}
                                    </div>
                                )}
                                {useCase === "registration" && (
                                    <>
                                        <Divider style={{ marginTop: margin.s }} />
                                        <div>Bereits einen Account?</div>
                                        <Button type='primary' ghost onClick={() => history.push(URL_LOGIN)}>
                                            Jetzt einloggen
                                        </Button>
                                    </>
                                )}
                            </LayoutForm>
                        </>
                    )
                }}
            </Formik>
        </>
    )
    // }

    return <LoginContainer title={useCaseLabel} size={step === 0 ? "xs" : "s"} content={content} />
}

export default RegistrationPanel
