/** Allows number types to be undefined. */
export type OptionalNumberTypes<T extends object> = {
    [key in keyof T]: T[key] extends number ? T[key] | undefined : T[key];
};

export type OptionalTypes<T extends object, O> = {
    [key in keyof T]: T[key] extends O ? T[key] | undefined : T[key];
};

type PickNumberProperties<T> = {
    [key in keyof T]: T[key] extends number ? key : never;
};

/** Pick all properties from "T" that extend "number". */
export type PickNumbers<T> = Pick<T, PickNumberProperties<T>[keyof T]>;

export const assertNever = (x: never, name?: string): never => {
    throw new Error(
        `Unexpected object: ${name === undefined ? JSON.stringify(x) : name}"`,
    );
};

export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

// Determines if `value` is neither null, nor undefined or an empty string.
// It can also be used as a type guard, but narrows out the empty string for else cases.
// That means in case of `isDefined("")` the if case is typed as `null | undefined` and rules out the string.
//
// Disable eslint function style, because of problems with generics and JSX.
// Extending any would work, but we are not allowed to use any, so this is more complicated.
// https://github.com/Microsoft/TypeScript/issues/4922
//

export function isDefined<T>(value: T): value is NonNullable<T> {
    return (value !== null &&
        value !== undefined && !(typeof value === "string" && value === ""));
}

/**
 * A date string in ISO8601 format (yyyy-mm-ddThh:mm:ss.ffffff)
 */
export type ISODateString = string;

/**
 * Requires optional properties of `T` to be defined, even if the value is undefined.
 */
export type RequireUndefined<T> = {
    [P in keyof Required<T>]: Pick<T, P> extends Required<Pick<T, P>>
        ? T[P]
        : T[P] | undefined;
};
