import React, { FC, memo, useEffect, useState } from "react"
import moment from "moment"
import {
    Booker,
    BookerAttendeeInput,
    BookerPaymentMethodInput,
    Gender,
    GetUpdatedBookerDocument,
    useUpdateBookerMutation,
} from "../../generated/graphql"
import { isDefined, RequireUndefined } from "../../helpers/typeScriptHelpers"
import { v4 } from "uuid"
import css from "./CreateEditAttendee.module.css"
import { Formik, FormikProps } from "formik"
import { BookerAttendeeInputValidationSchema } from "../../containers/CreateBooking/Steps/validationSchemas/BookerAttendeeInputValidationSchema"
import { Button, Form, Select, Input, Space } from "antd"
import { getMobileOperatingSystem } from "../../styles/layout"
import { createInputHelpers } from "../../helpers/createInputHelpers"
import LayoutForm from "../LayoutForm/LayoutForm"
import { omit } from "../../helpers/omit"

type CreateEditAttendeeProps = {
    onCancel?: () => void
    onOk?: (data: BookerAttendeeInput) => void
    initialValues?: BookerAttendeeInput
    editMode?: boolean
    attendeeToEdit?: BookerAttendeeInput
    booker: Booker
    courseId?: string
    showAlert?: boolean
}

export const getDefaultInitialValues = (
    courseId?: string
): RequireUndefined<BookerAttendeeInput> & { courseId?: string } => ({
    id: v4(),
    gender: Gender.NotDefined,
    firstname: "",
    lastname: "",
    birthday: undefined,
    customer: { id: v4() },
    member: false,
    defaultPaymentMethod: undefined,
    bookings: undefined,
    addition: "",
    annotation: "",
    courseId,
    bookingsKo9: null,
})

const CreateEditAttendee: FC<CreateEditAttendeeProps> = ({
    onCancel = Function.prototype,
    onOk = Function.prototype,
    editMode,
    attendeeToEdit,
    booker,
    courseId,
    showAlert,
}) => {
    const oS = getMobileOperatingSystem()

    const [updateBookerDb, { loading: updatingBooker }] = useUpdateBookerMutation()

    const [initialValues, setInitialValues] = useState<BookerAttendeeInput & { courseId?: string }>(
        getDefaultInitialValues(courseId)
    )

    useEffect(() => {
        if (isDefined(attendeeToEdit) && isDefined(courseId)) {
            const attendeeWithCourseId = { ...attendeeToEdit, courseId }
            setInitialValues(attendeeWithCourseId)
        }
    }, [attendeeToEdit, courseId])

    const updateBookersAttendee = async (
        newAttendeeData: BookerAttendeeInput,
        setSubmitting: (isSubmitting: boolean) => void,
        resetForm: (nextState?: Partial<FormikProps<BookerAttendeeInput>>) => void
    ) => {
        try {
            const currentBookerData = booker
            const omitedEntries: Array<Extract<keyof Booker, string>> = [
                "createDate",
                "updateDate",
                "customerId",
                "addition",
                "annotation",
                "createdByUserId",
                "updateDate",
                "createdBy",
            ]
            const updateBookerData = omit(["bookerId", ...omitedEntries], currentBookerData)

            updateBookerData.customer = { id: currentBookerData.customerId }

            newAttendeeData = omit(["bookerId", "courseId", ...omitedEntries], newAttendeeData)

            // IF THE BOOKER HAS NO PAYMENTMETHODS, CREATE ONE (cash)
            if (updateBookerData.paymentMethods?.length === 0) {
                console.log("booker has no payment methods")
                const newDefaultPaymentMethodId = v4()
                updateBookerData.paymentMethods.push({
                    id: newDefaultPaymentMethodId,
                    cash: true,
                } as BookerPaymentMethodInput)
            }

            newAttendeeData = {
                ...newAttendeeData,
                defaultPaymentMethod: { id: updateBookerData.paymentMethods[0].id },
            }
            newAttendeeData.bookings = []
            // newAttendeeData.birthday = moment(updateBookerData.birthday).toISOString()
            if (updateBookerData.attendees) {
                updateBookerData.attendees.push(newAttendeeData)
            } else {
                updateBookerData.attendees = [newAttendeeData]
            }

            await updateBookerDb({
                variables: {
                    updateBookerData,
                    fromEndUserApp: true,
                },
                refetchQueries: [
                    { query: GetUpdatedBookerDocument, variables: { skip: !booker?.id, id: booker?.id ?? "" } },
                ],
            }).then(() => {
                // resetForm()
                if (!updatingBooker) {
                    onOk(newAttendeeData)
                }
            })
        } catch (error) {
            console.error(`Error on updateBooker(updateBookersAttendee): ${error}`)

            return error
        } finally {
            resetForm()
            console.log("finally")
        }
    }

    return (
        <Formik<BookerAttendeeInput>
            validateOnMount
            validateOnBlur
            initialValues={initialValues}
            onSubmit={(data, { resetForm, setSubmitting }) => {
                resetForm()
                updateBookersAttendee(data, setSubmitting, resetForm)
            }}
            validationSchema={BookerAttendeeInputValidationSchema}
            enableReinitialize={oS !== "Android"}
        >
            {(formikProps) => {
                const { values, handleSubmit, resetForm, isValid } = formikProps
                const { handleTextInputChange, handleSelectInputChange, validateStatus, handleBlur, help } =
                    createInputHelpers(formikProps)

                const handleCancle = () => {
                    resetForm()
                    onCancel()
                }

                const handleOk = () => {
                    handleSubmit()
                }

                return (
                    <LayoutForm columns={1}>
                        <Form.Item
                            style={{ gridColumn: "span 1" }}
                            label='Geschlecht'
                            validateStatus={validateStatus("gender")}
                            help={help("gender")}
                            required
                        >
                            <Select<Gender>
                                data-testid='AttendeeCreateModalGender'
                                onChange={handleSelectInputChange("gender")}
                                onBlur={handleBlur("gender")}
                                value={values.gender}
                            >
                                <Select.Option key={Gender.NotDefined} value={Gender.NotDefined}>
                                    Keine Angabe
                                </Select.Option>
                                <Select.Option key={Gender.Female} value={Gender.Female}>
                                    Weiblich
                                </Select.Option>
                                <Select.Option key={Gender.Male} value={Gender.Male}>
                                    Männlich
                                </Select.Option>
                                <Select.Option key={Gender.Diverse} value={Gender.Diverse}>
                                    Divers
                                </Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label='Vorname'
                            validateStatus={validateStatus("firstname")}
                            help={help("firstname")}
                            style={{ gridColumn: "1" }}
                            required
                        >
                            <Input
                                data-testid='AttendeeCreateModalFirstName'
                                // disabled={state === AttendeeModalState.BookerModalOpen}
                                onChange={handleTextInputChange("firstname")}
                                onBlur={handleBlur("firstname")}
                                value={values.firstname}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Nachname'
                            validateStatus={validateStatus("lastname")}
                            help={help("lastname")}
                            style={{ gridColumn: "1" }}
                            required
                        >
                            <Input
                                data-testid='AttendeeCreateModalLastName'
                                // disabled={state === AttendeeModalState.BookerModalOpen}
                                onChange={handleTextInputChange("lastname")}
                                onBlur={handleBlur("lastname")}
                                value={values.lastname}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Geburtsdatum'
                            validateStatus={validateStatus("birthday")}
                            help={help("birthday")}
                            // style={{ gridColumn: "1" }}
                            style={{ width: "100%" }}
                            required
                        >
                            <Input
                                data-testid='AttendeeCreateModalBirthday'
                                type='date'
                                size='middle'
                                style={{ width: "100%" }}
                                onChange={handleTextInputChange("birthday")}
                                onBlur={handleBlur("birthday")}
                                placeholder='Datum wählen'
                                value={
                                    isDefined(values.birthday)
                                        ? moment(values.birthday).format("YYYY-MM-DD").toString()
                                        : undefined
                                }
                                status={
                                    formikProps.values.birthday && formikProps.errors.birthday ? "error" : undefined
                                }
                            />
                        </Form.Item>
                        <Space
                            className={css.footer}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Button block danger onClick={() => handleCancle()} style={{ flex: 1 }}>
                                Abbrechen
                            </Button>
                            <Button
                                block
                                type='primary'
                                onClick={() => handleOk()}
                                disabled={!isValid && !updatingBooker}
                                style={{ flex: 1 }}
                                loading={updatingBooker}
                            >
                                {editMode ? "Teilnehmer speichern" : "Teilnehmer anlegen"}
                            </Button>
                        </Space>
                    </LayoutForm>
                )
            }}
        </Formik>
    )
}

export default memo(CreateEditAttendee)
