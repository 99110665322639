import React, { FC, memo } from "react"
import { BankTwoTone, CreditCardTwoTone, WalletTwoTone } from "@ant-design/icons"
import { Tag } from "antd"
import { PaymentMethodType } from "../../helpers/getPaymentMethodType"

type PaymentMethodMap = {
    [key in PaymentMethodType]: string
}

export const paymentMethodTypeLabels: PaymentMethodMap = {
    sepaDebit: "Lastschrift",
    creditCard: "Kreditkarte",
    cash: "Bar",
    bankTransfer: "Überweisung",
    payPal: "PayPal",
    esr: "Einzahlungsschein",
    none: "Keine",
}

type PaymentMethodBadgeProps = {
    type?: PaymentMethodType
}

const PaymentMethodBadge: FC<PaymentMethodBadgeProps> = ({ type = "none" }) => {
    const label = paymentMethodTypeLabels[type]

    const renderPaymentIcon = (type: PaymentMethodType) => {
        switch (type) {
            case "sepaDebit":
                return <BankTwoTone />
            case "esr":
                return <BankTwoTone />
            case "payPal":
                return <CreditCardTwoTone />
            case "creditCard":
                return <CreditCardTwoTone />
            case "cash":
                return <WalletTwoTone />
            case "bankTransfer":
                return <BankTwoTone />
            case "none":
                return <BankTwoTone />
            default:
                return <BankTwoTone />
        }
    }

    return (
        <Tag>
            {renderPaymentIcon(type)} {label}
        </Tag>
    )
}

export default memo(PaymentMethodBadge)
