import * as Yup from "yup";
import {BookerPaymentMethodInput} from "../../../../generated/graphql";

const {string, object, boolean} = Yup;

// TODO: Improve schema. It should be one of four object types.
// It should require all values, that are needed for one payment method.
// @ts-ignore
export const BookerPaymentMethodInputValidationSchema = object().shape<BookerPaymentMethodInput>({
    id: string().required(),
    cash: boolean()
        .notRequired()
        .nullable(),
    creditCardAccountholder: string()
        .notRequired()
        .nullable(),
    creditcardNumber: string()
        .notRequired()
        .nullable(),
    expirationDate: string()
        .notRequired()
        .nullable(),
    cvc: string()
        .notRequired()
        .nullable(),
    validFrom: string()
        .notRequired()
        .nullable(),
    paypalAccountholder: string()
        .notRequired()
        .nullable(),
    email: string()
        .notRequired()
        .nullable(),
    iban: string()
        .notRequired()
        .nullable(),
    bank: string()
        .notRequired()
        .nullable(),
    bic: string()
        .notRequired()
        .nullable(),
    sepaAccountholder: string()
        .notRequired()
        .nullable(),
});
