import React from "react"
import {
    FilePdfOutlined,
    EditOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    InfoCircleTwoTone,
} from "@ant-design/icons"
import { Badge, Space, Tag, Tooltip, Typography } from "antd"
import classnames from "classnames"
// import moment, { MomentFormatSpecification } from "moment"
import moment, { MomentFormatSpecification } from "moment-timezone"
import { blue, green, grey, orange, red, yellow } from "@ant-design/colors"
// import Maybe from "graphql/tsutils/Maybe";
import { Link } from "react-router-dom"
import Button from "../Button/Button"
import css from "./TableColumnRenderers.module.css"
// import i18n from "../../services/i18n"
import {
    PaymentInterval,
    BookerPaymentMethodInput,
    PaymentType,
    Gender,
    Instructor,
    CourseLesson,
    CourseType,
    Location,
    SalutationType,
    Venue,
    GeneralLocationContact,
    Course,
    Maybe,
    MonthlySchedule,
    GetCourseDetailsByIdPublicQuery,
} from "../../generated/graphql"
import { renderAmount as renderAmountHelper } from "../../helpers/renderAmount"
import { renderPercent as renderPercentHelper } from "../../helpers/renderPercent"
import { paymentMethodTypeLabels } from "../PaymentMethodBadge/PaymentMethodBadge"
import { getPaymentMethodType } from "../../helpers/getPaymentMethodType"
import { colorLightGrey } from "../../styles/colors"
import { renderAsMoney } from "../../helpers/renderAsMoney"
import { isDefined, ISODateString } from "../../helpers/typeScriptHelpers"
import { naturalLanguageAge } from "../../helpers/natualLanguageAge"
// import {BookingType} from "../../../server/booking/booking.entity";
// import {TableType as CourseTableType} from "../../containers/courses/CourseEdit/CourseOverviewColumns";
import { BookingType } from "../../types"
import "moment/locale/de"
import { Color, getTextColor } from "../../helpers/getTextColor"
import { MediaSize } from "../../layouts/Main/GetWindowDimensions/GetWindowDimensions"
import { getTotalCoursePrice } from "../../helpers/getTotalCoursePrice"
import { CourseTableType } from "../../containers/CoursesTable/CourseTableColumns"

type ReqiredPaymentData = Pick<
    BookerPaymentMethodInput,
    | "id"
    | "cash"
    | "creditCardAccountholder"
    | "creditcardNumber"
    | "expirationDate"
    | "validFrom"
    | "cvc"
    | "paypalAccountholder"
    | "email"
    | "iban"
    | "bank"
    | "bic"
    | "sepaAccountholder"
>

export const renderTitle = (title: string) => {
    return <div className={classnames(css.header)}>{title}</div>
}

export const renderTextStandard = (text: string | undefined | number | null, bold?: boolean) => {
    const classes = bold ? classnames(css.text__standard, css.text__bold) : classnames(css.text__standard)

    return <div className={classes}>{text ?? ""}</div>
}

export const renderUnknown = (text: unknown) => {
    if (typeof text === "string" || typeof text === "number") {
        return renderTextStandard(text)
    }

    return <div>{"!!Attention!!"}</div>
}

type TextColoredInterface = {
    text: string | number
    color: string
    centered?: boolean
}

export const renderTextColored = ({ text, color, centered }: TextColoredInterface) => {
    return (
        <div
            style={{ backgroundColor: color || "transparent" }}
            // className={classnames(centered ? (css.text__colored css.text__centered) : (css.text__colored))}
            className={centered ? classnames(css.text__colored, css.text__centered) : css.text__colored}
        >
            <span>{text}</span>
        </div>
    )
}

export const renderBill = (text: string, cb: () => void) => {
    return (
        <div tabIndex={0} role='button' onKeyDown={cb} onClick={cb} className={classnames(css.bill)}>
            <FilePdfOutlined style={{ paddingRight: "8px" }} />
            <span>{text}</span>
        </div>
    )
}

export const renderFirstCourseLesson = (startDateTime: string | Date, endDateTime: string | Date) => {
    return (
        <>
            <Space size='small' className={css.firstCourseLesson}>
                <div className={css.date}>
                    <div className={css.firstCourseLessonDate}>
                        {startDateTime && moment(startDateTime).format("dd. DD MMM yy")}
                    </div>
                    <div className={css.firstCourseLessonDateShort}>
                        {startDateTime && moment(startDateTime).format("dd. DD MMM yy")}
                    </div>
                </div>
                <div className={css.time}>
                    <div
                        style={{
                            color: colorLightGrey,
                            lineBreak: "strict",
                        }}
                        className={css.firstCourseLessonTime}
                    >
                        {moment(startDateTime).format("HH:mm") + " - " + moment(endDateTime).format("HH:mm")}
                    </div>
                </div>
            </Space>
        </>
    )
}

export const renderAmount = (amount: number) => {
    return <div className={classnames([css.text__standard, css.text__bold])}>{renderAmountHelper(amount)}</div>
}

export const renderCoursePrice = (course: CourseTableType | GetCourseDetailsByIdPublicQuery["courseByIdPublic"]) => {
    const { coursePrice, paymentInterval, monthlySchedule, lessonCount, paymentType, installmentRates } = course

    const totalCoursePrice = getTotalCoursePrice({
        coursePrice: coursePrice[0].grossPrice,
        lessonCount: lessonCount ?? undefined,
        paymentInterval: paymentInterval ?? undefined,
    })

    const renderMonthlyConsistentPrice = (installmentRates: CourseTableType["installmentRates"]) => {
        if (installmentRates?.[0]) {
            // return <>{`mtl. ${renderAmountHelper(installmentRates[0].installmentAmount)}`}</>
            return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title={`Kursdauer ${installmentRates.length} Monate`}>
                        <InfoCircleTwoTone twoToneColor={blue[5]} style={{ marginRight: 5, marginBottom: 1 }} />
                    </Tooltip>
                    <span style={{ opacity: 0.7 }}>mtl.</span>
                    <span>{renderAmountHelper(installmentRates[0].installmentAmount)}</span>
                </div>
            )
        }

        return "-"
    }

    const renderContent = () => {
        if (paymentType === PaymentType.Monthly && monthlySchedule === MonthlySchedule.Consistent && installmentRates) {
            return (
                <div className={classnames([css.text__standard, css.text__bold, css.col_coursePrice])}>
                    {renderMonthlyConsistentPrice(installmentRates)}
                </div>
            )
        }

        return (
            <div className={classnames([css.text__standard, css.text__bold, css.col_coursePrice])}>
                {renderAmountHelper(totalCoursePrice)}
            </div>
        )
    }

    return renderContent()
}

export const renderPercent = (amount: number) => {
    return renderPercentHelper(amount)
}

export const renderDueDate = (date: string, color: string) => {
    return (
        <div className={classnames(css.date__due)}>
            <Badge color={color || "#d9d9d9"} />
            <span>{date && moment(date).format("DD.MM.YYYY")}</span>
        </div>
    )
}

export const renderDueDateColored = (date: Date, customText?: string) => {
    const today = moment()
    const dueDate = moment(date)
    const diffInDays = dueDate.diff(today, "days")

    const color = diffInDays > 0 ? (diffInDays > 7 ? "" : orange[2]) : red[2]

    return (
        <>
            {renderTextColored({
                text: customText ?? moment(date).format("DD.MM.YYYY"),
                color,
                centered: true,
            })}
        </>
    )
}

export const renderPaymentDate = (date?: string | null) =>
    date === null || !date ? (
        <span>{"offen"}</span>
    ) : (
        <Button type='link' size='small'>
            <EditOutlined />
            <span>{date && moment(date).format("DD.MM.YYYY")}</span>
        </Button>
    )

export const renderPaymentType = (type: PaymentType | null | undefined) => {
    let paymentType = "-"

    switch (type) {
        case PaymentType.PrePaid:
            paymentType = "i18n.containers.payments.DonePaymentsList.prePaid()"
            break
        case PaymentType.Bill:
            paymentType = "i18n.containers.payments.DonePaymentsList.bill()"
            break
        case PaymentType.Monthly:
            paymentType = "i18n.containers.payments.DonePaymentsList.monthly()"
            break
        case PaymentType.OnTheSpot:
            paymentType = "i18n.containers.payments.DonePaymentsList.onTheSpot()"
            break
        default:
            paymentType = "-"
    }

    return renderTextStandard(paymentType)
}

export const renderCustomerNumber = (customerNumberPrefix: string, customerNumber: number, className?: string) => (
    <div className={className}>{`${customerNumberPrefix} ${customerNumber}`}</div>
)

export const renderFullName = (firstname?: string, lastname?: string, className?: string) => {
    if (!firstname && !lastname) {
        return <span className={css.secondary}>{" - "}</span>
    }

    return <div className={className}>{`${firstname} ${lastname}`}</div>
}

export const renderAttendeesOfBooker = (
    attendees: Array<{ firstname: string; lastname: string; id: string }> | null
) => {
    if (attendees !== null && attendees.length > 0) {
        return attendees.map((attendee) => {
            return <div key={attendee.id}>{`${attendee.firstname} ${attendee.lastname}`}</div>
        })
    }

    return <div className={css.secondary}>{"i18n.containers.customers.BookersOverview.noAttendees()"}</div>
}

export const renderAddress = (street: string, houseNumber: string, postCode: string, city: string) => {
    return (
        <div>
            <div>
                {street} {houseNumber}
            </div>
            <div>
                {postCode} {city}
            </div>
        </div>
    )
}

export const renderPaymentMethods = (availablePaymentMethods: Array<ReqiredPaymentData> | null) => {
    if (availablePaymentMethods !== null) {
        return availablePaymentMethods.map((method) => {
            return <div key={method.id}>{paymentMethodTypeLabels[getPaymentMethodType(method)]}</div>
        })
    }

    return "-"
}

export const renderPaymentMethod = (paymentMethod: ReqiredPaymentData | null) => {
    if (paymentMethod !== null) {
        return paymentMethodTypeLabels[getPaymentMethodType(paymentMethod)]
    }

    return "-"
}

export const renderBooleanAsCheckmark = (value: boolean, style?: React.CSSProperties) =>
    value ? (
        <CheckCircleTwoTone twoToneColor={green[3]} style={{ fontSize: "1.7em", ...style }} />
    ) : (
        <CloseCircleTwoTone twoToneColor={grey[1]} style={{ fontSize: "1.7em", ...style }} />
    )

export const renderPricePer = (feeAmount?: Maybe<number>, paymentInterval?: Maybe<PaymentInterval>) => {
    const amount = isDefined(feeAmount) ? feeAmount : 0

    switch (paymentInterval) {
        case PaymentInterval.PerCourse:
            return renderAsMoney(amount, "i18n.containers.courses.CourseEdit.perCourse()")
        case PaymentInterval.PerLesson:
            return renderAsMoney(amount, "i18n.containers.courses.CourseEdit.perLesson()")
        default:
            return amount
    }
}

export const renderCourseTypeFee = (feeAmount?: Maybe<number>, paymentInterval?: Maybe<PaymentInterval>) => {
    const amount = isDefined(feeAmount) ? feeAmount : 0

    switch (paymentInterval) {
        case PaymentInterval.PerCourse:
            return renderAsMoney(amount, "i18n.containers.courses.CourseEdit.perCourse()")
        case PaymentInterval.PerLesson:
            return renderAsMoney(amount, "i18n.containers.courses.CourseEdit.perLesson()")
        default:
            return amount
    }
}

export const renderBirthdayAsAge = (value: ISODateString) => {
    return <div className={classnames(css.text__standard)}>{naturalLanguageAge(moment().diff(value, "months"))}</div>
}

export const renderDate = (value: ISODateString, time?: boolean) => {
    return (
        <div className={classnames(css.text__standard)}>
            {moment(value).format("DD.MM.YYYY")}
            <span className={css.secondary}>{time ? ` ${moment(value).format("HH:mm")} Uhr` : null}</span>
        </div>
    )
}

export const renderBookerAndCustomerNumber = (booker?: {
    firstname?: string
    lastname?: string
    customerNumberPrefix?: string
    customerNumber?: number
}) => {
    if (booker) {
        return (
            <div>
                <div>
                    {booker.firstname} {booker.lastname}
                </div>
                <div className={css.secondary}>
                    {booker.customerNumberPrefix}
                    {" - "}
                    {booker.customerNumber}
                </div>
            </div>
        )
    }

    return <span>{"-"}</span>
}

export const renderGender = (gender: Gender) => {
    return <span>{"i18n.containers.common.Gender[gender]()"}</span>
}

/* export const renderCourseType = ({ name, color }: { name: string; color: string }) => {
    return renderTextColored({ text: name, color })
} */

export const renderCourseType = ({
    name,
    color,
    style,
}: {
    name: string
    color?: string
    style?: React.CSSProperties
}) => {
    const theColor: Color = { name: color, value: color ? color : blue[3] }

    const actualStyle: React.CSSProperties = {
        backgroundColor: color ? color : blue[3],
        minWidth: "204px",
        borderRadius: "50px",
        // padding: 1,
        // paddingLeft: 10,
        "--bg-color": color ? color : blue[3],
        "--text-color": getTextColor(theColor),
        ...style,
    } as React.CSSProperties

    return (
        <div style={actualStyle} className={css.coloredBadge}>
            <div>{name}</div>
        </div>
    )
}

export const renderCourseNumber = (
    { courseNumber, courseId, editLink }: { courseNumber: string; courseId: string; editLink: string },
    className?: string
) => {
    // const history = useHistory()

    return (
        <Button
            type='link'
            size='small'
            style={{ fontSize: "1em", fontWeight: 600 }}
            href={editLink.replace(":id", courseId)}
        >
            <span>{courseNumber}</span>
        </Button>
    )
}

export const renderFreePlaces = (freePlaces: number) => {
    const color = freePlaces ? (freePlaces > 2 ? green[2] : orange[2]) : red[2]

    const freePlacesCount = freePlaces < 0 ? 0 : freePlaces

    return <>{renderTextColored({ text: freePlacesCount, color, centered: true })}</>
}

export const renderInstructors = (instructors: Array<Pick<Instructor, "id" | "firstname" | "lastname">>) => {
    return (
        <div>
            {instructors.map((instructor, index) => {
                return (
                    <div key={instructor.id}>
                        {instructor.firstname + " " + instructor.lastname}
                        {index + 1 < instructors.length ? "," : null}
                    </div>
                )
            })}
        </div>
    )
}

export const renderCourseLessons = (
    lessons: Array<Pick<CourseLesson, "id" | "startDateTime" | "endDateTime">>,
    showTime?: boolean
) => {
    return lessons.map((lesson, index) => {
        return (
            <div key={lesson.id}>
                <span className={css.primary}>{index + 1 + " - "}</span>
                <span>{moment(lesson.startDateTime).format("DD.MM.YY")}</span>
                {showTime ? (
                    <span className={css.secondary}>
                        {" | " +
                            moment(lesson.startDateTime).format("HH:mm") +
                            "-" +
                            moment(lesson.endDateTime).format("HH:mm")}
                    </span>
                ) : null}
                {/* {index + 1 < lessons.length ? "," : null} */}
            </div>
        )
    })
}

export const renderMonthAsAge = (months?: Maybe<number>) => {
    if (isDefined(months)) {
        if (months > 0) {
            return naturalLanguageAge(months)
        } else {
            return "0"
        }
    }

    return "∞"
}

export const renderContinuousText = (text: Maybe<string>) => {
    return (
        <>
            <Tooltip title={text}>
                <Typography.Text ellipsis={true} style={{ maxWidth: 200 }} /* style={COLUMN_STYLE} */>
                    {text}
                </Typography.Text>
            </Tooltip>
        </>
    )
}

export const renderLinkedCourseTypes = (courseTypes: Array<Pick<CourseType, "id" | "name" | "color">>) => {
    return courseTypes.map((courseType, index) => {
        // return <Tag key={courseType.id}>{courseType.name}</Tag>;
        return (
            <div key={courseType.id} style={{ margin: 4 }}>
                {renderCourseType({
                    name: courseType.name,
                    color: courseType.color,
                })}
            </div>
        )
    })
}

export const renderJobTitle = (level?: number) => {
    if (level === 0) {
        return <span>{"i18n.containers.staff.StaffCreate.steps.workData.jobTitle.instructorLevel[level]()"}</span>
    } else if (level === 1) {
        return <span>{"i18n.containers.staff.StaffCreate.steps.workData.jobTitle.instructorLevel[level]()"}</span>
    }

    return "-"
}

export const renderAssignedLocations = (locations: Array<Pick<Location, "id" | "name">>) => {
    return locations.map((location) => {
        return <div key={location.id}>{`${location.name}`}</div>
    })
}

export const renderSalutation = (salutation: SalutationType) => {
    return <span>{"i18n.containers.staff.StaffCreate.steps.personalData.salutation.options[salutation]()"}</span>
}

export const renderVenues = (venues: Array<Pick<Venue, "id" | "name">>) => {
    return venues.map((venue, index) => {
        return (
            <div key={venue.id}>
                {venue.name}
                {index + 1 < venues.length ? "," : null}
            </div>
        )
    })
}

export const renderGeneralLocationContact = (contact: Pick<GeneralLocationContact, "email" | "phoneNumber">) => {
    return (
        <>
            <div>{contact.email}</div>
            <div>{contact.phoneNumber}</div>
        </>
    )
}

export const renderCourses = (courses: Maybe<Array<Pick<Course, "id" | "prefixedCourseNumber">>>) => {
    if (courses) {
        return courses.map((course, index) => {
            return (
                <div key={course.id}>
                    {course.prefixedCourseNumber}
                    {index + 1 < courses.length ? "," : null}
                </div>
            )
        })
    }

    return "-"
}

export const renderBookingType = (bookingType: Extract<BookingType, string> | string) => {
    switch (bookingType) {
        case "Booking":
            return renderTextColored({
                text: "i18n.containers.bookings.BookingList.bookingTypes[bookingType]()",
                color: blue[1],
                centered: true,
            })
        case "Waitlist":
            return renderTextColored({
                text: "i18n.containers.bookings.BookingList.bookingTypes[bookingType]()",
                color: yellow[1],
                centered: true,
            })
        case "PlaceReservation":
            return renderTextColored({
                text: "i18n.containers.bookings.BookingList.bookingTypes[bookingType]()",
                color: red[1],
                centered: true,
            })

        default:
            return <span>{bookingType}</span>
    }
}

export const renderBookingNumber = (
    bookingNumberPrefix: string,
    bookingNumber: number,
    bookingType?: Extract<BookingType, string> | string,
    className?: string
) => {
    const color = () => {
        switch (bookingType) {
            case "Booking":
                return blue[5]
            case "Waitlist":
                return yellow[5]
            case "PlaceReservation":
                return red[5]

            default:
                return blue[5]
        }
    }

    return (
        <Badge
            color={color()}
            text={
                <span
                    className={classnames(className, css.bookingNumber)}
                >{`${bookingNumberPrefix}-${bookingNumber}`}</span>
            }
        />
    )
}

export const renderDetailsButton = (detailsLink: string, id: string, record: unknown, tableData: unknown) => {
    return (
        <Button type='link' size='small'>
            <Link
                to={{
                    pathname: detailsLink.replace(":id", id),

                    state: { record, tableData },
                }}
            >
                {"i18n.containers.invoice.Invoice.details()"}
            </Link>
        </Button>
    )
}

/* export const renderCourseDetails = (path: string, record: CourseTableType) => (
    <Link
        to={{
            pathname: path,
            state: record,
        }}
    >
        <Button size="small" type="link">
            {i18n.containers.invoice.Invoice.details()}
        </Button>
    </Link>
); */

export const renderSettled = (settledBookingCount: number, bookingCount: number) => {
    return <div>{`${settledBookingCount} ${"i18n.containers.invoice.Invoice.of()"} ${bookingCount}`}</div>
}

export const renderDateFormatted = (value: ISODateString, format?: MomentFormatSpecification) => {
    if (typeof format === "string") {
        return <div className={classnames(css.text__standard)}>{moment(value).format(format)}</div>
    }

    return <div className={classnames(css.text__standard)}>{moment(value).format("DD.MM.YYYY")}</div>
}

export const renderDateAsWeekdayTag = ({
    value,
    style,
    color,
}: {
    value: ISODateString
    style?: React.CSSProperties
    color?: string
}) => {
    const actualColor = color ?? blue[3]
    const weekday = moment(value).format("dddd")

    return (
        <Tag style={style} color={actualColor}>
            {weekday}
        </Tag>
    )
}

export const renderStartEndTime = ({
    startTime,
    endTime,
    format,
}: {
    startTime: ISODateString
    endTime: ISODateString
    format?: MomentFormatSpecification
}) => {
    const actualFormat = typeof format === "string" ? format : "HH:mm"

    return <div>{`${moment(startTime).format(actualFormat)} - ${moment(endTime).format(actualFormat)} Uhr`}</div>
}

export const renderDuration = ({
    startTime,
    endTime,
    format,
}: {
    startTime: ISODateString
    endTime: ISODateString
    format?: MomentFormatSpecification
}) => {
    // const actualFormat = typeof format === "string" ? format : "HH:mm"

    const durationInMinutes = moment(endTime).diff(moment(startTime), "minutes")

    return <div>{`${durationInMinutes}min`}</div>
}

export const renderEntranceFee = (entranceFee: number | null | undefined, mediaSize: MediaSize) => {
    const { media } = mediaSize
    const containerStyle: React.CSSProperties = {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        whiteSpace: "nowrap",
        cursor: "pointer",
    }

    if (entranceFee === undefined) {
        return "-"
    } else if (entranceFee === null) {
        return (
            <Tooltip
                trigger={["click"]}
                placement={media !== "sm" ? "topRight" : "top"}
                autoAdjustOverflow={false}
                title='Der Eintrittspreis für das Bad ist im Kurspreis bereits enthalten.'
            >
                <div style={containerStyle}>
                    <Badge color='green' />
                    {/* {media !== "sm" && <Badge color='green' />} */}
                    inkl.
                    <InfoCircleTwoTone twoToneColor={blue[5]} style={{ marginLeft: 5 }} />
                </div>
            </Tooltip>
        )
    } else if (entranceFee === 0) {
        return (
            <Tooltip
                trigger={["click"]}
                placement={media !== "sm" ? "topRight" : "top"}
                autoAdjustOverflow={false}
                title={`Der Eintrittspreis für das Bad ist im Kurspreis nicht enthalten.`}
            >
                <div style={containerStyle}>
                    <Badge color='red' />
                    {/* {media !== "sm" && <Badge color='red' />} */}
                    <span style={{ whiteSpace: "nowrap" }}>nicht inkl.</span>

                    <InfoCircleTwoTone twoToneColor={blue[5]} style={{ marginLeft: 5 }} />
                </div>
            </Tooltip>
        )
    }

    const value = entranceFee.toFixed(2).toString().replace(".", ",")

    return (
        <Tooltip
            trigger={["click"]}
            placement={media !== "sm" ? "topRight" : "top"}
            autoAdjustOverflow={false}
            title={`Der Eintrittspreis für das Bad von ${entranceFee.toFixed(
                2
            )} € pro Kurseinheit ist nicht im Kurspreis enthalten.`}
        >
            <div style={containerStyle}>
                <Badge color='red' />
                {/* {media !== "sm" && <Badge color='red' />} */}
                {media !== "sm" && <span>zzgl.</span>}

                <span style={{ whiteSpace: "nowrap", marginLeft: 3 }}>{` ${value} €`}</span>

                <InfoCircleTwoTone twoToneColor={blue[5]} style={{ marginLeft: 5 }} />
            </div>
        </Tooltip>
    )
}
