import { Alert, Button, Card, Col, Row, Space, Tooltip } from "antd"
import React, { FC, memo, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {
    Attendee,
    Booker,
    Course,
    GetUpdatedBookerQuery,
    NewBookingInput,
    useCreateBookingMutation,
    useGetUpdatedBookerQuery,
    GetUpdatedBookerDocument,
    PaymentInterval,
    SettingEndUserAppQuery,
    useSettingEndUserAppQuery,
    SettingEndUserApp,
    OptionalInputFieldsInput,
} from "../../../generated/graphql"
import { margin } from "../../../styles/layout"
import { useMediaSize } from "../../../layouts/Main/GetWindowDimensions/GetWindowDimensions"
import SummaryRight from "../Steps/StepSummary/SummaryRight"
import SummaryLeft from "../Steps/StepSummary/SummaryLeft"
import { URL_LOGIN, URL_RESULTS } from "../../../models/url"
import css from "./BookingSummary.module.css"
import { blue, red } from "@ant-design/colors"
import ResultScreen from "../../../views/ResultScreens/ResultScreen"
import RequiredAlert from "./RequiredAlert"

export type ModeType = "PlaceReservation" | "createBooking"
export type NewBookingInputKeys = keyof NewBookingInput

type BookingSummaryProps = {}

const BookingSummary: FC<BookingSummaryProps> = () => {
    // const params = useParams<{ courseId?: string }>()
    const mediaSize = useMediaSize()
    const { media } = mediaSize
    const isLoggedIn = !!localStorage.getItem("loggedInUser")
    const history = useHistory()
    const [course, setCourse] = useState<Course>()
    const [booker, setBooker] = useState<Booker>(JSON.parse(localStorage.getItem("booker") as string))
    const [attendeeId, setAttendeeId] = useState<string>()
    const [attendee, setAttendee] = useState<Attendee>()
    const [mode, setMode] = useState<ModeType>("createBooking")
    // const [bookingData, setBookingData] = useState<NewBookingInput>()
    const [bookingData, setBookingData] = useState<NewBookingInput>(
        JSON.parse(localStorage.getItem("bookingData") as string)
    )
    const [attendeeAgeDoesFitCourse, setAttendeeAgeDoesFitCourse] = useState(false)
    const [validSepaDebitDataValidIfSelected, setValidSepaDebitDataValidIfSelected] = useState(true)
    const [creatingAttendee, setCreatingAttendee] = useState(false)
    const [showAlert, setShowAlert] = useState(false)

    const bookerHasAttendees = Boolean(booker.attendees?.length && booker.attendees.length > 0)
    const attendeeIsSelected = Boolean(attendeeId !== undefined)

    // HANDLE PARTICIPATION REQUIREMENTS -------------------------------------------------------------------------
    const [checkedOptions, setCheckedOptions] = useState<{ [id: string]: Array<string> }>({})
    const [requermentGroupsMet, setRequermentGroupsMet] = useState<{ [id: string]: boolean }>({})
    const allReirementGroupsMet = Object.values(requermentGroupsMet).every((value) => value === true)
    // HANDLE PARTICIPATION REQUIREMENTS -------------------------------------------------------------------------

    // GETTING APP SETTINGS --------------------------------------------------------------------------------------
    const [appSettings, setAppSettings] = useState<SettingEndUserAppQuery["settingEndUserAppPublic"]>()

    const { data: appSettingsData, loading: loadingAppSettings, error: errorAppSettings } = useSettingEndUserAppQuery()

    useEffect(() => {
        if (appSettingsData) {
            setAppSettings(appSettingsData.settingEndUserAppPublic)
        }
    }, [appSettingsData, loadingAppSettings, errorAppSettings])

    // GET OPTIONAL INPUT FIELDS ---------------------------------------------------------------------------------
    const [optionalInputFields, setOptionalInputFields] = useState<SettingEndUserApp["optionalInputFields"]>([
        { name: "test" },
    ])

    useEffect(() => {
        const updatedFields: Array<OptionalInputFieldsInput> = []
        appSettings?.optionalInputFields?.forEach((field: OptionalInputFieldsInput) => {
            if (field.enabeld) {
                updatedFields.push(field)
            }

            setOptionalInputFields(updatedFields)
        })
    }, [appSettings])

    // -----------------------------------------------------------------------------------------------------------

    const [createBooking] = useCreateBookingMutation({
        refetchQueries: [
            // { query: GetCoursesPublicDocument }, // DEBUG THIS COULD BE A PROBLEM, BECAUSE THER IS NO LIMIT ON REFETCH
            // { query: GetCoursesPublicDocument, variables: { limit: 10 } },
            "GetUpdatedBooker",
            { query: GetUpdatedBookerDocument, variables: { id: booker?.id ?? "" } },
        ],
    })

    const {
        data: getBookerData,
        loading: loadingBooker,
        error: getBookerError,
        refetch: refetchBooker,
    } = useGetUpdatedBookerQuery({ variables: { id: booker?.id ?? "" } })

    useEffect(() => {
        if (getBookerData) {
            const fetchedBookerData: GetUpdatedBookerQuery["booker"] | undefined = getBookerData?.booker
            const fetchedBookerAsBooker: Booker = fetchedBookerData as Booker
            setBooker(fetchedBookerAsBooker)
            localStorage.setItem("booker", JSON.stringify(fetchedBookerAsBooker))
        }
    }, [getBookerData, getBookerError, loadingBooker])

    useEffect(() => {
        if (bookingData?.attendeeId) setAttendeeId(bookingData.attendeeId)
    }, [bookingData])

    if (getBookerError) {
        console.log("getBookerError:::", getBookerError)
        const rawErrorMessage = JSON.stringify(getBookerError.graphQLErrors[0].message, null, 4)
        if (rawErrorMessage.includes("Access token not valid")) {
            history.push(URL_RESULTS.replace(":resultType", "loggedOut"))
        } else if (rawErrorMessage.includes("Bad Request booker")) {
            history.push(URL_RESULTS.replace(":resultType", "loggedOut"))
        } else {
            if (rawErrorMessage) {
                history.push(URL_RESULTS.replace(":resultType", "unknown"))
            } else {
                history.push(URL_RESULTS.replace(":resultType", "unknown"))
            }
            console.log(getBookerError)
            // throw new Error(error)
        }
    }

    const submitBooking = async () => {
        console.log("submitBooking:::")
        // const isLoggedIn = !!localStorage.getItem("loggedInUser")
        console.log("isLoggedIn:::", isLoggedIn)

        if (disabledText().length) {
            setShowAlert(true)
            return
        }

        try {
            if (bookingData) {
                const { data: booking } = await createBooking({
                    variables: { newBookingData: bookingData, sendMail: true },
                })
                // DEBUG, AFTER THIS MUTATION SOMETHING BAD HAPPENS
                if (booking) {
                    // refetchBooker({ id: booker?.id ?? "" })
                    // successModal(booking.createBooking.bookingType)
                    const bookingType = booking.createBooking.bookingType
                    if (bookingType === "Waitlist") {
                        history.push(URL_RESULTS.replace(":resultType", "successfullyOnWaitingList"))
                    } else {
                        history.push(URL_RESULTS.replace(":resultType", "succesfullyBooked"))
                    }
                    console.log(`booking is done ==> ${bookingType}`)
                }
                // console.log(`booking is done`)
                // loading(false)
            }
        } catch (error: any) {
            // loading(false)
            console.log(error)
            const rawErrorMessage = JSON.stringify(error.graphQLErrors[0].message, null, 4)
            if (rawErrorMessage.includes("Already enrolled")) {
                history.push(URL_RESULTS.replace(":resultType", "alreadyEnrolled"))
            } else if (rawErrorMessage.includes("Cannot read property 'courseId' of undefined")) {
                history.push(URL_RESULTS.replace(":resultType", "courseNotFound"))
            } else if (rawErrorMessage.includes("Access token not valid")) {
                history.push(URL_RESULTS.replace(":resultType", "loggedOut"))
            } else {
                if (rawErrorMessage) {
                    return <ResultScreen resultType='unknown' errorMsg={`[1015]: ${rawErrorMessage}`} />
                    // errorModal(`Ein Fehler ist aufgetreten [1015]: ${rawErrorMessage}`)
                } else {
                    return <ResultScreen resultType='unknown' errorMsg={`Fehler: [1015]`} />
                    // errorModal("Ein unbekannter Fehler ist aufgetreten. [1015]")
                }
                // console.log(error)
                // throw new Error(error)
            }
        } finally {
            console.log("submitBooking finally")
            // onBookingDone()
        }
    }

    type KeyOfNewBookingInput = keyof NewBookingInput
    type ValueOf<T> = T[keyof T]
    type ValueOfNewBookingInput = ValueOf<NewBookingInput>

    const updateBookingData = (key: KeyOfNewBookingInput, value: ValueOfNewBookingInput) => {
        if (value !== undefined && value !== null) {
            const updatedBookingData = { ...bookingData }

            // @ts-ignore
            updatedBookingData[key] = value

            localStorage.setItem("bookingData", JSON.stringify(updatedBookingData))

            setBookingData(updatedBookingData)
        }
    }

    useEffect(() => {
        const bookingData = JSON.parse(localStorage.getItem("bookingData") as string)

        // THIS ISN'T A GOOD WAY TO DO THIS!
        const coursePriceCorrection = () => {
            let coursePrice = course?.coursePrice[0]
            const grossPrice = coursePrice?.grossPrice ?? 0
            const netPrice = coursePrice?.netPrice ?? grossPrice
            // const vat = coursePrice?.vat ?? 0
            const lessonCount = course?.lessonCount ?? 0

            if (coursePrice && course?.paymentInterval === PaymentInterval.PerLesson) {
                coursePrice = {
                    ...coursePrice,
                    grossPrice: grossPrice * lessonCount,
                    netPrice: netPrice * lessonCount,
                    // vat: vat,
                }
            }

            return coursePrice
        }

        if (course && booker && attendeeId && attendee && course.coursePrice) {
            const updatedBookingData: NewBookingInput = {
                courseId: course.id,
                attendeeId: attendeeId,
                // paymentMethodId: attendee.defaultPaymentMethod.id,
                // paymentMethodId: bookingData.paymentMethodId,
                paymentMethodId: bookingData?.paymentMethodId,
                coursePriceId: course.coursePrice[0].id,
                specialPrice: coursePriceCorrection()?.grossPrice,
                vat: course.coursePrice[0].vat,
                isSpecial: course.coursePrice[0].isSpecial,
                netPrice: coursePriceCorrection()?.netPrice,
                grossPrice: coursePriceCorrection()?.grossPrice,
                notifyUser: true,
                noticeStaff: bookingData?.noticeStaff,
                foundUs: bookingData?.foundUs,
                startDateTime: course.startDateTime,
                noticeBooker: bookingData?.noticeBooker,
                paymentType: course.paymentType,
                monthlySchedule: course.monthlySchedule,
                agbConfirmed: bookingData?.agbConfirmed,
                privacyStatementConfirmed: bookingData?.privacyStatementConfirmed,
                // paymentInterval: course.paymentInterval,
            }

            setBookingData(updatedBookingData)
        }
    }, [attendee, attendeeId, booker, course])

    const attendeesFirstName = booker?.attendees
        ?.filter((attendee) => {
            return attendee.id === bookingData.attendeeId
        })
        .map((attendee) => attendee.firstname)[0]

    const bindingBookingText =
        media === "xs" ? "Buchung verbindlich abschließen" : `Buchung verbindlich für ${attendeesFirstName} abschließen`

    const waitlistText =
        media === "xs" ? "Wartelistenplatz buchen" : `Wartelistenplatz für ${attendeesFirstName} buchen`

    const submitButtonText = mode === "PlaceReservation" ? waitlistText : bindingBookingText
    const submitButtonDisabled =
        showAlert &&
        (bookingData?.paymentMethodId === undefined ||
            bookingData?.attendeeId === undefined ||
            attendeeAgeDoesFitCourse === false ||
            validSepaDebitDataValidIfSelected === false ||
            creatingAttendee === true ||
            allReirementGroupsMet === false ||
            (Boolean(bookingData.agbConfirmed) === false &&
                optionalInputFields?.some((field) => field.name === "agb")) ||
            (Boolean(bookingData.privacyStatementConfirmed) === false &&
                optionalInputFields?.some((field) => field.name === "privacyStatement")))

    const disabledText = () => {
        const disabledText: Array<string> = []

        // if bookerHasAttendees then the only message should be "Bitte wähle einen Teilnehmer."
        if (bookerHasAttendees === false) {
            disabledText.push("Bitte lege zunächst einen Teilnehmer für den Kurs an.")
            return disabledText
        }

        if (bookingData?.paymentMethodId === undefined) {
            disabledText.push("Bitte wähle eine Bezahlmethode.")
        }

        if (bookingData?.attendeeId === undefined) {
            disabledText.push("Bitte wähle einen Teilnehmer.")
        }

        if (attendeeAgeDoesFitCourse === false) {
            disabledText.push("Bitte wähle einen Teilnehmer der zu diesem Kurs passt.")
        }

        if (validSepaDebitDataValidIfSelected === false) {
            disabledText.push("Bitte überprüfe deine SEPA-Lastschrift-Daten.")
        }

        if (creatingAttendee === true) {
            disabledText.push("Bitte bestätige den neu angelegten Teilnehmer.")
        }

        if (allReirementGroupsMet === false) {
            disabledText.push("Bitte bestätige die Teilnahmebedingungen.")
        }
        if (Boolean(bookingData.agbConfirmed) === false && optionalInputFields?.some((field) => field.name === "agb")) {
            disabledText.push("Bitte bestätige die AGBs.")
        }
        if (
            Boolean(bookingData.privacyStatementConfirmed) === false &&
            optionalInputFields?.some((field) => field.name === "privacyStatement")
        ) {
            disabledText.push("Bitte bestätige die Datenschutzerklärung.")
        }

        return disabledText
    }

    useEffect(() => {
        if (course) {
            const newMode: ModeType = course.freePlaces > 0 ? "createBooking" : "PlaceReservation"
            setMode(newMode)
        }
    }, [course])

    useEffect(() => {
        if (!!localStorage.getItem("course")) {
            setCourse(JSON.parse(localStorage.getItem("course") as string))
        }
        // if (!!localStorage.getItem("booker")) {
        //     setBooker(JSON.parse(localStorage.getItem("booker") as string))
        // }
        if (!!localStorage.getItem("attendeeId")) {
            setAttendeeId(JSON.parse(localStorage.getItem("attendeeId") as string))
        }
    }, [])

    useEffect(() => {
        if (booker)
            booker.attendees?.forEach((attendee) => {
                if (attendee.id === attendeeId) {
                    setAttendee(attendee)
                }
            })
    }, [attendeeId, booker])

    useEffect(() => {
        if (isLoggedIn === false) {
            history.push(URL_LOGIN)
            // return (
            //     <>
            //         <h1>Redirect to Login</h1>
            //     </>
            // )
        }
    }, [isLoggedIn, history])

    return (
        <>
            <Card
                bodyStyle={{ padding: 0, borderTop: `3px solid ${blue[1]}` }}
                title={
                    <h1 style={{ marginBottom: 0, paddingBottom: 0 }}>
                        <div className={css.titleSecondary}>
                            <div>Buchungsübersicht</div>
                            {media !== "xs" && (
                                <Tooltip
                                    trigger={["hover", "click" /* , "focus" */]}
                                    title={
                                        disabledText().length
                                            ? disabledText().map((helperText) => {
                                                  return <div>{helperText}</div>
                                              })
                                            : null
                                    }
                                    placement='bottom'
                                    color={red[3]}
                                >
                                    <Button onClick={submitBooking} type='primary' disabled={submitButtonDisabled}>
                                        {`${submitButtonText}`}
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    </h1>
                }
                // cover={media !== "xs" && <RequiredAlert disabledText={disabledText} small />}
                actions={[
                    <div>
                        <Tooltip
                            trigger={["hover", "click", "focus"]}
                            title={
                                disabledText().length
                                    ? disabledText().map((helperText) => {
                                          return <div>{helperText}</div>
                                      })
                                    : null
                            }
                            color={red[3]}
                        >
                            <Button
                                onClick={submitBooking}
                                block
                                type='primary'
                                disabled={submitButtonDisabled}
                                style={{ marginLeft: 10, marginRight: 10, maxWidth: "calc(100% - 20px)" }}
                            >
                                {`${submitButtonText}`}
                            </Button>
                            {media === "xs" && (
                                <Space style={{ fontWeight: 600, marginTop: 10 }} direction='vertical'>
                                    {/* <div style={{ color: red[3] }}>
                                        {disabledText().length
                                            ? disabledText().map((helperText) => {
                                                  return <div key={helperText}>{helperText}</div>
                                              })
                                            : null}
                                    </div> */}
                                    <div>{`Teilnehmer:in ${attendeesFirstName} ist ausgewählt`}</div>
                                </Space>
                            )}
                        </Tooltip>
                    </div>,
                ]}
            >
                <Row style={{ margin: 0 }}>
                    <Col
                        span={media === "xs" ? 24 : 10}
                        style={{
                            background: "#eee",
                            padding: media === "sm" ? margin.xs : media === "xs" ? margin.s : margin.std,
                        }}
                    >
                        {booker === undefined && <div>booker ist da</div>}
                        {bookingData === undefined && <div>bookingData ist nicht da</div>}
                        {booker === undefined ? (
                            <Space
                                align='center'
                                direction='vertical'
                                style={{
                                    width: "100%",
                                }}
                            >
                                <h3>Teilnehmer fehlt</h3>
                            </Space>
                        ) : (
                            <>
                                {booker ? (
                                    <SummaryLeft
                                        booker={booker}
                                        loadingBooker={loadingBooker}
                                        attendee={attendee}
                                        bookingData={bookingData}
                                        updateBookingData={(key, value) => updateBookingData(key, value)}
                                        refetchBooker={() => refetchBooker({ id: booker?.id ?? "" })}
                                        attendeeAgeDoesFitCourse={(e: boolean) => setAttendeeAgeDoesFitCourse(e)}
                                        creatingAttendee={(e: boolean) => setCreatingAttendee(e)}
                                        showAlert={showAlert}
                                    />
                                ) : (
                                    <Alert
                                        type='error'
                                        message='Fehler beim laden der Bucherdaten. Bitte versuchen Sie es später erneut.'
                                    />
                                )}
                            </>
                        )}
                    </Col>
                    <Col
                        span={media === "xs" ? 24 : 14}
                        style={{
                            background: "#fff",
                            padding: media === "sm" ? margin.xs : media === "xs" ? margin.s : margin.std,
                        }}
                    >
                        {booker === undefined || course === undefined || bookingData === undefined ? (
                            <Space
                                align='center'
                                direction='vertical'
                                style={{ width: "100%", display: "flex", justifyItems: "center" }}
                            >
                                {booker === undefined && <div>Bucher fehlt</div>}
                                {course === undefined && <div>Kurs fehlt</div>}
                                {bookingData === undefined && <div>bookingData fehlt</div>}
                                <h3>Es wurde kein Kurs ausgewählt.</h3>
                                <Button type='primary' href='/'>
                                    Zurück zur Kursauswahl
                                </Button>
                            </Space>
                        ) : (
                            <>
                                {booker ? (
                                    <SummaryRight
                                        booker={booker}
                                        course={course}
                                        mode={mode}
                                        updateBookingData={(key, value) => updateBookingData(key, value)}
                                        bookingData={bookingData}
                                        liftSepaDebitData={(isValid: boolean) =>
                                            setValidSepaDebitDataValidIfSelected(isValid)
                                        }
                                        setCheckedOptions={setCheckedOptions}
                                        checkedOptions={checkedOptions}
                                        setRequermentGroupsMet={setRequermentGroupsMet}
                                        requermentGroupsMet={requermentGroupsMet}
                                        showAlert={showAlert}
                                        appSettings={appSettings}
                                    />
                                ) : (
                                    <Alert
                                        type='error'
                                        message='Fehler beim laden der Bucherdaten. Bitte versuchen Sie es später erneut.'
                                    />
                                )}
                            </>
                        )}
                    </Col>
                </Row>
                {showAlert && <RequiredAlert disabledText={disabledText} small />}
            </Card>
        </>
    )
}

export default memo(BookingSummary)
