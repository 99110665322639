import React, { useContext, useState } from "react"
import { Form, Button, Modal, Input, Spin, ModalFuncProps } from "antd"
import "antd/dist/antd.css"
import "./style.css"
import { useMutation } from "@apollo/react-hooks"
import { FORGOT_USER_PASSWORD } from "../../containers/customers/user.graphql"
import { useMediaSize } from "../../layouts/Main/GetWindowDimensions/GetWindowDimensions"
import css from "./ForgotPassword.module.css"
import PanelCard from "../PanelCard/PanelCard"
import { CoursesContext } from "../../containers/CoursesTable/CourseTableContainer"

const ForgotPassword = () => {
    const mediaSize = useMediaSize()
    const { media } = mediaSize
    const { visible, handleCancel, loginForm } = useContext(CoursesContext)
    const [form] = Form.useForm()
    const [email, setEmail] = useState("")
    const [closeManual, setCloseManual] = useState(false)
    const [loading, setLoading] = useState(false)
    const [forgotUserPassword] = useMutation(FORGOT_USER_PASSWORD)

    const modalOkButtonProps: ModalFuncProps = {
        okText: "Schließen",
        onOk: () => {
            setCloseManual(true)
            if (handleCancel) {
                handleCancel()
            }
        },
    }
    const successModal = () => {
        Modal.success({
            title: "Wir haben Ihnen einen Link gesendet, über den Sie Ihr Passwort zurücksetzen können.",
            centered: media !== "xs",
            ...modalOkButtonProps,
        })
    }

    const errorModal = (message: string) => {
        Modal.error({
            title: "Leider konnten wir Ihnen den Link zum Zurücksetzen des Passworts nicht senden.",
            content: message,
            centered: media !== "xs",
            ...modalOkButtonProps,
        })
    }
    const resetPassword = async () => {
        if (email) {
            setLoading(true)
            try {
                const { data: forgetPassword } = await forgotUserPassword({
                    variables: { email },
                })

                setLoading(false)

                if (forgetPassword) {
                    successModal()
                }
            } catch (error: any) {
                setLoading(false)
                if (error instanceof Error) {
                    console.log(error.message)
                    errorModal(error.message)
                    // errorModal(error.graphQLErrors.map((item: { message: any }) => item.message))
                }
            }
        }
    }
    const handleChange = (event: any) => {
        setEmail(event.target.value)
    }
    return (
        <>
            <Modal
                title='Passwort vergessen'
                visible={closeManual ? false : visible}
                footer={
                    <Button type='link' onClick={loginForm} style={{ margin: "auto" }}>
                        Zurück zum Login
                    </Button>
                }
                onOk={handleCancel}
                onCancel={handleCancel}
                width={350}
                centered={media !== "xs"}
            >
                <Spin spinning={loading}>
                    <Form form={form} layout='vertical'>
                        <PanelCard columns={1} gapAfter>
                            <div className={css.text}>
                                Bitte gib die E-Mail-Adresse ein, die mit deinem Account verbunden ist. Dann schicken
                                wir dir einen Link, mit dem du dein Passwort neu setzen kannst.
                            </div>
                        </PanelCard>
                        <Form.Item
                            className={css.input}
                            label='E-mail'
                            name='email'
                            rules={[
                                {
                                    required: true,
                                    type: "email",
                                    message: "The input is not valid E-mail!",
                                },
                            ]}
                        >
                            <Input value={email} placeholder='E-Mail-Adresse' onChange={handleChange} />
                        </Form.Item>
                        <Button type='primary' onClick={resetPassword} block htmlType='submit'>
                            Link zum Zurücksetzen senden
                        </Button>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}

export default ForgotPassword
