import React, { createContext, useContext, useEffect, useState } from "react"
import { Layout, Spin } from "antd"
import { Footer } from "antd/lib/layout/layout"
import css from "./MainLayout.module.css"
import packageJson from "../../../package.json"
import {
    GetCompanyQuery,
    SettingEndUserAppQuery,
    useGetCompanyQuery,
    useSettingEndUserAppQuery,
} from "../../generated/graphql"
import "../../app.css"
import { useMediaSize } from "./GetWindowDimensions/GetWindowDimensions"
import { getMobileOperatingSystem } from "../../styles/layout"
import AuthHeader from "../../containers/auth/AuthHeader"

const { Content } = Layout

// We define our type for the context properties right here
type MainContextProps = {
    userAuthDate: (date: Date) => any
}

// we initialise them without default values, to make that happen, we
// apply the Partial helper type.
export const MainContext = createContext<Partial<MainContextProps>>({})

// Settings Context -------------------------------------------------------------------------------------
export type AppSettings = SettingEndUserAppQuery["settingEndUserAppPublic"]

const defaultSettingEndUserApp: AppSettings = {
    // id: "1",
    colors: {},
    courseTable: {},
    courseDetails: {},
    filters: {},
    textEntries: {},
    optionalInputFields: undefined,
    hideAfterDays: 1,
    passwordlessLoginEnabled: false,
    passwordlessIdCodeLength: 6,
}

export const SettingsContext = createContext<AppSettings>(defaultSettingEndUserApp)

// ----------------------------------------------------------------------------------------------------

const Main = ({ ...props }) => {
    const mediaSize = useMediaSize()
    const { media } = mediaSize
    const oS = getMobileOperatingSystem()
    const [loading] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [authDate, setAuthDate] = useState(new Date())
    const userAuthDate = (date: React.SetStateAction<Date>) => {
        setAuthDate(date)
    }

    // SETTINGS FOR THE END_USER_APP -------------------------------------------------------------------------------------

    const { data: appSettingsData } = useSettingEndUserAppQuery()

    const fetchedAppSettings: AppSettings = appSettingsData?.settingEndUserAppPublic ?? defaultSettingEndUserApp

    const appSettings = useContext(SettingsContext)

    useEffect(() => {
        Object.keys(appSettings).forEach((property) => {
            const typedProperty = property as keyof AppSettings
            if (typedProperty !== "passwordlessLoginEnabled") {
                appSettings[typedProperty] = fetchedAppSettings[typedProperty]
            } else {
                appSettings[typedProperty] = fetchedAppSettings.passwordlessLoginEnabled
            }
        })
    }, [appSettings, fetchedAppSettings])

    // -------------------------------------------------------------------------------------------------------------------

    const footerInfos = (device: "mobile" | "desktop") => {
        const versionText = "KursOrganizer V"
        const footerText = [`${versionText} ${packageJson.version}`]
        const mode = process.env.REACT_APP_ENV

        if (mode !== "production") {
            footerText.push(
                ` | ${process.env.REACT_APP_ENV} | ${companyName} | ${tenantId} | size: ${currentSize} | oS: ${oS}`
            )
        } else {
            footerText.push(device === "desktop" ? ` | ${companyName}` : "")
        }

        // console.log(mode)
        return footerText
    }

    // console.log("footerInfos: ", footerInfos)

    const { data: companyData } = useGetCompanyQuery()
    const companyName: GetCompanyQuery["companyPublic"]["name"] = companyData?.companyPublic.name ?? ""

    const tenantId = process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_APPLICATION_TYPE}` : ""
    const currentSize = process.env.NODE_ENV === "development" ? media : null

    return (
        <MainContext.Provider
            value={{
                userAuthDate: (date: React.SetStateAction<Date>) => userAuthDate(date),
            }}
        >
            <Layout className={css.root}>
                <Content className={css.content} id={"content"}>
                    <AuthHeader
                        /* setAuthDate={(authDate: Date) => setAuthDate(authDate)} */
                        currentLocation={props.currentLocation}
                    />
                    <SettingsContext.Provider value={appSettings}>
                        <Spin spinning={loading}>
                            <div>{props.children}</div>
                        </Spin>
                    </SettingsContext.Provider>
                    <Footer className={css.footer}>
                        <div className={css.versionInfo}>{footerInfos("desktop")}</div>
                        <div className={css.versionInfoMobile}>{footerInfos("mobile")}</div>
                    </Footer>
                </Content>
            </Layout>
        </MainContext.Provider>
    )
}

export default Main
