import { useState, useEffect } from "react"
import { mediaSizes } from "../../../styles/layout"

export type MediaSize = { media: string; value: number }

function getWindowDimensions() {
    if (typeof window !== "undefined") {
        const { innerWidth: width, innerHeight: height } = window
        return { width, height }
    }

    // Return a default value for SSR or other environments where window is undefined.
    return { width: null, height: null }
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    // useEffect(() => {
    //     if (typeof window !== "undefined") {
    //         const handleResize = () => {
    //             setWindowDimensions(getWindowDimensions())
    //         }

    //         window.addEventListener("resize", handleResize)
    //         return () => window.removeEventListener("resize", handleResize)
    //     }
    // }, [])

    useEffect(() => {
        if (typeof window !== "undefined") {
            const handleResize = () => {
                setWindowDimensions(getWindowDimensions())
            }

            window.addEventListener("resize", handleResize)
            window.addEventListener("orientationchange", handleResize)

            return () => {
                window.removeEventListener("resize", handleResize)
                window.removeEventListener("orientationchange", handleResize)
            }
        }
    }, [])

    return windowDimensions
}

export const useMediaSize = () => {
    const windowWidth = useWindowDimensions().width
    const [mediaSize, setMediaSize] = useState({ media: "xs", value: mediaSizes.xs })

    useEffect(() => {
        const updateMediaSize = () => {
            if (windowWidth === null) {
                return
            }

            for (const [media, value] of Object.entries(mediaSizes).sort((a, b) => b[1] - a[1])) {
                if (windowWidth >= value) {
                    setMediaSize({ media, value })
                    return
                }
            }

            // If no other sizes match, set to xs.
            setMediaSize({ media: "xs", value: mediaSizes.xs })
        }
        updateMediaSize()
    }, [windowWidth])

    return mediaSize
}

// ---------------------------------------------------------------------------------------------------------------------------------------

// function getWindowDimensions() {
//     const { innerWidth: width, innerHeight: height } = window
//     return {
//         width,
//         height,
//     }
// }

// export default function useWindowDimensions() {
//     const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

//     useEffect(() => {
//         function handleResize() {
//             setWindowDimensions(getWindowDimensions())
//         }

//         window.addEventListener("resize", handleResize)
//         return () => window.removeEventListener("resize", handleResize)
//     }, [])

//     return windowDimensions
// }

// export type MediaSize = { media: string; value: number }

// export type MediaSize = "xs" | "sm" | "md" | "lg" | "xl"
// export type MediaSize = {mediaSize: "xs" | "sm" | "md" | "lg" | "xl"}

// export const useMediaSize = (): MediaSize => {
//     const windowWidth = useWindowDimensions().width
//     const mediaSize = { media: "sm", value: 768 }

//     Object.entries(mediaSizes).forEach(([key, value]) => {
//         if (windowWidth >= value) {
//             mediaSize.value = value
//             mediaSize.media = key
//         } else if (windowWidth < mediaSizes.xs) {
//             mediaSize.value = windowWidth
//             mediaSize.media = "xs"
//         }
//     })

//     return mediaSize
// }
