import React, { CSSProperties, FC, memo } from "react"
import { GetCompanyQuery, NewBookingInput, OptionalInputFieldsInput } from "../../../../../generated/graphql"
import FormItem from "antd/lib/form/FormItem"
import { Button, Checkbox } from "antd"
import css from "../../StepSummary2.module.css"
import { NewBookingInputKeys } from "../../StepSummary2"
import { blue, red } from "@ant-design/colors"
import classnames from "classnames"
import { LinkOutlined } from "@ant-design/icons"
// import { Link } from "@reach/router"

type AGBInputProps = {
    field: OptionalInputFieldsInput
    bookingData?: NewBookingInput
    updateBookingData: (key: NewBookingInputKeys, value: any) => void
    style?: CSSProperties
    showAlert?: boolean
    companySettings?: GetCompanyQuery["companyPublic"]
}

const AGBInput: FC<AGBInputProps> = ({ field, bookingData, updateBookingData, style, showAlert, companySettings }) => {
    const updateAGBConfirmation = (checked: boolean) => {
        updateBookingData("agbConfirmed", checked)
    }

    const AGBLink = ({ label, icon }: { label?: string; icon?: boolean }) => {
        if (!companySettings?.agb) return null
        return (
            <Button style={{ paddingLeft: 0, fontWeight: 500 }} type='link' size='small'>
                <a href={companySettings.agb} target='_blank' rel='noopener noreferrer'>
                    {label ?? label}
                    {icon && <LinkOutlined style={{ color: blue[5], marginLeft: 3, fontSize: "small" }} />}
                </a>
            </Button>
        )
    }

    const alertAndNotChecked = showAlert && !Boolean(bookingData?.agbConfirmed)

    return (
        <>
            <FormItem style={style} key={field.name}>
                <Checkbox
                    className={alertAndNotChecked ? classnames(css.inputLabel, css.alert) : css.inputLabel}
                    onChange={(e) => updateAGBConfirmation(e.target.checked)}
                    checked={Boolean(bookingData?.agbConfirmed)}
                >
                    <AGBLink label={field.label ?? undefined} />
                </Checkbox>
                <div className={css.inputDescription}>
                    {field.description}
                    <AGBLink icon />
                </div>
            </FormItem>
        </>
    )
}

export default memo(AGBInput)
