import { EditOutlined } from "@ant-design/icons"
import { Button, Empty, message, Table, Tooltip } from "antd"
import ButtonGroup from "antd/lib/button/button-group"
import { Key, TableRowSelection } from "antd/lib/table/interface"
import { isFuture } from "date-fns"
import React, { FC, memo, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { NewBookingInputKeys } from "../../containers/CreateBooking/BookingSummary/BookingSummary"
import {
    Attendee,
    Booker,
    BookerAttendeeInput,
    Maybe,
    useCheckAttendeesAgeAtCourseStartQuery,
    useDeleteAttendeeMutation,
    useGetCourseLessonStartDatesQuery,
} from "../../generated/graphql"
import { URL_RESULTS } from "../../models/url"
import DeleteButton from "../DeleteButton/DeleteButton"
import ResultScreen from "../ResultScreens/ResultScreen"
import { renderBirthdayAsAge } from "../TableColumnRenderers/TableColumnRenderers"
import css from "./SelectAttendeeTable.module.css"

type SelectAttendeeTableProps = {
    booker: Booker
    loadingBooker: boolean
    refetchBooker: () => void
    attendees?: Maybe<Array<Attendee>>
    selectedAttendeeId?: Key
    updateBookingData: (key: NewBookingInputKeys, value: any) => void
    editAttendee: (attendee: BookerAttendeeInput) => void
    setSelectedAttendee: (attendeeId: string) => void
    courseId?: string
    attendeeAgeDoesFitCourse: (arg0: boolean) => void
}

const SelectAttendeeTable: FC<SelectAttendeeTableProps> = ({
    booker,
    loadingBooker,
    refetchBooker,
    selectedAttendeeId,
    updateBookingData,
    editAttendee,
    setSelectedAttendee,
    courseId,
    attendeeAgeDoesFitCourse,
}) => {
    const history = useHistory()
    const [attendees, setAttendees] = useState<Maybe<Array<Attendee>>>([])
    const [deleteAttendeeById, { loading: deletingAttendee }] = useDeleteAttendeeMutation()

    // CHECK ATTENDEES AGE -------------------------------------------------------------------------------------------------------------
    const [selectedAttendeesAgeOk, setSelectedAttendeesAgeOk] = useState<boolean>(true)

    const {
        data: attendeesAgeAtCourseStartData,
        loading: loadingAttendeesAgeAtStart,
        refetch: refetchAttendeesAge,
    } = useCheckAttendeesAgeAtCourseStartQuery({
        skip: !courseId || !selectedAttendeeId,
        variables: { courseId: courseId ?? "", attendeeId: (selectedAttendeeId as string) ?? "" },
    })

    const ageAtCourseStartData = attendeesAgeAtCourseStartData?.checkAttendeesAgeAtCourseStart

    useEffect(() => {
        if (ageAtCourseStartData && ageAtCourseStartData.ageError !== undefined) {
            setSelectedAttendeesAgeOk(ageAtCourseStartData.ageError === null)
            attendeeAgeDoesFitCourse(ageAtCourseStartData)
        }
    }, [ageAtCourseStartData, attendeeAgeDoesFitCourse, selectedAttendeeId])

    useEffect(() => {
        if (!courseId || !selectedAttendeeId || !refetchAttendeesAge) return
        setTimeout(() => {
            // refetchAttendeesAge({ attendeeId: selectedAttendeeId as string, courseId: courseId })
            refetchAttendeesAge()
        }, 10)
    }, [courseId, refetchAttendeesAge, selectedAttendeeId])

    useEffect(() => {
        attendeeAgeDoesFitCourse(selectedAttendeesAgeOk)
    }, [attendeeAgeDoesFitCourse, selectedAttendeesAgeOk])

    // -----------------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (booker.attendees) {
            const uniqueAttendees = <T,>(array: Array<T>): Array<T> =>
                array.length === 0 ? [] : Array.from(new Set(array))

            setAttendees(uniqueAttendees(booker.attendees))
        }
    }, [booker])

    if (!attendees) {
        return <>No attendees found</>
    }

    type ActionButtonProps = {
        onDelete?: () => void
        onEdit?: () => void
        row: BookerAttendeeInput
    }

    const ActionButtons: React.FC<ActionButtonProps> = ({ onDelete, onEdit, row }) => {
        const uniqueValues = <T,>(array: Array<T>): Array<T> => (array.length === 0 ? [] : Array.from(new Set(array)))

        const { firstname } = row
        const uniqueCourseLessonIds = uniqueValues(
            row.bookings?.flatMap(({ courseLessons }) => courseLessons.map(({ id }) => id)) ?? []
        )

        const { data } = useGetCourseLessonStartDatesQuery({
            variables: { ids: uniqueCourseLessonIds },
        })

        const attendeeIsBooker = booker.customerId === row.customer.id

        const blockingCoursesTooltipText = uniqueValues(
            data?.courseLessons
                .filter(({ startDateTime }) => isFuture(new Date(startDateTime)) === false)
                .map(({ course }) => course.prefixedCourseNumber) ?? []
        ).join(", ")

        const editDisabledText = () => {
            if (attendeeIsBooker) {
                return "Der Bucher kann nicht bearbeitet werden. Gehe dazu in deinen Profil"
            }
            if (blockingCoursesTooltipText !== "") {
                return `${firstname} ist in die folgenden Kurse ${blockingCoursesTooltipText} gebucht und kann deshalb nicht bearbeitet werden.`
            }
            return ""
        }

        const deleteDisabledText = () => {
            if (blockingCoursesTooltipText !== "") {
                return `${firstname} ist in die folgenden Kurse ${blockingCoursesTooltipText} gebucht und kann deshalb nicht gelöscht werden.`
            } else if (attendeeIsBooker) {
                return "Bei diesem Teilnehmer handelt es sich um den Bucher, dieser kann nicht gelöscht werden."
            }

            return ""
        }

        return (
            <ButtonGroup>
                <Tooltip title={editDisabledText()} placement='top'>
                    <Button
                        size='small'
                        data-testid='EditAttendeeButton'
                        type='primary'
                        icon={<EditOutlined />}
                        onClick={onEdit}
                        disabled={blockingCoursesTooltipText !== "" || attendeeIsBooker}
                    />
                </Tooltip>
                <DeleteButton
                    size='small'
                    data-testid='DeleteAttendeeButton'
                    loading={data === undefined || deletingAttendee}
                    filled
                    // disableConfirm
                    disabled={blockingCoursesTooltipText !== "" || attendeeIsBooker}
                    confirmText={`${firstname} entgültig löschen?`}
                    onConfirm={onDelete}
                    disabledText={deleteDisabledText()}
                />
            </ButtonGroup>
        )
    }

    const selectNextAttendee = async () => {
        const nextUnselectedAttendee = attendees?.find((attendee) => attendee.id !== selectedAttendeeId)

        if (nextUnselectedAttendee === undefined) {
            updateBookingData("attendeeId", attendees[0].id ?? undefined)
            setSelectedAttendee(attendees[0].id ?? "")
            return
        }

        updateBookingData("attendeeId", nextUnselectedAttendee.id)
        setSelectedAttendee(nextUnselectedAttendee.id)
    }

    const deleteAttendee = async (attendeeId: string) => {
        try {
            await deleteAttendeeById({ variables: { id: attendeeId } })
                .then((res) => {
                    message.success("Teilnehmer wurde gelöscht")
                    refetchBooker()
                })
                .catch((error) => {
                    message.error("Fehler beim Löschen des Teilnehmers")

                    console.log("error: ", error)
                    const rawErrorMessage = JSON.stringify(error.graphQLErrors[0].message, null, 4)
                    if (rawErrorMessage.includes("attendee has bookings")) {
                        history.push(URL_RESULTS.replace(":resultType", "notDeletableHasBookings"))
                    } else {
                        if (rawErrorMessage) {
                            return <ResultScreen resultType='unknown' errorMsg={`[1019]: ${rawErrorMessage}`} />
                        } else {
                            return <ResultScreen resultType='unknown' errorMsg={`Fehler: [1019]`} />
                        }
                    }
                })
        } catch (error) {
            console.log("Error on deleteAttendeeById", error)
        }
    }

    const renderActionButtons = () => (value: unknown, record: BookerAttendeeInput, index: number) =>
        (
            <ActionButtons
                row={record}
                onDelete={async () => {
                    if (record.id === selectedAttendeeId) {
                        await selectNextAttendee()
                    }
                    deleteAttendee(record.id)
                }}
                onEdit={() => editAttendee(record)}
            />
        )

    // ROW SELECTION --------------------------------------------------------------------------------------------

    const selectedRowKeys = selectedAttendeeId === undefined ? [] : [selectedAttendeeId]

    const rowSelectionSettings: TableRowSelection<BookerAttendeeInput> = {
        type: "radio",
        selectedRowKeys,
        onSelect: async (record, selected, selectedRows) => {
            const { id } = selectedRows[0] as BookerAttendeeInput
            setSelectedAttendee(id)
            updateBookingData("attendeeId", id)
            attendeeAgeDoesFitCourse(selectedAttendeesAgeOk)
        },
    }

    if (attendees.length === 0) {
        return <Empty description='Keine Teilnehmer gefunden' imageStyle={{ display: "none" }} />
    }

    // RETURN -------------------------------------------------------------------------------------------------------------

    return (
        <Table
            rowClassName={(record) =>
                record.id === selectedAttendeeId && !selectedAttendeesAgeOk ? css.ageColumnWarning : css.ageColumn
            }
            showHeader={false}
            loading={loadingBooker || loadingAttendeesAgeAtStart}
            columns={[
                {
                    title: "Vorname",
                    dataIndex: "firstname",
                },
                {
                    title: "Nachname",
                    dataIndex: "lastname",
                },
                {
                    title: "Alter",
                    dataIndex: "birthday",
                    render: renderBirthdayAsAge,
                },
                {
                    title: "",
                    dataIndex: "actions",
                    render: renderActionButtons(),
                    align: "right",
                },
            ]}
            dataSource={attendees}
            rowSelection={rowSelectionSettings}
            rowKey='id'
            pagination={false}
            size='small'
            expandable={{
                expandedRowRender: (record) => {
                    if (record.id === selectedAttendeeId && !selectedAttendeesAgeOk) {
                        switch (ageAtCourseStartData.ageError) {
                            case "to young":
                                return (
                                    <p
                                        style={{
                                            color: "red",
                                            background: "rgba(255, 117, 117, 0.2)",
                                            textAlign: "center",
                                        }}
                                    >
                                        Dieser Teilnehmer ist zu jung für diesen Kurs
                                    </p>
                                )
                            case "to old":
                                return (
                                    <p
                                        style={{
                                            color: "red",
                                            background: "rgba(255, 117, 117, 0.2)",
                                            textAlign: "center",
                                        }}
                                    >
                                        Dieser Teilnehmer ist zu alt für diesen Kurs
                                    </p>
                                )
                            default:
                                return null
                        }
                    }
                    return null
                },
                expandedRowClassName: () => css.expandedRow,
                expandedRowKeys: selectedRowKeys,
                rowExpandable: (record) => record.id === selectedAttendeeId && !selectedAttendeesAgeOk,
            }}
            // @ts-ignore
            showExpandColumn={false}
        />
    )
}

export default memo(SelectAttendeeTable)
