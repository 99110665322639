import React, { CSSProperties, FC, memo } from "react"
import {
    BookerInput,
    Gender,
    GetAppSettingsDocument,
    GetUpdatedBookerQuery,
    OptionalInputFieldsInput,
    useUpdateBookerMutation,
} from "../../../../../generated/graphql"
import { omit } from "../../../../../helpers/omit"
import FormItem from "antd/lib/form/FormItem"
import { Checkbox } from "antd"
import { LoadingOutlined } from "@ant-design/icons/lib/icons"
import css from "../../StepSummary2.module.css"

type NewsLetterInputProps = {
    field: OptionalInputFieldsInput
    booker: GetUpdatedBookerQuery["booker"]
    style?: CSSProperties
}

const NewsLetterInput: FC<NewsLetterInputProps> = ({ field, booker, style }) => {
    const { id, customer, gender, firstname, lastname, initialContact, dsgvo } = booker
    const bookersGender = gender as Gender
    const updateBookerData: BookerInput = {
        ...omit(["createDate", "updateDate", "customerId"], booker),
        id,
        customer,
        gender: bookersGender,
        firstname,
        lastname,
        initialContact,
        dsgvo,
    }

    const [updateBookerMutation, { loading }] = useUpdateBookerMutation()
    const updateBooker = async (checked: boolean) => {
        try {
            await updateBookerMutation({
                // variables: { id: booker.id, newsletter: checked, fromEndUserApp: true },
                variables: { updateBookerData: { ...updateBookerData, newsletter: checked }, fromEndUserApp: true },
                refetchQueries: ["GetUpdatedBookerQuery", { query: GetAppSettingsDocument }],
            })
        } catch (error) {
            console.log("error updateBookerMutation:", error)
        }
    }

    return (
        <>
            <FormItem style={style} key={field.name}>
                <Checkbox
                    className={css.inputLabel}
                    onChange={(e) => updateBooker(e.target.checked)}
                    checked={booker.newsletter}
                >
                    {loading ? <LoadingOutlined /> : field.label}
                </Checkbox>
                <div className={css.inputDescription}>{field.description}</div>
            </FormItem>
        </>
    )
}

export default memo(NewsLetterInput)
