import { Select, Spin } from "antd"
import { FormProps } from "antd/es/form/Form"
import React, { FC, useContext, useEffect, useState } from "react"
import {
    CourseListFilters,
    DayFilter,
    FiltersInput,
    GetLocationsPublicQuery,
    SettingEndUserAppQuery,
    useCourseGetInstructorsPublicQuery,
    useGetCourseTypesPublicQuery,
    useGetLocationsPublicQuery,
    useSettingEndUserAppQuery,
} from "../../generated/graphql"
import LayoutForm from "../../views/LayoutForm/LayoutForm"
import { useMediaSize } from "../../layouts/Main/GetWindowDimensions/GetWindowDimensions"
import css from "./CourseFilters.module.css"
import { InCommingAttributes } from "../../types/filters"
import { SettingsContext } from "../../layouts/Main/MainLayout"
import { v4 as uuid } from "uuid"

export const useCourseFilters = () => {}

type CourseFilterProps = {
    otherSettings?: Partial<InCommingAttributes>
    courseFilterValues: (e: CourseListFilters) => void
    incommingFilters: InCommingAttributes
    style?: React.CSSProperties
}

const CourseFilters: FC<CourseFilterProps> = ({ otherSettings, courseFilterValues, incommingFilters, style }) => {
    const mediaSize = useMediaSize()
    const { media } = mediaSize
    const [courseFilters, setCourseFilters] = useState<CourseListFilters>(incommingFilters)

    const { data, loading } = useGetLocationsPublicQuery()
    const locations: GetLocationsPublicQuery["locationsPublic"] = data?.locationsPublic ?? []

    // SETTINGS FOR THE END_USER_APP -------------------------------------------------------------------------------------

    const appSettingsFromContext = useContext(SettingsContext)

    const [appSettings, setAppSettings] =
        useState<SettingEndUserAppQuery["settingEndUserAppPublic"]>(appSettingsFromContext)

    const { data: appSettingsData, loading: loadingAppSettings, error: errorAppSettings } = useSettingEndUserAppQuery()

    useEffect(() => {
        if (appSettingsData) {
            setAppSettings(appSettingsData.settingEndUserAppPublic)
            courseFilterValues(courseFilters)
        }
    }, [appSettingsData, loadingAppSettings, errorAppSettings, courseFilterValues, courseFilters])

    // LAYOUT ---------------------------------------------------------------------------------------------------------

    type FilterContainerCSSProperties = React.CSSProperties & {
        "--column-count": number
    }

    const actualStyle: FilterContainerCSSProperties = {
        "--column-count": 2,
        ...style,
    }

    const layout: FormProps = {
        labelCol: { xs: 6, sm: 6, md: 6, lg: 6 },
        // wrapperCol: { xs: 2, sm: 2, md: 24, lg: 24 },
        labelAlign: "left",
        layout: "horizontal",
        size: media === "xs" ? "small" : "middle",
    }

    // LAYOUT ---------------------------------------------------------------------------------------------------------

    const defaultOption = (filterName?: string) => {
        const key = uuid()
        return (
            <Select.Option key={key} value={undefined}>
                {`Alle ${filterName}`}
            </Select.Option>
        )
    }
    const defaultAll = (filterName?: string) => {
        return "Alle " + filterName
    }

    const CourseTypeFilter = () => {
        const { data, loading } = useGetCourseTypesPublicQuery()
        const courseTypes = data?.courseTypesPublic ?? []
        const sortedCourseTypes = courseTypes.sort((a, b) => {
            return a.name.localeCompare(b.name)
        })

        const onChange = (value: string) => {
            const updatedCourseFilters = { ...courseFilters, courseTypeId: value }
            setCourseFilters(updatedCourseFilters)
        }

        return (
            <Select
                showSearch
                filterOption={(input, option) => {
                    return option?.children
                        ? option.children.toString().toLowerCase().includes(input.toLowerCase())
                        : false
                }}
                onChange={onChange}
                loading={loading}
                value={courseFilters.courseTypeId}
                allowClear
                className={css.input}
                defaultValue={defaultAll("Kursnamen")}
            >
                {defaultOption("Kursnamen")}
                {sortedCourseTypes.map((courseType) => {
                    return (
                        <Select.Option key={courseType.id} value={courseType.id}>
                            {courseType.name}
                        </Select.Option>
                    )
                })}
            </Select>
        )
    }

    const InstructorsFilter = () => {
        const { data, loading } = useCourseGetInstructorsPublicQuery()
        const instructors = data?.instructorsPublic ?? []

        const onChange = (value: string) => {
            const updatedCourseFilters = { ...courseFilters, instructorId: value }
            setCourseFilters(updatedCourseFilters)
        }

        return (
            <Select
                showSearch
                filterOption={(input, option) =>
                    option?.children ? option.children.toString().toLowerCase().includes(input.toLowerCase()) : false
                }
                onChange={onChange}
                loading={loading}
                value={courseFilters.instructorId}
                allowClear
                className={css.input}
                defaultValue={defaultAll("Kursleiter")}
            >
                {defaultOption("Kursleiter")}
                {instructors.map((instructor) => {
                    const fullName = `${instructor.firstname} ${instructor.lastname}`
                    return (
                        <Select.Option key={instructor.id} value={instructor.id}>
                            {fullName}
                        </Select.Option>
                    )
                })}
            </Select>
        )
    }

    const CityFilter = () => {
        const cities: Array<string> = []

        locations.forEach((location) => {
            cities.push(location.city)
        })

        const uniqueCities = Array.from(new Set(cities)).sort((a, b) => a.localeCompare(b))

        const onChange = (value: string) => {
            const updatedCourseFilters = { ...courseFilters, city: value, locationId: null }
            // const updatedCourseFilters = { ...courseFilters, city: value }
            setCourseFilters(updatedCourseFilters)
        }

        return (
            <Select
                showSearch
                onChange={onChange}
                loading={loading}
                value={courseFilters.city}
                allowClear
                className={css.input}
                // placeholder='Alle'
                defaultValue={defaultAll("Orte")}
            >
                {defaultOption("Orte")}
                {uniqueCities.map((city) => {
                    return <Select.Option key={city} value={city}>{`${city}`}</Select.Option>
                })}
            </Select>
        )
    }

    const LocationFilter = () => {
        let cityLocations: GetLocationsPublicQuery["locationsPublic"] = locations
        const onChange = (value: string) => {
            const updatedCourseFilters = { ...courseFilters, locationId: value }
            setCourseFilters(updatedCourseFilters)
        }

        if (courseFilters.city) {
            const locationsOfCity: GetLocationsPublicQuery["locationsPublic"] = []
            locations.forEach((location) => {
                if (courseFilters.city === location.city) {
                    locationsOfCity.push(location)
                }
            })
            cityLocations = locationsOfCity
        }

        return (
            <Select
                showSearch
                filterOption={(input, option) =>
                    option?.children ? option.children.toString().toLowerCase().includes(input.toLowerCase()) : false
                }
                onChange={onChange}
                loading={loading}
                value={courseFilters.locationId}
                allowClear
                className={css.input}
                defaultValue={defaultAll("Kursorte")}
            >
                {defaultOption("Kursorte")}
                {cityLocations.map((location) => {
                    return (
                        <Select.Option key={location.id} value={location.id}>
                            {`${location.name}`}
                        </Select.Option>
                    )
                })}
            </Select>
        )
    }

    const DaysFilter = () => {
        const mapedDaysFilter = Object.values(DayFilter).map((value) => {
            return { [value]: DayFilter[value] }
        })

        const onChange = (value: CourseListFilters["dayFilter"]) => {
            const updatedCourseFilters = { ...courseFilters, dayFilter: value }
            setCourseFilters(updatedCourseFilters)
        }

        return (
            <Select
                showSearch
                onChange={onChange}
                loading={loading}
                value={courseFilters.dayFilter}
                allowClear
                // size={media === "xs" ? "small" : "middle"}
                className={css.input}
                // @ts-ignore
                defaultValue={defaultAll("Wochentage")}
            >
                {defaultOption("Wochentage")}
                {mapedDaysFilter.map((day, index) => {
                    let key = ""
                    let value = ""

                    Object.entries(day).forEach(([oKey, oValue]) => {
                        key = oKey
                        value = oValue
                    })

                    return <Select.Option key={key} value={mapedDaysFilter[index][value]}>{`${value}`}</Select.Option>
                })}
            </Select>
        )
    }

    if (otherSettings?.showFilterMenu === false) {
        return null
    }

    // CUSTOMER FILTERS --------------------------------------------------------------------------------

    const allFilters = [
        { label: "Kursname", component: <CourseTypeFilter /> },
        { label: "Kursleiter", component: <InstructorsFilter /> },
        { label: "Orte", component: <CityFilter /> },
        { label: "Kursorte", component: <LocationFilter /> },
        { label: "Wochentage", component: <DaysFilter /> },
    ]

    const customerFilters: FiltersInput = appSettings.filters

    const renderCustomFilters = () => {
        const filterArray: Array<React.ReactElement> = []

        const courseTypeIdsIsSet = incommingFilters.courseTypeIds?.length && incommingFilters.courseTypeIds?.length > 0

        if (customerFilters && Object.keys(customerFilters).length > 0) {
            Object.keys(customerFilters).forEach((filterName) => {
                switch (filterName) {
                    case "courseType":
                        if (!courseTypeIdsIsSet) {
                            filterArray.push(
                                <div className={css.filter} key={filterName}>
                                    <div className={css.label}>Kursname</div>
                                    <CourseTypeFilter />
                                </div>
                            )
                        }
                        break
                    case "instructor":
                        filterArray.push(
                            <div className={css.filter} key={filterName}>
                                <div className={css.label}>Kursleiter</div>
                                <InstructorsFilter />
                            </div>
                        )
                        break
                    case "city":
                        filterArray.push(
                            <div className={css.filter} key={filterName}>
                                <div className={css.label}>Orte</div>
                                <CityFilter />
                            </div>
                        )
                        break
                    case "location":
                        filterArray.push(
                            <div className={css.filter} key={filterName}>
                                <div className={css.label}>Kursorte</div>
                                <LocationFilter />
                            </div>
                        )
                        break
                    case "weekDay":
                        filterArray.push(
                            <div className={css.filter} key={filterName}>
                                <div className={css.label}>Wochentage</div>
                                <DaysFilter />
                            </div>
                        )
                        break
                }
            })
        } else {
            allFilters.forEach((filter) => {
                filterArray.push(
                    <div className={css.filter} key={filter.label}>
                        <div className={css.label}>{filter.label}</div>
                        {filter.component}
                    </div>
                )
            })
        }

        return filterArray
    }

    // RETURN ------------------------------------------------------------------------------------------

    return (
        <LayoutForm className={css.filterContainer} style={actualStyle} {...layout}>
            {loadingAppSettings ? <Spin /> : renderCustomFilters()}
        </LayoutForm>
    )
}

export default CourseFilters
