import React, { FC, memo } from "react"
import classnames from "classnames"
import css from "./PanelCard.module.css"

type PanelCardProps = {
    children: React.ReactNode
    columns?: number
    style?: React.CSSProperties
    gapAfter?: boolean
    noBorder?: boolean
    noPadding?: boolean
    title?: string | React.ReactNode
    smallTitle?: boolean
    className?: string
}

type PanelCardCSSProperties = React.CSSProperties & {
    "--column-count": number
}

const PanelCard: FC<PanelCardProps> = ({
    children,
    columns = 6,
    style,
    gapAfter = false,
    noBorder = false,
    noPadding = false,
    title,
    smallTitle = false,
    className,
}) => {
    const actualStyle: PanelCardCSSProperties = {
        "--column-count": columns,
        ...style,
    }

    return (
        <div id='PanelCard' className={classnames(css.PanelCard, className)}>
            <div
                className={
                    noBorder
                        ? classnames(css.noBorder, css.noPadding, css.root, gapAfter ? css.gap : "")
                        : noPadding
                        ? classnames(css.noPadding, css.root, gapAfter ? css.gap : css.root)
                        : gapAfter
                        ? classnames(css.gap, css.root, css.padding)
                        : classnames(css.root, css.padding)
                }
                style={actualStyle}
            >
                {title ? (
                    smallTitle ? (
                        <div className={css.smallTitle} style={actualStyle}>
                            {title}
                        </div>
                    ) : (
                        <h3 className={css.title} style={actualStyle}>
                            {title}
                        </h3>
                    )
                ) : null}
                <div
                    className={css.inner}
                    style={{
                        padding: noBorder ? 0 : actualStyle.padding,
                        height: actualStyle.height,
                        ...actualStyle,
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    )
}

export default memo(PanelCard)
