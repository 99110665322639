import React, { FC } from "react"

type PageProps = { style?: React.CSSProperties; content: React.ReactElement }

const Page: FC<PageProps> = ({ style, content }) => {
    type ContactCardCSSProperties = React.CSSProperties & {
        "--column-count": number
    }

    const actualStyle: ContactCardCSSProperties = {
        "--column-count": 1,
        lineBreak: "auto",
        ...style,
    }
    return <div style={actualStyle}>{content}</div>
}

export default Page
