export const renderAmount = (amount: number, short?: boolean) => {
    // TODO connect this to the settings 'currency'
    const digits = short ? (amount % 1 === 0 ? 0 : 2) : 2;
    const formatter = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: digits,
    });

    return formatter.format(amount);
};
