import React, { memo, FC, useState, useEffect, useContext } from "react"
import LoginContainer from "../../views/LoginContainer/LoginContainer"
import css from "./CompletePasswordLessFlowPanel.module.css"
import { URL_BASE, URL_LOGIN, URL_LOGIN_EMAIL } from "../../models/url"
import LayoutForm from "../../views/LayoutForm/LayoutForm"
import { Button, Form, Input, Space, Spin } from "antd"
import { useCompletePasswordlessLoginEndUserAppMutation } from "../../generated/graphql"
import { MainContext, SettingsContext } from "../../layouts/Main/MainLayout"
import { useHistory } from "react-router"
import { margin } from "../../styles/layout"
import { blue, grey } from "@ant-design/colors"
import ErrorPanel, { KnownError } from "../../errors/ErrorPanel"

type CompletePasswordLessFlowPanelProps = {
    setToken: (token: string) => void
    email?: string
}

type Digit = "" | "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9"
type CodeType = {
    [key in `digit${number}`]: Digit | undefined
}

const CompletePasswordLessFlowPanel: FC<CompletePasswordLessFlowPanelProps> = ({ setToken, email }) => {
    const appSettingsFromContext = useContext(SettingsContext)
    const { passwordlessIdCodeLength } = appSettingsFromContext
    const history = useHistory()
    const { userAuthDate } = useContext(MainContext)
    const initializeCodeState = (length: number) => {
        let initialState: CodeType = {}
        for (let i = 1; i <= length; i++) {
            initialState[`digit${i}`] = undefined
        }
        return initialState
    }
    const [code, setCode] = useState<CodeType>(initializeCodeState(passwordlessIdCodeLength))
    const [error, setError] = useState<string | undefined>()

    // completePasswordlessFlowEndUserApp
    const [completePasswordlessFlow, { data, loading: completePasswordlessLoading }] =
        useCompletePasswordlessLoginEndUserAppMutation()

    const completePasswordlessData = data?.completePasswordlessFlowEndUserApp

    useEffect(() => {
        if (completePasswordlessData) {
            const { accessToken, refreshToken, booker, id } = completePasswordlessData
            if (accessToken && refreshToken && booker && id) {
                setToken(accessToken)
                localStorage.setItem("accessToken", accessToken)
                localStorage.setItem("refreshToken", refreshToken)
                localStorage.setItem("booker", JSON.stringify(booker))
                localStorage.setItem("loggedInUser", id)
                if (userAuthDate) {
                    userAuthDate(new Date())
                }
                history.push(URL_BASE)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completePasswordlessData])

    const backToLoginUrl = email ? URL_LOGIN_EMAIL.replace(":email", email) : URL_LOGIN

    const knownErrors: Array<KnownError> = [
        {
            key: "Invalid code",
            title: "Falscher Code",
            msg: "Der eingegebene Code ist leider falsch. Bitte versuche es erneut.",
            actionBtn: (
                <Button type='primary' onClick={() => history.push(backToLoginUrl)}>
                    Zurück zum Login
                </Button>
            ),
        },
    ]

    const handleSubmit = async () => {
        const loginCode = Object.values(code).join("")

        completePasswordlessFlow({ variables: { loginCode } }).catch((error) => {
            console.log("error:", error)
            if (JSON.stringify(error).includes("statusCode: 404")) {
                console.log("error: 404")
                setError("404")
            } else if (JSON.stringify(error).includes("Invalid code")) {
                console.log("console.log('Invalid code')")
                setError("Invalid code")
            }
        })
    }

    useEffect(() => {
        const isCodeFilled = Object.values(code).every((digit) => (digit !== undefined && digit !== "" ? true : false))
        if (isCodeFilled) {
            handleSubmit()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code])

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault()
        const pastedData = event.clipboardData.getData("text")
        const digits = pastedData.split("")

        let updatedCode: CodeType = { ...code }
        for (let i = 0; i < passwordlessIdCodeLength && i < digits.length; i++) {
            updatedCode[`digit${i + 1}`] = digits[i] as Digit
        }

        form.setFieldsValue({
            code: updatedCode,
        })

        setCode(updatedCode)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value } = event.target
        const updatedCode = { ...code, [name]: value }
        setCode(updatedCode)

        // If a value is entered, focus the next input field
        if (value && index < passwordlessIdCodeLength - 1) {
            const nextIndex = index + 1
            const nextInput = document.querySelector(`input[data-index='${nextIndex}']`) as HTMLInputElement
            nextInput && nextInput.focus()
        }
    }

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.select()
    }

    const description1 = email
        ? `Wir haben dir einen Bestätigungscode an ${email} gesendet.`
        : `Wir haben dir einen Bestätigungscode per Email an die eingetragene Adresse gesendet.`
    const description2 = `Bitte gib diesen Code hier ein.`

    const [form] = Form.useForm()
    const renderContent = () => {
        if (completePasswordlessLoading) {
            return (
                <Space direction='vertical' size='large'>
                    <h3 style={{ color: grey[3] }}>Einen Moment bitte</h3>
                    <Spin />
                    <h2 style={{ color: grey[4], marginBottom: margin.std, fontWeight: 600 }}>
                        Wir loggen dich ein...
                    </h2>
                </Space>
            )
        }

        if (error) {
            return <ErrorPanel error={error} knownErrors={knownErrors} />
        }

        const devidableBy = () => {
            if (passwordlessIdCodeLength % 5 === 0) {
                return 5
            } else if (passwordlessIdCodeLength % 4 === 0) {
                return 4
            } else if (passwordlessIdCodeLength % 3 === 0) {
                return 3
            } else if (passwordlessIdCodeLength % 2 === 0) {
                return 2
            } else {
                return 0
            }
        }

        return (
            <div className={css.root}>
                <LayoutForm onSubmitCapture={handleSubmit} columns={1} form={form}>
                    <Space direction='vertical' size='middle'>
                        <p className={css.description}>{description1}</p>
                    </Space>
                    <Form.Item>
                        <Input.Group className={css.inputGroup} style={{ marginBottom: margin.std }}>
                            {/* take codeLenght and generate InputFields */}
                            {Array.from(Array(passwordlessIdCodeLength).keys()).map((index) => {
                                return (
                                    <Form.Item name={["code", `digit${index + 1}`]} noStyle key={index}>
                                        <Input
                                            name={`digit${index + 1}`}
                                            data-index={index}
                                            style={{
                                                width: "32px",
                                                marginLeft:
                                                    index % devidableBy() === 0
                                                        ? 48 / passwordlessIdCodeLength
                                                        : 12 / passwordlessIdCodeLength,
                                                marginRight:
                                                    (index + 1) % devidableBy() === 0
                                                        ? 48 / passwordlessIdCodeLength
                                                        : 12 / passwordlessIdCodeLength,
                                                height: "42px",
                                                borderRadius: "5px",
                                                border: `2px solid ${blue[2]}`,
                                                textAlign: "center",
                                                padding: 0,
                                                fontSize: "20px",
                                            }}
                                            maxLength={1}
                                            onPaste={handlePaste}
                                            onFocus={handleFocus}
                                            onChange={(event) => handleChange(event, index)}
                                        />
                                    </Form.Item>
                                )
                            })}
                        </Input.Group>
                    </Form.Item>
                    <span className={css.description} style={{ fontWeight: 600 }}>
                        {description2}
                    </span>
                </LayoutForm>
            </div>
        )
    }

    return (
        <LoginContainer
            title={"Bestätigungscode"}
            size='xs'
            style={{ paddingBottom: margin.std }}
            content={renderContent()}
            backToUrl={backToLoginUrl}
        />
    )
}

export default memo(CompletePasswordLessFlowPanel)
