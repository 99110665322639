import React, { ReactNode, FC, memo } from "react"
import { ApolloProvider } from "react-apollo"
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks"
import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloClient } from "apollo-client"
import { createHttpLink } from "apollo-link-http"
import { setContext } from "@apollo/client/link/context"
import { APOLLO_SERVER_URL, APPLICATION_TYPE, APPLICATION_KO_ORGANIZATIO_ID } from "../../constants/constants"
import LocalResolvers from "../common/LocalResolver"
// @ts-ignore
import LocalSchema from "../common/LocalSchema.graphql"

type ApolloWrapperProps = {
    children: ReactNode
}

const uri = APOLLO_SERVER_URL

const cache = new InMemoryCache({
    addTypename: false,
    dataIdFromObject: ({ id }) => (id === undefined ? null : id),
})

// inital data
cache.writeData({
    data: {
        LocalState: {
            createCourse: {
                locationId: null,
            },
            bookAttendees: {
                selectedAttendee: {
                    id: null,
                    firstname: null,
                    bookedCourseIds: null,
                },
                selectedCourseId: null,
            },
            bookPaymentMethods: {
                selectedPaymentMethod: {
                    id: null,
                    type: null,
                    bookedCourseIds: null,
                },
                selectedCourseId: null,
            },
        },
    },
})

const httpLink: any = createHttpLink({
    uri,
    fetch,
    headers: {
        "x-application-type": APPLICATION_TYPE,
        "x-ko-organization-id": APPLICATION_KO_ORGANIZATIO_ID,
    },
    credentials: "include",
})

const authLink = setContext((_, { headers }) => {
    const accessToken = localStorage.getItem("accessToken")
    const refreshToken = localStorage.getItem("refreshToken")
    return {
        headers: {
            ...headers,
            "access-token-end-user-app": accessToken ? `${accessToken}` : "",
            "refresh-token-end-user-app": refreshToken ? `${refreshToken}` : "",
        },
    }
})

const link: any = authLink.concat(httpLink)

export const apolloClient = new ApolloClient({
    link,
    cache,
    typeDefs: LocalSchema,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolvers: LocalResolvers as any,
})

const ApolloWrapper: FC<ApolloWrapperProps> = ({ children }) => (
    <ApolloProvider client={apolloClient}>
        <ApolloHooksProvider client={apolloClient}>{children}</ApolloHooksProvider>
    </ApolloProvider>
)

export default memo(ApolloWrapper)
