import React, { FC, memo, ReactNode } from "react"
import { Form } from "antd"
import { FormProps } from "antd/es/form/Form"
// @ts-ignore
import css from "./LayoutForm.module.css"

type LayoutFormProps = {
    children?: ReactNode
    columns?: number
} & FormProps

type LayoutFormCSSProperties = React.CSSProperties & {
    "--column-count": number
}

const LayoutForm: FC<LayoutFormProps> = ({ columns = 6, style, ...props }) => {
    const actualStyle: LayoutFormCSSProperties = {
        "--column-count": columns,
        ...style,
    }

    return <Form layout='vertical' className={css.root} colon={false} {...props} style={actualStyle} />
}

export default memo(LayoutForm)
