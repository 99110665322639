import { isDefined } from "../../helpers/typeScriptHelpers"
import {
    MutationResolvers,
    QueryResolvers,
    LocalState as LocalStateType,
    GetLocalStateDocument,
} from "../../generated/graphql"
import { BOOK_ATTENDEE_LOCAL_STATE } from "../courses/course.graphql"
import { BOOK_PAYMENT_METHOD_LOCAL_STATE } from "../customers/booker.graphql"

const updateLocalLocationId: MutationResolvers["updateLocalLocationId"] = (_, { id }, { cache }) => {
    cache.writeData({
        data: {
            LocalState: {
                createCourse: {
                    locationId: isDefined(id) ? id : null,
                },
            },
        },
    })

    return isDefined(id) ? id : null
}

const bookAttendeeSetSelectedAttendee: MutationResolvers["bookAttendeeSetSelectedAttendee"] = (_, args, { cache }) => {
    const { id = null, firstname = null, bookedCourseIds = null } = args
    const data = cache.readQuery({ query: BOOK_ATTENDEE_LOCAL_STATE })

    if (id !== null && firstname !== null) {
        data.LocalState.bookAttendees.selectedAttendee = {
            id,
            firstname,
            bookedCourseIds,
        }
    } else {
        data.LocalState.bookAttendees.selectedAttendee = null
    }

    cache.writeQuery({ query: BOOK_ATTENDEE_LOCAL_STATE, data })

    return id
}

const bookPaymentMethodSetSelectedPaymentMethod: MutationResolvers["bookPaymentMethodSetSelectedPaymentMethod"] = (
    _,
    args,
    { cache }
) => {
    const { id = null, type = null, bookedCourseIds = null } = args
    const data = cache.readQuery({ query: BOOK_PAYMENT_METHOD_LOCAL_STATE })

    if (id !== null) {
        data.LocalState.bookPaymentMethods.selectedPaymentMethod = {
            id,
            type,
            bookedCourseIds,
        }
    } else {
        data.LocalState.bookPaymentMethods.selectedPaymentMethod = null
    }

    cache.writeQuery({ query: BOOK_PAYMENT_METHOD_LOCAL_STATE, data })

    return id
}

const bookAttendeeSetSelectedCourse: MutationResolvers["bookAttendeeSetSelectedCourse"] = (
    _,
    { id = null },
    { cache }
) => {
    const data = cache.readQuery({ query: BOOK_ATTENDEE_LOCAL_STATE })

    data.LocalState.bookAttendees.selectedCourseId = id

    cache.writeQuery({ query: BOOK_ATTENDEE_LOCAL_STATE, data })

    return id
}

const LocalState: QueryResolvers["LocalState"] = (_, queryArgs, { cache }) => {
    const state: LocalStateType = cache.readQuery({ GetLocalStateDocument })

    return state
}

const resolvers = {
    Query: {
        LocalState,
    },
    Mutation: {
        updateLocalLocationId,
        bookAttendeeSetSelectedAttendee,
        bookPaymentMethodSetSelectedPaymentMethod,
        bookAttendeeSetSelectedCourse,
    },
}

export default resolvers
