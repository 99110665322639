import React, { memo, FC } from "react"
import { Attendee, Maybe } from "../../../../generated/graphql"
import css from "./AttendeeAlert.module.css"

type AttendeeAlertProps = {
    selectedAttendeeId?: string
    attendees?: Maybe<Array<Attendee>>
}

const AttendeeAlert: FC<AttendeeAlertProps> = ({ selectedAttendeeId, attendees }) => {
    const bookerHasAttendees = attendees?.length && attendees.length > 0
    const attendeeIsSelected = Boolean(selectedAttendeeId !== undefined)

    if (!bookerHasAttendees) {
        return <div className={css.root}>{"Bitte lege zunächst einen Teilnehmer für den Kurs an."}</div>
    }

    if (!attendeeIsSelected) {
        return <div className={css.root}>{"No attendee selected"}</div>
    }

    return null
}

export default memo(AttendeeAlert)
