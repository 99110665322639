import * as Yup from "yup"
import { Gender, BookerInput, ThirdPartyUserInput } from "../../generated/graphql"
import { BaseInputValidationSchema } from "../common/BaseInputValidationSchema"
import { BookerAttendeeInputValidationSchema } from "../CreateBooking/Steps/validationSchemas/BookerAttendeeInputValidationSchema"
import { BookerPaymentMethodInputValidationSchema } from "../CreateBooking/Steps/validationSchemas/BookerPaymentMethodInputValidationSchema"
import { v4 } from "uuid"
import moment from "moment"

const { string, object, mixed, boolean, array, date } = Yup

// export const RegistrationPanelValidationSchema = object().shape<BookerInput>({
//     id: string().required(),
//     customer: BaseInputValidationSchema.required(),
//     gender: mixed().oneOf(Object.values(Gender)).required(),
//     firstname: string().required(),
//     lastname: string().required(),
//     street: string().required(),
//     houseNumber: string().required(),
//     postCode: string().required(),
//     city: string().required(),
//     phoneNumber: string().when(["mobileNumber", "email"], {
//         is: (mobile: string | undefined, email: string | undefined) =>
//             isDefined(mobile) === false && isDefined(email) === false,
//         then: string().required(
//             i18n.containers.customers.CustomersCreate.steps.bookerContact.fields.phoneNumber.needContactError()
//         ),
//         otherwise: string().notRequired().nullable(),
//     }),
//     mobileNumber: string().when(["phoneNumber", "email"], {
//         is: (phone: string | undefined, email: string | undefined) =>
//             isDefined(phone) === false && isDefined(email) === false,
//         then: string().required(
//             i18n.containers.customers.CustomersCreate.steps.bookerContact.fields.mobileNumber.needContactError()
//         ),
//         otherwise: string().notRequired().nullable(),
//     }),
//     email: string().email().required(),
//     initialContact: string().notRequired().nullable(),
//     dsgvo: Yup.boolean().required(),
//     newsletter: Yup.boolean().required(),
//     healthInsuranceFund: string().notRequired().nullable(),
//     annotation: string().notRequired().nullable(),
//     paymentMethods: array().of(BookerPaymentMethodInputValidationSchema).required(),
//     attendees: array().of(BookerAttendeeInputValidationSchema).notRequired().nullable(),
// })
//@ts-ignore
export const BookerInputValidationSchema = object().shape<BookerInput>(
    {
        id: string().required(),
        // customer: BaseInputValidationSchema.required(),
        gender: mixed().oneOf(Object.values(Gender)).required(),
        firstname: string()
            .min(3, "Der Vorname muss mindestens 3 Zeichen haben.")
            .required("Vorname ist ein Pflichtfeld"),
        lastname: string()
            .min(3, "Der Nachname muss mindestens 3 Zeichen haben.")
            .required("Nachname ist ein Pflichtfeld"),
        street: string()
            .matches(/([a-zA-Z])+.+\d/, "Hausnummer vergessen?")
            .min(3, "Bitte gib eine Sinnvolle Straße ein")
            .required("Straße, Hausnummer ist ein Pflichtfeld"),
        houseNumber: string().notRequired().nullable(),
        postCode: string()
            .required("PLZ ist ein Pflichtfeld")
            .matches(/^[0-9]+$/, "Nur Zahlen sind erlaubt")
            .min(4, "Bitte gib eine gültige Postleitzahl ein")
            .max(5, "Bitte gib eine gültige Postleitzahl ein"),
        city: string().min(3, "Bitte gib einen sinnvollen Ort ein.").required("Ort ist ein Pflichtfeld"),
        phoneNumber: string()
            .matches(/^[0-9]+$/, "Nur Zahlen sind erlaubt")
            .notRequired()
            .nullable(),
        mobileNumber: string()
            .matches(/^(([0-9]{10,})+|(\+[0-9]{9,})+)$/, "Bitte gib eine gültige Handynummer ein")
            .required("Handy Nr. ist ein Pflichtfeld"),
        email: string().email().required(),
        initialContact: string().notRequired().nullable(),
        dsgvo: boolean().oneOf([true], "Bitte akzeptiere die AGBs.").required(),
        newsletter: boolean().required(),
        healthInsuranceFund: string().notRequired().nullable(),
        annotation: string().notRequired().nullable(),
        paymentMethods: array().of(BookerPaymentMethodInputValidationSchema).required(),
        attendees: array().of(BookerAttendeeInputValidationSchema).notRequired().nullable(),
        birthday: date()
            .required("Geburtsdatum ist ein Pflichtfeld")
            .test("dateIsBeforeToday", "Das Geburtsdatum darf nicht in der Zukunft liegen", async function (value) {
                if (!value) return false

                // if date is in the future
                if (new Date().getTime() >= new Date(value).getTime()) {
                    return true
                }

                console.log("FALSE")

                return false
            })
            .test("underAge", "Du musst als Bucher mindestens 18 Jahre alt sein", async function (value) {
                if (!value) return false

                const birthday = new Date(value).getTime()
                const ageInYears = moment().diff(birthday, "years")

                // console.log("ageInYears", ageInYears)
                // if date is in the future

                if (ageInYears >= 18) {
                    return true
                }

                console.log("FALSE")

                return false
            }),
    },
    [
        ["phoneNumber", "mobileNumber"],
        ["phoneNumber", "email"],
        ["mobileNumber", "email"],
    ]
)

export const bookerInputInitialValues: BookerInput = {
    id: v4(),
    customer: { id: v4() },
    gender: Gender.Female,
    firstname: "",
    lastname: "",
    street: "",
    houseNumber: "",
    postCode: "",
    city: "",
    email: "",
    annotation: undefined,
    // eslint-disable-next-line no-warning-comments
    // birthday: new Date(0).toISOString(), // TODO:  Handle birthday input, this is temporary to prevent error
    birthday: undefined, // TODO:  Handle birthday input, this is temporary to prevent error
    initialContact: new Date().toISOString(),
    healthInsuranceFund: undefined,
    dsgvo: false,
    newsletter: false,
    mobileNumber: "",
    phoneNumber: "",
    attendees: [],
    paymentMethods: [],
}

//@ts-ignore
export const ThirdPartyUserValidationSchema = object().shape<ThirdPartyUserInput>({
    username: string().required(),
    email: string().required(),
    password: Yup.string()
        .min(8, "Passwort muss mindestens 8 Zeichen lang sein")
        .required("Passwort ist ein Pflichtfeld"),
    passwordConfirmation: Yup.string()
        .required("Bitte wiederhole das Passwort")
        .oneOf([Yup.ref("password"), null], "Die Passwörter müssen überinstimmen"),
})

export const thirdPartyUserInitialValues: ThirdPartyUserInput & { passwordConfirmation: string } = {
    username: "",
    password: "",
    passwordConfirmation: "",
    email: "",
}

export type RegistrationPanelValidationType = ThirdPartyUserInput & BookerInput & { passwordConfirmation: string }
// export type RegistrationPanelValidationType = ThirdPartyUserInput &
//     GetUpdatedBookerQuery & { passwordConfirmation: string }

// return fieldValue.replace(/ /g, "").match(/^(([0-9]{11,})+|\+[0-9]+)$/)
// endDateTime: Yup.date()
//         .min(
//             Yup.ref("startDateTime"),
//             i18n.containers.courses.CourseCreate.courseEndDateTime.endTimeBeforeStart(),
//         )
//         .required(
//             i18n.containers.courses.CourseCreate.courseEndDateTime.errorMsg(),
//         ),

//@ts-ignore
export const RegistrationPanelValidationSchema = object().shape<RegistrationPanelValidationType>(
    {
        username: string().min(3, "Bitte gib mindestens 3 Zeichen ein").required("Benutzername ist ein Pflichtfeld"),
        // email: string().required().email("Bitte gib eine gültige E-Mail Adresse ein"),
        password: Yup.string()
            .min(8, "Passwort muss mindestens 8 Zeichen lang sein")
            .required("Passwort ist ein Pflichtfeld"),
        passwordConfirmation: Yup.string()
            .required("Bitte wiederhole das Passwort")
            .oneOf([Yup.ref("password"), null], "Die Passwörter müssen überinstimmen"),
        id: string().required(),
        customer: BaseInputValidationSchema.required(),
        gender: mixed().oneOf(Object.values(Gender)).required("Anrede ist ein Pflichtfeld"),
        firstname: string()
            .min(3, "Der Vorname muss mindestens 3 Zeichen haben.")
            .required("Vorname ist ein Pflichtfeld"),
        lastname: string()
            .min(3, "Der Nachname muss mindestens 3 Zeichen haben.")
            .required("Nachname ist ein Pflichtfeld"),
        street: string()
            .matches(/([a-zA-Z])+.+\d/, "Hausnummer vergessen?")
            .min(3, "Bitte gib eine Sinnvolle Straße ein")
            .required("Straße, Hausnummer ist ein Pflichtfeld"),
        houseNumber: string().notRequired().nullable(),
        // postCode: string().required("PLZ ist ein Pflichtfeld"),
        postCode: string()
            .required("PLZ ist ein Pflichtfeld")
            .matches(/^[0-9]+$/, "Nur Zahlen sind erlaubt")
            .min(4, "Bitte gib eine gültige Postleitzahl ein")
            .max(5, "Bitte gib eine gültige Postleitzahl ein"),
        city: string().min(3, "Bitte gib einen sinnvollen Ort ein.").required("Ort ist ein Pflichtfeld"),
        phoneNumber: string()
            .matches(/^[0-9]+$/, "Nur Zahlen sind erlaubt")
            .notRequired()
            .nullable(),
        mobileNumber: string()
            .matches(/^(([0-9]{10,})+|(\+[0-9]{9,})+)$/, "Bitte gib eine gültige Handynummer ein")
            .required("Handy Nr. ist ein Pflichtfeld"),
        // email: string().email().required(),
        initialContact: string().notRequired().nullable(),
        dsgvo: boolean().oneOf([true], "Bitte akzeptiere die AGBs.").required(),
        newsletter: boolean().required(),
        healthInsuranceFund: string().notRequired().nullable(),
        annotation: string().notRequired().nullable(),
        paymentMethods: array().of(BookerPaymentMethodInputValidationSchema).required(),
        attendees: array().of(BookerAttendeeInputValidationSchema).notRequired().nullable(),
        birthday: date()
            .typeError("Bitte gib ein gültiges Datum ein")
            .required("Geburtsdatum ist ein Pflichtfeld")
            .test("dateIsBeforeToday", "Das Geburtsdatum darf nicht in der Zukunft liegen", async function (value) {
                if (!value) return false

                // if date is in the future
                if (new Date().getTime() >= new Date(value).getTime()) {
                    return true
                }

                // console.log("FALSE")

                return false
            })
            .test("underAge", "Du musst als Bucher mindestens 18 Jahre alt sein", async function (value) {
                if (!value) return false

                const birthday = new Date(value).getTime()
                const ageInYears = moment().diff(birthday, "years")

                // console.log("ageInYears", ageInYears)
                // if date is in the future

                if (ageInYears >= 18) {
                    return true
                }

                console.log("FALSE")

                return false
            }),
    },
    [
        ["phoneNumber", "mobileNumber"],
        ["phoneNumber", "email"],
        ["mobileNumber", "email"],
    ]
)

export const registrationPanelValidationInitialValues: RegistrationPanelValidationType = {
    username: "",
    password: "",
    passwordConfirmation: "",
    email: "",
    id: v4(),
    customer: { id: v4() },
    // gender: Gender.Female,
    gender: Gender.NotDefined,
    firstname: "",
    lastname: "",
    street: "",
    houseNumber: "",
    postCode: "",
    city: "",
    // email: "",
    annotation: undefined,
    // eslint-disable-next-line no-warning-comments
    // birthday: new Date(0).toISOString(), // TODO:  Handle birthday input, this is temporary to prevent error
    birthday: undefined, // TODO:  Handle birthday input, this is temporary to prevent error
    initialContact: new Date().toISOString(),
    healthInsuranceFund: undefined,
    dsgvo: false,
    newsletter: false,
    mobileNumber: "",
    phoneNumber: "",
    attendees: [],
    paymentMethods: [],
}

// export const getInitialValues = (
//     existingBooker?: BookerInput,
// ): BookerInput => {
//     const defaultValues = {
//         id: v4(),
//         customer: {id: v4()},
//         gender: Gender.Female,
//         firstname: "",
//         lastname: "",
//         street: "",
//         houseNumber: "",
//         postCode: "",
//         city: "",
//         email: "",
//         annotation: undefined,
//         // eslint-disable-next-line no-warning-comments
//         birthday: new Date(0).toISOString(), // TODO:  Handle birthday input, this is temporary to prevent error
//         initialContact: new Date().toISOString(),
//         healthInsuranceFund: undefined,
//         dsgvo: false,
//         newsletter: false,
//         mobileNumber: "",
//         phoneNumber: "",
//         attendees: [],
//         paymentMethods: [],
//     };

//     if (existingBooker === undefined) {
//         return defaultValues;
//     }

//     const {customerId: bookerCustomerId, ...booker} = existingBooker;

//     return {
//         ...defaultValues,
//         ...booker,
//         customer: {id: bookerCustomerId},
//         attendees: booker.attendees
//             ?.map(({customerId: attendeeCustomerId, ...attendee}) => ({
//                 ...attendee,
//                 bookings: attendee.bookings?.map((booking) => ({
//                     ...booking,
//                     courseLessons: booking.courseLessons.map(
//                         ({courseId, course, ...lesson}) => lesson,
//                     ),
//                     courseId: booking.courseLessons[0].courseId,
//                 })),
//                 customer: {id: attendeeCustomerId},
//             }))
//             .sort(moveCustomerToTop(bookerCustomerId)),
//     };
// };
