import React, { FC, memo } from "react"
import css from "./BookingPricePreview.module.css"
import {
    GetCourseDetailsByIdPublicQuery,
    MonthlySchedule,
    NewBookingInput,
    PaymentInterval,
    PaymentType,
    useGetAppSettingsInvoiceDueDaysQuery,
} from "../../../../generated/graphql"
import { Radio, Spin } from "antd"
import { margin } from "../../../../styles/layout"
import { NewBookingInputKeys } from "../BookingSummary"
import MonthlyPaymentList from "./MonthlyPaymentList"
import moment from "moment"
import { renderAmount } from "../../../../helpers/renderAmountTwo"
import classnames from "classnames"

export type FetchedCourse = GetCourseDetailsByIdPublicQuery["courseByIdPublic"]

type BookingPricePreviewProps = {
    course: FetchedCourse
    updateBookingData: (key: NewBookingInputKeys, value: any) => void
    bookingData: NewBookingInput
}

const BookingPricePreview: FC<BookingPricePreviewProps> = ({ course, updateBookingData, bookingData }) => {
    const booking = bookingData

    const SwitchMonthlyPayment = () => {
        if (!course) return <>CourseNotFound</>
        const bothOptionsActive = course.allowMonthlyPayment && course.allowUpFrontPayment

        return (
            <>
                <Radio.Group
                    style={{ width: "100%", marginTop: margin.s }}
                    defaultValue={booking.paymentType as PaymentType}
                    // defaultValue={bookingsPaymentType as PaymentType}
                    buttonStyle='solid'
                    onChange={(e) => updateBookingData("paymentType", e.target.value)}
                >
                    {course.allowUpFrontPayment && (
                        <Radio.Button
                            style={{
                                width: bothOptionsActive ? "50%" : "100%",
                                textAlign: "center",
                            }}
                            value={PaymentType.PrePaid}
                        >
                            Vorkasse
                        </Radio.Button>
                    )}
                    {course.allowMonthlyPayment && (
                        <Radio.Button
                            style={{
                                width: bothOptionsActive ? "50%" : "100%",
                                textAlign: "center",
                            }}
                            value={PaymentType.Monthly}
                        >
                            Monatliche Kursgebühr
                        </Radio.Button>
                    )}
                </Radio.Group>
            </>
        )
    }

    const TotalPrice = ({ paymentType }: { paymentType?: PaymentType }) => {
        const coursePrice = course?.coursePrice?.[0]

        let totalGrossPrice = coursePrice?.grossPrice ?? 0
        let totalNetPrice = coursePrice?.netPrice ?? 0
        let totalVat = coursePrice?.vat ?? 0

        const lessonCount = course?.lessonCount ?? 1
        if (course?.paymentInterval === PaymentInterval.PerLesson) {
            const coursePricePerLesson = coursePrice.grossPrice
            const netPricePerLesson = coursePrice.netPrice ?? coursePrice.grossPrice
            const vatPerLesson = coursePrice.vat

            totalGrossPrice = coursePricePerLesson * lessonCount
            totalNetPrice = netPricePerLesson * lessonCount
            totalVat = vatPerLesson * lessonCount
        }

        const vatToShow = totalVat * 100

        const { data, loading } = useGetAppSettingsInvoiceDueDaysQuery()

        const invoiceDueDays = data?.settings?.invoiceDueDays

        const firstCourseLessonDate = course?.lessons[0].startDateTime

        if (!firstCourseLessonDate) {
            return null
        }

        const paymentDue = moment(firstCourseLessonDate).subtract(invoiceDueDays, "days").toISOString()

        if (loading) return <Spin />

        return (
            <div className={css.priceContainer}>
                <div className={css.totalPrice}>
                    <div>{"Gesamtpreis"}</div>
                    <div>{renderAmount({ amount: totalGrossPrice })}</div>
                </div>
                {paymentType === PaymentType.Monthly ? (
                    <div className={css.totalPriceSub}>
                        {`netto ${renderAmount({ amount: totalNetPrice ?? totalGrossPrice })} |
                        ${vatToShow.toFixed(0)} % Mwst.`}
                    </div>
                ) : (
                    <div className={classnames(css.totalPriceSub, css.spaceBetween)}>
                        <div>{`Zahlbar bis ${moment(paymentDue).format("DD.MM.YY")}`}</div>
                        <div>
                            {`netto ${renderAmount({
                                amount: totalNetPrice ?? totalGrossPrice,
                            })} | ${vatToShow.toFixed(0)} % Mwst.`}
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className={css.root}>
            <SwitchMonthlyPayment />
            {booking?.paymentType === PaymentType.Monthly ? (
                <>
                    <MonthlyPaymentList installmentPlan={course.installmentRates} />
                    {booking.monthlySchedule !== MonthlySchedule.Consistent && (
                        <TotalPrice paymentType={booking?.paymentType ?? undefined} />
                    )}
                </>
            ) : (
                <TotalPrice paymentType={booking?.paymentType ?? undefined} />
            )}
        </div>
    )
}

export default memo(BookingPricePreview)
