import React, { useContext, useEffect, useState } from "react"
import { Form, Button, Modal, Input, Space, Spin, Collapse } from "antd"
import "antd/dist/antd.css"
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import "./style.css"
// import { CoursesContext } from "../../containers/courses/Courses"
import { Link } from "react-router-dom"
import { useMutation } from "@apollo/react-hooks"
import { useHistory } from "react-router"
import { MainContext } from "../../layouts/Main/MainLayout"
import { useMediaSize } from "../../layouts/Main/GetWindowDimensions/GetWindowDimensions"
import PanelCard from "../PanelCard/PanelCard"
import { margin } from "../../styles/layout"
import css from "./LoginForm.module.css"
import { USER_LOGIN } from "../../containers/users/user.graphql"
import { CoursesContext } from "../../containers/CoursesTable/CourseTableContainer"

const LoginForm = () => {
    const mediaSize = useMediaSize()
    const { media } = mediaSize
    let history = useHistory()
    const { visible, record, handleCancel, forgotPassword, showError } = useContext(CoursesContext)
    const { userAuthDate } = useContext(MainContext)
    const [userLogin] = useMutation(USER_LOGIN)
    const [fieldsValues, setFieldsValues] = useState({ username: "", password: "" })
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const onReset = () => {
        form.resetFields()
    }

    const cancel = () => {
        onReset()
        if (handleCancel) {
            handleCancel()
        }
    }
    const handleChange = (event: any) => {
        const changedFieldValue = { [event.target.name]: event.target.value }
        setFieldsValues({ ...fieldsValues, ...changedFieldValue })
    }

    const login = async () => {
        setLoading(true)
        const { username, password } = fieldsValues
        try {
            if (username && username !== "" && password && password.length > 7) {
                const { data } = await userLogin({
                    variables: {
                        thirdPartyLoginInput: {
                            username,
                            password,
                        },
                    },
                })
                // setLoading(false)
                const thirdPartyLogin = await data.thirdPartyLogin
                localStorage.setItem("accessToken", thirdPartyLogin.accessToken)
                localStorage.setItem("refreshToken", thirdPartyLogin.refreshToken)
                localStorage.setItem("booker", JSON.stringify(thirdPartyLogin.booker))
                localStorage.setItem("loggedInUser", thirdPartyLogin.id)
                if (userAuthDate) {
                    userAuthDate(new Date())
                }
                cancel()
                history.push({
                    pathname: `/details/${record.id}`,
                    // state: { data: record },
                })
            } // else {
            setLoading(false)
            // }
        } catch (error: any) {
            setLoading(false)
            if (showError) {
                const errorMessage = JSON.stringify(error.graphQLErrors[0].message, null, 4)
                console.log("errorMessage", errorMessage)
                if (error.message === "GraphQL error: Wrong password") {
                    showError(`Das ist leider das falsche Passwort für den Account "${username}"`)
                } else if (error.message === "GraphQL error: User not found") {
                    showError(`Wir haben leider keinen Account mit der E-Mailadresse gefunden.\n"${username}"`)
                } else {
                    showError("Es ist ein unerawarteter Fehler aufgetreten.")
                }
            }
        }
    }

    const [loadingModal, setLoadingModal] = useState(false)
    const [yPosition, setYPosition] = useState(60)
    useEffect(() => {
        const handleClick = function (e: { clientY: number }) {
            const modalHeight = 400
            const modalPosition = e.clientY - modalHeight / 2
            if (visible) {
                setYPosition(modalPosition)
            }
            setLoadingModal(false)
        }

        document.addEventListener("click", handleClick, { once: true })

        return () => {
            document.removeEventListener("click", handleClick)
        }
    }, [visible])

    const { Panel } = Collapse

    // return <h3>Hi</h3>

    return (
        <Modal
            title={`Anmelden`}
            visible={visible && !loadingModal}
            getContainer={document.getElementById("content")!}
            maskClosable={media !== "xs" && media !== "sm"}
            centered={media !== "xs"}
            style={{ top: media === "xs" ? yPosition : 0 }}
            footer={
                <Space align='center'>
                    <span>
                        Neu bei uns?
                        <Link
                            to={{
                                pathname: `/details/${record.id}`,
                                state: { data: record },
                            }}
                        >
                            <span className='mock-block'> Registriere dich jetzt </span>
                        </Link>
                    </span>
                </Space>
            }
            onOk={cancel}
            onCancel={cancel}
            width={350}
        >
            <Spin spinning={loading}>
                <Form form={form} layout={media === "xs" ? "horizontal" : "vertical"} onFinish={() => login()}>
                    <Collapse defaultActiveKey={"1"} style={{ marginBottom: margin.s }}>
                        <Panel
                            showArrow={false}
                            header={<div className={css.headLine}>Hi, Kennen wir uns?</div>}
                            key='1'
                        >
                            <Space direction='vertical' className={css.text}>
                                <div>
                                    Falls du schon einmal einen Kurs bei uns gebucht hast, haben wir dir bereits einen
                                    Account angelegt. Du kannst dir dafür ein neues Passwort zusenden lassen.
                                </div>
                                <Button block type='primary' ghost size='small' onClick={forgotPassword}>
                                    Jetzt Passwort erhalten
                                </Button>
                            </Space>
                        </Panel>
                    </Collapse>
                    <PanelCard columns={1}>
                        <Form.Item
                            label='E-mail'
                            name='username'
                            rules={[
                                { required: true, message: "Bitte gib deine E-Mailadresse hier ein." },
                                { type: "email", message: "Das ist leider keine valide E-Mailadresse." },
                            ]}
                        >
                            <Input
                                value={fieldsValues.username}
                                name='username'
                                type='email'
                                placeholder='E-Mail-Adresse'
                                onChange={handleChange}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Passwort'
                            name='password'
                            rules={[
                                { required: true, message: "Es muss ein Passwort vergeben sein." },
                                {
                                    min: 8,
                                    message: "Das Passwort muss mindestens 8 Zeichen lang sein.",
                                },
                            ]}
                        >
                            <Input.Password
                                value={fieldsValues.password}
                                onChange={handleChange}
                                placeholder='Passwort'
                                name='password'
                                type='password'
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                        <Button type='primary' onClick={login} block htmlType='submit'>
                            Anmelden
                        </Button>
                    </PanelCard>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button type='link' onClick={forgotPassword}>
                            Passwort vergessen?
                        </Button>
                    </div>
                </Form>
            </Spin>
        </Modal>
    )
}

export default LoginForm
