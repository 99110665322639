import { PaymentInterval } from "../generated/graphql"

export const getTotalCoursePrice = ({
    coursePrice,
    lessonCount,
    paymentInterval,
}: {
    coursePrice: number
    lessonCount?: number
    paymentInterval?: PaymentInterval
}) => {
    if (paymentInterval === PaymentInterval.PerLesson && lessonCount !== undefined) {
        return coursePrice * lessonCount
    }

    return coursePrice
}
