import gql from "graphql-tag"

export const USER_LOGIN = gql`
    mutation thirdPartyLogin($thirdPartyLoginInput: ThirdPartyLoginInput!) {
        thirdPartyLogin(thirdPartyLoginInput: $thirdPartyLoginInput) {
            id
            roles
            email
            booker {
                id
                firstname
                lastname
                gender
                createDate
                updateDate
                customerId
                addition
                street
                houseNumber
                postCode
                city
                phoneNumber
                mobileNumber
                email
                birthday
                initialContact
                dsgvo
                newsletter
                paymentMethods {
                    id
                    cash
                    creditCardAccountholder
                    creditcardNumber
                    expirationDate
                    validFrom
                    cvc
                    paypalAccountholder
                    email
                    iban
                    bank
                    bic
                    sepaAccountholder
                    bankTransfer
                    esr
                }
                attendees {
                    id
                    customer {
                        id
                    }
                    gender
                    lastname
                    firstname
                    birthday
                    member
                    defaultPaymentMethod {
                        id
                    }
                    bookings {
                        id
                        courseLessons {
                            id
                        }
                        paymentMethod {
                            id
                        }
                        paymentType
                        payDate
                    }
                }
                healthInsuranceFund
                annotation
                createdBy
            }
            refreshToken
            accessToken
        }
    }
`

export const START_PASSWORDLESS_LOGIN = gql`
    mutation startPasswordlessLogin($endUserPasswordlessLoginInput: EndUserPasswordlessLoginInputType!) {
        passwordlessLoginEndUserApp(endUserPasswordlessLoginInput: $endUserPasswordlessLoginInput)
    }
`

export const COMPLETE_PASSWORDLESS_LOGIN_END_USER_APP = gql`
    mutation completePasswordlessLoginEndUserApp($loginCode: String!) {
        completePasswordlessFlowEndUserApp(loginCode: $loginCode) {
            id
            email
            roles
            hasProfile
            refreshToken
            accessToken
            booker {
                id
                firstname
                lastname
                gender
                createDate
                updateDate
                customerId
                addition
                street
                houseNumber
                postCode
                city
                phoneNumber
                mobileNumber
                email
                birthday
                initialContact
                dsgvo
                newsletter
                paymentMethods {
                    id
                    cash
                    creditCardAccountholder
                    creditcardNumber
                    expirationDate
                    validFrom
                    cvc
                    paypalAccountholder
                    email
                    iban
                    bank
                    bic
                    sepaAccountholder
                    bankTransfer
                    esr
                }
                attendees {
                    id
                    customer {
                        id
                    }
                    gender
                    lastname
                    firstname
                    birthday
                    member
                    defaultPaymentMethod {
                        id
                    }
                    bookings {
                        id
                        courseLessons {
                            id
                        }
                        paymentMethod {
                            id
                        }
                        paymentType
                        payDate
                    }
                }
                healthInsuranceFund
                annotation
                createdBy
            }
            firstName
            lastName
        }
    }
`
