import React, {FC, memo} from "react";
import Spin from "../Spin/Spin";
// @ts-ignore
import css from "./LoadingContent.less";

type LoadingContentProps = {};

const LoadingContent: FC<LoadingContentProps> = () => {
    return (
        <div className={css.root}>
            <Spin />
        </div>
    );
};

export const LoadingLoggedIn: FC<LoadingContentProps> = () => {
    return <LoadingContent />;
};

export default memo(LoadingContent);
