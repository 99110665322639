import React, { FC } from "react"
import { FetchedCourse } from "./BookingPricePreview"
import { Currency, InstallmentRate } from "../../../../generated/graphql"
import moment from "moment"
import css from "./MonthlyPaymentList.module.css"
import { useCurrency } from "../../../../helpers/currencyHelper"
import { renderAmount } from "../../../../helpers/renderAmountTwo"

type InstallmentRates = FetchedCourse["installmentRates"]
export type PartInstallmentRate = Pick<InstallmentRate, "id" | "installmentAmount" | "installmentDue">

type MonthlyPaymentListProps = { installmentPlan: InstallmentRates }

const MonthlyPaymentList: FC<MonthlyPaymentListProps> = ({ installmentPlan }) => {
    return (
        <>
            {installmentPlan?.map((rate, index) => {
                if (!rate) return null

                return (
                    <div key={rate.id}>
                        <RenderMonthlyPaymentEntry rate={rate} index={index} />
                    </div>
                )
            })}
        </>
    )
}

export default MonthlyPaymentList

export const RenderMonthlyPaymentEntry = ({ rate, index }: { rate?: PartInstallmentRate; index: number }) => {
    const currency = useCurrency()
    if (!rate) return null

    return (
        <div key={rate.id} className={css.monthlyPaymentEntry}>
            <div className={css.main}>
                <div className={css.month}>
                    <span className={css.num}>{`${index + 1}.`}</span>
                    <span>{moment(rate.installmentDue).format("MMM YY")}</span>
                </div>
                <div className={css.amount}>
                    {renderAmount({
                        amount: rate.installmentAmount ?? 0,
                        currency: currency.short as Currency,
                    })}
                </div>
            </div>
            <div className={css.sub}>{`Zahlbar bis ${moment(rate.installmentDue).format("DD.MM.YY")}`}</div>
        </div>
    )
}
