import { hot } from "react-hot-loader/root"
import React, { FC, useEffect, useState } from "react"
import "antd/dist/antd.less"
import { MainLayout } from "../layouts"
import { BrowserRouter as Router, Switch, Route, useHistory, useLocation } from "react-router-dom"
import CourseDetails from "../containers/CourseDetails/CourseDetails"
import CourseTableContainer from "../containers/CoursesTable/CourseTableContainer"
import { InCommingAttributes } from "../types/filters"
import { CourseListFilters, DayFilter } from "../generated/graphql"
import { getEnumKeyByEnumValue } from "../helpers/enumHelpers"
import {
    URL_ACCOUNT,
    URL_BASE,
    URL_BOOKING_SUMMARY,
    URL_COURSE_DETAILS,
    URL_FORGOT_PASSWORD,
    URL_LOGIN,
    URL_LOGIN_COMPLETE_PASSWORDLESS,
    URL_LOGIN_COMPLETE_PASSWORDLESS_EMAIL,
    URL_LOGIN_EMAIL,
    URL_REGISTER,
    URL_RESULTS,
} from "../models/url"
import LoginPanel from "../containers/login/LoginPanel"
import RegistrationPanel from "../containers/login/RegistrationPanel"
import BookingSummary from "../containers/CreateBooking/BookingSummary/BookingSummary"
import ForgotPasswordPanel from "../containers/login/ForgotPasswordPanel"
import LogRocket from "logrocket"
import ResultScreen from "../views/ResultScreens/ResultScreen"
import CompletePasswordLessFlowPanel from "../containers/login/CompletePasswordLessFlowPanel"

const koOrganizationId = process.env.REACT_APP_APPLICATION_TYPE

if (process.env.REACT_APP_ENV === "production" && koOrganizationId !== "69bca0e2-3aae-4ca5-9968-ea33f187938d") {
    LogRocket.init("1hnae2/ko-integrated")
}

type AppProps = { parameters?: InCommingAttributes; settings?: any; version: string }

const App: FC<AppProps> = ({ parameters, version }) => {
    const isLoggedIn = !!localStorage.getItem("loggedInUser")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [accessToken, setAccesToken] = useState<string | undefined>()
    // const updatedIncommingFilters: { [key in Filters]?: string } = {}
    const updatedIncommingFilters: CourseListFilters = {}
    const otherSettings: Partial<InCommingAttributes> = {}
    let parentUrl: string = window.location.href

    // console.log("version", version)

    if (parameters) {
        Object.entries(parameters).forEach(([key, value]) => {
            // console.log("typeof key", key, ":", typeof key)
            if (typeof value === "string") {
                if (key === "city") {
                    console.log("city")
                    updatedIncommingFilters.city = value
                } else if (key === "courseTypeId" || key === "coursetypeid") {
                    console.log("courseTypeId::: ", value)
                    updatedIncommingFilters.courseTypeId = value
                } else if (key === "courseTypeIds" || key === "coursetypeids") {
                    // remove spaces and "%" from value
                    value = value.replace(/%20/g, "")
                    // remove spaces from value
                    value = value.replace(/ /g, "")
                    const courseTypeIds = value.split(",")
                    console.log("courseTypeIds::: ", courseTypeIds)
                    if (courseTypeIds.length > 0 && courseTypeIds[0] !== "") {
                        updatedIncommingFilters.courseTypeIds = courseTypeIds
                        // updatedIncommingFilters.courseTypeId = value
                    }
                } else if (key === "locationId") {
                    console.log("locationId::: ", value)
                    updatedIncommingFilters.locationId = value
                } else if (key === "instructorId") {
                    console.log("instructorId::: ", value)
                    updatedIncommingFilters.instructorId = value
                } else if (key === "courseCategory" || key === "coursecategory") {
                    console.log("courseCategory::: ", value)
                    updatedIncommingFilters.courseCategoryId = value
                } else if (key === "dayFilter" || key === "dayfilter") {
                    console.log("dayFilter::: ", value)

                    const day = getEnumKeyByEnumValue(DayFilter, value)

                    if (day !== null) {
                        updatedIncommingFilters.dayFilter = [DayFilter[day]]
                    }
                } else if (key === "parentUrl" || key === "parenturl") {
                    console.log("parentUrl::: ", value)
                    parentUrl = value
                } else if (key === "showFilterMenu" || key === "showfiltermenu") {
                    console.log("showFilterMenu::: ", value)
                    const boolValue = value === "false" ? false : true
                    otherSettings.showFilterMenu = boolValue
                } else {
                    console.log("others::: ", key, ":", value)
                }
            } else if (typeof value === "boolean") {
                switch (key) {
                    case "showOnlyInWebActive":
                        otherSettings.showOnlyInWebActive = typeof value === "boolean" ? value : false
                        break
                    case "showFilterMenu":
                        otherSettings.showFilterMenu = typeof value === "boolean" ? value : false
                        break

                    default:
                        break
                }
            }
        })
    }

    const useResetRouterState = () => {
        const location = useLocation()
        const history = useHistory()

        useEffect(() => {
            history.replace(location.pathname, {})
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
    }

    const [currentLocation, setCurrentLocation] = useState<string>("")

    const Routes: FC = () => {
        useResetRouterState()

        const history = useHistory()

        useEffect(() => {
            // console.log("history.location.pathname: ", history.location.pathname)
            setCurrentLocation(history.location.pathname)
        }, [history.location.pathname])

        return (
            <>
                <Switch>
                    <Route exact path={URL_COURSE_DETAILS} component={CourseDetails} />
                    {/* <Route exact path={URL_LOGIN} component={LoginPanel} /> */}
                    <Route
                        exact
                        path={URL_LOGIN_EMAIL}
                        render={(props) => (
                            <LoginPanel setToken={(token) => setAccesToken(token)} email={props.match.params.email} />
                        )}
                    />
                    <Route exact path={URL_LOGIN}>
                        <LoginPanel setToken={(token) => setAccesToken(token)} />
                    </Route>
                    <Route
                        exact
                        path={URL_LOGIN_COMPLETE_PASSWORDLESS_EMAIL}
                        render={(props) => (
                            <CompletePasswordLessFlowPanel
                                setToken={(token) => setAccesToken(token)}
                                email={props.match.params.email}
                            />
                        )}
                    />
                    <Route exact path={URL_LOGIN_COMPLETE_PASSWORDLESS}>
                        <CompletePasswordLessFlowPanel setToken={(token) => setAccesToken(token)} />
                    </Route>
                    <Route exact path={URL_REGISTER}>
                        <RegistrationPanel useCase='registration' />
                    </Route>
                    <Route exact path={URL_FORGOT_PASSWORD}>
                        <ForgotPasswordPanel parentUrl={parentUrl} />
                    </Route>
                    <Route exact path={URL_ACCOUNT}>
                        {isLoggedIn ? (
                            <RegistrationPanel useCase='account' />
                        ) : (
                            <CourseTableContainer
                                incommingFilters={updatedIncommingFilters}
                                otherSettings={otherSettings}
                            />
                        )}
                    </Route>
                    {/* <Route exact path={URL_BOOKING}>
                        <CreateBooking />
                    </Route> */}
                    <Route exact path={URL_BOOKING_SUMMARY}>
                        <BookingSummary />
                    </Route>
                    <Route exact path={URL_BASE}>
                        <CourseTableContainer
                            incommingFilters={updatedIncommingFilters}
                            otherSettings={otherSettings}
                        />
                    </Route>
                    <Route
                        exact
                        path={URL_RESULTS}
                        render={(props) => <ResultScreen resultType={props.match.params.resultType} />}
                    />
                </Switch>
            </>
        )
    }
    return (
        <Router>
            <MainLayout currentLocation={currentLocation}>
                {process.env.REACT_APP_ENV !== "production" &&
                    Object.entries(updatedIncommingFilters).map(([filter, value]) => {
                        return <div key={filter}>{`${filter}: ${value}`}</div>
                    })}
                <Routes />
            </MainLayout>
        </Router>
    )
}

export default hot(App)
