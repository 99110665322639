import { Gender } from "../generated/graphql"

export const handleGenderText = ({ type, gender }: { type: "attendee" | "booker"; gender?: Gender }) => {
    if (type === "attendee") {
        switch (gender) {
            case Gender.Male:
                return "Teilnehmer"
            case Gender.Female:
                return "Teilnehmerin"
            default:
                return "Teilnehmer:in"
        }
    }
    if (type === "booker") {
        switch (gender) {
            case Gender.Male:
                return "Bucher"
            case Gender.Female:
                return "Bucherin"
            default:
                return "Bucher:in"
        }
    }
}
